import { getPublicConfig } from './client-server/config'

export enum AuthErrorCodes {
	ReusedPassword = 1,
}
export enum AuthErrorMessages {
	ReusedPassword = 'Cannot reuse any of your previous 4 passwords',
}
export enum ResetPasswordOperation {
	/* 
    The operation is appended to the end of the idm url to determine which action is being taken
	Reference URL: https://underarmour.atlassian.net/wiki/spaces/FS/pages/1100088609/UP+-+Password+Reset+API
    */

	/** Step 1: Initiates the request and sends the user an email */
	INITIATE = 'initiate',

	/** Step 2: Validates the reset token automatically appended to the url params from the reset password link contained in email */
	PEEK = 'peek',

	/** Step 3: Resets the user's password in idm */
	RESET = 'reset',
}

interface ResetPasswordData {
	locale: string
	operation: string
	email?: string
	encodedPassword?: string
	idmToken?: string
}

export async function resetPassword(resetOptions: ResetPasswordData) {
	// Call API
	const res = await fetch('/api/auth/v2/resetpass/', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...resetOptions,
		}),
	})

	if (res.status !== 200) {
		const resJSON = await res.json()
		return { success: false, errorCode: resJSON.errorCode }
	}
	return { success: true }
}

/**
 * Check the expiration of the tokens.  If the token is expired, then we'll
 * return true.  Otherwise, we'll return false.
 * @param session
 * @returns
 */
export function isTokenExpired(date: Date): boolean {
	// NOTE: We take the current time and we subtract some buffer from it.  This is
	// is to help ensure that we don't have any issues with clock skew.  We'll use
	// this time to check the expiration of the token.
	const bufferedCurrentTime = new Date(
		Math.floor(new Date().getTime() - (getPublicConfig()?.uacapi.access_token_buffer_ms || 0) / 1000),
	)
	return bufferedCurrentTime >= date
}
