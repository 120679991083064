import { EnrollmentSource, enrollSourceValues, type LoyaltyEmailSubscriberSources } from '~/lib/types/loyalty.interface'

/**
 * Stripe the readonly from the keys by using a record along with `keyof typeof`
 */
export const EnrollmentSourceValues: Record<keyof typeof EnrollmentSource, LoyaltyEmailSubscriberSources> =
	enrollSourceValues

export const EmailSubscriberSourceValues = EnrollmentSourceValues

export const LoyaltyEmailSubscriberSourceValues = EnrollmentSourceValues

/**
 * Maps the email subscriber source value from the provided click source based on
 * whether the customer enrolled into loyalty or not.
 */
export const mapSubscriberSource = (source: EnrollmentSource, isLoyaltyEnrollment: boolean): string | undefined => {
	const values = Object.values(EnrollmentSource)
	if (isLoyaltyEnrollment) {
		return LoyaltyEmailSubscriberSourceValues[values[source]] || undefined
	}
	return EmailSubscriberSourceValues[values[source]] || undefined
}

/**
 * Maps the enroll source value from the provided click source
 */
export const mapEnrollmentSource = (source?: EnrollmentSource): LoyaltyEmailSubscriberSources | undefined =>
	(source !== undefined && EnrollmentSourceValues[Object.values(EnrollmentSource)[source]]) || undefined
