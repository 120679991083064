import BeaconBridge, {
	type UaDataLayerSegment,
	type UaEvent,
	adobeAnalyticsPlugin,
	eventValidationPlugin,
	pageViewFirstPlugin,
	uaDatalayerPlugin,
	tealiumPlugin,
} from '@ua-digital-commerce/beacon-bridge'

import { getPublicConfig } from '~/lib/client-server/config'
import type { AnalyticsAction, DataLayerInterface, FiredActionData } from './types/analytics.interface'
import { createClientLogger } from './logger'
import type { BeaconBridgeConfiguration, Environment } from '@ua-digital-commerce/beacon-bridge/dist/core/src/types'
import { UaConfigPublicImpl } from './client-only/ua-config'

const config = getPublicConfig()
const isBeaconBridgeEnabled = config.metrics.bb_enabled
const logger = createClientLogger('beacon-bridge')

if (isBeaconBridgeEnabled) {
	const adobeAccount = config.metrics.bb_adobe_report_suite_name
	const isChannelManagerEnabled = config.metrics.bb_adobe_channel_manager_enabled
	const { environment } = UaConfigPublicImpl
	let plugins: BeaconBridgeConfiguration['plugins'] = []

	// Check if configuration for dual tagging is needed.
	// Dual tagging refers to running Beacon Bridge and Tealium + Adobe Extensions
	// at the same time so that dual tags (or beacons) are fired at the same time
	// for comparison purposes
	if (config.metrics.bb_dual_tagging_enabled) {
		plugins = [
			eventValidationPlugin(),
			uaDatalayerPlugin({
				dataLayerName: 'uaDatalayerStone',
				environment: environment as Environment | undefined,
			}),
			adobeAnalyticsPlugin({
				adobeAccount,
				adobeTrackingObjectName: 'sStone',
				isChannelManagerEnabled,
			}),
		]
	} else {
		plugins = [
			eventValidationPlugin(),
			pageViewFirstPlugin(),
			uaDatalayerPlugin({
				environment: environment as Environment | undefined,
			}),
			adobeAnalyticsPlugin({
				adobeAccount,
				isChannelManagerEnabled,
			}),
			tealiumPlugin(),
		]
	}

	BeaconBridge.initialize({
		logger,
		plugins,
	})
}

/** Updates data in BeaconBridge */
export function updateData(data: DataLayerInterface) {
	if (isBeaconBridgeEnabled) {
		BeaconBridge.updateData(data as unknown as UaDataLayerSegment)
	}
}

/** Updates next page data in BeaconBridge */
export function updateNextData(data: Partial<DataLayerInterface>) {
	if (isBeaconBridgeEnabled) {
		BeaconBridge.updateNextData(data as unknown as UaDataLayerSegment)
	}
}

/** Fires event in BeaconBridge */
export function fireEvent(action: AnalyticsAction, data: FiredActionData) {
	if (isBeaconBridgeEnabled) {
		BeaconBridge.fireEvent({
			...data,
			name: action,
		} as UaEvent)
	}
}
