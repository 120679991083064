import type {
	AssignmentInformation,
	SlotConfiguration,
	SlotConfigurationCampaignAssignment,
} from '~/graphql/generated/uacapi/type-document-node'
import { ContextType, GetContentSlotByIdDocument } from '~/graphql/generated/uacapi/type-document-node'
import type { NonEmptyArray, Optional } from '~/types/strict-null-helpers'
import { ensureArray, ensureEpochTime } from '~/types/strict-null-helpers'
import getContentAssets from './client-server/getContentAssets'
import { standAloneApolloClient } from './client-server/uacapi-client'
import { deepCopy } from './utils'

export const guestCustomerGroups = [
	'Everyone',
	'Everyone Minus BF',
	'Everyone Minus Employee and VIP',
	'Everyone Minus Employee, ID.me, & VIP',
	'Everyone Minus IDme',
	'Everyone Minus VIP',
	'Unregistered',
	'Unregistered minus BF Countries',
]

export interface BannerFilters {
	/** Array of customer groups used in filtering */
	customerGroups: NonEmptyArray<string>
	/** Date string set by the SFCC content preview tool. Used to filter upcoming banners */
	siteDate?: Optional<string>
}

interface SafeAssignment {
	customerGroups: NonEmptyArray<string>
	schedule?: {
		startDate: Optional<string>
		endDate: Optional<string>
	}
}

interface SafeAssignmentInformation {
	activeCampaignAssignments: SafeAssignment[]
	upcomingCampaignAssignments: SafeAssignment[]
}

/**
 * Gets a safe array of customer groups, meaning if (null | undefined | []) are passed-in, then return ['Everyone'].
 * If passed-in array includes elements, then a clone is returned.
 * @param sessionCustomerGroups
 * @returns NonEmptyArray<string>
 */
export function ensureSafeCustomerGroupsArray(sessionCustomerGroups: Optional<string[]>) {
	let customerGroups = deepCopy(ensureArray(sessionCustomerGroups))
	if (customerGroups.length === 0) {
		customerGroups = guestCustomerGroups
	}
	return customerGroups as NonEmptyArray<string>
}

function ensureSafeCampaignAssignments(assignments: Optional<SlotConfigurationCampaignAssignment[]>) {
	return ensureArray(assignments).map((assignment) => ({
		customerGroups: ensureSafeCustomerGroupsArray(assignment.customerGroups),
		schedule: deepCopy(assignment.schedule ?? {}),
	})) as SafeAssignment[]
}

function ensureSafeAssignmentInformation(assignmentInformation: Optional<AssignmentInformation>) {
	return {
		activeCampaignAssignments: ensureSafeCampaignAssignments(assignmentInformation?.activeCampaignAssignments),
		upcomingCampaignAssignments: ensureSafeCampaignAssignments(assignmentInformation?.upcomingCampaignAssignments),
	} as SafeAssignmentInformation
}

/**
 * Checks if any of the assignments include the `siteDate` in their schedule. If `siteDate` is nullish
 * then returns false.
 * @param assignments
 * @param siteDate
 * @returns boolean
 */
function isSiteDateAllowed(assignments: SafeAssignment[], siteDate: Optional<string>) {
	const date = ensureEpochTime(siteDate, Date.now())
	return (
		assignments.reduce((count, assignment) => {
			const startDate = ensureEpochTime(assignment.schedule?.startDate)
			const endDate = ensureEpochTime(assignment.schedule?.endDate)
			// Check for continuous schedule, which counts as a valid date
			if (!startDate && !endDate) return count + 1
			if (date >= startDate && date <= endDate) return count + 1
			return count
		}, 0) > 0
	)
}

/**
 * Checks if a slot configuration campaign is allowed or not, by taking into account the
 * customer groups that may be in context.
 * @param campaignAssignments
 * @param sessionCustomerGroups
 * @returns boolean
 */
function isCampaignAllowed(campaignAssignments: SafeAssignment[], customerGroups: NonEmptyArray<string>) {
	// Check the customer groups assigned to the campaign and see if they're allowed
	// in the current session
	return (
		campaignAssignments.reduce(
			(count, assignment) => (customerGroups.some((x) => assignment.customerGroups.includes(x)) ? count + 1 : count),
			0,
		) > 0
	)
}

/**
 * Checks if a slot configuration is allowed according to the filters passed in.
 * If a siteDate filter is passed in, then priority is given to upcomingCampaignAssignments.
 * Otherwise, activeCampaignAssignments are checked.
 * @param assignmentInformation
 * @param filters
 * @returns boolean
 */
function isConfigurationAllowed(config: SlotConfiguration, filters: Optional<BannerFilters>) {
	const assignmentInformation = ensureSafeAssignmentInformation(config.assignmentInformation)
	const customerGroups = ensureSafeCustomerGroupsArray(filters?.customerGroups)

	// If the upcoming assignments have an entry and is allowed according to
	// customer group membership, and the siteDate filter is valid, then
	// the configuration is allowed
	// Note: The siteDate filter is only ever set by the SFCC content preview tool
	const upcomingAssignments = assignmentInformation?.upcomingCampaignAssignments
	if (
		isSiteDateAllowed(upcomingAssignments, filters?.siteDate) &&
		isCampaignAllowed(upcomingAssignments, customerGroups)
	) {
		return true
	}

	// If the active assignments have an entry and is allowed according to
	// customer group membership, then the configuration is allowed
	const activeAssignments = assignmentInformation?.activeCampaignAssignments
	if (isSiteDateAllowed(activeAssignments, filters?.siteDate) && isCampaignAllowed(activeAssignments, customerGroups)) {
		return true
	}

	// Check if default schedule exists and if so, is it allowed
	if (config.customerGroups) {
		const defaultSchedule: Array<SafeAssignment> = [
			{
				customerGroups: ensureSafeCustomerGroupsArray(config.customerGroups),
				schedule: {
					startDate: config.assignmentInformation?.startDate?.toString(),
					endDate: config.assignmentInformation?.endDate?.toString(),
				},
			},
		]
		if (isSiteDateAllowed(defaultSchedule, filters?.siteDate) && isCampaignAllowed(defaultSchedule, customerGroups)) {
			return true
		}
	}
	return false
}

export const getContentSlotById = async (
	slotId: string,
	filters?: BannerFilters,
	locale?: string,
): Promise<string[]> => {
	const result = await standAloneApolloClient(locale).query({
		query: GetContentSlotByIdDocument,
		variables: {
			slotId,
			contextType: ContextType.GLOBAL,
		},
	})

	if (!result?.data?.contentSlot) return []

	const filteredIds = ensureArray(result.data.contentSlot?.slotConfigurations).reduce((acc, config) => {
		const isAllowed = isConfigurationAllowed(config, filters)

		if (config?.slotContent?.contentAssetIds && config?.enabled && isAllowed) {
			return acc.concat(...config.slotContent.contentAssetIds)
		}
		return acc
	}, [] as string[])
	return filteredIds.length < 2 ? filteredIds : [filteredIds[0]]
}

/**
 * Fetches header and promo banners based on the provided locale and filters.
 *
 * @param {string} locale - The locale to fetch banners for.
 * @param {BannerFilters} [filters] - Optional filters to apply when fetching banners.
 * @returns {Promise<(HeaderBannerAsset | PromoBannerAsset | undefined)[]>} A promise that resolves to an array containing the fetched header and promo banners. If no banner is found, the corresponding array element will be undefined.
 */
export async function getBanners(locale: string, filters?: BannerFilters) {
	// content slot lookups based on ids
	// each return an array of slot configurations
	const [headerBannerConfigIds, promoBannerConfigIds] = await Promise.all([
		getContentSlotById('header-banner', filters, locale),
		getContentSlotById('global-message-banner', filters, locale),
	])
	// combine both arrays of ids to fetch content assets
	const filteredIds = [...headerBannerConfigIds, ...promoBannerConfigIds]
	const contentAssetsResult = await getContentAssets(filteredIds, locale)
	const assets = ensureArray(contentAssetsResult?.data?.contentAssets)
	// separate the header and the promo specific assets from the single query above
	const headerBanner = assets.filter((asset) => asset?.id && headerBannerConfigIds.includes(asset.id))
	const promoBanner = assets.filter((asset) => asset?.id && promoBannerConfigIds.includes(asset.id))
	// returning the first object in each array until the need to handle multiple
	// slot configurations for a single content slot id
	return [headerBanner[0] || undefined, promoBanner[0] || undefined]
}
