import type { ParsedUrlQuery } from 'querystring'
import type {
	Category,
	CustomRefinementInput,
	SearchRefinementAttribute,
	SearchRefinementAttributeValue,
} from '~/graphql/generated/uacapi/type-document-node'
import { isString } from './utils'

import { ensureArray, ensureString } from 'types/strict-null-helpers'
import type { BreadcrumbTrail } from '~/components/shared/Breadcrumbs'
import { sum } from '~/lib/arrays'
import { createRefinementUrl } from './routes'
import type { IntlShape } from '@formatjs/intl'
import type { SearchProductsQuery } from './products'

function hasNoIndex(
	productListCount: number,
	currentCategoryRefinements: CustomRefinementInput[],
	queryParams: SearchProductsQuery,
) {
	// RULES FOR WHEN TO NOINDEX
	// 1. If the number of refinements is greater than or equal to 2
	// 2. If the refinements include a size search
	// 3. If the refinements include a viewPreference
	// 4. If the number of products is less than or equal to 2

	const hasAnyRefinementWithMultipleValues = !!currentCategoryRefinements.find((x) => x.values?.length > 1)
	const isPageWithSizeSearch = currentCategoryRefinements.some((refinement) => refinement.attributeId === 'c_size')
	const isPageWithViewPreference = queryParams.viewPreference !== undefined && queryParams.viewPreference !== ''

	return productListCount <= 2 || hasAnyRefinementWithMultipleValues || isPageWithSizeSearch || isPageWithViewPreference
}

export function isURLIndexable(url: string): boolean {
	if (url?.includes('demandware.store')) {
		return false
	}
	return true
}

export interface SEOInfo {
	noindex: boolean
	filterInfo: Record<string, string[] | undefined>
}

export interface SEODetails {
	seoTitle: string
	seoDescription: string
}

export function getSeoInfo(
	category: Category,
	productListCount: number,
	currentCategoryRefinements: CustomRefinementInput[],
	queryParams: SearchProductsQuery,
): SEOInfo {
	const filterInfo = currentCategoryRefinements.reduce(
		(filterMap, refinement) => ({ ...filterMap, [refinement.attributeId.replace(/^c_/, '')]: refinement.values }),
		{} as SEOInfo['filterInfo'],
	)

	return {
		noindex: hasNoIndex(productListCount, currentCategoryRefinements, queryParams),
		filterInfo,
	}
}

const HEADING_ATTRS_WITH_SPACES = [
	'c_gender',
	'c_colorgroup',
	'c_enduse',
	'c_silhouette',
	'c_subsilhouette',
	'c_subsubsilhouette',
	'c_division',
]

const HEADING_ATTRS_WITH_DASH = ['c_fittype', 'c_size']

const ALL_HEADING_REFINEMENTS = HEADING_ATTRS_WITH_SPACES.concat(HEADING_ATTRS_WITH_DASH)

export function getHeading(
	pageQuery: SearchProductsQuery,
	refinements: CustomRefinementInput[],
	category?: Category,
): string {
	if (pageQuery.q) {
		return pageQuery.q
	}
	const catName = ensureString(category?.displayName ?? category?.name) || ''
	if (
		refinements.length === 0 ||
		refinements.filter((r) => !!r.values.length).some((r) => r.values.length >= 2 || r.values[0].split('|').length >= 2)
	) {
		return catName
	}

	const refinementsInHeader = ALL_HEADING_REFINEMENTS.map((hr) => ({
		key: hr,
		value: refinements.find((r) => r.attributeId === hr)?.values[0],
	}))
		.filter(({ value }) => !!value)
		.slice(0, 5)

	return refinementsInHeader.reduce((heading, { key, value }) => {
		const includeDash = HEADING_ATTRS_WITH_DASH.includes(key) && heading.indexOf(' - ') < 0
		return heading.concat(includeDash ? ' - ' : ' ').concat(value || '')
	}, catName)
}

const BREADCRUMB_REFINEMENT_ATTRIBUTES = [
	'c_gender',
	'c_colorgroup',
	'c_fittype',
	'c_size',
	'c_enduse',
	'c_silhouette',
	'c_subsilhouette',
	'c_subsubsilhouette', // TODO: find out if this is deprecated
	'c_division',
]
const MAX_BREADCRUMB_VALUES = 15

/**
 * Iterates through `BREADCRUMB_REFINEMENT_ATTRIBUTES` to generate a quantity of breadcrumbs, sourced from the
 * currently selected refinements, than or equal to `MAX_BREADCRUMB_VALUES`.
 */
export function getBreadcrumbs(
	refinements: SearchRefinementAttribute[],
	baseUrl: string,
	ancestors: BreadcrumbTrail,
	locale: string,
	categoryId?: string,
): BreadcrumbTrail {
	const results = BREADCRUMB_REFINEMENT_ATTRIBUTES.reduce<{
		includedRefinements: SearchRefinementAttribute[]
		trail: BreadcrumbTrail
	}>(
		(acc, attributeId) => {
			const isSelected = (value: SearchRefinementAttributeValue) => value.selected && value.hitCount > 0
			const refinement = refinements.find((r) => r.attributeId === attributeId && r.values.some(isSelected))
			if (!refinement) {
				return acc
			}
			const { includedRefinements, trail } = acc
			const previousSelectedTotal = sum(includedRefinements.map((r) => r.values.length))
			if (previousSelectedTotal === MAX_BREADCRUMB_VALUES) {
				return acc
			}
			const currentSelectedRefinementValues = refinement.values
				.filter(isSelected)
				.slice(0, MAX_BREADCRUMB_VALUES - previousSelectedTotal)
			const currentRefinement: SearchRefinementAttribute = {
				...refinement,
				values: currentSelectedRefinementValues,
			}
			const nextRefinements = includedRefinements.concat(currentRefinement)
			const name = currentRefinement.values.map((v) => v.label).join(' + ')
			const url = createRefinementUrl({
				refinements: nextRefinements,
				url: baseUrl,
				categoryId: ensureString(categoryId),
				updatedRefinements: {},
				locale,
			})
			return { includedRefinements: nextRefinements, trail: trail.concat({ name, url }) }
		},
		{ includedRefinements: [], trail: ancestors },
	)

	return results.trail
}

export const categoryTreeToBreadcrumbTrail = (tree: Category['parentCategoryTree']): BreadcrumbTrail =>
	ensureArray(tree?.map(({ id, name, url, hideFromBreadCrumbs }) => ({ id, name, url, hideFromBreadCrumbs })))

export const findMatchingAttributeValue = (
	query: ParsedUrlQuery = {},
	refinementInput: CustomRefinementInput[] = [],
	attributeIds: string[] = [],
): string | undefined => {
	let params: string[] = []

	if (isString(query.prefv1)) {
		params = [query.prefv1]
	} else if (Array.isArray(query.cid)) {
		params = query.cid
			.filter((str) => str.includes('-'))
			.map((str) => str.split('-'))
			.flat()
	} else if (isString(query.cid)) {
		params = [query.cid]
	}

	const valuesToCheck = query.prefn1
		? [(params[0] as string).toLowerCase()]
		: (params as string[]).map((param) => param.toLowerCase())

	const matchingAttribute = refinementInput.find((attribute) => attributeIds.includes(attribute.attributeId))
	const matchingValue = matchingAttribute?.values.find((value) => valuesToCheck.includes(value.toLowerCase()))
	return matchingValue?.toLowerCase()
}

export function getPlpSeoTitleAndDescription({
	filterInfo,
	category,
	intl,
}: {
	filterInfo?: SEOInfo['filterInfo']
	category?: Category
	intl: IntlShape
}): SEODetails {
	/// SEO Title logic
	let seoTitle = ''
	if (filterInfo?.gender?.length) seoTitle += ` ${filterInfo?.gender.join(` ${intl.formatMessage({ id: 'or' })} `)}`
	const pageName = category?.pageTitle || category?.displayName
	if (pageName) seoTitle += ` ${pageName}`

	/// Inner Details
	const specialKeys = ['team', 'silhouette', 'subsilhouette', 'division', 'fittype']
	if (filterInfo && specialKeys.some((categoryKey) => categoryKey in filterInfo)) {
		seoTitle += ` -`
	}

	if (filterInfo?.team) {
		seoTitle += ` ${filterInfo?.team.join(` ${intl.formatMessage({ id: 'or' })} `)}`
	}

	if (filterInfo?.fittype) {
		seoTitle += ` ${filterInfo?.fittype[0]} ${intl.formatMessage({ id: 'fit' })}`
	}

	if (filterInfo?.subsilhouette) {
		seoTitle += ` ${filterInfo?.subsilhouette.join(` ${intl.formatMessage({ id: 'or' })} `)}`
	} else if (filterInfo?.silhouette) {
		seoTitle += ` ${filterInfo?.silhouette.join(` ${intl.formatMessage({ id: 'or' })} `)}`
	} else if (filterInfo?.division) {
		seoTitle += ` ${filterInfo?.division.join(` ${intl.formatMessage({ id: 'or' })} `)}`
	}

	// End Section
	if (filterInfo?.colorgroup) {
		seoTitle += ` ${intl.formatMessage({ id: 'in' })} ${filterInfo?.colorgroup.join(
			` ${intl.formatMessage({ id: 'or' })} `,
		)}`
	}
	if (filterInfo?.enduse) {
		seoTitle += ` ${intl.formatMessage({ id: 'for' })} ${filterInfo?.enduse.join(
			` ${intl.formatMessage({ id: 'or' })} `,
		)}`
	}

	// Seo Description logic
	let seoDescription = intl.formatMessage({ id: 'seo-plp-description-1' })
	if (filterInfo?.gender?.length) {
		seoDescription += ` ${filterInfo?.gender.join(` ${intl.formatMessage({ id: 'or' })} `)}`
	}
	const pageTitle = category?.pageTitle || category?.displayName
	if (pageTitle) {
		seoDescription += ` ${pageTitle}`
	}

	// Inner Details
	const specialKeysDescription = ['team', 'silhouette', 'subsilhouette', 'division', 'fittype']
	if (filterInfo && specialKeysDescription.some((categoryKey) => categoryKey in filterInfo)) {
		seoDescription += ` -`
	}
	if (filterInfo?.team) {
		seoDescription += ` ${filterInfo?.team.join(` ${intl.formatMessage({ id: 'or' })} `)}`
	}
	if (filterInfo?.fittype) {
		seoDescription += ` ${filterInfo?.fittype[0]} ${intl.formatMessage({ id: 'fit' })}`
	}

	if (filterInfo?.subsilhouette) {
		seoDescription += ` ${filterInfo?.subsilhouette.join(` ${intl.formatMessage({ id: 'or' })} `)}`
	} else if (filterInfo?.silhouette) {
		seoDescription += ` ${filterInfo?.silhouette.join(` ${intl.formatMessage({ id: 'or' })} `)}`
	} else if (filterInfo?.division) {
		seoDescription += ` ${filterInfo?.division.join(` ${intl.formatMessage({ id: 'or' })} `)}`
	}

	// End Section
	if (filterInfo?.colorgroup) {
		seoDescription += ` in ${filterInfo?.colorgroup.join(` ${intl.formatMessage({ id: 'or' })} `)}`
	}
	if (filterInfo?.enduse) {
		seoDescription += ` for ${filterInfo?.enduse.join(` ${intl.formatMessage({ id: 'or' })} `)}`
	}
	seoDescription += ` ${intl.formatMessage({ id: 'seo-plp-description-2' })}`
	if (category?.pageName) {
		seoDescription += ` ${category.pageName}`
	} else {
		seoDescription += ` ${pageTitle}`
	}
	seoDescription += ` ${intl.formatMessage({ id: 'seo-plp-description-3' })}`

	return { seoTitle, seoDescription }
}
