import type { ApolloError } from '@apollo/client'
import type { ColorVariation, Maybe } from '~/graphql/generated/uacapi/type-document-node'
import type { AsyncStatus } from '~/types/global'
import type { AnalyticsActionDataBase, ProductDetailProduct } from './analytics.interface'
import type { ClientProductDetail, ClientProductVariantDetail, ProductRecommendationsData } from './product.interface'

export interface NotifyMeTrackProps {
	formData?: NotifyMeFormData | null
	event: NotifyMeEvents
}

export interface NotifyMeProductProps {
	product: ClientProductDetail
	productRecommendationsData?: ProductRecommendationsData
	selectedVariant: ClientProductVariantDetail | undefined
	displayedColor: ColorVariation | undefined
	trackNotifyMeEvent?: ({ formData, event }: NotifyMeTrackProps) => void
	handlePersonalizationClickEvent?: () => void
	handlePersonalizationImpressionEvent?: () => void
}

export interface NotifyMeFormData {
	firstName: string
	email: string
}

export enum NotifyMeEvents {
	CLICKED = 'clicked',
	SUBMITTED = 'submitted',
	CLOSED = 'closed',
	NULL = 'null',
}

export enum NotifyMeButtonStates {
	ENABLED = 'enabled',
	DISABLED = 'disabled',
}

export interface NotifyMeFormAnalyticData {
	first_name: string
	email: string
}

export interface NotifyMeActionData extends AnalyticsActionDataBase {
	products: ProductDetailProduct[]
	notifyMe: {
		event: NotifyMeEvents
		button_state: NotifyMeButtonStates
		data: NotifyMeFormAnalyticData | null
	}
}

export type NotifyMeSubscribe = (options?: {
	firstName?: Maybe<string>
	email?: Maybe<string>
	productId?: Maybe<string>
	retry?: boolean
}) => Promise<void>

export interface NotifyMeContextInterface {
	firstName: NotifyMeFormData['firstName'] | undefined
	email: NotifyMeFormData['email'] | undefined
	setFormData: React.Dispatch<React.SetStateAction<NotifyMeFormData | null>>
	setProductId: React.Dispatch<React.SetStateAction<string | undefined>>
	addSubscriber: NotifyMeSubscribe
	subscribeStatus: AsyncStatus
	success: boolean
	error: ApolloError | undefined
	showError: boolean
	reset: () => void
}
