export interface UserData {
	authType: string
	email: string
	firstName: string
	lastName: string
	isEmployee: boolean
	loyalty: {
		status: string
	}
}

export interface SubscribeToNewsletterInput {
	clientMutationId: string
	email: string
	sourceCode: string
}

export enum LoginFailureReasons {
	INVALID_CREDENTIALS = 'access_denied',
	FORCED_PASSWORD_RESET = 'forced_reset',
	UNKNOWN_FAILURE = 'unknown_failure',
	FAILED_SESSION_REFRESH = 'failed_session_refresh',
	EXPIRED_TOKEN = 'expired_token',
	NOT_ACCEPTABLE = 'not_acceptable',
}

export interface CustomerGroup {
	__typename?: 'CustomerGroup'
	UUID?: string | null | undefined
	id?: string | null | undefined
}

export interface LoginFormData {
	email: string
	password: string
	rememberMe: boolean
}

export type PromptedLoginFormData = Pick<LoginFormData, 'email' | 'password'>
