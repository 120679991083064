import type { LoyaltyClaimedCoupon, LoyaltyRewardGroup } from '~/graphql/generated/uacapi/type-document-node'

export interface LoyaltyRewardsData {
	customerPoints: number
	rewardGroups: LoyaltyRewardGroup[]
	claimedRewards?: LoyaltyClaimedCoupon[]
}

export enum EnrollmentSource {
	/** If a user enrolls in UA Rewards via the Register button in the .com header */
	ECOMMREGISTRATION,

	/** If a user enrolls in UA Rewards via the Rewards Landing page
	 * /[locale]/t/rewards.html
	 */
	ECOMMREWARDSLANDER,

	/** If a user enrolls in UA Rewards from the Rewards Enrollment fly-in modal */
	ECOMMFLYIN,

	/** If a user enrolls in UA Rewards from the order confirmation page */
	ECOMMORDERCONF,

	/** If a user enrolls in UA Rewards from the Early Access enrollment experience */
	ECOMMEA,

	/** If a user enrolls in UA Rewards after clicking the Register button in cart */
	ECOMMCARTREGISTER,

	/** If a user enrolls in UA Rewards after clicking the Register button in checkout modal */
	ECOMMCHECKOUTMODAL,

	/** If a user Clicks Log In For Faster Checkout button in checkout */
	ECOMMCHECKOUT,

	/** If a user enrolls in UA Rewards from Wishlist auth screen */
	ECOMMWISHLIST,

	/** Fallback value when no other enrollment source is set */
	ECOMMFALLBACK,

	/** If a user enrolls in UA Rewards from the login buttin in the .com header */
	ECOMMLOGIN,

	/** If a user enrolls in UA Rewards from the login page /[locale]/login */
	ECOMMLOGINPAGE,

	/** If a user enrolls in UA Rewards after clicking login from the free shipping banner on the cart page */
	ECOMMLOGINCARTFREESHIPPING,

	/** If a user enrolls in UA Rewards after clicking login from My Rewards Locker */
	ECOMMLOGINREWARDSLOCKER,

	/** If a user enrolls in UA Rewards after clicking login from the Search Bar */
	ECOMMLOGINSEARCHBAR,

	/** If a user unenrolls from UA Rewards from My Rewards Locker page */
	ECOMMUNENROLLREWARDS,
}

/**
 * The intention is to use const to create to force specific values versus allowing keys to be of generic string type
 */
export const enrollSourceValues = {
	ECOMMREGISTRATION: 'eCommLoyaltyRegistration',
	ECOMMREWARDSLANDER: 'eCommLoyaltyRewardsLander',
	ECOMMFLYIN: 'eCommLoyaltyFlyIn',
	ECOMMORDERCONF: 'eCommLoyaltyOrderConf',
	ECOMMEA: 'eCommLoyaltyEarlyAccess',
	ECOMMCARTREGISTER: 'eCommLoyaltyCartRegister',
	ECOMMCHECKOUTMODAL: 'eCommLoyaltyCheckoutModal',
	ECOMMWISHLIST: 'eCommLoyaltyWishList',
	ECOMMCHECKOUT: 'eCommLoyaltyCheckout',
	ECOMMFALLBACK: '',
	ECOMMLOGIN: 'eCommLogin',
	ECOMMLOGINPAGE: 'eCommLoginPage',
	ECOMMLOGINCARTFREESHIPPING: 'eCommLoginCartFreeShipping',
	ECOMMLOGINREWARDSLOCKER: 'eCommLoginRewardsLocker',
	ECOMMLOGINSEARCHBAR: 'eCommLoginSearchBar',
	ECOMMUNENROLLREWARDS: 'eCommUnenrollRewards',
} as const

/**
 * The intention is to create a union of the values to prevent passing generic strings
 * "" | "eCommLoyaltyRegistration" | "eCommLoyaltyRewardsLander" | "eCommLoyaltyFlyIn" | "eCommLoyaltyOrderConf" | "eCommLoyaltyEarlyAccess" | "eCommLoyaltyCartRegister" | ... more ...
 */
export type LoyaltyEmailSubscriberSources = (typeof enrollSourceValues)[keyof typeof enrollSourceValues]
