import type {
	Address,
	AurusCreditCardPaymentInstrument,
	BasketPartsFragment,
	KlarnaPaymentInstrument,
	PayPalPaymentInstrument,
	PaymentCard,
	PaymetricPaymentInstrument,
	PriceAdjustment,
	Shipment,
	ShippingMethod,
	VipPaymentInstrument,
	ProductItemEdge,
} from '~/graphql/generated/uacapi/type-document-node'
import {
	CategoryExperienceType,
	ExclusiveType,
	GiftCardType,
	ProductExperienceType,
	ShippingMethodType,
} from '~/graphql/generated/uacapi/type-document-node'
import { groupBy, sum } from '~/lib/arrays'
import { PaymentMethod } from '~/lib/enums/basket.enum'
import { CheckoutStep } from '~/lib/types/analytics.interface'
import type { Cart, CartProduct, CartShippingMethods, CartTotals } from '~/lib/types/cart.interface'
import { ensureArray, ensureString } from '~/types/strict-null-helpers'
import { getShipment } from './cart'
import { PRODUCT_FALLBACK_IMAGE, isEGiftCard, getBopisContactInfo } from './products'
import { CUSTOMER_BOPIS_CONTACT_STORAGE_KEY, CUSTOMER_PHONE_STORAGE_KEY } from '~/lib/constants'
import type { UserProfileInfo, UserWithSession } from '~/lib/client-only/auth/types'

export type AgnosticBasketPartsFragment = Omit<BasketPartsFragment, '__typename'>
export type BasketPartsFragmentPick = Pick<BasketPartsFragment, 'orderPriceAdjustments' | 'productItems' | 'totals'>
export type PaymentInstrumentFragment = BasketPartsFragment['paymentInstruments'][number] & {
	amount?: number | null
}
export type PaymentInstrumentGrouped = Record<PaymentMethod, PaymentInstrumentFragment[]>
export type PriceAdjustmentPick = Pick<PriceAdjustment, 'price'>
export type ProductItemEdgeNode = Pick<BasketPartsFragment['productItems']['edges'][number], 'node'>
export type ShippingMethodFragment = BasketPartsFragment['shipments'][number]['shippingMethod']

export function hasValidContact(userProfile: UserProfileInfo | undefined, customerInfo?): boolean {
	const customerPhone = localStorage.getItem(CUSTOMER_PHONE_STORAGE_KEY)
	const accountPhone = userProfile?.phone
	return (!!customerPhone || !!accountPhone) && (!!userProfile?.email || !!customerInfo?.email)
}

export function hasValidBopisContact(userProfile: UserProfileInfo | undefined): boolean {
	const customerName = localStorage.getItem(CUSTOMER_BOPIS_CONTACT_STORAGE_KEY)
	const bopisContact = getBopisContactInfo(userProfile)
	return !!customerName || !!bopisContact
}

export function determineCheckoutStep(
	{ billingAddress, paymentInstruments, productItems, shipments, totals, customerInfo }: AgnosticBasketPartsFragment,
	isNewCheckoutEnabled: boolean,
	user: UserWithSession | null,
): CheckoutStep {
	const products = productItems?.edges?.map((pi) => pi.node)
	const shippingComplete = hasValidShipment(products, shipments)
	// we use localStorage here due to https://underarmour.atlassian.net/browse/CSTR-160 and https://uacf.slack.com/archives/C0611A325EV/p1706019729045539
	const bopisContactInfo = getBopisContactInfo()

	const groupedInstruments = groupBy(paymentInstruments ?? [], (pi) => pi.__typename as PaymentMethod)
	const billingComplete =
		!paymentMethodsRequireBilling(Object.keys(groupedInstruments) as PaymentMethod[]) ||
		isAddressComplete(billingAddress)
	const paymentComplete =
		!!paymentInstruments?.length &&
		!!Object.entries(groupedInstruments).find(([key, val]) =>
			isValidInstrument(key as PaymentMethod, val, totals?.order ?? 0),
		)
	// pickup only items will skip shipping section
	const pickupOnly = products?.every((item) => !!item.cFromStoreId) || false
	const hasPickup = products?.some((item) => !!item.cFromStoreId) || false
	const bopisComplete = (pickupOnly || hasPickup) && !!bopisContactInfo
	const hasEGiftCardOnly = products?.every((item) => item.product.giftCardType === GiftCardType.EGIFTCARD)
	const isValidContact =
		hasValidContact(user?.profile, customerInfo) && (hasPickup ? hasValidBopisContact(user?.profile) : true)

	if (isNewCheckoutEnabled) {
		const showShipping = pickupOnly || hasPickup ? bopisComplete && isValidContact : isValidContact

		if (!isValidContact) {
			return CheckoutStep.CONTACT
		}
		if (showShipping) {
			if (pickupOnly || shippingComplete || hasEGiftCardOnly) {
				return CheckoutStep.PAYMENT
			}
			return CheckoutStep.SHIPPING
		}
		return CheckoutStep.CONTACT
	}

	if (shippingComplete) {
		if (billingComplete && paymentComplete) {
			return CheckoutStep.CONTACT
		}
		return CheckoutStep.PAYMENT
	}
	return CheckoutStep.SHIPPING
}

export const getPaymentMethodName = ({
	paymentInstruments,
	totals,
}: Pick<AgnosticBasketPartsFragment, 'paymentInstruments' | 'totals'>): string => {
	const groupedInstruments = groupBy(paymentInstruments ?? [], (pi) => pi.__typename as PaymentMethod)
	const [paymentMethod, instruments] = (Object.entries(groupedInstruments).find(([key, val]) =>
		isValidInstrument(key as PaymentMethod, val, totals?.order ?? 0),
	) ?? []) as [PaymentMethod | undefined, AgnosticBasketPartsFragment['paymentInstruments']]

	switch (paymentMethod) {
		case PaymentMethod.ApplePay:
			return 'dw_apple_pay'
		case PaymentMethod.AurusCreditCard:
		case PaymentMethod.Paymetric:
			return (
				(instruments as AurusCreditCardPaymentInstrument[] | PaymetricPaymentInstrument[])
					.map((i) => i.paymentCard?.cardType)
					// isValidInstrument ensures cardType exists, asserting as string to prevent
					// unreachable nullish coalescing
					.find(Boolean) as string
			)
		case PaymentMethod.GiftCard:
			return 'gift_card'
		case PaymentMethod.Klarna:
			return 'klarna_payments'
		case PaymentMethod.PayPal:
			return 'paypal'
		case PaymentMethod.VIP:
			return 'vip'
		default:
			return ''
	}
}

export function hasValidShipment(products?: ProductItemEdgeNode['node'][], shipments?: Shipment[]): boolean {
	if (!products?.length) {
		return false
	}

	const isBopis = (product: ProductItemEdgeNode['node']) => !!product.cFromStoreId

	const isEGift = (product: ProductItemEdgeNode['node']) =>
		product.product.giftCardType === GiftCardType.EGIFTCARD && !!product.eGiftCardDetails

	const shippableProducts = products?.some((p) => !isBopis(p) && !isEGift(p))

	// If basket contains shippable products, there must be a valid shipping address
	// and a selected shipping method which is present in available shipping methods
	if (shippableProducts) {
		const shipment = getShipment(shipments)
		return isAddressComplete(shipment?.shippingAddress) && hasValidShippingMethod(shipment)
	}

	// If the basket only contains BOPIS and/or EGift card products, no shipping address required
	return true
}

export function hasValidShippingMethod(shipment?: Shipment) {
	return (
		!!shipment?.shippingMethod &&
		!!shipment.availableShippingMethods?.some((asm) => asm.id === shipment.shippingMethod?.id)
	)
}

export function isAddressComplete(address?: Address | null): boolean {
	return (
		!!ensureString(address?.firstName).length &&
		!!ensureString(address?.lastName).length &&
		!!ensureString(address?.address1).length &&
		!!ensureString(address?.city).length &&
		!!ensureString(address?.stateCode).length &&
		!!ensureString(address?.postalCode).length
	)
}

export function isValidInstrument<T extends keyof PaymentInstrumentGrouped>(
	paymentMethod: T,
	paymentInstruments?: PaymentInstrumentGrouped[T],
	orderTotal = 0,
): boolean {
	if (!paymentInstruments) {
		return false
	}

	switch (paymentMethod) {
		case PaymentMethod.ApplePay:
			// Applying ApplyPay as a payment method will always complete the order.
			// There is not a scenario where this will be applied and an order not placed.
			// ie: step: CheckoutStep.RECEIPT
			return !!paymentInstruments?.length

		case PaymentMethod.AurusCreditCard:
			return !!(paymentInstruments as AurusCreditCardPaymentInstrument[])
				.map((pi) => (pi.paymentCard ?? {}) as PaymentCard)
				.find(
					({ cardType, expirationMonth, expirationYear, maskedNumber }) =>
						!!cardType && !!expirationMonth && !!expirationYear && !!maskedNumber,
				)

		// UACAPI is not returning the PaymentCard associated with Paymetric currently.
		// Therefore, unfortunately we can't do any more validation than this.
		// See https://underarmour.atlassian.net/browse/CSVT-915
		case PaymentMethod.Paymetric:
			return !!(paymentInstruments as PaymetricPaymentInstrument[])

		case PaymentMethod.GiftCard:
			return !!paymentInstruments?.length && sumPaymentInstruments(paymentInstruments, paymentMethod) >= orderTotal

		case PaymentMethod.Klarna:
			return !!(paymentInstruments as KlarnaPaymentInstrument[]).find(
				({ clientToken, sessionId }) => !!clientToken && !!sessionId,
			)

		case PaymentMethod.PayPal:
			return !!(paymentInstruments as PayPalPaymentInstrument[]).find(({ id }) => !!id)

		case PaymentMethod.VIP:
			return !!(paymentInstruments as VipPaymentInstrument[]).find(({ id }) => !!id)
	}

	return false
}

export function mapCartProduct({
	node: {
		id,
		quantity,
		cFromStoreId,
		cStoreInventory,
		prices,
		product,
		priceAdjustments,
		eGiftCardDetails,
		cPrimaryContactBOPIS,
	},
}: ProductItemEdgeNode): CartProduct {
	return {
		id,
		ats: product.inventory?.ats ?? 0,
		availableForInStorePickup: !!product.availableForInStorePickup,
		cFromStoreId: cFromStoreId ?? undefined,
		color: `${product.color?.colorway} - ${product?.color?.code}`,
		colorCode: product.color?.code ?? '',
		cStoreInventory: cStoreInventory ?? 0,
		currency: product.currency ?? '',
		eGiftCardDetails,
		productExperienceType: product.experienceType ?? ProductExperienceType.NONE,
		categoryExperienceType: product.master?.product?.primaryCategory?.experienceType ?? CategoryExperienceType.NONE,
		giftCardType: product.giftCardType,
		image: product.assets?.images?.[0]?.url ?? PRODUCT_FALLBACK_IMAGE,
		length: product.sizePreferenceOption?.extendedSize ?? '',
		lineItemQuantityLimit: product.lineItemQuantityLimit ?? undefined,
		masterProductId: product.master?.product?.id,
		productId: product.id,
		name: product.copy?.name ?? '',
		orderable: !!product.inventory?.orderable,
		outlet: product.isOutlet ?? false,
		preorderable: !!product.inventory?.preorderable,
		oos: product.inventory?.exclusiveType === ExclusiveType.OUT_OF_STOCK ? 'yes' : 'no',
		shopTheLook: product.shopTheLookInfo?.status ?? false,
		prices: {
			list: {
				max: product.prices?.list,
				min: product.prices?.list,
			},
			sale: {
				max: product.prices?.sale,
				min: product.prices?.sale,
			},
		},
		preorderMessage: product.copy?.preorderCopy?.pdpMessage,
		promoCalloutAssetID: product?.promoCalloutAssetID,
		priceAdjustments,
		productPromotions: ensureArray(product.master?.product?.productPromotions),
		quantity,
		productGender: product.gender ?? '',
		silhouette: product.silhouette ?? '',
		size: product.size?.size ?? '',
		sku: `${product.style}-${product.color?.code}-${product.size?.size}`,
		style: product.style ?? '',
		totalPrice: prices,
		url: product.url ?? '',
		upc: product.upc ?? '',
		cPrimaryContactBOPIS: cPrimaryContactBOPIS ?? '',
		team: product.team ?? '',
	}
}

export function mapCartTotals({
	orderPriceAdjustments,
	paymentInstruments,
	productItems,
	totals,
}: Pick<BasketPartsFragment, 'orderPriceAdjustments' | 'paymentInstruments' | 'productItems' | 'totals'>): CartTotals {
	const employeeDiscountAmount = sumPriceAdjustments(
		orderPriceAdjustments,
		(adj) => adj.itemText === 'Employee Discount',
	)
	const orderDiscountAmount = sumPriceAdjustments(
		orderPriceAdjustments,
		(adj) => !adj.couponCode && adj.itemText !== 'Employee Discount',
	)

	const orderPromoAmount = sumPriceAdjustments(
		orderPriceAdjustments,
		(adj) => !!adj.couponCode && adj.itemText !== 'Employee Discount',
	)
	const productPromoAmount = sumPriceAdjustments(
		productItems?.edges?.flatMap((edge) => edge.node.priceAdjustments ?? 0),
		(adj) => !!adj.couponCode,
	)

	const subTotalBeforeAdjustments = sum(
		productItems?.edges?.flatMap(
			(edge) =>
				(edge?.node?.product?.prices?.list || edge?.node?.prices?.totalBeforeAdjustments || 0) *
				(edge?.node?.quantity ?? 1),
		) ?? [],
	)
	/**
	 * We use the non-sale price as our subtotal product price, to call out the product discount as a line item
	 * This considers the pre-sale subtotal price difference coupled with any productPromoAmount to get the complete
	 * discount amount.
	 */
	const productDiscountAmount = subTotalBeforeAdjustments - (totals?.productSub ?? 0) - productPromoAmount

	return {
		employeeDiscountAmount,
		estimatedLoyaltyPoints: totals?.estimatedLoyaltyPoints ?? 0,
		giftCardTotal: sumPaymentInstruments(paymentInstruments, 'GiftCardPaymentInstrument'),
		grandTotal: totals?.order ?? undefined,
		orderPriceAdjustments,
		orderDiscountAmount,
		productDiscountAmount,
		promoAmount: +(orderPromoAmount + productPromoAmount).toFixed(2),
		shipping: totals?.shipping ?? 0,
		subTotal: totals?.productSub ?? 0,
		subTotalAfterDiscounts: totals?.product ?? 0,
		subTotalBeforeAdjustments,
		tax: totals?.tax ?? undefined,
		totals: productItems?.edges?.reduce((acc, { node }) => acc + node.quantity, 0) || 0,
	}
}

export function mapCartShippingMethods(shippingMethods?: ShippingMethod[]): CartShippingMethods {
	return groupBy(
		shippingMethods ?? [],
		(sm) => sm.methodType as ShippingMethodType,
		Object.keys(ShippingMethodType).reduce((prev, cur) => ({ ...prev, [cur]: [] }), {}) as Record<
			ShippingMethodType,
			ShippingMethod[]
		>,
	)
}

export function reorderProductsInCart(products): ProductItemEdge[] {
	if (products) {
		const reversedProducts = [...products]
		return reversedProducts.reverse()
	}

	return []
}

export const mapCartObject = (
	basket: Omit<BasketPartsFragment, '__typename'>,
	isNewCheckoutEnabled = false,
	user: UserWithSession | null = null,
): Cart => {
	const products = reorderProductsInCart(basket.productItems?.edges).map(mapCartProduct)
	const includesBopis = products.some((p) => p.cFromStoreId)
	const includesEgift = products.some((p) => isEGiftCard(p.giftCardType))
	const includesShippable = products.some((p) => !p.cFromStoreId && !isEGiftCard(p.giftCardType))
	const productsMeta: Cart['productsMeta'] = {
		includesBopis,
		includesEgift,
		includesShippable,
		onlyBopis: includesBopis && !products.some((p) => !p.cFromStoreId),
		onlyEgift: includesEgift && !products.some((p) => !isEGiftCard(p.giftCardType)),
		onlyShippable: includesShippable && !includesBopis && !includesEgift,
	}
	const shippingMethods = mapCartShippingMethods(
		basket.shipments?.map((s) => s.shippingMethod)?.filter((sm): sm is ShippingMethod => !!sm),
	)

	return {
		...mapCartTotals(basket),
		basketId: basket.id,
		checkoutStep: determineCheckoutStep(basket, isNewCheckoutEnabled, user),
		couponItems: basket.couponItems,
		shipments: basket.shipments,
		shippingMethods,
		uiHints: basket.uiHints ?? null,
		products,
		limitExceededItems: basket.limitExceededItems,
		productsMeta,
		paymentInstruments: basket.paymentInstruments,
		billingAddress: basket.billingAddress,
		contactEmail: basket.customerInfo?.email ?? undefined,
		customerNo: basket.customerInfo?.customerNo,
		currency: basket.currency,
		idMeScope: basket.idMeScope,
		applicablePaymentMethods: basket.applicablePaymentMethods,
	}
}
export function paymentMethodsRequireBilling(methods: PaymentMethod[]): boolean {
	const billingRequired = (method: PaymentMethod) => {
		switch (method) {
			case PaymentMethod.VIP:
				return false
			default:
				return true
		}
	}

	return methods.some(billingRequired)
}

export function sumPaymentInstruments<T extends PaymentInstrumentFragment>(
	instruments?: T[],
	instrumentType?: T['__typename'],
): number {
	return (instruments ?? [])
		.filter((ins) => ins.__typename === instrumentType)
		.reduce((prev, cur) => prev + (cur.amount ?? 0), 0)
}

export function sumPriceAdjustments<T extends PriceAdjustmentPick>(
	adjustments?: T[],
	condition: (adj: T) => boolean = () => true,
): number {
	return Math.abs((adjustments ?? []).reduce((prev, cur) => (condition(cur) ? prev + cur.price : prev), 0)) || 0
}

export function validateStepChange(cart: Cart, next: CheckoutStep, isNewCheckout: boolean) {
	if (isNewCheckout) {
		switch (next) {
			case CheckoutStep.SHIPPING:
				return [CheckoutStep.SHIPPING, CheckoutStep.PAYMENT].includes(cart.checkoutStep)
			default:
				return next === cart.checkoutStep
		}
	} else {
		switch (next) {
			case CheckoutStep.SHIPPING:
				return true
			case CheckoutStep.PAYMENT:
				return [CheckoutStep.PAYMENT, CheckoutStep.CONTACT].includes(cart.checkoutStep)
			default:
				return next === cart.checkoutStep
		}
	}
}
