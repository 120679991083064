
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "BasketOrder": [
      "Basket",
      "OrderReceipt"
    ],
    "Customer": [
      "GuestCustomer",
      "RegisteredCustomer"
    ],
    "LoyaltyApiResponse": [
      "EstimatedLoyaltyPointsPayload",
      "LoyaltyAvailableRewardsToClaimPayload",
      "LoyaltyClaimDiscountCouponPayload",
      "LoyaltyClaimedRewardsPayload",
      "LoyaltyCouponStatusPayload",
      "LoyaltyPointsPayload",
      "RejectRedeemedRewardEventPayload",
      "UpdateCustomerProfilePayload"
    ],
    "LoyaltyReward": [
      "LoyaltyAvailableReward",
      "LoyaltyClaimedReward",
      "LoyaltyCouponClaimEvent"
    ],
    "Node": [
      "Basket",
      "BasketPaymentInstrument",
      "BasketShipment",
      "CustomerAddress",
      "CustomerListsCustomer",
      "CustomerPaymentInstrument",
      "GuestCustomer",
      "IntegrationUser",
      "MasterProduct",
      "OrderPaymentInstrument",
      "OrderReceipt",
      "OrderShipment",
      "ProductList",
      "RegisteredCustomer",
      "SizeChart",
      "SizePreferenceOptions",
      "Store",
      "VariantProduct"
    ],
    "OIS_InvoiceDocument": [
      "OIS_CreditInvoice",
      "OIS_DebitInvoice",
      "OIS_ReceiptConfirmationInvoice"
    ],
    "OIS_Rma": [
      "OIS_ItemizedRma",
      "OIS_PreprintedLabelRma",
      "OIS_StoreRma"
    ],
    "OIS_RmaItem": [
      "OIS_ExchangeItem",
      "OIS_RefundItem"
    ],
    "OIS_VariantProduct": [
      "OIS_VariantProductEGiftCard",
      "OIS_VariantProductRegular"
    ],
    "PaymentInstrument": [
      "ApplePayPaymentInstrument",
      "AurusCreditCardPaymentInstrument",
      "BasketPaymentInstrument",
      "CreditCardPaymentInstrument",
      "CustomerPaymentInstrument",
      "GiftCardPaymentInstrument",
      "KlarnaPaymentInstrument",
      "OrderPaymentInstrument",
      "PayPalPaymentInstrument",
      "PaymetricPaymentInstrument",
      "VipPaymentInstrument"
    ],
    "Product": [
      "MasterProduct",
      "VariantProduct"
    ],
    "SearchHit": [
      "MasterProductSearchHit",
      "SlicingGroupSearchHit"
    ],
    "Shipment": [
      "BasketShipment",
      "OrderShipment"
    ],
    "SizeChartRowData": [
      "SizeChartRowDatum",
      "SizeChartRowMeasurement"
    ]
  }
};
      export default result;
    