import { sanitizeString } from '../client-only/forms'
import { getPublicConfig } from '../client-server/config'
import { getCountryCodeByLocale } from '~/lib/i18n/locale'

export const postalCodeRegexPattern: Record<
	string,
	{ pattern: RegExp; minLength: number; maxLength: number; inputMode: 'numeric' | 'text' }
> = {
	US: { pattern: /^\d{5}$|^\d{5}-\d{4}$/, minLength: 5, maxLength: 10, inputMode: 'numeric' },
	CA: {
		pattern:
			/^[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY]\d[abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ]( |-)?\d[abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ]\d$/,
		minLength: 4,
		maxLength: 10,
		inputMode: 'text',
	},
	AT: { pattern: /^\d{4}?$/, minLength: 4, maxLength: 10, inputMode: 'numeric' },
	BE: { pattern: /^\d{4}?$/, minLength: 4, maxLength: 11, inputMode: 'numeric' },
	DK: { pattern: /^[0-9]{4}$/, minLength: 4, maxLength: 11, inputMode: 'numeric' },
	FR: {
		pattern:
			/^(^\d{5}(-\d{4})?$)|(^[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Za-z]{1} *\d{1}[A-Za-z]{1}\d{1}$)$/,
		minLength: 5,
		maxLength: 10,
		inputMode: 'text',
	},
	DE: {
		pattern:
			/^(^\d{5}(-\d{4})?$)|(^[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Za-z]{1} *\d{1}[A-Za-z]{1}\d{1}$)$/,
		minLength: 5,
		maxLength: 10,
		inputMode: 'text',
	},
	GB: {
		pattern:
			/ ?(([BEGLMNSWbeglmnsw][0-9][0-9]?)|(([A-PR-UWYZa-pr-uwyz][A-HK-Ya-hk-y][0-9][0-9]?)|(([ENWenw][0-9][A-HJKSTUWa-hjkstuw])|([ENSWenw][A-HK-Ya-hk-y][0-9][ABEHMNPRVWXYabehmnprvwxy])))) ?[0-9][ABD-HJLNP-UW-Zabd-hjlnp-uw-z]{2}$/,
		minLength: 4,
		maxLength: 11,
		inputMode: 'text',
	},
	IE: {
		pattern: /(?:^[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$/i,
		minLength: 5,
		maxLength: 10,
		inputMode: 'text',
	},
	IT: { pattern: /^[0-9]{5}$/, minLength: 4, maxLength: 10, inputMode: 'numeric' },
	NL: { pattern: /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-zA-Z]{2}$/, minLength: 5, maxLength: 10, inputMode: 'text' },
	ES: { pattern: /^[0-9]{5}$/, minLength: 4, maxLength: 10, inputMode: 'numeric' },
	SE: { pattern: /^[0-9]{3} ?(?!sa|sd|ss)[0-9]{2}$/, minLength: 5, maxLength: 10, inputMode: 'text' },
	CH: { pattern: /^\d{4}?$/, minLength: 4, maxLength: 10, inputMode: 'numeric' },
	LI: { pattern: /^\d{4}?$/, minLength: 4, maxLength: 10, inputMode: 'numeric' },
	NO: { pattern: /^[0-9]{4}$/, minLength: 4, maxLength: 10, inputMode: 'numeric' },
	PL: { pattern: /^([0-9]{5})|([0-9]{2}-[0-9]{3})$/, minLength: 4, maxLength: 10, inputMode: 'numeric' },
	PT: { pattern: /^([0-9]{7})|([0-9]{4}-[0-9]{3})$/, minLength: 4, maxLength: 11, inputMode: 'numeric' },
} as const

export const isPostalCodeValid = (country?: string, postalCode?: string) => {
	if (!country || !postalCode) return false
	const constraint = postalCodeRegexPattern[country.toUpperCase()]?.pattern
	if (constraint?.test(postalCode)) {
		return true
	}

	return false
}

const nonDegitsRegex = /[^\d]/g

const postalCodeFormatters = {
	US: (postalCode: string) => {
		const formattedPostalCode = postalCode.replaceAll(nonDegitsRegex, '')
		if (formattedPostalCode.length > 0) {
			return (formattedPostalCode.match(/.{1,5}/g) || []).join('-')
		}
		return formattedPostalCode
	},
	CA: (postalCode: string) => {
		const max = 7

		// It prevents user to enter emoji and other string
		const cleanPostalCode = sanitizeString(postalCode)

		const formattedPostalCode = cleanPostalCode.slice(0, max).split(' ').join('')

		// const formattedPostalCode =
		if (postalCode.length > 0) {
			return (formattedPostalCode.match(/.{1,3}/g) || []).join(' ')
		}
		return formattedPostalCode
	},
}

export const formatPostalcode = (postalCodeValue: string, country: string) => {
	switch (country.toUpperCase()) {
		case 'US':
			return postalCodeFormatters.US(postalCodeValue)
		case 'CA':
			return postalCodeFormatters.CA(postalCodeValue)
		default:
			return postalCodeValue
	}
}

export const getBillingCountries = (locale: string): string[] =>
	getPublicConfig().billing_countries_accepted[locale] ?? []

export const getInputModeByLocale = (locale: string): 'numeric' | 'text' => {
	const countryCode = locale.split('-')?.[1]
	if (!!countryCode && countryCode.toUpperCase() in postalCodeRegexPattern) {
		return postalCodeRegexPattern[getCountryCodeByLocale(locale).toUpperCase()].inputMode
	}
	return 'text'
}
