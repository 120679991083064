import { onError } from '@apollo/client/link/error'
import type { createClientLogger } from '~/lib/logger'

/**
 * The error link tries to capture the most relevant information from the GraphQL failure
 * and sends it to the pino logger.
 */
export const createErrorLink = ({
	logger,
	service,
}: {
	logger: ReturnType<typeof createClientLogger>
	service: string
}) =>
	onError(({ graphQLErrors, networkError }) => {
		if (graphQLErrors) {
			graphQLErrors.forEach(({ message, locations, path, extensions }) => {
				const args = extensions?.arguments
					? `, Arguments: [${extensions?.arguments?.statusCode}: ${extensions?.arguments?.statusMessage}]`
					: ''
				logger.error(`[${service}] Message: ${message}, Location: ${locations}, Path: ${path}${args}`)
			})
		}
		if (networkError) {
			logger.error(`[${service}] Network error: ${networkError.message ?? networkError}`)
		}
	})
