import type { ApolloError } from '@apollo/client'
import type { GraphQLError } from 'graphql'
import { CartProviderProductErrorCode } from '~/lib/types/cart.interface'

type HandledError = { code: CartProviderProductErrorCode; error?: GraphQLError; message: string }

const handledGraphErrors: Record<string, CartProviderProductErrorCode> = {
	InvalidProductItemIdException: CartProviderProductErrorCode.MISSING_PRODUCT,
	ProductItemNotAvailableException: CartProviderProductErrorCode.ITEM_UNAVAILABLE,
	ProductItemNotFoundException: CartProviderProductErrorCode.MISSING_PRODUCT,
}

const handledComplexGraphErrors = (error: GraphQLError): HandledError | undefined => {
	if (error.extensions?.code === 'BAD_USER_INPUT' && error.message === 'Product item could not be found.') {
		return { code: CartProviderProductErrorCode.MISSING_PRODUCT, error, message: '' }
	}

	return undefined
}

export function getHandledGraphErrors(error: ApolloError | undefined): HandledError[] {
	const simpleErrors = error?.graphQLErrors
		.filter((e) => !!handledGraphErrors[e.extensions?.code])
		.map((e) => ({ code: handledGraphErrors[e.extensions?.code], error: e, message: '' }))

	const complexErrors = error?.graphQLErrors.map(handledComplexGraphErrors).filter((e): e is HandledError => !!e)

	const errors = [...(simpleErrors ?? []), ...(complexErrors ?? [])]

	return errors.length ? errors : [{ code: CartProviderProductErrorCode.UNHANDLED_ERROR, message: '' }]
}
