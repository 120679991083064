import type { ApolloClient } from '@apollo/client'
import { gql } from '@apollo/client'
import type {
	Item as CIOSuggestion,
	Facet,
	FacetOption,
	FilterExpression,
	GetBrowseResultsResponse,
	SearchParameters,
	SearchResponse,
} from '@constructor-io/constructorio-client-javascript/lib/types'
import Cookies from 'universal-cookie'
import type { UserWithSession } from '~/lib/client-only/auth/types'
import {
	GiftCardType,
	type Category,
	type ColorVariation,
	type CustomRefinementInput,
	type SearchRefinementAttribute,
	type SearchRefinementAttributeValue,
	type SizeVariation,
	ProductExperienceType,
	ExclusiveType,
	type ShopTheLookColor,
	type ShopTheLookImage,
} from '~/graphql/generated/uacapi/type-document-node'
import { unique } from '~/lib/arrays'
import { getPublicConfig } from '~/lib/client-server/config'
import type {
	CioBreadcrumb,
	CioBrowseEndpointParams,
	CioProductList,
	CioPromotion,
	CioRequest,
	CioResultsProps,
	CioSearchEndpointParams,
	CioSearchRefinementAttribute,
	CioSearchRefinementAttributeValue,
	CioSortOption,
	ClientProductList,
	ClientProductTile,
	Refinement,
} from '~/lib/types/product.interface'
import type {
	LinkedSuggestion,
	SizeModelImage,
	PricedColorVariant,
	CIOProduct,
	CIOProductVariant,
} from '~/lib/types/search-suggestions.interface'
import {
	ensureNonNullishArray,
	ensureNumber,
	ensureString,
	isArrayWithItems,
	type NonEmptyArray,
} from '~/types/strict-null-helpers'
import logger from './logger'
import {
	PRODUCTS_PER_PAGE,
	PRODUCT_TILE_LOADING_IMAGE,
	getProductListByCategory,
	NUMBER_PRODUCTS_PER_PAGE,
	type SearchProductsQuery,
	isGiftCard,
} from './products'
import {
	PRICE_RANGE_LOWER_BOUND,
	PRICE_RANGE_UPPER_BOUND,
	getFilterRefinementPrice,
	getFilterRefinementInput,
	GIFTS_PRICE_MAP,
} from './routes'
import { standAloneApolloClient } from './client-server/uacapi-client'
import { getDefaultLocale } from './i18n/locale'
import type { HashMap } from './types/category.interface'
import type ConstructorIONode from '@constructor-io/constructorio-node'
import { ensureSafeCustomerGroupsArray } from './banners'
import { SIZE_MAP, type SizeMapOptionsType } from './size-like-mine'
import { getConstructorClientForBrowser } from './client-only/search'
import { getBreadcrumbs } from './plp-seo'
import type { BreadcrumbTrail } from '~/components/shared/Breadcrumbs'
import { DEFAULT_SORT_RULE } from './constants'
import { productTileImgBuilder } from './scene7-recipes'

const SFCC_RANGE_SEPARATOR = '..'
const SFCC_ROOT_CATEGORY_ID = 'root'
const SFCC_UPC_PAGE_REGEX = /\/\d{12}\.html/
export const SEARCH_REFINEMENT_URL = '/search/'
const RECENT_SEARCH_COOKIE_NAME = 'ua-recent-search'
export const CIO_PRICE_FACET_ID = 'salePriceLow'
const GIFTS_FACET_ID = 'giftsByPrice'
const CIO_RANGE_SEPARATOR = '-'
const CIO_SELECTED = 'selected'
export const CIO_BROWSE_GROUP = 'group_id'
const CIO_RANGE_REGEX = /\(|\)/g
const CIO_RANGE_NEG_INF_REGEX = /\b0\b/
const CIO_RANGE_NEG_INF = '-inf'
const CIO_RANGE_INF_REGEX = /\b9999\b/
const CIO_RANGE_INF = 'inf'
const COLOR_GROUP_HEX = {
	Black: '#000000',
	Blue: '#4248e9',
	Brown: '#572c1a',
	Gold: "url('//underarmour.scene7.com/is/image/Underarmour/refinement_texture_sprite?fmt=png8') 0 105px;",
	Gray: '#7a7a7a',
	Green: '#488032',
	Maroon: '#800000',
	'Misc/Assorted': "url('//underarmour.scene7.com/is/image/Underarmour/refinement_texture_sprite?fmt=png8') 0 0px;",
	Assorted: "url('//underarmour.scene7.com/is/image/Underarmour/refinement_texture_sprite?fmt=png8') 0 0px;",
	Navy: '#1c2075',
	Orange: '#fc7b0a',
	Pink: '#ffa2a2',
	Purple: '#800080',
	Red: '#f00000',
	Silver: "url('//underarmour.scene7.com/is/image/Underarmour/refinement_texture_sprite?fmt=png8') 0 70px;",
	White: '#ffffff',
	Yellow: '#f9f777',
	Camo: "url('//underarmour.scene7.com/is/image/Underarmour/refinement_texture_sprite?fmt=png8') 0 35px;",
}
const BADGE_MAP = {
	'runners-world': "2019 Runner's World Recommended Award",
	'3for50': '3 FOR $50',
	'32aa-44ddd': '32A-44DDD',
	'app-exclusive': 'App Exclusive',
	ellen: 'As Seen On Ellen',
	'short-and-tall': 'Available in Short & Tall',
	'tall-sizes': 'Comes in Tall',
	'back-in-stock': 'Back in Stock',
	bestsellers: 'Best Seller',
	'black-Friday': 'Black Friday',
	'built-in-mask': 'Built-ln Face Mask',
	'cyber-monday': 'Cyber Monday',
	'cyber-weekend': 'Cyber Weekend',
	'excluded-promo': 'Excluded From Promotions',
	'extended-sizes': 'Extended Sizes',
	'free-shipping': 'Free Shipping',
	'free-shipping-returns': 'Free Shipping & Free Returns',
	'glitter-graphic': 'Glitter Graphic',
	'glow-in-dark': 'Glow-in-the-Dark',
	'limited-qty': 'Limited Quantities',
	'mothers-day': 'Mothers Day Top Gift',
	'never-before-discounted': 'Never Before Discounted',
	'new-product': 'New Product',
	'new-to-outlet': 'New To Sale/Outlet',
	'only-at-ua': 'ONLY AT UA',
	'pre-order': 'Pre-Order',
	'ua-online-exclusive': 'UA.COM Exclusive',
	'pro-pick': 'PRO PICK',
	'retiring-soon': 'Retiring Soon',
	reversible: 'Reversible',
	'sock-2-pair': '2-pack',
	'sock-3-pair': '3-pack',
	'sock-6-pair': '6-pack',
	'top-gift': 'Top Gift',
	'ua-exclusive': 'UA Exclusive',
	'updated-construction': 'Updated Construction',
	'uv-activated-graphic': 'UV-Activated Graphic',
	'water-activated-graphic': 'Water-Activated Graphic',
	'xs-3x': 'xs-3X',
	'web-exclusive': 'Exclusive',
	'new-colors-available': 'New Colors',
	'1X-3X': '1X-3X',
	'youth-graphic': 'Youth Graphic Applications',
}
export const FACET_MAP = {
	ageGroup: {
		uacapiId: 'c_agegroup',
		displayName: 'Age Group',
	},
	collection: {
		uacapiId: 'c_merchCollection',
		displayName: 'Collection',
	},
	colorGroup: {
		uacapiId: 'c_colorgroup',
		displayName: 'Color',
	},
	division: {
		uacapiId: 'c_division',
		displayName: 'Product Category',
	},
	enduse: {
		uacapiId: 'c_enduse',
		displayName: 'Sports',
	},
	fitType: {
		uacapiId: 'c_fittype',
		displayName: 'Fit',
	},
	gender: {
		uacapiId: 'c_gender',
		displayName: 'Gender',
	},
	length: {
		uacapiId: 'c_length',
		displayName: 'Size Range',
	},
	giftsByPrice: {
		uacapiId: 'price',
		displayName: 'Gifts By Price',
	},
	salePriceLow: {
		uacapiId: 'price',
		displayName: 'Price',
	},
	silhouette: {
		uacapiId: 'c_silhouette',
		displayName: 'Silhouette',
	},
	subsilhouette: {
		uacapiId: 'c_subsilhouette',
		displayName: 'Product Type',
	},
	size: {
		uacapiId: 'c_size',
		displayName: 'Size',
	},
	team: {
		uacapiId: 'c_team',
		displayName: 'Team',
	},
}
/* 
	Currently the CIO Sorting Options do not align with UACAPI by name/id.
	To do so, we'd need to coordinate with the App team to change these values.
	If we can align in the future, we can remove this static map and load CIO
	sort options dynamically on the server. This would allow changes to sorting
	options to happen in the future without code changes.
 */
export const SORT_OPTIONS_MAP = {
	'now-trending': {
		sortBy: 'relevance',
		sortOrder: 'descending',
	},
	'top-sellers': {
		sortBy: 'bestSellers',
		sortOrder: 'descending',
	},
	'top-rated': {
		sortBy: 'rating',
		sortOrder: 'descending',
	},
	newest: {
		sortBy: 'newest',
		sortOrder: 'descending',
	},
	'price-low-high': {
		sortBy: 'salePrice',
		sortOrder: 'ascending',
	},
	'price-high-low': {
		sortBy: 'salePrice',
		sortOrder: 'descending',
	},
}
export const VARIATIONS_MAP = {
	filter_by: {
		and: [
			{
				field: 'data.hideColorWay',
				value: false,
			},
			{
				field: 'data.orderable',
				value: true,
			},
		],
	},
	group_by: [
		{
			name: 'colorWayId',
			field: 'data.colorWayId.color',
		},
	],
	values: {
		colorWay: {
			aggregation: 'first',
			field: 'data.colorWay',
		},
		hexColor: {
			aggregation: 'first',
			field: 'data.hexColor',
		},
		hideColorWay: {
			aggregation: 'first',
			field: 'data.hideColorWay',
		},
		isLoyaltyExclusive: {
			aggregation: 'first',
			field: 'data.isLoyaltyExclusive',
		},
		colorValue: {
			aggregation: 'first',
			field: 'data.colorValue',
		},
		secondaryHexColor: {
			aggregation: 'first',
			field: 'data.secondaryHexColor',
		},
		orderable: {
			aggregation: 'first',
			field: 'data.orderable',
		},
		listPrice: {
			aggregation: 'first',
			field: 'data.listPrice',
		},
		salePrice: {
			aggregation: 'first',
			field: 'data.salePrice',
		},
		imageName: {
			aggregation: 'first',
			field: 'data.imageName',
		},
		image_url: {
			aggregation: 'first',
			field: 'data.image_url',
		},
		gridTileHoverImageURL: {
			aggregation: 'first',
			field: 'data.gridTileHoverImageURL',
		},
		title: {
			aggregation: 'first',
			field: 'value',
		},
		facets: {
			aggregation: 'first',
			field: 'data.facets',
		},
		sizeModelImages: {
			aggregation: 'all',
			field: 'data.sizeModelImages',
		},
		exclusiveType: {
			aggregation: 'first',
			field: 'data.exclusiveType',
		},
	},
	dtype: 'array',
}

/**
 * Builds a non-localized search url, given a search query
 */
export const buildSearchUrl = (query: string): string => `/search/?q=${encodeURIComponent(query)}`

/**
 * Gets the stored list of recent search terms as an array
 * @returns An array of recent search term strings
 */
export const getRecentSearches = (): string[] => {
	const cookies = new Cookies()
	return (
		cookies
			.get(RECENT_SEARCH_COOKIE_NAME)
			?.split(';')
			?.filter((s: string) => !!s) || []
	)
}

/**
 * This will add a new search term to a locally stored list of recent search terms.
 * @param searchTerm The new search term to add
 */
export const addToRecentSearch = (searchTerm: string) => {
	const cookies = new Cookies()

	// This will get the current list of recent searches and prepend the new search result, dedupe it and return the top 3
	const oldRecentSearches = getRecentSearches()
	const recentSearches = unique([searchTerm, ...oldRecentSearches]).slice(0, 3)

	// Update the cookie with the list.
	cookies.set(RECENT_SEARCH_COOKIE_NAME, recentSearches.join(';'))
}

export const searchRefinements = async (
	client: ApolloClient<object>,
	q: string,
	first: number,
): Promise<SearchRefinementAttribute[]> => {
	const GET_SEARCH_REFINEMENTS = gql`
		query GetSearchRefinements($q: String!, $first: Int!) {
			searchProducts(q: $q, first: $first) {
				refinementAttributes {
					attributeId
					label
					values {
						label
					}
				}
			}
		}
	`

	const refinements = await client.query({
		query: GET_SEARCH_REFINEMENTS,
		variables: {
			q,
			first,
		},
	})

	return refinements.data.searchProducts.refinementAttributes
}

export const categorySuggestionsArray = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	categories: any[],
): { categorySuggestions: LinkedSuggestion[] } => ({
	categorySuggestions: categories?.map(
		(category): LinkedSuggestion => ({
			id: category.node.category.id,
			url: category.node.category.alternativeUrl ?? category.node.category.url,
			displayName: category.node.category.displayName,
			parent: category.node.category.parent,
		}),
	),
})

export const productTileArray = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	products: any[],
): { products: ClientProductTile[] } => ({
	products: products?.map((product): ClientProductTile => {
		const firstAvailableImage = product.node.product.colors?.find((colors) => colors?.assets?.images)
		const images = firstAvailableImage ? firstAvailableImage?.assets?.images : null
		return {
			id: product.node.product.id,
			currency: product.node.product.currency,
			style: product.node.product.style,
			name: product.node.productName,
			url: product.node.product.url,
			price: product.node.product.prices,
			colors: product.node.product.colors.filter((c: ColorVariation) => !c.hideColorway),
			badges: product.node.product.badges,
			productPromotions: product.node.product.productPromotions,
			orderable: product.node.product.inventory.orderable,
			preorderable: product.node.product.inventory.preorderable,
			image: {
				alt: product.node.product.colors[0]?.assets?.alt,
				url: images ? images[0].url : PRODUCT_TILE_LOADING_IMAGE,
				urlHov: images ? images.slice(0, 2).at(-1).url : PRODUCT_TILE_LOADING_IMAGE,
			},
			exclusiveType: product.node.product.inventory.exclusiveType,
			comingSoonMessage: product.node.product.comingSoonMessage,
			tilePreorderMessage: product.node.product?.copy?.preorderCopy?.tileMessage,
		}
	}),
})

/**
 * Returns the ConstructorIO index key for a specific locale.
 * Returns an empty string if index key is not defined for locale
 */
export const getConstructorIOIndexKey = (locale?: string) => {
	const activeLocale = locale || getDefaultLocale()
	return getPublicConfig().cio.index_keys[activeLocale] ?? ''
}

/**
 * Returns whether ConstructorIO is enabled for a given index.
 * Uses default index when no locale is provided.
 */
export const isConstructorIOEnabled = (locale?: string) => !!getConstructorIOIndexKey(locale)

/**
 * Helper to determine if the "Browse" endpoint for ConstructorIO is enabled
 */
export const isConstructorIOBrowseEnabled = (locale: string) => {
	if (!locale) return false

	const cioConfig = getPublicConfig().cio
	const isBrowseEnabled = cioConfig.category_data_source === 1
	const isCioConfiguredForLocale = isConstructorIOEnabled(locale)

	return Boolean(isBrowseEnabled && isCioConfiguredForLocale)
}

/**
 * Builds sha256 hash of user email to pass with CIO requests
 */
export const hashUserEmail = async (userEmail?: string | null): Promise<string | undefined> => {
	if (!userEmail) return undefined

	try {
		// Use Browser's SubtleCrypto API for Client Hashing
		const utf8 = new TextEncoder().encode(userEmail)
		const hashBuffer = await crypto.subtle.digest('SHA-256', utf8)
		const hashArray = Array.from(new Uint8Array(hashBuffer))
		const hashHex = hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('')

		return hashHex
	} catch (e) {
		logger.info(`Error hashing user email`)
		return undefined
	}
}

/**
 * This looks in data or facets within data for the field specified returning defaultValue if the metadata and facets are both nullish
 * @param product The product from Constructor
 * @param fieldName The name of the field to find in the product
 * @param defaultValue In case the field is not found, return this value
 */
export const getConstructorFieldData = (product: CIOProduct, fieldName: string, defaultValue) =>
	// There will always be more metadata than facets, so check that first
	product[fieldName] ??
	product.data?.[fieldName] ??
	product.data?.facets?.find((f) => f.name === fieldName)?.values?.[0] ??
	defaultValue

/**
 * Helper function to get an array of all customer groups a user belongs
 *
 * @param userDetails
 * @returns Array of customer groups strings
 */
export const getUserCustomerGroups = (userDetails?: UserWithSession | null): NonEmptyArray<string> => {
	const userCustomerGroups =
		userDetails && isArrayWithItems(userDetails?.profile?.customerGroups) ? userDetails?.profile?.customerGroups : []
	let customerGroupNames: Array<string> = []

	if (userCustomerGroups && userCustomerGroups?.length > 0) {
		customerGroupNames = userCustomerGroups.map((group) => (typeof group === 'string' ? group : group.id))
	}

	return ensureSafeCustomerGroupsArray(customerGroupNames)
}

/**
 * Takes data from a ConstructorIO request and returns a
 * breadcrumb trail formatted identically to a UACAPI request.
 */
export const getCioBreadcrumbs = ({
	locale,
	baseUrl,
	query,
	trail,
	refinementAttributes,
}: {
	locale: string
	baseUrl: string
	query?: string
	trail?: CioBreadcrumb[]
	refinementAttributes?: SearchRefinementAttribute[]
}): BreadcrumbTrail => {
	if (!refinementAttributes) return []

	// For CLPs (CIO Browse Endpoint), the breadcrumb trail is not sorted on return.
	// We need to sort it to get the correct order of breadcrumbs.
	const orderedTrail =
		trail?.sort((a, b) => {
			const aVal = a.id?.split('-').length ?? 9999
			const bVal = b.id?.split('-').length ?? 9999
			return aVal - bVal
		}) ?? []

	let crumbs: BreadcrumbTrail = getBreadcrumbs(refinementAttributes, baseUrl, orderedTrail, locale)

	// Search breadcrumbs need to include the search query within the breadcrumb URL.
	// This is not returned by default so we need to manually build the URL to include
	// the query on search requests.
	if (query) {
		crumbs = crumbs.map((crumb) => {
			const { name, url } = crumb

			if (!url || !query) return crumb
			return {
				name,
				url: `${url}?q=${query}`,
			}
		})
	}

	return crumbs
}

/**
 * This function retrieves the sizes of a product from its variations map.
 *
 * @param product - The product object from which to retrieve sizes.
 * @returns An array of size variations for the product. If the product does not have a variations map, an empty array is returned.
 */
export const getProductSizes = (product: CIOProduct): SizeVariation[] => {
	if (!product.variations_map || product.variations_map?.length === 0) {
		return []
	}

	const sizes = new Set<string>()

	product.variations_map.forEach((variation) => {
		if (!variation.facets || !variation.facets.size || !variation.facets.size.length) {
			return
		}
		variation.facets.size?.forEach((size) => sizes.add(size))
	})

	return Array.from(sizes).map((size) => ({
		size,
	}))
}

/**
 * Helper function to pull either the promotional, sale, or list
 * price from a ConstructorIO Search API Response and show it as a range
 * on a Product Tile.
 *
 * @param product
 * @param customerGroups
 * @returns
 */
export const getProductPrices = (product: CIOProduct, customerGroups?: Array<string>) => {
	const list = {
		min: getConstructorFieldData(product, 'listPriceLow', null),
		max: getConstructorFieldData(product, 'listPriceHigh', null),
	}
	const sale = {
		min: getConstructorFieldData(product, 'salePriceLow', null),
		max:
			getConstructorFieldData(product, 'salePriceHigh', null) ||
			getConstructorFieldData(product, 'listPriceHigh', null),
	}

	// For promo pricing, we check to see if any of the customer groups that a user belongs
	// has an active promo. If so, we replace the sale prices the promo prices.
	if (customerGroups && product.data && product.data.groupPricing) {
		const groupIntersection = Object.keys(product.data?.groupPricing)?.filter((promoGroup) =>
			customerGroups.includes(promoGroup),
		)

		// Only replace sale prices if there is a listed minimum price
		if (groupIntersection?.length > 0 && product.data.groupPricing[groupIntersection[0]].min) {
			sale.min = product.data.groupPricing[groupIntersection[0]].min
		}
	}

	return { list, sale }
}

/**
 * Given a customer group string, returns the facet name of the given
 * for reference in a ConstructorIO facet.
 */
export const getGroupPriceFacetName = (customerGroup?: string): string =>
	customerGroup ? `Price ${customerGroup}` : ''

/**
 * Gets the lowest possible price from ConstructorIO for a particular product variant.
 * These prices are individualized to show variant-specific pricing and promotions
 * when a user hovers on a particular color chip within a Product Tile.
 */
export const getVariantPrices = (variant: CIOProductVariant, promotion?: CioPromotion) => {
	const { listPrice } = variant
	let { salePrice } = variant

	// When a particular variant has a promotion applied to it, we need to get the
	// promotional price. Then we must show the lowest possible price to the customer.
	if (promotion && promotion.customerGroups?.[0] && variant.facets) {
		const customerGroupFacet = getGroupPriceFacetName(promotion.customerGroups[0])
		const promoPrice = variant.facets[customerGroupFacet]?.[0]

		if (promoPrice < salePrice) {
			salePrice = promoPrice
		}
	}

	// Keeping this format for compatability with UACAPI product range pricing.
	// This can be altered if we also remove range pricing from UACAPI.
	return {
		list: {
			min: listPrice,
			max: listPrice,
		},
		sale: {
			min: salePrice,
			max: salePrice,
		},
	}
}

/**
 * Helper function to correctly filter and sort promotions.
 * If user customerGroups are available, only show promotions
 * that fall within those groups.
 *
 * @param promotions
 * @param userDetails
 * @returns An array of a single valid promotion
 */
export const getFilteredPromotions = (
	promotions: Array<CioPromotion>,
	customerGroups?: Array<string>,
): Array<CioPromotion> => {
	let filteredPromotions: Array<CioPromotion> = promotions

	// Get intersection of user customer groups and promotional customer groups
	if (customerGroups && customerGroups.length > 0) {
		filteredPromotions = promotions?.filter((promotion) => {
			if (promotion.customerGroups) {
				return promotion.customerGroups?.filter((value) => customerGroups.includes(value)).length > 0
			}
			return false
		})
	}

	if (filteredPromotions?.length > 0) {
		const rankedPromotions: Array<CioPromotion> = []
		const datedPromotions: Array<CioPromotion> = []

		filteredPromotions?.forEach((promotion) => {
			if (promotion.rank) {
				rankedPromotions.push(promotion)
			} else if (promotion.startDate) {
				datedPromotions.push(promotion)
			}
		})

		if (rankedPromotions.length > 0) {
			rankedPromotions.sort((first, second) => {
				const firstRank = first.rank ? Number(first.rank) : 0
				const secondRank = second.rank ? Number(second.rank) : 0
				return firstRank - secondRank
			})
			return [rankedPromotions[0]]
		}
		if (datedPromotions.length > 0) {
			datedPromotions.sort((first, second) => {
				const firstDate = first.startDate ? new Date(first.startDate).valueOf() : 0
				const secondDate = second.startDate ? new Date(second.startDate).valueOf() : 0
				return firstDate - secondDate
			})
			return [datedPromotions[0]]
		}

		return [filteredPromotions[0]]
	}

	return []
}

/**
 * Helper function to return a 3-digit string for the color way id
 * of a product regardless if CIO returns the value as a string,
 * or object
 *
 * @param color
 */
export const getCioColorWayId = (colorway: string | { color: string } = '001') => {
	if (!colorway) return null

	return String(typeof colorway === 'string' ? colorway : colorway.color).padStart(3, '0')
}

/**
 * Returns an object that contains a localized size for the defined sizes
 * contained in a ShopTheLookColors object. This is needed for localized
 * sizes and differences in CIO vs UACAPI size filters.
 *
 * For example:
 * {
 * 		XS: "XS",
 * 		SM: "S",
 * 		MD: "M",
 * 		...
 * }
 */
export const getLocalizedSizeMap = (refinements?: CioSearchRefinementAttribute[]): SizeMapOptionsType => {
	const sizeFilter = refinements?.find((filter) => filter?.attributeId === 'c_size')
	const shopTheLookSizeOptions = Object.keys(SIZE_MAP).reduce((a, v) => ({ ...a, [v]: v }), {}) as SizeMapOptionsType

	if (sizeFilter && sizeFilter?.values?.length > 0) {
		sizeFilter.values.forEach((size) => {
			const sizeIsValidSizeOption = shopTheLookSizeOptions[size.label]
			const sizeHasBucketedValues = size.values && size.values?.length > 0

			// If the current size option is not a shop the look option,
			// search it's bucketed values to determine if it should replace
			// one of the default STL sizes
			if (!sizeIsValidSizeOption && sizeHasBucketedValues) {
				const shopTheLookOption = size.values?.find((option) => shopTheLookSizeOptions[option])
				if (shopTheLookOption) {
					shopTheLookSizeOptions[shopTheLookOption] = size.label
				}
			}
		})
	}

	return shopTheLookSizeOptions
}

/**
 * Captures size of model from image id
 */
const getModelSize = (id: string, sizeMap: SizeMapOptionsType): string | undefined => {
	const size = id.split('_').pop() ?? ''
	const cleanedSize = size === 'BC' || size === 'FC' ? 'SM' : size

	return sizeMap[cleanedSize] ?? size
}

/**
 * Given an array of Size Model Images, returns a cleaned array of images
 * formatted for ShopTheLook categories
 */
const getSizedModelImages = (
	modelImages: Array<SizeModelImage>,
	sizeMap: SizeMapOptionsType,
): Array<ShopTheLookImage> => {
	const images: Array<ShopTheLookImage> = []

	modelImages.forEach((size) => {
		if (size?.frontImage) {
			images.push({
				image: size.frontImage,
				materialCodes: null,
				modelSize: getModelSize(size.frontImage, sizeMap),
			})
		}
		if (size?.backImage) {
			images.push({
				image: size.backImage,
				materialCodes: null,
				modelSize: getModelSize(size.backImage, sizeMap),
			})
		}
	})

	return images
}

/**
 * Helper function to mimic the shopTheLookColors array
 * using the product variations from CIO
 *
 * @param product
 * @returns
 */
export const getShopTheLookColors = (
	product: CIOProduct,
	refinements?: CioSearchRefinementAttribute[],
): Array<ShopTheLookColor> => {
	const hasVariationsMap = Boolean(product.variations_map)
	let images: Array<ShopTheLookImage> = []
	const sizeMap = getLocalizedSizeMap(refinements)

	if (hasVariationsMap) {
		const colorImages: Array<ShopTheLookColor> = []

		product.variations_map?.forEach((variant) => {
			const color = getCioColorWayId(variant.colorWayId)

			if (variant.sizeModelImages?.length > 0 && !!color) {
				images = getSizedModelImages(variant.sizeModelImages, sizeMap)

				if (images.length > 0) {
					colorImages.push({
						color,
						outfit: [],
						images,
					})
				}
			}
		})

		return colorImages
	}

	const colors = {}
	product.variations?.forEach((variant) => {
		const color = getCioColorWayId(variant.data.colorWayId)

		if (variant.data.sizeModelImages && !!color) {
			const current = colors[color]
			images = getSizedModelImages([variant.data.sizeModelImages], sizeMap)

			if (images.length > 0) {
				colors[color] = { images: current ? current.images.concat(images) : images }
			}
		}
	})

	return Object.keys(colors)
		? Object.keys(colors).map((color) => ({
				color,
				outfit: [],
				images: colors[color].images,
		  }))
		: []
}

/**
 * Retrieves an array of orderable color variants for a product
 * regardless if CIO Response returns variant data back in a
 * 'variations' array (Autocomplete) or a `variations_map` array (Search)
 */
export const getVariantData = ({
	product,
	customerGroups,
	giftCardType = GiftCardType.NONE,
	isSuggestion = false,
}: {
	product: CIOProduct
	customerGroups?: Array<string>
	giftCardType?: GiftCardType
	isSuggestion?: boolean
}): PricedColorVariant[] => {
	const isVariationsMap = Boolean(product.variations_map)
	let filteredVariations: CIOProductVariant[] | undefined

	// Cleans CIO data by removing any variant data without the necessary
	// fields (colorway, hideColorway, orderable, image_url)
	if (isVariationsMap) {
		filteredVariations = product.variations_map?.filter(
			(v) => !!v?.colorWayId && !v?.hideColorWay && !!v?.orderable && !!v?.image_url,
		)
	} else {
		filteredVariations = product.variations?.reduce(
			(result, option) => {
				const filtered = { ...result }
				const { data } = option
				const colorWayId = data.colorWayId ? getCioColorWayId(data.colorWayId) : undefined

				if (colorWayId && !filtered[colorWayId] && !data?.hideColorWay && data.orderable && data.image_url) {
					filtered[option.data.colorWayId.color] = true
					filtered.variants.push({
						...option.data,
						title: option.value,
					})
				}

				return filtered
			},
			{ variants: [] },
		).variants
	}

	if (filteredVariations && filteredVariations?.length > 0) {
		// Find the list of active promotions that apply to the user given their customer groups
		const filteredPromotions = getFilteredPromotions(getConstructorFieldData(product, 'promotions', []), customerGroups)

		// Determine and use range pricing for gift cards
		const giftCardPricing = isGiftCard(giftCardType) ? getProductPrices(product) : null

		return filteredVariations
			.sort((a) => (a.image_url === getConstructorFieldData(product, 'image_url', null) ? -1 : 0))
			.map((variant): PricedColorVariant => {
				// Find/determine if the current variant is contained within any valid promo
				const variantPromo = filteredPromotions?.[0]
				const variantPrice = giftCardPricing || getVariantPrices(variant, variantPromo)

				// When available, grab the SizeModel images from the product
				const sizeModel = ensureNonNullishArray(variant.sizeModelImages)?.[0]
				const sizeModelImages = {
					frontImage: sizeModel?.frontImage ? productTileImgBuilder(sizeModel.frontImage) : null,
					backImage: sizeModel?.backImage ? productTileImgBuilder(sizeModel.backImage) : null,
				}

				return {
					color: getCioColorWayId(variant?.colorWayId) || '',
					colorway: variant?.colorWay || null,
					hex: ensureString(variant?.hexColor) || '',
					hideColorway: Boolean(variant.hideColorWay),
					isLoyaltyExclusiveColor: variant?.isLoyaltyExclusive || false,
					name: variant.facets?.team?.[0] || variant?.colorValue || '',
					secondaryHex: ensureString(variant.secondaryHexColor) || '',
					orderable: variant.orderable || false,
					assets: {
						alt: variant.imageName || '',
						images: [
							{
								assetName: variant.imageName || '',
								url: sizeModelImages.frontImage || variant.image_url?.replace('http://', 'https://') || '',
							},
							{
								assetName: variant.imageName || '',
								url: sizeModelImages.backImage || variant.gridTileHoverImageURL?.replace('http://', 'https://') || null,
							},
						],
						title: variant.title || null,
					},
					team: variant.facets?.team?.[0] ?? null,
					price: variantPrice ?? undefined,
					productPromotions: variantPromo
						? [
								{
									calloutMsg: variantPromo.callOut || '',
									promotionId: variantPromo.id || null,
									tooltip: !isSuggestion && variantPromo.toolTipText ? variantPromo.toolTipText : null,
								},
						  ]
						: [],
				}
			})
	}

	return []
}

type GetFaceourVariantResult = {
	faceOutVariant: PricedColorVariant
	index?: number
}

function getFaceoutVariant({
	product,
	variants,
	requestContext,
}: {
	product: CIOProduct
	variants: PricedColorVariant[]
	requestContext?: SearchProductsQuery
}): GetFaceourVariantResult {
	const faceOutColorId = getCioColorWayId(product.colorwayId ? product.colorwayId : product.data?.colorWayId)
	const defaultFaceOutVariant = variants?.[0]

	if (requestContext?.pmax) {
		const pMax = parseInt(requestContext?.pmax || '', 10)
		let index = -1
		const variant = variants.find((item, i) => {
			if (item?.price?.list?.max && item?.price?.sale?.max) {
				if (item?.price?.list?.max <= pMax || item?.price?.sale?.max <= pMax) {
					index = i
					return item
				}
			}

			return undefined
		})

		return {
			faceOutVariant: variant ?? defaultFaceOutVariant,
			index,
		}
	}

	return {
		faceOutVariant: variants.find((v) => v.color === faceOutColorId) ?? defaultFaceOutVariant,
	}
}

/**
 *
 * this function is only modifying the existing varaints array no need to return a new array
 */
function sortVariants({ variants, index }: { variants: PricedColorVariant[]; index: number | undefined }) {
	if (index && index !== -1) {
		variants.unshift(variants.splice(index, 1)[0])
	}
}

/**
 * This converts Constructor-structured search responses to Front-end Product Tile Structures
 * @param cioResponse The unmodified getAutocompleteResults Response
 */
export const convertConstructorResponseToProducts = ({
	products,
	userDetails,
	refinements = [],
	isSuggestion = false,
	requestContext,
}: {
	products: CIOProduct[]
	userDetails?: UserWithSession | null
	refinements?: SearchRefinementAttribute[] | CioSearchRefinementAttribute[]
	isSuggestion?: boolean
	requestContext?: SearchProductsQuery
}): ClientProductTile[] => {
	if (!products || products?.length === 0) return []

	const customerGroups = getUserCustomerGroups(userDetails)

	return products.map((product): ClientProductTile => {
		const giftCardType =
			GiftCardType[
				ensureString(getConstructorFieldData(product, 'giftCardType', GiftCardType.NONE).replace('_', ''))
			] ?? GiftCardType.NONE
		const variants = getVariantData({ product, customerGroups, giftCardType, isSuggestion })
		const { faceOutVariant, index } = getFaceoutVariant({ product, variants, requestContext })
		sortVariants({ variants, index })

		/*
			CIO sets the "orderable" variable on a parent product by rolling up the
			orderable value fromthe most "attractive" color. This sometimes sets orderable
			to false on the parent if that color is sold out. This is a check that
			there are no other colors available before setting that value to ensure 
			"Sold Out" messaging isn't falsely attached to a product.
		*/
		const hasOrderableVariants = variants.length > 0

		return {
			id: getConstructorFieldData(product, 'variation_id', ''),
			currency: getConstructorFieldData(product, 'priceCurrency', ''),
			style: getConstructorFieldData(product, 'id', ''),
			name: getConstructorFieldData(product, 'value', null),
			price: faceOutVariant?.price ? faceOutVariant.price : getProductPrices(product, customerGroups),
			badges: {
				bottomLeft: getConstructorFieldData(product, 'badgeImage', null),
				upperLeft: product.data?.badge ? BADGE_MAP[product.data.badge] ?? product.data.badge : null,
				upperLeftFlameIcon: getConstructorFieldData(product, 'upperLeftFlameIcon', null),
			},
			orderable: hasOrderableVariants ? true : getConstructorFieldData(product, 'orderable', false),
			preorderable: getConstructorFieldData(product, 'preorderable', false),
			url: getConstructorFieldData(product, 'url', '').replace(SFCC_UPC_PAGE_REGEX, `/${product.data?.id}.html`), // Once Constructor returns url with style, delete this replace
			productPromotions: faceOutVariant?.productPromotions ? faceOutVariant.productPromotions : [],
			shopTheLookColors: getShopTheLookColors(product, refinements) || [],
			colors: variants,
			sizes: getProductSizes(product),
			exclusiveType: getConstructorFieldData(product, 'exclusiveType', ExclusiveType.NONE)
				.toUpperCase()
				.replaceAll('-', '_'),
			tilePreorderMessage: product.data?.preorderMessage?.tileMessage ?? '',
			comingSoonMessage: getConstructorFieldData(product, 'comingSoonMessage', ''),
			isSliced: getConstructorFieldData(product, 'isSliced', false),
			isLoyaltyExclusive: getConstructorFieldData(product, 'isLoyaltyExclusive', false),
			giftCardType,
			experienceType:
				ProductExperienceType[
					ensureString(getConstructorFieldData(product, 'experienceType', ProductExperienceType.BOTH)).toUpperCase()
				] ?? ProductExperienceType.BOTH,
		}
	})
}

/**
 * This converts a Constructor search response to a usable linked search suggestion object
 * @param cioResponse Array of Cio Category Suggestions or Search Suggestions
 */
export const getConstructorLinkedSuggestions = (cioResponse: CIOSuggestion[], useCategoryData = false) => {
	// Handler for empty suggestion results
	if (!cioResponse || cioResponse?.length === 0) return []

	// When using CIO Category Suggestions, include parent category in returned object
	// This is used to improve the displayed string in the SearchSuggestionsResults.tsx
	if (useCategoryData) {
		return cioResponse
			.filter(
				(c, index, arrRet) =>
					c.data?.id !== SFCC_ROOT_CATEGORY_ID &&
					arrRet.findIndex(
						(i) =>
							i.data?.data?.categoryDisplayName === c.data?.data?.categoryDisplayName &&
							i.data?.data?.categoryParentName === c.data?.data?.categoryParentName,
					) === index,
			)
			.map(
				(category): LinkedSuggestion => ({
					id: ensureString(category.data?.id),
					url: category.data?.data?.categoryAltUrl ?? category.data?.data?.categoryUrl ?? '',
					displayName: ensureString(category.data?.data?.categoryDisplayName),
					parent: {
						id: category.data?.data?.categoryParentId,
						name: category.data?.data?.categoryParentName,
					},
				}),
			)
	}

	return cioResponse.map((suggestion) => ({
		id: ensureString(`${suggestion.value}-${suggestion.result_id}`),
		displayName: ensureString(suggestion.value),
		url: buildSearchUrl(suggestion.value),
	}))
}

/**
 * Helper function to convert search filters/refinements from UACAPI
 * format to CIO format.
 *
 * @param userRefinements
 * @returns object of refinements in CIO format
 */
export const getCioSearchRefinements = (userRefinements: CustomRefinementInput[]): Refinement => {
	const filters: Refinement = {}

	if (userRefinements.length > 0) {
		// Swap keys/values of FACET_MAP to use CIO naming convention
		const refinementIds = {}
		Object.keys(FACET_MAP).forEach((key) => {
			refinementIds[FACET_MAP[key]?.uacapiId] = key
		})

		userRefinements?.forEach((refinement) => {
			if (refinement.attributeId) {
				filters[refinementIds[refinement.attributeId]] =
					refinement.attributeId !== FACET_MAP[CIO_PRICE_FACET_ID].uacapiId
						? refinement.values
						: refinement.values?.map((value) =>
								value
									.replace(SFCC_RANGE_SEPARATOR, CIO_RANGE_SEPARATOR)
									.replaceAll(CIO_RANGE_REGEX, '')
									.replace(CIO_RANGE_NEG_INF_REGEX, CIO_RANGE_NEG_INF)
									.replace(CIO_RANGE_INF_REGEX, CIO_RANGE_INF),
						  )
			}
		})
	}

	return filters
}

/**
 * Helper function to convert UACAPI sort option to format
 * used by CIO.
 *
 * @param sortRule UACAPI sort term
 * @returns {CioSortOption} that contains sortBy param and sortOrder param
 */
export const getCioSortOptions = (sortRule: string): CioSortOption => SORT_OPTIONS_MAP[sortRule] ?? {}

/**
 * Builds price filter expressions for ConstructorIO request
 */
export const getCioFilterExpressions = ({
	requestContext,
	customerGroups,
	locale,
}: {
	requestContext: SearchProductsQuery
	customerGroups?: Array<string>
	locale?: string
}): FilterExpression => {
	const [selectedPriceLow, selectedPriceHigh] = getFilterRefinementPrice(requestContext, locale)
	const promotionInclusions: Array<FilterExpression> = [
		{
			name: CIO_PRICE_FACET_ID,
			range: [selectedPriceLow, selectedPriceHigh],
		},
	]

	customerGroups?.forEach((group) => {
		promotionInclusions.push({
			name: getGroupPriceFacetName(group),
			range: [selectedPriceLow, selectedPriceHigh],
		})
	})

	return { or: promotionInclusions }
}

/**
 * Gets the displayable name for a CIO facet that is presented in the
 * left filter menu on PLP/CLPs
 *
 * @param facet
 * @param categoryId
 * @returns displayable name for given facet
 */
const getFacetDisplayName = (facet: Facet, categoryId?: string): string => {
	let displayName = facet.display_name
	const hasCategoryOverrides = categoryId && facet.data?.categoryOverrides

	if (hasCategoryOverrides && facet.data.categoryOverrides[categoryId]) {
		displayName = facet.data.categoryOverrides[categoryId]
	} else if (facet.data?.uaDisplayName) {
		displayName = facet.data?.uaDisplayName
	}

	return displayName
}

/**
 * Converts an array of refinement objects into a hashable object
 * by refinement attributeId
 * @param activeRefinements
 * @returns
 */
export const getActiveRefinementsObject = (activeRefinements: SearchRefinementAttribute[]): HashMap =>
	activeRefinements.reduce((acc, cur) => ({ ...acc, [cur.attributeId]: cur.label }), {})

/**
 * Reformats a ConstructorIO Facet option to mimic the same format as a refinement
 * object returned by UACAPI. This allows us to use CIO Facets data without
 * any changes to the components
 */
const getFacetOption = (
	option: FacetOption,
	attributeId: string,
	customRefinementsObject?: object,
): CioSearchRefinementAttributeValue => {
	let isSelected = false

	// Manually CIO Filter Options as Selected
	if (customRefinementsObject && Boolean(customRefinementsObject[attributeId])) {
		if (attributeId === 'price') {
			const selectedPriceRange = customRefinementsObject[attributeId][0].match(/\d+/g)

			if (selectedPriceRange.length === 2 && option.range?.length === 2) {
				const currentOptionLow = option.range[0] === '-inf' ? `${PRICE_RANGE_LOWER_BOUND}` : `${option.range[0]}`
				const currentOptionHigh = option.range[1] === 'inf' ? `${PRICE_RANGE_UPPER_BOUND}` : `${option.range[1]}`
				const rangesAreEqual = currentOptionLow === selectedPriceRange[0] && currentOptionHigh === selectedPriceRange[1]

				isSelected = option.status === CIO_SELECTED || rangesAreEqual
			}
		} else {
			isSelected = option.status === CIO_SELECTED || customRefinementsObject[attributeId]?.includes(option.value)
		}
	}

	// For the color filters, we use the description to determine
	// what hex color or pattern to show. Otherwise, the option
	// description is unused
	let optionDescription = ''
	if (attributeId === FACET_MAP.colorGroup.uacapiId) {
		if (option.data?.hexCode) {
			optionDescription = option.data?.hexCode
		} else if (option.data?.imgUrl) {
			optionDescription = option.data?.imgUrl
		} else if (COLOR_GROUP_HEX[option.display_name]) {
			optionDescription = COLOR_GROUP_HEX[option.display_name]
		}
	}

	// For the team filters, we use the description to determine
	// what image to show.
	if (attributeId === FACET_MAP.team.uacapiId) {
		optionDescription = option?.data?.logo || ''
	}

	// We need translated sizes for the ShopTheLookColors functionality.
	// For this, we need to pull out the buckets values for each size option
	let containedValues: undefined | string[]
	if (attributeId === 'c_size' && option.data?.values?.length > 0) {
		containedValues = option.data.values
	}

	return {
		description: optionDescription,
		hitCount: option.count,
		label: option.display_name,
		selected: isSelected,
		...(containedValues && { values: containedValues }),
	}
}

/**
 * Helper function to take CIO Facets and convert them
 * to the same format as UACAPI Refinements. Given
 * customRefinements, it will return the Refinement
 * Attributes
 *
 * @param cioFacets
 * @param customRefinements
 * @returns
 */
export const convertFacetsToRefinements = ({
	facets,
	customerGroups,
	customRefinements,
	activeRefinements,
	categoryId,
}: {
	facets: Array<Partial<Facet>>
	customerGroups?: Array<string>
	customRefinements?: CustomRefinementInput[]
	activeRefinements?: HashMap
	categoryId?: string
}): SearchRefinementAttribute[] => {
	if (!facets || facets?.length === 0) return []

	const refinements: SearchRefinementAttribute[] = []
	const customRefinementsObject = customRefinements?.reduce(
		(arr, refinement) => ({ ...arr, [refinement.attributeId]: refinement.values }),
		{},
	)

	// Need to track these separately because we find the union of all
	// arrays for filter categories
	let isGiftsCategory = false
	const includedPriceBuckets = {}
	const priceBucketOptions: SearchRefinementAttributeValue[] = []
	let priceFacet: Facet | undefined

	facets.forEach((facet) => {
		if (facet.name) {
			const attributeId = FACET_MAP[facet.name]?.uacapiId
			// When given an activeRefinements hash map, ensure the current facet
			// is contained as an active filter. Otherwise, omit it from visible filters
			const isActiveFacet = activeRefinements ? activeRefinements[attributeId] : true

			// Gift categories like 'top-gifts' handle pricing differently, these vars
			// are used to track when we need to account for this
			const isGiftsFacet = facet.name === GIFTS_FACET_ID
			if (isGiftsFacet) isGiftsCategory = true

			if (isActiveFacet) {
				const options: SearchRefinementAttributeValue[] = []
				const isPriceFacet = facet.name === CIO_PRICE_FACET_ID
				const isValidCustomerGroupFacet =
					facet.name.includes('Price ') && customerGroups?.includes(facet.name.substring(6))

				if (attributeId && facet.options && facet.options.length > 0) {
					if (!isPriceFacet) {
						facet.options?.forEach((option) =>
							options.push(getFacetOption(option, attributeId, customRefinementsObject)),
						)
						const displayName = getFacetDisplayName(facet as Facet, categoryId)

						refinements.push({
							label: displayName,
							attributeId,
							values: options,
						})
					} else if (isPriceFacet || isValidCustomerGroupFacet || isGiftsFacet) {
						facet.options?.forEach((option) => {
							const { display_name: displayName } = option
							const hasArrayValues = Boolean(option.range?.[0])

							// Use the salePriceLow facet to get the localized price name
							if (isPriceFacet) {
								priceFacet = facet as Facet
							}

							// If facet has price range buckets, display all unique buckets options
							if (!Object.keys(includedPriceBuckets).includes(displayName) && hasArrayValues) {
								const rangeLow = option.range[0] === '-inf' ? 0 : option.range[0]
								includedPriceBuckets[displayName] = rangeLow
								priceBucketOptions.push(getFacetOption(option, 'price', customRefinementsObject))
							}
						})
					}
				}
			}
		}
	})

	if (priceBucketOptions.length > 0) {
		priceBucketOptions.sort((a, b) => includedPriceBuckets[a.label] - includedPriceBuckets[b.label])
		const localizedName = priceFacet && getFacetDisplayName(priceFacet)
		const facetDetails = FACET_MAP[isGiftsCategory ? GIFTS_FACET_ID : CIO_PRICE_FACET_ID]

		refinements.push({
			label: localizedName ?? facetDetails.displayName,
			attributeId: facetDetails.uacapiId,
			values: priceBucketOptions,
		})
	}

	return refinements
}

/**
 * Uses ConstructorIO's Browse endpoint to get all the facets for a given category
 * @param cioClient
 * @param groupId UA category.id string
 * @returns
 */
export const getAllCioFacets = (
	cioClient: ConstructorIONode,
	groupId = 'root',
): Promise<CioSearchRefinementAttribute[]> =>
	cioClient?.browse?.getBrowseResults(CIO_BROWSE_GROUP, groupId, { resultsPerPage: 1 }).then((data) => {
		if (data.response?.facets) {
			return convertFacetsToRefinements({ facets: data.response?.facets })
		}
		return []
	}) ?? []

/**
 * Helper function that returns Base64 enconded string
 * used by search page for pagination end cursor. Mimics
 * the same functionality as in UACAPI:
 * https://github.com/ua-digital-commerce/ua-commerce-api/blob/dc131fb6c74eefaf8b771271ae4d54738cb4cc74/src/lib/pagination.ts
 *
 * @param offset starting offset of first product on page
 * @param count number of products shown on a given page
 * @returns Base64 enconded string
 */
export const getEndCursor = (offset: number, count: number) => btoa(`cursor:${offset + count}`)

export async function fetchUACAPISearchResults({
	locale,
	requestContext,
	searchTerm,
	sortRule,
	offset,
	refinementUrl,
	category = null,
}: {
	locale: string
	requestContext: SearchProductsQuery
	searchTerm: string
	sortRule: string
	offset: number
	refinementUrl: string
	category?: Category | null
}): Promise<ClientProductList> {
	const apolloClient = standAloneApolloClient(locale)
	const allRefinements = await searchRefinements(apolloClient, searchTerm, 12)
	const customRefinements = getFilterRefinementInput(allRefinements, refinementUrl, requestContext, locale)

	return getProductListByCategory(apolloClient, {
		refinement: {
			...(category !== null && { category: category.id }),
			customRefinements: customRefinements.refinements,
		},
		amount: PRODUCTS_PER_PAGE,
		sortDirection: sortRule,
		offset,
		query: searchTerm,
	})
}

/**
 * Utility function responsible for making api calls to the ConstructorIO
 * Search & Browse endpoints
 *
 * @param client cio javascript client, if available
 * @param fetchParams parameters used to build cio request
 * @param parameters search paramters passed alongside cio request
 * @returns
 */
const fetchCioData = ({
	cioClient,
	fetchParams,
	parameters,
	isSearchQuery,
	shouldUseGiftPricing,
}: CioRequest): Promise<SearchResponse | GetBrowseResultsResponse> | undefined => {
	// Search Query
	if (isSearchQuery) {
		const { query } = fetchParams as CioSearchEndpointParams
		const networkParameters = {
			timeout: getPublicConfig().cio.network_timeout,
		}

		return cioClient?.search.getSearchResults(query, parameters, networkParameters)
	}

	// Browse Query
	const { filterName, filterValue } = fetchParams as CioBrowseEndpointParams
	// When using gift categories that use their own price filters,
	// we need to manually unhide the giftsByPrice facet
	return cioClient?.browse?.getBrowseResults(filterName, filterValue, {
		...parameters,
		...(shouldUseGiftPricing ? { hiddenFacets: [GIFTS_FACET_ID] } : {}),
	})
}

/**
 * Returns a Search/Browse response from ConstructorIO formatted
 * to mimic a UACAPI response that can be used in a Search/Category
 * PLP page.
 */
export const getCioResults = async ({
	user,
	locale,
	fetchParams,
	requestContext,
	allRefinements,
	cioClient,
	sortOptions,
	refinementUrl = SEARCH_REFINEMENT_URL,
}: CioResultsProps): Promise<CioProductList> => {
	const customerGroups = getUserCustomerGroups(user)
	const { start = 0, srule = DEFAULT_SORT_RULE } = requestContext
	const offset = ensureNumber(+start)
	const client = cioClient ?? getConstructorClientForBrowser({ locale, user })

	const searchParams: SearchParameters = {
		offset,
		resultsPerPage: NUMBER_PRODUCTS_PER_PAGE,
		...getCioSortOptions(srule),
		variationsMap: VARIATIONS_MAP,
	}

	// For specific gifting categories, we use different price filter options.
	// We need to account for this to ensure the right filters populate
	const isSearchQuery = 'query' in fetchParams
	const shouldUseGiftPricing = Boolean(!isSearchQuery && GIFTS_PRICE_MAP[fetchParams.filterValue])

	const customCioRefinements = getFilterRefinementInput(
		allRefinements,
		refinementUrl,
		requestContext,
		locale,
		shouldUseGiftPricing,
	)
	const cioSearchFilters = getCioSearchRefinements(customCioRefinements.refinements)
	const activeRefinements = getActiveRefinementsObject(allRefinements)

	// When price parameters are included, we need to build a filter expression
	// to appropriately account for promotional product pricing
	if (cioSearchFilters[CIO_PRICE_FACET_ID]) {
		// The filter expression replaces the need for price as a filter
		// so we can remove it from the listed filters.
		delete cioSearchFilters[CIO_PRICE_FACET_ID]
		const preFilterExpression = getCioFilterExpressions({
			requestContext,
			customerGroups,
			locale,
		})

		if (preFilterExpression) {
			searchParams.preFilterExpression = preFilterExpression
		}
	}

	// Add remaining filters to CIO Params
	if (Object.keys(cioSearchFilters).length > 0) {
		searchParams.filters = {
			...cioSearchFilters,
		}
	}

	const cioResponse = await fetchCioData({
		cioClient: client,
		fetchParams,
		parameters: searchParams,
		isSearchQuery,
		shouldUseGiftPricing,
	})?.catch((err) => {
		throw new Error(`[Constructor] ${err}`)
	})

	const cioRefinementAttributes = convertFacetsToRefinements({
		facets: cioResponse?.response?.facets,
		customerGroups,
		customRefinements: customCioRefinements?.refinements,
		activeRefinements,
		categoryId: !isSearchQuery ? fetchParams.filterValue : undefined,
	})
	const cioBreadcrumbTrail = cioResponse?.response?.groups[0]?.data?.parents ?? []
	const formattedCIOProducts = cioResponse
		? convertConstructorResponseToProducts({
				products: cioResponse?.response?.results,
				userDetails: user,
				refinements: cioRefinementAttributes,
				requestContext,
		  })
		: []
	const numberOfResults = ensureNumber(cioResponse?.response?.total_num_results) ?? 0

	return {
		paginationInfo: {
			endCursor: getEndCursor(offset, numberOfResults),
			hasNextPage: false,
		},
		refinementAttributes: cioRefinementAttributes,
		sortingOptions: sortOptions,
		products: formattedCIOProducts,
		totalCount: numberOfResults,
		currentRefinements: customCioRefinements.refinements,
		breadcrumbTrail: cioBreadcrumbTrail,
	}
}
