/* eslint-disable no-underscore-dangle */
import { useCallback } from 'react'
import { useLocale } from './useLocale'
import { getTranslationsByLocale } from '~/lib/i18n/text'
import { createIntl } from '@formatjs/intl'
import type { PrimitiveType, FormatXMLElementFn } from 'intl-messageformat'
import { ensureString } from '~/types/strict-null-helpers'

/**
 * We keep a cache of intl objects so that we don't have to create a new one each time we
 * need to format a message.  We also keep one per locale so that we can switch between
 * them as needed without having to recreate them.
 */
const _intl: Record<string, ReturnType<typeof createIntl<React.ReactElement>>> = {}

/**
 * Creates a new instance of the intl object based on the current locale.  This is used to
 * format messages based on the current locale.  We are using FormatJS behind the scenes to
 * handle the formatting and caching of messages.
 * @param locale
 * @returns
 */
function getIntl(locale: string) {
	if (!(locale in _intl)) {
		_intl[locale] = createIntl<React.ReactElement>({
			locale,
			messages: getTranslationsByLocale(locale),
		})
	}
	return _intl[locale]
}

// eslint-disable-next-line -- The following implementation signature cannot be called directly
type FinalValues = any

function formatMessageCallback(id: string, values?: Record<string, PrimitiveType>, locale?: string): string
function formatMessageCallback<T extends string | React.ReactElement | (React.ReactElement | string)[]>(
	id: string,
	values?: Record<string, PrimitiveType | React.ReactElement | FormatXMLElementFn<React.ReactElement>>,
	locale?: string,
): T
function formatMessageCallback(id: string, values?: FinalValues, locale?: string) {
	return formatMessage(id, ensureString(locale), values)
}

/**
 * Use this hook to get a function that formats messages based on the current locale.  It
 * uses FormatJS behind the scenes to the formatting and caching of messages.  We are not
 * using the intl provider here for reasons related to bandwidth (more details below).
 *
 * Why Not Use IntlProvider?
 *
 * We are not using IntlProvider here because in order to support IntlProvider's rendering
 * of translations, we would have to pass in the messages (a very large object) into the providers
 * params.  This is problematic when it comes to NextJS because of the way that React Server
 * Components work.  Each time a component (or page) is rendered, the payload will include the
 * messages for the current locale.  This is a lot of data to be sending over the wire for each
 * request.  Instead, we are using this hook to create an instance of the intl object and then
 * using that to format messages.  This way, we only send the messages over the wire once and
 * then we can use the instance to format messages.  While this does increase the size of the
 * static bundle, that will only be downloaded once per version and then cached in the browser.
 *
 * Opportunities for Improvement
 *
 * In the near future we should consider dropping the provider entirely and using our own hook
 * to handle all internationalization needs (it can still use FormatJS behind the scenes).  This
 * would allow us to have more control over the caching and formatting of localizable content and
 * control the way we send the translations over the wire.
 *
 * @returns
 */
export const useFormatMessage = () => {
	const locale = useLocale()

	/**
	 * Cast callback to the overloaded `formatMessageCallback` function
	 * defined above to get the correct return type inference.
	 *
	 * @example
	 *   const text = formatMessage('message')
	 *     => string
	 *
	 * @example
	 *   const node = formatMessage('qr-list-item-3', {
	 *     bold: text => <b>{text}</b>,
	 *   })
	 *     => string | React.ReactElement | (React.ReactElement | string)[]
	 */
	return useCallback<typeof formatMessageCallback>(
		(id: string, values?: FinalValues) => {
			return formatMessageCallback(id, values, locale)
		},
		[locale],
	)
}

/**
 * This version of formatMessage can be used outside of the context of a component.  It is
 * useful for formatting messages in places where you don't have access to the intl provider.
 * @param id
 * @param locale
 * @param values
 * @returns
 */
export function formatMessage(id: string, locale: string, values?: Record<string, PrimitiveType>): string
export function formatMessage<T extends string | React.ReactElement | (React.ReactElement | string)[]>(
	id: string,
	locale: string,
	values?: Record<string, PrimitiveType | React.ReactElement | FormatXMLElementFn<React.ReactElement>>,
): T
export function formatMessage(id: string, locale: string, values?: FinalValues) {
	const intl = getIntl(locale)
	if (intl) {
		const formatted = intl.formatMessage({ id }, values)
		return formatted
	}

	return id
}
