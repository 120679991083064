/**
 * This will return the current context that the code is running in.  This is useful
 * for determining if the code is running in the browser, server, or edge.
 * @returns The current context that the code is running in.  This will be one of the following:
 * 	- build: This is running in a CI/CD environment (github actions or Vercel CI/CD)
 * 	- edge: This is running in the edge runtime (cloudflare)
 * 	- server: This is running in the server runtime (node)
 * 	- client: This is running in the client runtime (browser)
 *
 */
export function getContext(): 'build' | 'edge' | 'server' | 'client' {
	if (process.env.CI) {
		return 'build'
	}

	if (typeof globalThis.EdgeRuntime === 'string') {
		return 'edge'
	}

	if (typeof window === 'undefined') {
		return 'server'
	}

	return 'client'
}
