import type { FeatureFlags } from '~/graphql/generated/uacapi/type-document-node'

export type AbTestValue = boolean | string
export interface AbTestList {
	[key: string]: AbTestValue
}

export function deconstructAbTestString(test: string): [string, AbTestValue] {
	const [key, value] = test.split(':').map((v) => v.trim())

	// If the value is not set, default to true but also use values of 1, true, and yes as true
	if (!value || ['1', 'true', 'yes'].includes(value.toLowerCase())) {
		return [key, true]
	}

	// Use values of 0, false, and no as false
	if (['0', 'false', 'no'].includes(value.toLowerCase())) {
		return [key, false]
	}

	// Otherwise, return the value as a string
	return [key, value]
}

/**
 * Construct a list of AB tests to run based on the feature flags and the list of tests.
 * This will filter out any tests that are not in the feature flags and will remove duplicates
 * from the list.  Ab tests can be passed in as a string with a colon separating the key and value.
 * For example: 'test1:true', 'test2:false', 'test3:1', 'test4:0', 'test5:yes', 'test6:no'
 * @param features
 * @param tests
 */
export function constructAbTestList(
	features: Partial<FeatureFlags>,
	currentList: AbTestList,
	...tests: Array<string[]>
): AbTestList {
	// Take the array of arrays and flatten it into a single array, filter out duplicates and then
	//	filter out any tests that are not in the feature flags
	const newTests = [...new Set(tests.flat())].reduce((prev, curr) => {
		const [key, value] = deconstructAbTestString(curr)
		if (features && key in features && features[key] === false) {
			return { ...prev, [key]: false }
		}
		return { ...prev, [key]: value }
	}, {})

	// Merge the current list with the new list
	return { ...currentList, ...newTests }
}
