import type { ApolloQueryResult } from '@apollo/client'
import type { ContentAssetsQuery } from '~/graphql/generated/uacapi/type-document-node'
import { ContentAssetsDocument } from '~/graphql/generated/uacapi/type-document-node'
import { standAloneApolloClient } from './uacapi-client'

/**
 * This will make a call to UACAPI to pull all the content slot data for the given IDs.
 * @param filteredIds The IDs of the content assets to retrieve.
 * @returns
 */
export default async function getContentAssets(
	ids: string[],
	locale?: string,
): Promise<ApolloQueryResult<ContentAssetsQuery>> {
	return standAloneApolloClient(locale).query({
		query: ContentAssetsDocument,
		variables: { ids: Array.from(new Set(ids)) },
	})
}
