export function isNotNullish<T>(o: T | undefined | null): o is T {
	return o !== null && o !== undefined
}

/**
 * This is used to interpret environment variables to determine if
 * they are true or false.  Since env are strings, oftentimes "true" or "1"
 * is used to indicate a truthy value.  This does the work of converting
 * the value.
 * @param val The value to determine its truthiness
 * @returns
 */
export const asBool = (val: unknown): boolean => {
	if (typeof val === 'string') {
		return val === 'true' || val === '1'
	}

	if (typeof val === 'boolean') {
		return val
	}

	return !!val
}

/**
 * Use this type in order to make one or more fields of a type required and make
 * all the others optional.  For example, if you have the following:
 *
 * 	interface ProductTileImage {
 * 		url: string,
 * 		urlHov: string,
 * 		alt: string
 *  }
 *
 * 	But want to make only url required, then you would do the following:
 * 	type SpecialProductTileImage = RequireOne<ProductTileImage, 'url'>
 */
export type RequireOne<T, K extends keyof T> = {
	[X in Exclude<keyof T, K>]?: T[X]
} & {
	[P in K]-?: T[P]
}

export type ReducerAction<actionType = string> = {
	action: actionType
	data?: unknown
}

/**
 * Use this type in order to make all fields of a type optional.  For example,
 * if you have the following:
 *  ```
 * 	interface ProductTileImage {
 * 		url: string,
 * 		urlHov: string,
 * 		alt: string
 * 	}
 * 	```
 *
 * 	But want to make all fields optional, then you would do the following:
 * 	type SpecialProductTileImage = RecursivePartial<ProductTileImage>
 *
 *  Note that this is a recursive type, so if you have a type that has a
 * 	property that is an object, then that object's properties will also be
 * 	made optional.
 *
 * 	For example, if you have the following:
 * 	```
 * 	interface ProductTileImage {
 * 		url: string,
 * 		urlHov: string,
 * 		alt: string,
 * 		sizes: {
 * 			small: string,
 * 			medium: string,
 * 			large: string
 * 		}
 * 	}
 * 	```
 * 	But want to make all fields optional, then you would do the following:
 * 	type SpecialProductTileImage = RecursivePartial<ProductTileImage>
 *
 */
export type RecursivePartial<T> = {
	[P in keyof T]?: RecursivePartial<T[P]>
}
