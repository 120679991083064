import { ensureArray, ensureString, type Optional } from 'types/strict-null-helpers'
import type {
	CustomRefinementInput,
	SearchRefinementAttribute,
	SearchRefinementAttributeValue,
} from '~/graphql/generated/uacapi/type-document-node'
import type { MinPageData } from '~/lib/types/category.interface'
import isEqual from 'lodash.isequal'
import { getFormattedMoney } from './i18n/currency'
import { parseNumber } from './i18n/numbers'
import { getDefaultLocale } from './i18n/locale'
import type { SearchProductsQuery } from './products'
import { redirect } from 'next/navigation'

// Defines the routes/categories that require
// gift pricing filters
export const GIFTS_PRICE_MAP = {
	'top-gifts': true,
	'top-gifts-mens': true,
	'top-gifts-womens': true,
	'top-gifts-boys': true,
	'top-gifts-girls': true,
	'stocking-stuffers': true,
}

/*
 * These functions are based off of the requirements defined here:
 * https://underarmour.atlassian.net/wiki/spaces/GDC/pages/1392705854/URL+Rules#Category-(PLP)-URLs
 */

const transformRefinementParam = (param: string): string =>
	decodeURIComponent(param).replace('_and_', ' & ').replaceAll('_', ' ')

/**
 * Returns a mapping of filter parameters to their respective refinement attribute.  For example,
 * if you pass in /c/mens/maroon/ it will return an object that looks like this:
 * 	{
 * 		refinements: ['maroon']
 * 		unusedSegments: ['']
 *  }
 *
 * 	If you pass in /c/mens/maroon/unused/ it will return an object that looks like this:
 * 	{
 * 		refinements: ['maroon']
 * 		unusedSegments: ['unused']
 *  }
 *
 * 	In fact, anything after the first refinement will be considered unused.  For example, if you pass in
 * 	/c/mens/maroon/unused/unused2/ it will return an object that looks like this:
 * 	{
 * 		refinements: ['maroon']
 * 		unusedSegments: ['unused', 'unused2']
 *  }
 *
 *  It will also modify the refinement segment:
 * 		* Replace all underscores with spaces
 * 		* Replace all _and_ with &
 *
 * 	This is based on https://underarmour.atlassian.net/wiki/spaces/GDC/pages/1392705854/URL+Rules#Category-(PLP)-URLs
 * @param url
 * @param params
 * @returns
 */
const getRefinementsFromParams = (
	url: string,
	params: string[],
): { rawRouteRefinements: string[]; unusedSegments: string[] } => {
	const [_constant, ...restUrl] = url.split('/').filter((item) => item)

	const [refinements, ...unusedSegments] = params.filter((c) => restUrl.indexOf(c) < 0)

	// TODO: Need to make this i18n friendly
	return {
		rawRouteRefinements: refinements ? refinements.split(/\+|-|\s/).map(transformRefinementParam) : [],
		unusedSegments,
	}
}

type PriceRanges = Record<string, { min: number; max: number }>

const getPrice = (value: string) => value.match(/\d+/g) ?? []

export const PRICE_RANGE_LOWER_BOUND = 0.0
export const PRICE_RANGE_UPPER_BOUND = 9999.0

/*
 * This function creates a mapping of price ranges to their respective gifting refinement attribute.
 * The main difference between gift pricing refinements and regular price refinements is that
 * each option has no lower bound.
 *
 * Examples:
 * { 'Under $25': { min: 0, max: 25 } }
 * { 'Under $50': { min: 0, max: 50 } }
 * { 'Under $100': { min: 0, max: 100 } }
 * { 'Under $150': { min: 0, max: 150 } }
 */
export function getGiftPriceRanges(priceRefinements: SearchRefinementAttributeValue[]): PriceRanges {
	return priceRefinements.reduce<PriceRanges>((acc, value) => {
		const priceRanges = acc
		const price = getPrice(value.label)

		if (price.length === 1) {
			priceRanges[value.label] = {
				min: PRICE_RANGE_LOWER_BOUND,
				max: parseInt(price[0], 10),
			}
		}
		// This is a safeguard for type changes in the future. This is unlikely
		// to be needed but could prevent a breaking change.
		else if (price.length === 2) {
			const [min, max] = price
			priceRanges[value.label] = {
				min: parseInt(min, 10),
				max: parseInt(max, 10),
			}
		}

		return priceRanges
	}, {})
}

/*
 * This function creates a mapping of price ranges to their respective refinement attribute.
 * Examples:
 * { 'Under $25': { min: 0, max: 25 } }
 * { '$25 - $50': { min: 25, max: 50 } }
 * { '$50 - $75': { min: 50, max: 75 } }
 * { '$75 - $100': { min: 75, max: 100 } }
 * { '$100 - $200': { min: 100, max: 200 } }
 * { '$200 +': { min: 200, max: 9999 } }
 *
 * The goal of this function is to be indiscriminate of locale and currency by keying off of the label.
 */
export function getPriceRangeFromRefinements(
	refinements: SearchRefinementAttribute[],
	useGiftPricing = false,
): PriceRanges {
	const priceRefinements = refinements.find((refinement) => refinement.attributeId === 'price')?.values ?? []

	if (useGiftPricing) {
		return getGiftPriceRanges(priceRefinements)
	}

	return priceRefinements.reduce<PriceRanges>((acc, value, index) => {
		const priceRanges = acc
		const price = getPrice(value.label)
		// If it's the first value then we know it's the min range.
		// However, we need to check its length to type narrow the value.
		// Additionally, some locales do not have a min range so type narrowing
		// will incidentally help us to skip those cases.
		if (index === 0 && price.length === 1) {
			priceRanges[value.label] = {
				min: PRICE_RANGE_LOWER_BOUND,
				max: parseInt(price[0], 10),
			}
		}
		// If it's the last value then we know it's the max range and we need to
		// check its length to type narrow the value. If there is only a single value in priceRefinements
		// then let's select triple digits as the arbitrary split to determine if this is actually max
		if (
			index === priceRefinements.length - 1 &&
			price.length === 1 &&
			(priceRefinements.length > 1 || parseInt(price[0], 10) > 100)
		) {
			priceRanges[value.label] = {
				min: parseInt(price[0], 10),
				max: PRICE_RANGE_UPPER_BOUND,
			}
		}
		// If two values exist then it's a range of numbers
		if (price.length === 2) {
			const [min, max] = price
			priceRanges[value.label] = {
				min: parseInt(min, 10),
				max: parseInt(max, 10),
			}
		}
		return priceRanges
	}, {})
}

/**
 * Given all possible refinements, this will do the work of parsing the URL and query params to return a list of
 * refinements that can be used to query the API. It will also return a list of unused segments that can be used
 * to determine whether or not the URL is valid.
 *
 * For example, if you pass in /c/mens/maroon/unused/unused2/ it will return an object that looks like this:
 * 	{
 * 		refinements: [
 * 			{
 * 				attributeId: 'c_colorgroup',
 * 				values: ['maroon']
 * 			}
 * 		],
 * 		unusedSegments: ['unused', 'unused2']
 * 	}
 *
 * But it will also handle query params:
 * 	/c/mens/maroon/?prefn1=size&prefv1=XL
 * 	{
 * 		refinements: [
 * 			{
 * 				attributeId: 'c_colorgroup',
 * 				values: ['maroon']
 * 			},
 * 			{
 * 				attributeId: 'size',
 * 				values: ['XL']
 * 			}
 * 		],
 * 		unusedSegments: []
 * 	}
 */
export const getFilterRefinementInput = (
	allRefinements: SearchRefinementAttribute[],
	url: string,
	query: SearchProductsQuery,
	locale: string,
	useGiftPricing = false,
): { refinements: CustomRefinementInput[]; unusedSegments: string[] } => {
	const { rawRouteRefinements, unusedSegments } = getRefinementsFromParams(
		url,
		query.cid?.map((c) => decodeURIComponent(c)) || query.routeParams?.map((c) => decodeURIComponent(c)) || [],
	)
	const queryRefinements: { attributeId: string; values: string[] }[] = []
	const priceRanges = getPriceRangeFromRefinements(allRefinements, useGiftPricing)
	let isSizeFilterSelected = false

	// Example: ?pmax=75.00&pmin=50.00
	if (query.pmin && query.pmax) {
		const pMinString = parseNumber(query.pmin, locale)
		const pMaxString = parseNumber(query.pmax, locale)

		const cost = [pMinString, pMaxString].filter(
			(n) => !!n && n !== PRICE_RANGE_UPPER_BOUND && n !== PRICE_RANGE_LOWER_BOUND,
		)
		const refinement = allRefinements.find((r) => r.attributeId === 'price')
		const value = ensureArray(refinement?.values).find((value) => {
			const label = value?.label || ''
			const input = label
				?.match(/\d+/g)
				?.map((n) => parseInt(n, 10))
				.sort((a, b) => a - b)
			return isEqual(input, cost)
		})?.label
		if (refinement && value) {
			const priceRange = priceRanges[value]
			const queryValue = `(${priceRange.min}..${priceRange.max})`
			queryRefinements.push({
				attributeId: refinement.attributeId,
				values: [queryValue],
			})
		} else {
			const pMinNum = parseInt(query.pmin, 10)
			const pMaxNum = parseInt(query.pmax, 10)

			// If we can't find a price range from refinement values, then we'll just use the query params provided
			const queryValue = `(${pMinNum}..${pMaxNum})`
			queryRefinements.push({
				attributeId: 'price',
				values: [queryValue],
			})
		}
	}

	// Example: ?prefn1=size&prefv1=XL or prefv1=YXS|YSM
	Array.from(Object.keys(query))
		.filter((q) => q.includes('prefn'))
		.forEach((key) => {
			const [_, index] = key.split('prefn')
			const prefn = query[key]
			const prefv = query[`prefv${index}`]

			// Type narrow to ensure query param was a string and not an array of strings
			if (!prefn || !prefv || Array.isArray(prefn) || Array.isArray(prefv)) {
				return
			}
			if (prefn === 'size') isSizeFilterSelected = true

			const refinement = allRefinements.find((r) => r.attributeId === `c_${prefn}`)

			if (refinement) {
				const values = refinement.values
					.filter((value) => prefv.split('|').includes(value.label))
					.map((value) => value.label)
				queryRefinements.push({
					attributeId: refinement.attributeId,
					values,
				})
			}

			// below are valid refinement attributes present in outlet urls that are not returned by UACAPI
			const outletSpecificValues = ['silhouette', 'subsubsilhouette']
			if (outletSpecificValues.includes(prefn)) {
				queryRefinements.push({
					attributeId: `c_${prefn}`,
					values: [prefv],
				})
			}
		})

	// If viewPreference is selected and size filter is not, enforce size = viewPreference
	if (!isSizeFilterSelected && query.viewPreference) {
		queryRefinements.push({
			attributeId: `c_size`,
			values: [query.viewPreference],
		})
	}

	// 1. Matches either & or and
	// 2. Replaces the matched string with the return value of the callback function
	// 3. The callback function takes the matched string as an argument and returns the replacement string.
	// Example: 'Hoodies & Sweatshirts' => 'hoodies and sweatshirts
	// Example: 'Hoodies and Sweatshirts' => 'hoodies & sweatshirts
	// Example: 'Polos' => 'polos'
	interface ParamInput {
		__typename?: string
		label: string
	}
	const findFlexibleMatch = (inputString: ParamInput, param: string): boolean => {
		const lowerCaseInput = inputString?.label.toLowerCase()
		const lowerCaseParam = param.toLowerCase()
		// This function assumes we only care about the conjunction form of "and" or "&"
		// Without accounting for white-space something like "sandals and slides" does not get appropriately
		// captured because we're replacing the "and" substring in "sandals" instead of the conjunction.
		const altInputString = lowerCaseInput.replace(/\s(&|and)\s/g, (match: string) =>
			match.trim() === '&' ? ' and ' : ' & ',
		)

		return lowerCaseInput === lowerCaseParam || altInputString === lowerCaseParam
	}

	const findMatchingRefinementWithParam = (
		refinement: SearchRefinementAttribute | undefined,
		param: string,
	): string | undefined => ensureArray(refinement?.values).find((value) => findFlexibleMatch(value, param))?.label

	const routeRefinements = rawRouteRefinements.reduce<CustomRefinementInput[]>((acc, param) => {
		const currentRefinementAttribute = allRefinements.find((r) => findMatchingRefinementWithParam(r, param))

		if (!currentRefinementAttribute) {
			// If the param is not a refinement, then it's an unused segment.  But if it's a query param, then we don't
			// want to add it to the unused segments list. This is because there are a few query params that are not
			//	considered refinements, but are still used in the URL.  This is for SEO purposes.  The query params
			//	indicate.  For testing, you can use this url: /c/outlet/mens/brown-outerwear/?prefn1=silhouette&prefv1=Outerwear
			//	Notice how the silhouette is not a refinement, but it's still used in the URL.
			if (!queryRefinements.find((r) => r.values.map((v) => v.toLowerCase()).includes(param))) {
				unusedSegments.push(param)
			}

			return [...acc]
		}

		const isRefinementAlreadyAdded = acc.find((r) => r.attributeId === currentRefinementAttribute.attributeId)
		const refinementValue = findMatchingRefinementWithParam(currentRefinementAttribute, param)

		if (isRefinementAlreadyAdded) {
			return acc.map((r) => {
				const isCurrentRefinement = r.attributeId === currentRefinementAttribute.attributeId
				if (isCurrentRefinement) {
					return refinementValue ? { ...r, values: [...r.values, refinementValue] } : { ...r }
				}
				return r
			})
		}

		if (!refinementValue) return [...acc]
		return [...acc, { attributeId: currentRefinementAttribute.attributeId, values: [refinementValue] }]
	}, [])

	return {
		refinements: [...routeRefinements, ...queryRefinements],
		unusedSegments,
	}
}

/**
 * Gets the price values in the URL params and converts them to usable
 * values to pass with a ConstructorIO request.
 * @param query
 * @param locale
 * @returns
 */
export const getFilterRefinementPrice = (query: SearchProductsQuery, locale?: string): number[] => {
	let priceRanges: number[] = []

	// Example: ?pmax=75.00&pmin=50.00
	if (query.pmin && query.pmax) {
		const defaultLocale = getDefaultLocale()
		// For some intl locales, they replace periods with commas and commas for spaces
		// We must convert these values to be US-based for CIO filtering to work properly
		// e.g. In FR-CA, the $9,999.00 is written as $9 999,00

		// This normalizes any price string into its javascript number equivalent
		// e.g. $9 999,00 => 9999.00
		priceRanges = [parseNumber(query.pmin, locale || defaultLocale), parseNumber(query.pmax, locale || defaultLocale)]
	}

	return priceRanges
}

// Type to represent interstitial query param data structure
// Attributes are refinement attributeIds (without c_ prefix)
interface QueryRefinement {
	attribute: string
	value: string
}

const resetPositionalQueryParams = (oldQuery: string): URLSearchParams => {
	const oldQueryParams = new URLSearchParams(oldQuery)
	const newQueryParams = new URLSearchParams()
	oldQueryParams.forEach((value, key) => {
		if (!(key.startsWith('prefn') || key.startsWith('prefv'))) {
			newQueryParams.set(key, value)
		}
	})
	return newQueryParams
}

interface CreateRefinementUrlParams {
	refinements: SearchRefinementAttribute[]
	url: string
	categoryId: string
	updatedRefinements: Record<string, boolean>
	locale: string
	currentQueryParams?: string
}
/**
 * Given available refinements and the current URL, generates a new one using existing refinement rules
 * [gender]/[colorgroup]-[fittype]-[enduse]-[silhouette]-[division]
 *
 * @param refinements
 * @param url
 * @param updatedRefinements a record of refinements to update keyed by a refinement's label with the value being a boolean to represent if it's selected
 * @param currentQueryParams
 * @returns category refinement URL
 */
export const createRefinementUrl = ({
	refinements,
	url,
	categoryId,
	updatedRefinements,
	locale,
	currentQueryParams,
}: CreateRefinementUrlParams): string => {
	const isNoUpdatedRefinements = Object.keys(updatedRefinements).length === 0
	const shouldUseGiftPricing = Boolean(GIFTS_PRICE_MAP[categoryId])
	const priceRanges = getPriceRangeFromRefinements(refinements, shouldUseGiftPricing)
	// Route refinements
	const routeRefinementRules = [
		'c_gender',
		'c_colorgroup',
		'c_fittype',
		'c_enduse',
		'c_silhouette',
		'c_division',
		'c_team',
		'c_subsilhouette',
		'c_gearline',
		'c_subsubsilhouette',
	]

	const routeParams = routeRefinementRules.reduce<string[]>((acc, rule) => {
		const refinement = refinements.find((refinement) => refinement.attributeId === rule)
		if (refinement) {
			const values = refinement.values

				// Updated designated refinement to selected
				.map((value) =>
					value.label in updatedRefinements ? { ...value, selected: updatedRefinements[value.label] } : value,
				)
				// Grab selected refinements
				.filter((value) => value.selected && value.hitCount > 0)
			if (values.length) {
				// TODO: Need to make this i18n friendly
				const param = values
					.map((value) => encodeURIComponent(value.label.split(' ').join('_').replace('&', 'and').toLowerCase()))
					.join('+')
				return [...acc, param]
			}
			return acc
		}
		return acc
	}, [])
	// Query refinements
	const queryParams = resetPositionalQueryParams(ensureString(currentQueryParams))

	// refinements should start at page 0.
	queryParams.delete('start')

	// Remove params that are part of the route
	queryParams.delete('locale')
	queryParams.delete('cid')

	// Price refinement
	const priceRefinement = refinements.find(
		(refinement) =>
			refinement.attributeId === 'price' &&
			refinement.values.find(
				(value) =>
					(!isNoUpdatedRefinements && value.label in updatedRefinements) || (isNoUpdatedRefinements && value.selected),
			),
	)

	if (priceRefinement) {
		const value = priceRefinement.values
			// Updated designated refinement to selected
			.map((value) =>
				value.label in updatedRefinements ? { ...value, selected: updatedRefinements[value.label] } : value,
			)
			// Grab selected refinement
			.find(
				(value) =>
					(value.selected && value.hitCount > 0 && !isNoUpdatedRefinements && value.label in updatedRefinements) ||
					(isNoUpdatedRefinements && value.selected),
			)
		// Example: ?pmax=75.00&pmin=50.00
		if (value?.selected && !!priceRanges) {
			const priceRange = priceRanges[value.label]
			queryParams.set('pmin', getFormattedMoney(priceRange?.min, locale))
			queryParams.set('pmax', getFormattedMoney(priceRange?.max, locale))
		} else {
			queryParams.delete('pmin')
			queryParams.delete('pmax')
		}
	}

	// Refinement values that come from query params
	const generalQueryRefinementRules = ['c_merchCollection', 'c_size', 'c_length', 'c_agegroup']
	const outletQueryRefinementRules = ['c_silhouette', 'c_subsubsilhouette']
	const isOutlet = categoryId.includes('outlet') || categoryId.includes('sale')

	const queryRefinements = [...generalQueryRefinementRules, ...(isOutlet ? outletQueryRefinementRules : [])].reduce<
		QueryRefinement[]
	>((acc, rule) => {
		const refinement = refinements.find((refinement) => refinement.attributeId === rule)
		if (refinement) {
			const values = refinement.values
				// Updated designated refinement to selected
				.map((value) =>
					value.label in updatedRefinements ? { ...value, selected: updatedRefinements[value.label] } : value,
				)
				// Grab selected refinements
				.filter((value) => value.selected && value.hitCount > 0)
				.map((value) => value.label)
			if (values.length) {
				const queryRefinement: QueryRefinement = {
					attribute: refinement.attributeId.replace('c_', ''),
					value: values.join('|'),
				}
				return [...acc, queryRefinement]
			}
			return acc
		}

		// These refinements are for backwards compatibility with the old site and only apply to the outlet
		if (isOutlet && outletQueryRefinementRules.includes(rule)) {
			const searchParams = new URLSearchParams(currentQueryParams)
			const match = Array.from(searchParams.entries()).find(([_k, v]) => `c_${v}` === rule)
			if (match) {
				const [prefn] = match
				const value = ensureString(searchParams.get(`prefv${prefn.replace(/^\D+/g, '')}`))
				const queryRefinement: QueryRefinement = {
					attribute: rule.replace('c_', ''),
					value,
				}
				return [...acc, queryRefinement]
			}
			return acc
		}
		return acc
	}, [])

	queryRefinements.forEach((refinement, i) => {
		const index = i + 1 // query refinement indexes start at 1
		queryParams.set(`prefn${index}`, refinement.attribute)
		queryParams.set(`prefv${index}`, refinement.value)
	})

	const refinementUrl = routeParams.length ? `${url}${routeParams.join('-')}/` : url
	return queryParams.toString() ? `${refinementUrl}?${queryParams.toString()}` : refinementUrl
}

export interface ParsedCategoryData {
	matchingCategory?: MinPageData
	missingSegments: string[]
}

// Parse a category url to a string without the /c/ or /t/ prefix
// For example, "/c/womens/" parses to "/womens/"
function getCategoryUrlWithoutPrefix(url: string) {
	return `/${url
		.split('/')
		.filter((x) => x !== '' && !['c', 'd', 't'].includes(x))
		.join('/')}/`
}

export const getCategoryFromCid = (categories: MinPageData[], cid: string[]): Optional<MinPageData> => {
	// Create a category map with parsed category.url as key
	const categoryMap = categories.reduce(
		(map, category) => map.set(getCategoryUrlWithoutPrefix(category.url), category),
		new Map<string, MinPageData>(),
	)

	// Create the list of possible category urls in reverse order
	// For example, when cid equals ['womens', 'basketball-clothing'], then
	// possible urls will be in reverse order as ['/womens/basketball-clothing/', '/womens/']
	// In other words, we work bottom up with the url segments, and stop once we find the first matching category
	const possibleCategoryUrls = cid
		.reduce((urls, segment, index) => [...urls, `${(index > 0 ? urls[index - 1] : '/') + segment}/`], <string[]>[])
		.reverse()

	return categoryMap.get(ensureString(possibleCategoryUrls.find((url) => categoryMap.get(url))))
}

export function createSortUrl(url: string, sortRule?: string): string {
	const parsedUrl = url.split('?')
	const path = parsedUrl[0]
	const query = new URLSearchParams(parsedUrl[1] || '')
	if (!sortRule) {
		query.delete('srule')
	} else {
		query.set('srule', sortRule)
	}
	return `${path}?${query.toString()}`
}

export function redirectToNotFound(locale = 'en-us') {
	redirect(`/${locale}/notfound/`)
}
