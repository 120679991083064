/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-classes-per-file */
import { isNonNullish } from '~/types/strict-null-helpers'
import logger from '../logger'
import {
	addTrailingSlash,
	combineParamsAndMergeArrays,
	extractSearchParamsFromDynamicParameters,
	joinPathAndSearchParams,
} from '../utils'
import { DYNAMIC_PARAMS_SEGMENT_KEY } from '../constants'

export type CanonicalizePageType = 'ProductListingPage' | 'ProductDetailPage' | 'ProductSearchPage'

/**
 * The Canonicalizer class is used to canonicalize a URL.  This means that it will take a URL and
 * return a URL that is the canonical version of the given URL.  This is useful for SEO purposes
 * because it allows us to have a single URL that represents a page.  Each page type has its
 * own canonicalizer that is derived from this class.
 */
export abstract class Canonicalizer {
	protected readonly _name: CanonicalizePageType
	protected readonly _locators: RegExp[]
	protected readonly _allowedParams?: RegExp[]

	protected constructor(name: CanonicalizePageType, locators: RegExp[], allowedParams?: RegExp[]) {
		this._name = name
		this._locators = locators
		this._allowedParams = allowedParams
	}

	public get name(): string {
		return this._name
	}

	abstract buildPathFromParams(dynamicParams: URLSearchParams, searchParams: URLSearchParams): string
	abstract buildPathFromCombinedParams(combinedParams: URLSearchParams): string

	/**
	 * Determines if the given URL is a version of this type of page.  For example, if the
	 * canonicalizer is for ProductListingPage, then this will return true if the URL is
	 * /en-us/c/womens/clothing/baselayer/ or /en-us/c/womens/clothing/baselayer/?foo=bar
	 *
	 * @param url
	 * @returns
	 */
	isVersionOfUrl(url: string): boolean {
		// NOTE: We are instantiating a new RegExp object for each locator.  This is because we
		//  want to be able to use the global flag on the regular expression.  If we don't do
		//  this then the regular expression will not work correctly because it will keep track
		//  of the last match and not the current match.
		return this._locators.some((l) => new RegExp(l).test(url))
	}

	/**
	 * This will take a set of search params and filter out the ones that are not allowed to be part
	 * of a canonical URL.
	 * @param search
	 * @returns
	 */
	buildCanonicalSearchParams(search: URLSearchParams): URLSearchParams {
		// If we are being asked to allow certain query parameters, then we need to filter out the ones that
		//  we don't want.  So if the list of allowedParams is given but is empty, then we assume that they
		//  never want any query parameters.  If the list is not given, then we assume that they want all of
		//  the query parameters.  If the list is given and is not empty, then we will filter out the ones
		//  that are not in the list.
		let finalParams: URLSearchParams | undefined
		if (this._allowedParams !== undefined) {
			// If we are being asked to allow certain query parameters, then we need to filter out the ones that
			//  we don't want.  But we have to make sure that we
			finalParams = new URLSearchParams()

			this._allowedParams.forEach((param) => {
				search.forEach((value, key) => {
					if (param.test(key)) {
						finalParams?.set(key, value)
					}
				})
			})
		}

		return finalParams ?? search
	}
}
class ProductListingPageCanonicalizer extends Canonicalizer {
	private base: string

	constructor(name?: CanonicalizePageType, locators?: RegExp[], base?: string) {
		super(name || 'ProductListingPage', locators || [/([^/]+)\/products\/([^/]+)\/(.*)/g, /([^/]+)\/c\/(.*)/g], [
			/dwvar_\d*_color/,
			/dwvar_\d*_size/,
			/^extendedSize$/,
			/^size$/,
			/^viewPreference$/,
			/^start$/,
		])

		this.base = base || 'c'
	}

	public buildPathFromCombinedParams(combinedParams: URLSearchParams): string {
		const locale = combinedParams.get('locale')
		const cid = combinedParams.getAll('cid')

		if (!isNonNullish(locale) || !isNonNullish(cid) || !Array.isArray(cid)) {
			logger.error(
				`Unable to build canonical URL for ProductListingPage.  Missing required parameters.  locale: ${locale}, cid: ${cid}`,
			)
			return ''
		}

		const finalSearchParams = this.buildCanonicalSearchParams(combinedParams)

		return addTrailingSlash(joinPathAndSearchParams(`/${locale}/${this.base}/${cid.join('/')}`, finalSearchParams))
	}

	public buildPathFromParams(dynamicParams: URLSearchParams, queryParams: URLSearchParams): string {
		const locale = dynamicParams.get('locale')
		const cid = dynamicParams.getAll('cid')

		if (!isNonNullish(locale) || !isNonNullish(cid) || !Array.isArray(cid)) {
			logger.error(
				`Unable to build canonical URL for ProductListingPage.  Missing required parameters.  locale: ${locale}, cid: ${cid}`,
			)
			return ''
		}

		const combinedParams = combineParamsAndMergeArrays(
			extractSearchParamsFromDynamicParameters(dynamicParams),
			queryParams,
		)

		return this.buildPathFromCombinedParams(combinedParams)
	}
}

class ProductSearchPageCanonicalizer extends ProductListingPageCanonicalizer {
	constructor() {
		super('ProductSearchPage', [/([^/]+)\/search\/(.*)/g], 'search')
	}
}

class ProductDetailPageCanonicalizer extends Canonicalizer {
	constructor() {
		super('ProductDetailPage', [/([^/]+)\/product\/([^/]+)\/style\/(.*)/g, /([^/]+)\/p\/(.*)/g], [])
	}

	public buildPathFromCombinedParams(combinedParams: URLSearchParams): string {
		const locale = combinedParams.get('locale')
		const productPath = combinedParams.getAll('productId')

		if (!isNonNullish(locale) || !isNonNullish(productPath) || !Array.isArray(productPath)) {
			logger.error(
				`Unable to build canonical URL for ProductDetailPage.  Missing required parameters.  locale: ${locale}, productId: ${productPath}`,
			)
			return ''
		}

		const finalSearchParams = this.buildCanonicalSearchParams(combinedParams)

		// NOTE: The The product path is an array of string that contains descriptors followed by the product ID. The final
		//  element of the array is the product ID.  For example, with a product path of
		//  /unnecessary/descriptor/456.html the productId would be ['456']
		return addTrailingSlash(joinPathAndSearchParams(`/${locale}/p/${productPath.join('/')}`, finalSearchParams))
	}

	public buildPathFromParams(dynamicParams: URLSearchParams, queryParams: URLSearchParams): string {
		const locale = dynamicParams.get('locale')
		const searchParams = dynamicParams.get(DYNAMIC_PARAMS_SEGMENT_KEY)
		const productPath = dynamicParams.getAll('productId')

		if (
			!isNonNullish(locale) ||
			!isNonNullish(searchParams) ||
			!isNonNullish(productPath) ||
			typeof searchParams !== 'string' ||
			!Array.isArray(productPath)
		) {
			logger.error(
				`Unable to build canonical URL for ProductDetailPage.  Missing required parameters.  locale: ${locale}, searchParams: ${searchParams}, productId: ${productPath}`,
			)
			return ''
		}

		const combinedParams = combineParamsAndMergeArrays(
			extractSearchParamsFromDynamicParameters(dynamicParams),
			queryParams,
		)

		return this.buildPathFromCombinedParams(combinedParams)
	}
}

/**
 * This is the list of canonicalizers that we have.  Each canonicalizer has a locator, replacer, and
 * allowedParams.  The locator is a regular expression that will be used to match the path.  The
 * replacer is a function that will be used to replace the path.  The allowedParams is a list of
 * regular expressions that will be used to filter out the query parameters that are not allowed.
 */
export const Canonicalizers: Canonicalizer[] = [
	new ProductListingPageCanonicalizer(),
	new ProductSearchPageCanonicalizer(),
	new ProductDetailPageCanonicalizer(),
]

/**
 * This function does the work of canonicalizing a path using the canonicalizer given.  If none is
 * given then it will just return the given path.
 *
 * Some important notes:
 *  - The resulting URL could have query parameters assuming that the canonicalizer allows them.
 *  - The resulting URL may or may not have a trailing slash depending on the input.
 *
 * Examples:
 *  - canonicalizeUrl('/en-us/product/foo=bar/style/456', ProductDetailCanonicalizer) => '/en-us/p/456.html?foo=bar')
 *  - canonicalizeUrl('/en-us/', ProductDetailCanonicalizer) => '/en-us/')
 *  - canonicalizeUrl('/en-us/?country=US', ProductDetailCanonicalizer) => '/en-us/')
 *
 * @returns
 */
export function canonicalizeUrl(
	url: string,
	dynamicUrlParams: URLSearchParams,
	queryParams: URLSearchParams,
	alternateCanonicalizerList?: Canonicalizer[],
): string {
	const urlWithoutQueryParams = url.split('?')[0]

	const canonicalizers = alternateCanonicalizerList ?? Canonicalizers

	const canonicalizer = canonicalizers.find((c) => c.isVersionOfUrl(urlWithoutQueryParams))
	if (!canonicalizer) {
		return url
	}

	return canonicalizer.buildPathFromParams(dynamicUrlParams, queryParams)
}

export function canonicalizeUrlFromPageType(
	pageType: CanonicalizePageType,
	dynamicUrlParams: URLSearchParams,
	queryParams: URLSearchParams,
	alternateCanonicalizerList?: Canonicalizer[],
): string {
	const canonicalizer = (alternateCanonicalizerList ?? Canonicalizers).find((c) => c.name === pageType)
	if (!canonicalizer) {
		return ''
	}

	return canonicalizer.buildPathFromParams(dynamicUrlParams, queryParams)
}

export function canonicalizeUrlFromPageTypeWithCombinedParams(
	pageType: CanonicalizePageType,
	combinedParams: URLSearchParams,
	alternateCanonicalizerList?: Canonicalizer[],
): string {
	const canonicalizer = (alternateCanonicalizerList ?? Canonicalizers).find((c) => c.name === pageType)
	if (!canonicalizer) {
		return ''
	}

	return canonicalizer.buildPathFromCombinedParams(combinedParams)
}
