'use client'

import type { FetchResult } from '@apollo/client'
import type { ReadonlyURLSearchParams } from 'next/navigation'
import { usePathname } from 'next/navigation'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useAnalytics } from '~/components/hooks/useAnalytics'
import { useFeatureFlags } from '~/components/providers/CommerceConfigurationProvider/CommerceConfigurationProvider'
import type { EmailSubscribeResult } from '~/components/hooks/useEmailSubscribe'
import { useLocale } from '~/components/hooks/useLocale'
import { CustomerAuthType } from '~/graphql/generated/uacapi/type-document-node'
import { decodeUacapiToken } from '~/lib/api/uacapi'
import {
	authenticateWithEmail,
	authenticateWithPreviousSession,
	getSession,
	getUserProfile,
	registerUser,
	signOut,
} from '~/lib/client-only/auth'
import { UserWithSession, isLoginError, type UserProfileInfo, type UserSessionInfo } from '~/lib/client-only/auth/types'
import type { EnrollmentSource } from '~/lib/types/loyalty.interface'
import { mapSubscriberSource } from '~/lib/client-server/enrollment-helpers'
import logger from '~/lib/logger'
import { LoginFailureReasons } from '~/lib/types/user.interface'
import { deepObjectCompare } from '~/lib/utils'
import type { LoyaltyContextInterface } from '../LoyaltyProvider'
import { getSiteCodeByLocale } from '~/lib/i18n/locale'

interface LoginInterface {
	email: string
	password: string
	rememberMe?: boolean
}

export interface RegistrationInterface {
	email: string
	password: string
	enrollmentSource: EnrollmentSource
	readonlySearchParams: ReadonlyURLSearchParams
	rememberMe?: boolean
	sms?: boolean
	enrollLoyaltyMutation?: ({
		source,
		readonlySearchParams,
	}: {
		source: EnrollmentSource
		readonlySearchParams: ReadonlyURLSearchParams
	}) => ReturnType<LoyaltyContextInterface['enroll']>
	subscribeMutation?: (email: string, source?: string) => Promise<FetchResult<EmailSubscribeResult> | undefined>
}

interface SessionContextInterface {
	loading: boolean
	user?: UserWithSession
	login({ email, password, rememberMe }: LoginInterface): Promise<UserSessionInfo | undefined>
	logout(): Promise<void>
	refresh(session?: UserSessionInfo): Promise<void>
	register(input: RegistrationInterface): Promise<UserWithSession | undefined>
	setSession: (session?: UserSessionInfo) => void
}

const SessionContext = createContext({
	loading: false,
	login: async () => undefined,
	logout: async () => undefined,
	refresh: async () => undefined,
	register: async () => undefined,
	setSession: () => undefined,
} as SessionContextInterface)

export function useSession() {
	return useContext(SessionContext)
}

export default function UaSessionProvider({ children }: React.PropsWithChildren): React.ReactElement {
	const { analyticsManager } = useAnalytics()
	const featureFlags = useFeatureFlags()
	const locale = useLocale()
	const currentPath = usePathname()

	const [loading, setLoading] = useState<boolean>(false)
	const [session, setSession] = useState<UserSessionInfo>()
	const [user, setUser] = useState<UserWithSession>()

	const [_, setGetSessionPromise] = useState<Promise<UserSessionInfo | undefined>>()

	// We need to ensure there are never concurrent calls to getSession() as it's possible
	// for localStorage to get stomped on
	const getSessionWrapper = useCallback(async () => {
		return new Promise<UserSessionInfo | undefined>((resolve) => {
			setGetSessionPromise((current) => {
				// If we've a promise in flight, resolve and keep it in the promise state
				if (current) {
					resolve(current)
					return current
				}

				// If no promise, we can safely call getSession(), and store its promise
				// in local state
				setLoading(true)
				// TODO: should we throttle the ability to call getSession?
				// setTimeout(() => setGetSessionPromise(undefined), 1000)
				return getSession(locale).then((resp) => {
					// Once our getSession call resolves, return it, and return promise state
					// to undefined, allowing another call to getSession()
					resolve(resp)
					setLoading(false)
					setGetSessionPromise(undefined)
					return resp
				})
			})
		})
	}, [locale])

	const init = useCallback(async () => {
		getSessionWrapper().then((resp) => {
			setSession(resp)
		})
	}, [getSessionWrapper])

	const logout = useCallback(() => signOut(locale).then(setSession), [locale, setSession])

	const login = useCallback(
		async ({ email, password, rememberMe }: LoginInterface) => {
			try {
				const resp = await authenticateWithEmail({ locale, email, password, rememberMe })

				analyticsManager.fireLoginSuccess({
					logged_in_status: 'Logged In',
					customer_id: resp?.customerNo ?? '',
					plain_text_email: email,
					type: 'ua',
				})

				setSession(resp)
				return resp
			} catch (e) {
				if (isLoginError(e)) {
					const details = {
						type: 'ua',
						error_name: 'login invalid',
						error_message: e.loginResponse.code ?? LoginFailureReasons.UNKNOWN_FAILURE,
					}

					analyticsManager.fireLoginAttempt(details)
					analyticsManager.fireErrorMessageShown(details)
				}

				throw e
			}
		},
		[analyticsManager, locale, setSession],
	)

	const refresh = useCallback(
		(currentSession?: UserSessionInfo) =>
			authenticateWithPreviousSession(locale, currentSession ?? session).then(setSession),
		[locale, session, setSession],
	)

	const register = useCallback(
		async ({
			email,
			password,
			enrollmentSource,
			readonlySearchParams,
			rememberMe,
			sms,
			enrollLoyaltyMutation,
			subscribeMutation,
		}: RegistrationInterface) => {
			await registerUser(locale, email, password)

			if (subscribeMutation) {
				const source = mapSubscriberSource(enrollmentSource, !!enrollLoyaltyMutation)
				const result = await subscribeMutation(email, source)
				if (result?.errors) {
					logger.error('[Registration] Subscribing during registration failed')
				}
			}

			let resp: UserSessionInfo | undefined

			try {
				resp = await authenticateWithEmail({ locale, email, password, rememberMe, autoCreateAccountLink: false })
				if (!resp) {
					throw new Error("The registration completed successfully but couldn't retrieve a session afterwards")
				}
			} catch (e) {
				const error = e as Error
				logger.error(error.message)
				throw e
			}

			if (enrollLoyaltyMutation) {
				if (resp?.accountType === CustomerAuthType.REGISTERED) {
					const enrollResult = await enrollLoyaltyMutation({ readonlySearchParams, source: enrollmentSource })
					if (!enrollResult) {
						logger.error('[Registration] Enroll loyalty during registration failed')
					} else {
						// Refresh the session to ensure we've latest loyalty details.
						//  fallback to initial login session if refresh flow fails
						resp = (await authenticateWithPreviousSession(locale, resp)) ?? resp
					}
				}
			}

			const profile = await getUserProfile(locale, resp)
			// Update session after getUserProfile, so the new profile details
			//  are in localStorage for when the Effect below triggers
			setSession(resp)

			const userWithSession = new UserWithSession(resp, profile, featureFlags)
			const userData = analyticsManager.getUserData(userWithSession, true)

			analyticsManager.fireRegisterSuccess({
				...userData,
				opt_in: !!subscribeMutation,
				opted_in_SMS: sms,
			})

			return userWithSession
		},
		[analyticsManager, featureFlags, locale, setSession],
	)

	useEffect(() => {
		init()
	}, [init])

	useEffect(() => {
		if (!session) return

		// If a user changes locales, we need to invalidate their session at uacapi, as
		//  it's tied to the originating locale (site)
		const decodedToken = decodeUacapiToken(session.uacapi.accessToken)
		if (
			decodedToken &&
			getSiteCodeByLocale(locale).toLocaleLowerCase() !== decodedToken.subjectDecoded.site.toLocaleLowerCase()
		) {
			logger.warn(
				{ locale, site: decodedToken.subjectDecoded.site },
				'[UaSessionProvider] forced signout due to locale swap',
			)
			logout()
			return
		}

		getUserProfile(locale, session)
			.then((profile) => {
				if (
					deepObjectCompare<UserSessionInfo | undefined>(user?.session, session) &&
					deepObjectCompare<UserProfileInfo | undefined>(user?.profile, profile)
				) {
					// session and profile are identical, no need to mutate user state
					return
				}

				setUser(new UserWithSession(session, profile, featureFlags))
			})
			.catch((error) => {
				logger.error({ error }, '[UaSessionProvider] Failed requesting user profile')
				setUser(new UserWithSession(session))
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [featureFlags, locale, session, logout])

	useEffect(() => {
		// We will update the session (checking if it needs to be refreshed each time the user changes the path)
		init()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPath, init])

	const contextValue = useMemo(
		() => ({ loading, user, login, logout, refresh, register, setSession }),
		[loading, user, login, logout, refresh, register, setSession],
	)

	return <SessionContext.Provider value={contextValue}>{children}</SessionContext.Provider>
}
