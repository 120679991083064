import type { NextLink, Operation, ServerError } from '@apollo/client'
import { fromError } from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
import { isTokenExpired } from '~/lib/auth'
import type { UserSessionInfo } from '~/lib/client-only/auth/types'

export class RetryWithTokenLink extends RetryLink {
	ignoredOperations: string[]
	isServer: boolean
	getUserSession: () => UserSessionInfo | undefined
	handleUnauthorizedResponse: (() => void) | undefined

	constructor({
		ignoredOperations = [],
		isServer = false,
		maxAttempts = 5,
		minDelayMs = 500,
		getUserSession,
		handleUnauthorizedResponse,
	}: {
		ignoredOperations?: string[]
		isServer?: boolean
		maxAttempts?: number
		minDelayMs?: number
		getUserSession: () => UserSessionInfo | undefined
		handleUnauthorizedResponse?: () => void
	}) {
		super({
			attempts: (count, operation, error) => {
				if (
					count < maxAttempts &&
					!isServer &&
					!ignoredOperations.includes(operation.operationName) &&
					(error as ServerError)?.statusCode === 401
				) {
					// Only trigger callback if session _has_ already been established
					if (!!getUserSession() && handleUnauthorizedResponse) {
						handleUnauthorizedResponse()
					}
					return true
				}
				return false
			},
			delay: (count) => count * minDelayMs * (Math.random() + 1),
		})

		this.ignoredOperations = ignoredOperations
		this.isServer = isServer
		this.getUserSession = getUserSession
		this.handleUnauthorizedResponse = handleUnauthorizedResponse
	}

	request(operation: Operation, nextLink: NextLink) {
		const commonLink: NextLink = (operation) => {
			const session = this.isServer ? undefined : this.getUserSession()

			// If UACAPI token is expired or doesn't exist, short circuit and resort to attempts() logic above
			if (
				!this.isServer &&
				!this.ignoredOperations.includes(operation.operationName) &&
				(!session || isTokenExpired(session.uacapi.expiresAt))
			) {
				return fromError({
					message: `[RetryWithTokenLink] Short Circuit - [${operation.operationName}] - Expired uacapi token`,
					name: 'short-circuit',
					statusCode: 401,
				} as ServerError)
			}

			operation.setContext({
				headers: {
					...(operation.getContext()?.headers ?? {}),
					...(!!session?.uacapi.accessToken && { Authorization: `Bearer ${session.uacapi.accessToken}` }),
				},
			})

			return nextLink(operation)
		}

		return super.request(operation, commonLink)
	}
}
