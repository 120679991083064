import type { OperationDefinitionNode } from 'graphql'
import type { OisItemizedRma, VariantProduct, VariationSize } from '~/graphql/generated/uacapi/type-document-node'
import type { AurusCreditCard } from '~/types/creditcard'

export const isVariantProduct = (x: unknown): x is VariantProduct => typeof (x as VariantProduct)?.size !== 'undefined'
export const isVariantSize = (x: unknown): x is VariationSize => typeof (x as VariantProduct)?.size !== 'undefined'

export const isOperationDefinitionNode = (x: unknown): x is OperationDefinitionNode =>
	typeof (x as OperationDefinitionNode)?.operation !== 'undefined'

export const isAurusPayment = (x: unknown): x is AurusCreditCard =>
	(x as AurusCreditCard)?.__typename === 'AurusCreditCardPaymentInstrument'

export const isOisItemizedRma = (x: unknown): x is OisItemizedRma =>
	(x as OisItemizedRma).__typename === 'OIS_ItemizedRma'
