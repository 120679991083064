import type { ApolloError, FetchResult } from '@apollo/client'
import { useLazyQuery, useMutation } from '@apollo/client'
import type { GraphQLErrors } from '@apollo/client/errors'
import { createContext, useCallback, useRef, useContext, useEffect, useMemo, useState } from 'react'

import type { Maybe } from 'graphql/jsutils/Maybe'
import { useAnalytics } from '~/components/hooks/useAnalytics'
import { useSession } from '~/components/providers/UaSessionProvider/UaSessionProvider'
import { event as gaEvent } from '~/components/thirdparty/GoogleAnalytics'
import type {
	Address,
	AddressInput,
	Basket,
	BasketPartsFragment,
	EGiftCardDetailsInput,
	GetCartItemsDetailQuery,
	IdMeScope,
	LoyaltyRewardFlowType,
	OrderReceipt,
	RemovePaymentInstrumentInBasketMutation,
	UpdateShipmentByIdInBasketInput,
} from '~/graphql/generated/uacapi/type-document-node'
import {
	AddCouponItemToBasketDocument,
	AddEgiftCardToBasketDocument,
	AddProductItemsToBasketDocument,
	ApplyIdMeDiscountToBasketDocument,
	BeginPayPalCheckoutForBasketDocument,
	CouponStatusCode,
	GetCartItemsDetailDocument,
	GetCartShippingAddressForVerificationDocument,
	GetLoyaltyCartDataDocument,
	MoveBasketProductItemToWishListDocument,
	MoveWishListProductItemToBasketDocument,
	PlaceOrderDocument,
	RedeemRewardDocument,
	RemoveCouponItemFromBasketDocument,
	RemoveIdMeDiscountFromBasketDocument,
	RemovePaymentInstrumentInBasketDocument,
	RemoveProductItemsFromBasketDocument,
	SetSelectedStoreDocument,
	ShippingMethodType,
	UpdateApplePayPaymentInstrumentDocument,
	UpdateBasketDocument,
	UpdateBillingAddressInBasketDocument,
	UpdateCreditCardPaymentInstrumentInBasketDocument,
	UpdateEGiftCardInBasketDocument,
	UpdateGiftCardPaymentInstrumentInBasketDocument,
	UpdateGiftCheckboxInBasketDocument,
	UpdateKlarnaPaymentInstrumentInBasketDocument,
	UpdatePaymetricPaymentInstrumentInBasketDocument,
	UpdatePayPalPaymentInstrumentInBasketDocument,
	UpdateProductItemsInBasketDocument,
	UpdateShippingAddressByIdInBasketDocument,
	UpdateShippingAddressInBasketDocument,
	UpdateShippingAddressWithEmailDocument,
	UpdateCustomerInfoInBasketDocument,
	UpdateShippingMethodByIdInBasketShipmentDocument,
	UpdatePaymetricXiInterceptPaymentInstrumentInBasketDocument,
} from '~/graphql/generated/uacapi/type-document-node'
import { sanitizeSuggestions } from '~/lib/address'
import { mapCartObject } from '~/lib/basket'
import {
	getAvailableShippingMethods,
	getKlarnaPaymentInstrument,
	getShipment,
	getShipmentId,
	nullCart,
} from '~/lib/cart'
import logger from '~/lib/logger'
import type { FavoritesAddActionData } from '~/lib/types/analytics.interface'
import { CheckoutStep } from '~/lib/types/analytics.interface'
import type { Cart, CartProduct, CartProviderError } from '~/lib/types/cart.interface'
import type { MappedWishListProduct } from '~/lib/types/wishlist.interface'
import { mapWishListProducts } from '~/lib/wishlist'
import { getGiftCardBalance, getGiftCardErrorMessage } from '~/lib/gift-card'
import { useAbTest } from '~/components/providers/AbTestProvider/AbTestProvider'
import { CUSTOMER_BOPIS_CONTACT_STORAGE_KEY } from '~/lib/constants'
import { ensureString } from '~/types/strict-null-helpers'
import {
	CartProviderErrorCode,
	CartProviderApplyGiftErrorCode,
	CartProviderPayPalErrorCode,
	CartProviderPlaceOrderErrorCode,
	CartProviderProductErrorCode,
	ShippingAddressErrorCode,
} from '~/lib/types/cart.interface'
import { getHandledGraphErrors } from './utils'
import { partition } from '~/lib/arrays'

// When configuring the errorPolicy to 'none', the response.errors property is an ApolloError
type FetchErrorResult<T> = Omit<FetchResult<T>, 'errors'> & {
	errors?: ApolloError
}

async function mutationAsFetchErrorResult<TData>(
	input: () => Promise<FetchResult<TData>>,
): Promise<FetchErrorResult<TData>> {
	const resp = await input()
	return resp as FetchErrorResult<TData>
}

type CartProviderCard = {
	cardType: string
	creditCardToken: string
	expirationMonth: number
	expirationYear: number
	holder?: string
	numberLastDigits?: string
}

type CartProductItem = { id: string; quantity: number }

type CartProviderResultError<T = CartProviderErrorCode> = {
	errors: CartProviderError<T>[]
	success: false
}

type CartProviderResultSuccess<T = void> = {
	cart: Cart
	data: T
	success: true
}

type CartProviderResult<T = void, E = CartProviderErrorCode> = CartProviderResultError<E> | CartProviderResultSuccess<T>

// Intellisense / highlighting has issues when inlining this type
type HandleProductErrorParam = <T>(resp: FetchErrorResult<T>) => CartProviderResultError<CartProviderProductErrorCode>

export type CartProviderSplitCartData = {
	sku: string
	totalQuantitySplit: number
}

function wrapGenericError<E = CartProviderErrorCode>(code: E, message: string): CartProviderResultError<E> {
	return {
		errors: [
			{
				code,
				message,
			},
		],
		success: false,
	}
}

function wrapGraphError<T = unknown, E = CartProviderErrorCode>(
	{ errors: apolloError }: FetchErrorResult<T>,
	code: E,
	message: string,
): CartProviderResultError<E> {
	return {
		errors: [
			{
				code,
				error: apolloError,
				message,
			},
		],
		success: false,
	}
}

function wrapGraphSuccess<T>(data: T, cart: Cart): CartProviderResultSuccess<T> {
	return {
		cart,
		data,
		success: true,
	}
}

export interface CartContextInterface {
	addCouponLoading: boolean
	addEGiftError?: Error
	addEGiftLoading: boolean
	addItemsError?: Error
	addItemsLoading: boolean
	cart?: Cart
	cartOperationError?: Error
	cartOperationLoading: boolean
	updateItemsError?: Error
	updateItemsLoading: boolean
	updateKlarnaError?: Error
	addCoupon({ code }: { code: string }): Promise<CartProviderResult<void, CouponStatusCode>>
	addEGift(item: EGiftCardDetailsInput): Promise<CartProviderResult>
	addItems(
		items: { id: string; quantity: number; store?: string }[],
	): Promise<CartProviderResult<void, CartProviderProductErrorCode>>
	applyGiftCard({
		locale,
		number,
		pin,
	}: {
		locale: string
		number: string
		pin: string
	}): Promise<CartProviderResult<void, CartProviderApplyGiftErrorCode>>
	applyIdMeDiscount({ scope, token }: { scope: IdMeScope; token: string }): Promise<CartProviderResult>
	ensureShipmentId(shipmentId: Maybe<string>): Promise<string>
	getPaymentInstrumentKlarnaClientToken(): Promise<CartProviderResult<string>>
	getPaymentInstrumentPayPalToken(): Promise<CartProviderResult<string>>
	moveItemToWishlist(
		item: CartProduct,
	): Promise<CartProviderResult<MappedWishListProduct[], CartProviderProductErrorCode>>
	moveWishlistItemToCart(id: string): Promise<CartProviderResult<MappedWishListProduct[]>>
	optIn(): Promise<CartProviderResult>
	placeOrder({
		accertifyInAuthTransactionId,
		billingAddress,
		email,
	}: {
		accertifyInAuthTransactionId: string
		billingAddress: AddressInput
		email: string
	}): Promise<CartProviderResult<OrderReceipt, CartProviderPlaceOrderErrorCode>>
	placeOrderSubmitted: boolean
	/**
	 * @deprecated This **should not** be used directly. Instead, use the LoyaltyProvider::redeemRewards wrapper,
	 * via the `useLoyalty()` hook. This ensures all both cart and loyalty state stays in sync
	 */
	redeemReward({
		id,
		flowType,
		productId,
	}: {
		id: number
		flowType: LoyaltyRewardFlowType
		productId?: string
	}): Promise<CartProviderResult>
	refreshCart(): Promise<Cart | undefined>
	removeCoupon(id: string): Promise<CartProviderResult>
	removeIdMeDiscount(): Promise<CartProviderResult>
	removeItems(
		items: CartProduct[],
		isMiniCart?: boolean,
	): Promise<CartProviderResult<void, CartProviderProductErrorCode>>
	removePaymentInstrument(id: string): Promise<CartProviderResult>
	removePaymentInstruments(
		filter?: (pi: BasketPartsFragment['paymentInstruments'][number]) => boolean,
	): Promise<CartProviderResult>
	setPlaceOrderSubmitted(isSubmitted: boolean): void
	setSelectedStore({
		storeId,
		cartItems,
	}: {
		storeId: string
		cartItems: CartProductItem[]
	}): Promise<CartProviderResult>
	updateBillingAddress({
		billingAddress,
		useAsShipping,
	}: {
		billingAddress: AddressInput
		useAsShipping?: boolean
	}): Promise<CartProviderResult>
	updateEGift(item: EGiftCardDetailsInput): Promise<CartProviderResult<void, CartProviderProductErrorCode>>
	updateItems(
		updates: { item: CartProduct; quantity?: number; store?: string; variant?: string; primaryBopisContact?: string }[],
	): Promise<CartProviderResult<CartProviderSplitCartData[], CartProviderProductErrorCode>>
	updatePaymentInstrument({
		id,
		cardToken,
		expiry,
		lastFour,
		oneTimeToken,
		type,
	}: {
		id?: string
		cardToken?: string
		expiry?: string
		lastFour?: string
		oneTimeToken?: string
		type?: string
	}): Promise<CartProviderResult>
	updatePaymentInstrumentApplePay({
		id,
		data,
	}: {
		id?: string
		data: string
	}): Promise<CartProviderResult<undefined, ShippingAddressErrorCode>>
	updatePaymentInstrumentKlarna({ authToken }: { authToken: string }): Promise<CartProviderResult>
	updatePaymentInstrumentPaymetric({
		auth,
		expiry,
		lastFour,
		type,
	}: {
		auth: string
		expiry?: string
		lastFour?: string
		type?: string
	}): Promise<CartProviderResult>
	updatePaymentInstrumentPaymetricIframe({ accessToken }: { accessToken: string }): Promise<CartProviderResult>
	updatePaymentInstrumentPayPal({
		email,
		payerId,
		token,
	}: {
		email: string
		payerId: string
		token: string
	}): Promise<CartProviderResult<void, CartProviderPayPalErrorCode>>
	updateShipmentGiftDetails(input: UpdateShipmentByIdInBasketInput): Promise<CartProviderResult>
	updateShipping({
		email,
		shipmentId,
		shippingAddress,
		useAsBilling,
	}: {
		email?: string
		shipmentId: string
		// TODO: use local type
		shippingAddress: AddressInput
		useAsBilling?: boolean
	}): Promise<CartProviderResult>
	updateCustomerEmail({ email }: { email: string }): Promise<CartProviderResult>
	updateShippingById({ addressId, shipmentId }: { addressId: string; shipmentId: string }): Promise<CartProviderResult>
	updateShippingMethodById({
		shipmentId,
		shippingMethodId,
	}: {
		shipmentId: string
		shippingMethodId: string
	}): Promise<CartProviderResult>
	updateShoprunnerToken(token: string): Promise<CartProviderResult>
	verifyShippingAddress(): Promise<CartProviderResult<Address>>
	updateSavedCardOnBasket(_: CartProviderCard): Promise<CartProviderResultSuccess<undefined> | CartProviderResultError>
}

export const defaultCartProvider = {
	addCouponLoading: false,
	addEGiftLoading: false,
	addItemsLoading: false,
	cartOperationLoading: false,
	updateItemsLoading: false,
	placeOrderSubmitted: false,
	setPlaceOrderSubmitted: () => undefined,
	addCoupon: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	addEGift: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	addItems: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	applyGiftCard: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	applyIdMeDiscount: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	ensureShipmentId: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	getPaymentInstrumentKlarnaClientToken: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	getPaymentInstrumentPayPalToken: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	moveItemToWishlist: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	moveWishlistItemToCart: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	optIn: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	placeOrder: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	redeemReward: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	refreshCart: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	removeCoupon: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	removeIdMeDiscount: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	removeItems: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	removePaymentInstrument: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	removePaymentInstruments: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	setSelectedStore: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	updateBillingAddress: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	updateEGift: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	updateItems: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	updatePaymentInstrument: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	updatePaymentInstrumentApplePay: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	updatePaymentInstrumentKlarna: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	updatePaymentInstrumentPaymetric: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	updatePaymentInstrumentPaymetricIframe: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	updatePaymentInstrumentPayPal: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	updateShipmentGiftDetails: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	updateShipping: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	updateCustomerEmail: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	updateShippingById: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	updateShippingMethodById: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	updateShoprunnerToken: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	verifyShippingAddress: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
	updateSavedCardOnBasket: async () => {
		throw new Error('Not implemented. Please include CartProvider in your app.')
	},
} as CartContextInterface

export const CartContext = createContext(defaultCartProvider)

export function useCart() {
	return useContext(CartContext)
}

function CartProvider({ children }: React.PropsWithChildren): React.ReactElement {
	const { analyticsManager } = useAnalytics()
	const isNewCheckoutEnabled = useAbTest('isNewCheckoutEnabled')
	const { user } = useSession()

	const [cart, setCart] = useState<Cart>()
	const newCart = useMemo(
		() => ({
			...nullCart,
			checkoutStep: isNewCheckoutEnabled ? CheckoutStep.CONTACT : CheckoutStep.SHIPPING,
		}),
		[isNewCheckoutEnabled],
	)
	const mapAndSetCart = useCallback(
		(basket: Omit<BasketPartsFragment, '__typename'>) => {
			const cartData = mapCartObject(basket, !!isNewCheckoutEnabled, user)
			setCart(cartData)
			return cartData
		},
		[user, setCart, isNewCheckoutEnabled],
	)

	const handleOnCompletedMutation = useCallback(
		(data?: { [key: string]: unknown }) => {
			const [action, { basket }] = Object.entries(data ?? {})
				.filter(
					(kv): kv is [string, { basket?: BasketPartsFragment }] =>
						/* eslint-disable-next-line dot-notation */
						typeof kv[1] === 'object' && kv[1]?.['basket']?.['__typename'] === 'Basket',
				)
				.find(([_, val]) => !!val.basket) ?? [
				Object.keys(data ?? {}).find((k) => !k.startsWith('__')) ?? 'Unknown',
				{ basket: undefined },
			]

			if (!basket) {
				// if there is no basket passed in to this method
				// than no data was returned from the mutation that called it
				// the basket object is a non-nullable field returned from UACAPI
				// so this error should not be possible
				logger.warn(
					{
						action,
						prevBasket: cart,
					},
					'[CartProvider] Missing basket',
				)

				return
			}

			// Ignore the following mutations, as they intentfully modify the product count
			if (['moveBasketProductItemToWishList', 'removeProductItemsFromBasket'].includes(action)) {
				return
			}

			// validate previous basket had items to begin with
			if (!cart || !cart?.products || cart?.products.length === 0) return

			// at this step we validated there was a previous basket with items
			// and the new basket exists now we just need to validate the products
			// are still in the new basket, if not log a basket wipe error
			if ((basket.productItems?.totalCount ?? 0) === 0) {
				// this is an error as there should be items in the cart
				logger.error({ action, cart }, '[CartProvider] Basket wiped')
			}
		},
		[cart],
	)

	const [cartOperationError, setCartOperationError] = useState<Error | undefined>()
	const [cartOperationLoading, setCartOperationLoading] = useState<boolean>(false)
	const [addCouponItemToBasketError, setAddCouponItemToBasketError] = useState<ApolloError | undefined>()
	const [applyGiftCartError, setApplyGiftCardError] = useState<ApolloError | undefined>()
	const [paymentInstrumentApplePayError, setPaymentInstrumentApplePayError] = useState<Error | undefined>()
	const [placeOrderSubmitted, setPlaceOrderSubmitted] = useState<boolean>(false)

	const [getCartQuery, { loading: getCartLoading, error: getCartError }] = useLazyQuery<GetCartItemsDetailQuery>(
		GetCartItemsDetailDocument,
		{
			variables: {
				first: 50,
			},
			fetchPolicy: 'no-cache',
			// Do we want to log initial cart query failures?
			// onError: (error) => analyticsManager.fireApolloErrorShown(error),
		},
	)

	// #region mutations
	const [addCouponItemToBasketMutation, { loading: addCouponItemToBasketLoading }] = useMutation(
		AddCouponItemToBasketDocument,
		{
			errorPolicy: 'none',
			onCompleted: handleOnCompletedMutation,
			onError: (error) => analyticsManager.fireApolloErrorShown(error),
		},
	)

	const [addEGiftCardToBasket, { loading: addEGiftCardToBasketLoading, error: addEGiftCartToBasketError }] =
		useMutation(AddEgiftCardToBasketDocument, {
			errorPolicy: 'none',
			onCompleted: handleOnCompletedMutation,
			onError: (error) => analyticsManager.fireApolloErrorShown(error),
		})

	const [addProductItemsToBasket, { loading: addProductItemsToBasketLoading, error: addProductItemsToBasketError }] =
		useMutation(AddProductItemsToBasketDocument, {
			errorPolicy: 'none',
			onCompleted: handleOnCompletedMutation,
			onError: (error) => analyticsManager.fireApolloErrorShown(error),
		})

	const [
		applyIdMeDiscountToBasketMutation,
		{ loading: applyIdMeDiscountToBasketLoading, error: applyIdMeDiscountToBasketError },
	] = useMutation(ApplyIdMeDiscountToBasketDocument, {
		errorPolicy: 'none',
		onCompleted: handleOnCompletedMutation,
		onError: (error) => analyticsManager.fireApolloErrorShown(error),
	})

	const [beginPayPalCheckoutMutation, { loading: beginPayPalCheckoutLoading, error: beginPayPalCheckoutError }] =
		useMutation(BeginPayPalCheckoutForBasketDocument, {
			errorPolicy: 'none',
			onCompleted: handleOnCompletedMutation,
			onError: (error) => analyticsManager.fireApolloErrorShown(error),
		})

	const [
		moveBasketProductItemToWishListMutation,
		{ loading: moveBasketProductItemToWishListLoading, error: moveBasketProductItemToWishListError },
	] = useMutation(MoveBasketProductItemToWishListDocument, {
		errorPolicy: 'none',
		onCompleted: handleOnCompletedMutation,
		onError: (error) => analyticsManager.fireApolloErrorShown(error),
	})

	const [
		moveWishListProductItemToBasketMutation,
		{ loading: moveWishListProductItemToBasketLoading, error: moveWishListProductItemToBasketError },
	] = useMutation(MoveWishListProductItemToBasketDocument, {
		errorPolicy: 'none',
		onCompleted: handleOnCompletedMutation,
		onError: (error) => analyticsManager.fireApolloErrorShown(error),
	})

	const [placeOrderMutation, { loading: placeOrderLoading, error: placeOrderError }] = useMutation(PlaceOrderDocument, {
		errorPolicy: 'none',
		onError: (error) => analyticsManager.fireApolloErrorShown(error),
	})

	const [redeemRewardMutation, { loading: redeemRewardLoading, error: redeemRewardError }] = useMutation(
		RedeemRewardDocument,
		{
			errorPolicy: 'none',
			onCompleted: handleOnCompletedMutation,
			onError: (error) => analyticsManager.fireApolloErrorShown(error),
			// TODO: should this also be executed upon initial render?
			// This currently results in a number of failed calls:
			// > Unauthorized: Must be a registered user.
			refetchQueries: [GetLoyaltyCartDataDocument],
		},
	)

	const [
		removeCouponItemFromBasketMutation,
		{ loading: removeCouponItemFromBasketLoading, error: removeCouponItemFromBasketError },
	] = useMutation(RemoveCouponItemFromBasketDocument, {
		onCompleted: handleOnCompletedMutation,
		onError: (error) => analyticsManager.fireApolloErrorShown(error),
		// TODO: should this also be executed upon initial render?
		// This currently results in a number of failed calls:
		// > Unauthorized: Must be a registered user.
		refetchQueries: [GetLoyaltyCartDataDocument],
	})

	const [
		removeIdMeDiscountFromBasketMutation,
		{ loading: removeIdMeDiscountFromBasketLoading, error: removeIdMeDiscountFromBasketError },
	] = useMutation(RemoveIdMeDiscountFromBasketDocument, {
		errorPolicy: 'none',
		onCompleted: handleOnCompletedMutation,
		onError: (error) => analyticsManager.fireApolloErrorShown(error),
	})

	const [removeItemsMutation, { loading: removeItemsLoading, error: removeItemsError }] = useMutation(
		RemoveProductItemsFromBasketDocument,
		{
			errorPolicy: 'none',
			onCompleted: handleOnCompletedMutation,
			onError: (error) => analyticsManager.fireApolloErrorShown(error),
		},
	)

	const [
		removePaymentInstrumentMutation,
		{ loading: removePaymentInstrumentLoading, error: removePaymentInstrumentError },
	] = useMutation(RemovePaymentInstrumentInBasketDocument, {
		errorPolicy: 'none',
		onCompleted: handleOnCompletedMutation,
		onError: (error) => analyticsManager.fireApolloErrorShown(error),
	})

	const [setSelectedStoreMutation, { loading: setSelectedStoreLoading, error: setSelectedStoreError }] = useMutation(
		SetSelectedStoreDocument,
		{
			errorPolicy: 'none',
			onCompleted: handleOnCompletedMutation,
			onError: (error) => analyticsManager.fireApolloErrorShown(error),
		},
	)

	const [updateApplePayMutation, { loading: updateApplePayLoading }] = useMutation(
		UpdateApplePayPaymentInstrumentDocument,
		{
			errorPolicy: 'none',
			onCompleted: handleOnCompletedMutation,
			onError: (error) => analyticsManager.fireApolloErrorShown(error),
		},
	)

	const [updateBasketMutation, { loading: updateBasketLoading, error: updateBasketError }] = useMutation(
		UpdateBasketDocument,
		{
			errorPolicy: 'none',
			onCompleted: handleOnCompletedMutation,
			onError: (error) => analyticsManager.fireApolloErrorShown(error),
		},
	)

	const [updateBasketItemMutation, { loading: updateBasketItemLoading, error: updateBasketItemError }] = useMutation(
		UpdateProductItemsInBasketDocument,
		{
			errorPolicy: 'none',
			onCompleted: handleOnCompletedMutation,
			onError: (error) => analyticsManager.fireApolloErrorShown(error),
		},
	)

	const [updateBillingAddressMutation, { loading: updateBillingAddressLoading, error: updateBillingAddressError }] =
		useMutation(UpdateBillingAddressInBasketDocument, {
			errorPolicy: 'none',
			onCompleted: handleOnCompletedMutation,
			onError: (error) => analyticsManager.fireApolloErrorShown(error),
		})

	const [updateCreditCardMutation, { loading: updateCreditCardLoading, error: updateCreditCardError }] = useMutation(
		UpdateCreditCardPaymentInstrumentInBasketDocument,
		{
			errorPolicy: 'none',
			onCompleted: handleOnCompletedMutation,
			onError: (error) => analyticsManager.fireApolloErrorShown(error),
		},
	)

	const [updateEGiftCardMutation, { loading: updateEGiftCartLoading, error: updateEGiftCardError }] = useMutation(
		UpdateEGiftCardInBasketDocument,
		{
			errorPolicy: 'none',
			onCompleted: handleOnCompletedMutation,
			onError: (error) => analyticsManager.fireApolloErrorShown(error),
		},
	)

	const [updateKlarnaMutation, { loading: updateKlarnaLoading, error: updateKlarnaError }] = useMutation(
		UpdateKlarnaPaymentInstrumentInBasketDocument,
		{
			errorPolicy: 'none',
			onCompleted: handleOnCompletedMutation,
			onError: (error) => analyticsManager.fireApolloErrorShown(error),
		},
	)

	const [updateGiftCardPaymentMutation, { loading: updateGiftCardPaymentLoading }] = useMutation(
		UpdateGiftCardPaymentInstrumentInBasketDocument,
		{
			errorPolicy: 'none',
			onCompleted: handleOnCompletedMutation,
			onError: (error) => analyticsManager.fireApolloErrorShown(error),
		},
	)

	const [updateGiftCheckboxMutation, { loading: updateGiftCheckboxLoading, error: updateGiftCheckboxError }] =
		useMutation(UpdateGiftCheckboxInBasketDocument, {
			errorPolicy: 'none',
			onCompleted: handleOnCompletedMutation,
			onError: (error) => analyticsManager.fireApolloErrorShown(error),
		})

	const [updatePaymetricMutation, { loading: updatePaymetricLoading, error: updatePaymetricError }] = useMutation(
		UpdatePaymetricPaymentInstrumentInBasketDocument,
		{
			errorPolicy: 'none',
			onCompleted: handleOnCompletedMutation,
			onError: (error) => analyticsManager.fireApolloErrorShown(error),
		},
	)

	const [updatePaymetricIframeMutation, { loading: updatePaymetricIframeLoading, error: updatePaymetricIframeError }] =
		useMutation(UpdatePaymetricXiInterceptPaymentInstrumentInBasketDocument, {
			errorPolicy: 'none',
			onCompleted: handleOnCompletedMutation,
			onError: (error) => analyticsManager.fireApolloErrorShown(error),
		})

	const [updatePayPalMutation, { loading: updatePayPalLoading, error: updatePayPalError }] = useMutation(
		UpdatePayPalPaymentInstrumentInBasketDocument,
		{
			errorPolicy: 'none',
			onCompleted: handleOnCompletedMutation,
			onError: (error) => analyticsManager.fireApolloErrorShown(error),
		},
	)

	const [updateShippingAddressMutation, { loading: updateShippingAddressLoading, error: updateShippingAddressError }] =
		useMutation(UpdateShippingAddressInBasketDocument, {
			errorPolicy: 'none',
			onCompleted: handleOnCompletedMutation,
			onError: (error) => analyticsManager.fireApolloErrorShown(error),
		})

	const [
		updateShippingAddressByIdMutation,
		{ loading: updateShippingAddressByIdLoading, error: updateShippingAddressByIdError },
	] = useMutation(UpdateShippingAddressByIdInBasketDocument, {
		errorPolicy: 'none',
		onError: (error) => analyticsManager.fireApolloErrorShown(error),
	})

	const [
		updateShippingAddressWithEmailMutation,
		{ loading: updateShippingAddressWithEmailLoading, error: updateShippingAddressWithEmailError },
	] = useMutation(UpdateShippingAddressWithEmailDocument, {
		errorPolicy: 'none',
		onError: (error) => analyticsManager.fireApolloErrorShown(error),
	})

	const [
		updateCustomerInfoInBasketMutation,
		{ loading: updateCustomerInfoInBasketLoading, error: updateCustomerInfoInBasketError },
	] = useMutation(UpdateCustomerInfoInBasketDocument, {
		errorPolicy: 'none',
		onError: (error) => analyticsManager.fireApolloErrorShown(error),
	})

	const [
		updateShippingMethodByIdMutation,
		{ loading: updateShippingMethodByIdLoading, error: updateShippingMethodByIdError },
	] = useMutation(UpdateShippingMethodByIdInBasketShipmentDocument, {
		errorPolicy: 'none',
		onCompleted: handleOnCompletedMutation,
		onError: (error) => analyticsManager.fireApolloErrorShown(error),
	})

	const [verifyShippingAddressQuery, { loading: verifyShippingAddressLoading, error: verifyShippingAddressError }] =
		useLazyQuery(GetCartShippingAddressForVerificationDocument, {
			errorPolicy: 'none',
			onError: (error) => analyticsManager.fireApolloErrorShown(error),
		})
	// #endregion

	const refreshCart = useCallback(async () => {
		const resp = await getCartQuery()
		return resp?.data?.viewer?.basket ? mapAndSetCart(resp.data.viewer.basket) : undefined
	}, [getCartQuery, mapAndSetCart])

	// #region utils
	const handleProductError = useCallback<HandleProductErrorParam>(
		(resp) => {
			const errors = getHandledGraphErrors(resp.errors)
			const missingProductError = errors.find((e) => e.code === CartProviderProductErrorCode.MISSING_PRODUCT)

			if (missingProductError) {
				// Item doesn't exist in basket, refresh state
				logger.warn({ error: missingProductError.error }, 'Refreshing basket due to missing product')
				refreshCart()
			}

			return {
				errors,
				success: false,
			}
		},
		[refreshCart],
	)
	// #endregion

	const addCoupon = useCallback(
		async ({ code }: { code: string }): Promise<CartProviderResult<void, CouponStatusCode>> => {
			const resp = await mutationAsFetchErrorResult(() =>
				addCouponItemToBasketMutation({
					variables: {
						first: 50,
						input: {
							couponItem: {
								code: code.trim(),
							},
						},
					},
				}),
			)

			if (resp.data) {
				const { basket } = resp.data.addCouponItemToBasket
				const cart = mapAndSetCart(basket)

				if (
					basket.couponItems.find(
						(item) => item.code === code && item.statusCode === CouponStatusCode.NO_APPLICABLE_PROMOTION,
					)
				) {
					// all valid coupons that are added but at the time, not applicable to basket criteria,
					// get SUCCESSFULLY ADDED TO THE BASKET (present in couponItems), but has no price adjustment/effect to price
					// Analytics-wise, this is a 'failed' scenario - reason we check couponItems here in onCompleted and fire a promo_code_failed action
					analyticsManager.firePromoCodeAttempt({
						state: 'fail',
						abandon_checkout_field: 'order summary : promoCode',
						tealium_event: 'promo_code_failed',
						promo_code: code,
						error_message: 'Your cart does not meet the promotion criteria. Please check offer details to learn more.',
					})
				} else {
					const { promoName, promoSegment, promoClass, triggerId } = analyticsManager.getCartPromoCodes(
						basket as Basket,
						code,
					)

					analyticsManager.firePromoCodeAttempt({
						state: 'success',
						abandon_checkout_field: 'order summary : promoCode',
						tealium_event: 'promo_code_added',
						promo_code: code,
						promo_name: promoName,
						promo_segment: ensureString(promoSegment),
						promo_class: promoClass,
						promo_trigger_id: triggerId,
					})
				}

				return wrapGraphSuccess(undefined, cart)
			}

			const errorCode = resp.errors?.graphQLErrors[0]?.extensions?.arguments?.statusDetails
				?.errorCode as CouponStatusCode

			const errorMessage = ((couponStatusCode: string) => {
				switch (couponStatusCode) {
					case CouponStatusCode.NO_ACTIVE_PROMOTION:
					case CouponStatusCode.COUPON_DISABLED:
						return `Promo code ${code} cannot be added to your bag because the promotion is not active sorry for the inconvenience.`
					case CouponStatusCode.REDEMPTION_LIMIT_EXCEEDED:
					case CouponStatusCode.TIMEFRAME_REDEMPTION_LIMIT_EXCEEDED:
					case CouponStatusCode.COUPON_CODE_ALREADY_REDEEMED:
						return `Promo code ${code} already redeemed`
					case CouponStatusCode.COUPON_CODE_UNKNOWN:
						return `Promo code ${code} cannot be added to your bag because it is not a valid code. Please double check to ensure the code was entered properly.`
					case CouponStatusCode.COUPON_CODE_ALREADY_IN_BASKET:
					case CouponStatusCode.COUPON_ALREADY_IN_BASKET:
						return `Promo code ${code} cannot be combined with other promo codes already in your bag.`
					case CouponStatusCode.NO_APPLICABLE_PROMOTION:
						return `Your cart does not meet the promotion criteria for promo ${code}. Please check offer details to learn more.`
					default:
						return `Promo code cannot be added to your bag. Please make sure the code is valid, or check FAQ for more information on excluded products.`
				}
			})(errorCode)

			analyticsManager.firePromoCodeAttempt({
				state: 'fail',
				abandon_checkout_field: 'order summary : promoCode',
				tealium_event: 'promo_code_failed',
				promo_code: code,
				error_message: errorMessage,
			})

			/**
			 * Only if we receive a non-standard error response when posting a promo code will it proceed to throw a
			 * failed query-level state for display on the checkout page.
			 */
			if (!Object.values(CouponStatusCode).includes(errorCode)) {
				setAddCouponItemToBasketError(resp.errors)
			} else {
				setAddCouponItemToBasketError(undefined)
			}

			return wrapGraphError(resp, errorCode, errorMessage)
		},
		[analyticsManager, addCouponItemToBasketMutation, mapAndSetCart],
	)

	const addEGift = useCallback(
		async (item: EGiftCardDetailsInput): Promise<CartProviderResult> => {
			const resp = await mutationAsFetchErrorResult(() =>
				addEGiftCardToBasket({
					variables: {
						first: 50,
						input: {
							eGiftCardDetails: item,
						},
					},
				}),
			)

			if (resp.data) {
				return wrapGraphSuccess(undefined, mapAndSetCart(resp.data.addEGiftCardToBasket.basket))
			}

			return wrapGraphError(resp, CartProviderErrorCode.UNHANDLED_ERROR, '')
		},
		[addEGiftCardToBasket, mapAndSetCart],
	)

	const addItems = useCallback(
		async (
			items: { id: string; quantity: number; store?: string }[],
		): Promise<CartProviderResult<void, CartProviderProductErrorCode>> => {
			const resp = await mutationAsFetchErrorResult(() =>
				addProductItemsToBasket({
					variables: {
						input: {
							productItems: items.map(({ id: productId, quantity, store: cFromStoreId }) => ({
								cFromStoreId,
								productId,
								quantity,
							})),
						},
						first: 50,
					},
				}),
			)

			if (resp.data) {
				const updatedCart = mapAndSetCart(resp.data.addProductItemsToBasket.basket)
				const cartProducts = items.map(
					(i) =>
						({
							...(updatedCart.products.find((p) => p.productId === i.id) ?? {}),
							quantity: i.quantity,
						} as CartProduct),
				)

				cartProducts
					.map((i) => analyticsManager.getCartObjectFromCartProduct(i))
					.forEach((obj) => analyticsManager.fireCartAdd(obj))

				cartProducts.forEach((cp) =>
					gaEvent('add_to_cart', {
						currency: cp.currency || 'USD',
						value: cp.prices?.list?.max || 0,
						items: [
							{
								item_id: cp.style,
								item_name: cp.name,
							},
						],
					}),
				)

				return wrapGraphSuccess(undefined, updatedCart)
			}

			return {
				errors: getHandledGraphErrors(resp.errors),
				success: false,
			}
		},
		[analyticsManager, addProductItemsToBasket, mapAndSetCart],
	)

	const applyGiftCard = useCallback(
		async ({
			locale,
			number,
			pin,
		}: {
			locale: string
			number: string
			pin: string
		}): Promise<CartProviderResult<void, CartProviderApplyGiftErrorCode>> => {
			if (!user?.uacapiAccessToken) {
				return wrapGenericError(CartProviderApplyGiftErrorCode.UNHANDLED_ERROR, 'Missing session')
			}

			const defaultErrorMessage = getGiftCardErrorMessage(1)

			const resp = await mutationAsFetchErrorResult(() =>
				updateGiftCardPaymentMutation({
					variables: {
						input: {
							cardNumber: number,
							pin,
						},
						first: 50,
					},
				}),
			)

			if (resp.data) {
				return wrapGraphSuccess(undefined, mapAndSetCart(resp.data.updateGiftCardPaymentInstrumentInBasket.basket))
			}
			if (resp.errors?.graphQLErrors.some((e) => e.extensions?.arguments?.statusCode === 'EmptyOrInvalidGiftcard')) {
				setApplyGiftCardError(undefined)
				return wrapGenericError(CartProviderApplyGiftErrorCode.INVALID_NUMBER, defaultErrorMessage)
			}

			/**
			 * Only if we receive a non-standard error response when applying a gift card will it proceed to
			 * throw a failed query-level state for display on the checkout page.
			 */
			setApplyGiftCardError(resp.errors)

			const { error: errorMessage } = await getGiftCardBalance({
				cardNumber: number,
				pin,
				locale,
				token: user.uacapiAccessToken,
			}).catch(() => ({
				error: undefined,
			}))

			return wrapGraphError(resp, CartProviderApplyGiftErrorCode.UNHANDLED_ERROR, errorMessage ?? defaultErrorMessage)
		},
		[mapAndSetCart, updateGiftCardPaymentMutation, user?.uacapiAccessToken],
	)

	const applyIdMeDiscount = useCallback(
		async ({ scope, token }: { scope: IdMeScope; token: string }): Promise<CartProviderResult> => {
			const resp = await mutationAsFetchErrorResult(() =>
				applyIdMeDiscountToBasketMutation({
					variables: {
						first: 50,
						input: {
							idMeInfo: {
								token,
								scope,
							},
						},
					},
				}),
			)

			if (resp.data) {
				return wrapGraphSuccess(undefined, mapAndSetCart(resp.data.applyIdMeDiscountToBasket.basket))
			}

			return wrapGraphError(resp, CartProviderErrorCode.UNHANDLED_ERROR, '')
		},
		[applyIdMeDiscountToBasketMutation, mapAndSetCart],
	)

	const getPaymentInstrumentKlarnaClientToken = useCallback(async (): Promise<CartProviderResult<string>> => {
		const resp = await mutationAsFetchErrorResult(() =>
			updateKlarnaMutation({
				variables: {
					input: {
						clientMutationId: cart?.basketId,
					},
					first: 50,
				},
			}),
		)

		if (resp.data) {
			const localCart = mapAndSetCart(resp.data.updateKlarnaPaymentInstrumentInBasket.basket)
			const instrument = getKlarnaPaymentInstrument(localCart.paymentInstruments)
			if (!instrument?.clientToken) {
				return wrapGenericError(CartProviderErrorCode.UNHANDLED_ERROR, 'Missing clientToken in response')
			}
			return wrapGraphSuccess(instrument.clientToken, localCart)
		}

		return wrapGraphError(resp, CartProviderErrorCode.UNHANDLED_ERROR, '')
	}, [cart?.basketId, mapAndSetCart, updateKlarnaMutation])

	const getPaymentInstrumentPayPalToken = useCallback(async (): Promise<CartProviderResult<string>> => {
		const resp = await mutationAsFetchErrorResult(() =>
			beginPayPalCheckoutMutation({
				variables: {
					input: {
						clientMutationId: cart?.basketId,
					},
					first: 50,
				},
			}),
		)

		if (resp.data?.beginPayPalCheckoutForBasket.payPalToken) {
			return wrapGraphSuccess(
				resp.data.beginPayPalCheckoutForBasket.payPalToken,
				mapAndSetCart(resp.data.beginPayPalCheckoutForBasket.basket),
			)
		}

		return wrapGraphError(resp, CartProviderErrorCode.UNHANDLED_ERROR, '')
	}, [cart?.basketId, beginPayPalCheckoutMutation, mapAndSetCart])

	const moveItemToWishlist = useCallback(
		async (item: CartProduct): Promise<CartProviderResult<MappedWishListProduct[], CartProviderProductErrorCode>> => {
			const resp = await mutationAsFetchErrorResult(() =>
				moveBasketProductItemToWishListMutation({
					variables: {
						productId: item.id,
						first: 200,
						quantity: 1,
					},
				}),
			)

			if (resp.data) {
				analyticsManager.fireCartRemove(analyticsManager.getCartObjectFromCartProduct(item))
				const favoritesData: FavoritesAddActionData = {
					products: [
						{
							product_style: item.style,
							product_color: `${item.style}-${item.colorCode}`,
						},
					],
				}

				if (item.sku) {
					favoritesData.products[0].product_sku = item.sku
				} else if (item.size && item.style && item.colorCode) {
					favoritesData.products[0].product_sku = `${item.style}-${item.colorCode}-${item.size}`
				}
				// TODO: Refactor a more reliable way to get the sku from product data
				analyticsManager.fireFavoritesAdd(favoritesData)

				return wrapGraphSuccess(
					mapWishListProducts(resp.data.moveBasketProductItemToWishList.wishList.productListItems.edges),
					mapAndSetCart(resp.data.moveBasketProductItemToWishList.basket),
				)
			}

			return handleProductError(resp)
		},
		[analyticsManager, handleProductError, mapAndSetCart, moveBasketProductItemToWishListMutation],
	)

	// The newest products not appearing first due to sorting problems is a crucial concern.
	// By expanding the backend product pool to 200 and addressing the sorting issue,
	// we aim to ensure that the latest products are appropriately prioritized, resulting in a more effective and user-friendly experience.
	const moveWishlistItemToCart = useCallback(
		async (id: string): Promise<CartProviderResult<MappedWishListProduct[]>> => {
			const resp = await mutationAsFetchErrorResult(() =>
				moveWishListProductItemToBasketMutation({
					variables: {
						productId: id,
						first: 200,
					},
				}),
			)

			if (resp.data) {
				const analyticsProductObj = mapAndSetCart(resp.data.moveWishListProductItemToBasket.basket).products
				analyticsManager.fireCartAdd(analyticsManager.getCartObjectFromCartProduct(analyticsProductObj[0]))

				return wrapGraphSuccess(
					mapWishListProducts(resp.data.moveWishListProductItemToBasket.wishList.productListItems.edges),
					mapAndSetCart(resp.data.moveWishListProductItemToBasket.basket),
				)
			}

			return wrapGraphError(resp, CartProviderErrorCode.UNHANDLED_ERROR, '')
		},
		[mapAndSetCart, moveWishListProductItemToBasketMutation, analyticsManager],
	)

	const optIn = useCallback(async (): Promise<CartProviderResult> => {
		if (!cart?.basketId) {
			return wrapGenericError(CartProviderErrorCode.MISSING_BASKET_ID, '')
		}

		const resp = await mutationAsFetchErrorResult(() =>
			updateBasketMutation({
				variables: {
					input: {
						id: cart.basketId,
						basket: { cIsOptedInToNotifications: true },
					},
					first: 50,
				},
			}),
		)

		if (resp.data) {
			const cart = mapAndSetCart(resp.data.updateBasket.basket)
			return wrapGraphSuccess(undefined, cart)
		}

		return wrapGraphError(resp, CartProviderErrorCode.UNHANDLED_ERROR, '')
	}, [cart?.basketId, mapAndSetCart, updateBasketMutation])

	const placeOrder = useCallback(
		async ({
			accertifyInAuthTransactionId,
			billingAddress,
			email,
		}: {
			accertifyInAuthTransactionId: string
			billingAddress: AddressInput
			email: string
		}): Promise<CartProviderResult<OrderReceipt, CartProviderPlaceOrderErrorCode>> => {
			if (!cart?.basketId) {
				return wrapGenericError(CartProviderPlaceOrderErrorCode.MISSING_BASKET_ID, 'payment-processing-error')
			}
			setPlaceOrderSubmitted(true)
			const resp = await mutationAsFetchErrorResult(() =>
				placeOrderMutation({
					variables: {
						accertifyInAuthTransactionId,
						basketId: cart.basketId,
						billingAddress,
						email,
					},
				}),
			)
			setPlaceOrderSubmitted(false)
			if (resp.data) {
				setCart(newCart)
				return wrapGraphSuccess(resp.data.placeOrderFromBasket.order, newCart)
			}

			const [code, message] = ((errors?: GraphQLErrors): [CartProviderPlaceOrderErrorCode, string] => {
				if (!errors?.length) {
					return [CartProviderPlaceOrderErrorCode.UNHANDLED_ERROR, 'payment-processing-error']
				}

				const emailError = errors
					.filter((e) => e.extensions.arguments?.validationType === 'InvalidBuyerEmail')
					.map((e) => e.extensions.arguments?.validationMessage)

				if (emailError.length) {
					return [CartProviderPlaceOrderErrorCode.INVALID_EMAIL, emailError[0]]
				}

				const graphStatusErrors = errors
					.filter((e) => e.extensions?.arguments?.statusCode)
					.map(
						({
							extensions: {
								arguments: { statusCode, statusMessage },
							},
						}) => ({ statusCode, statusMessage }),
					)
					.map(({ statusCode, statusMessage }) => {
						switch (statusCode) {
							case 'QtyLimitExceededException':
								return [CartProviderPlaceOrderErrorCode.ITEM_OUT_OF_STOCK, statusMessage]
							case 'BasketValidationError':
								return [CartProviderPlaceOrderErrorCode.VALIDATION_ERROR, statusMessage]
							case 'placeOrderError':
							default:
								return [CartProviderPlaceOrderErrorCode.UNHANDLED_ERROR, statusMessage]
						}
					})
					// Only care for first?
					.find(Boolean)

				return graphStatusErrors
					? [graphStatusErrors[0], graphStatusErrors[1] ?? 'payment-processing-error']
					: [CartProviderPlaceOrderErrorCode.UNHANDLED_ERROR, 'payment-processing-error']
			})(resp.errors?.graphQLErrors)

			return wrapGraphError(resp, code, message)
		},
		[cart?.basketId, placeOrderMutation, setCart, newCart],
	)

	/**
	 * According to the documentation, redeemRewardMutation is a fusion of the following:
	 * 1. claimDiscountCouponWithRewardPoints
	 * 2. addProductItemsToBasket (If productSku is provided)
	 * 3. addCouponItemToBasket
	 *
	 * Being that it affects both cart and loyalty state, _and_ has the basket as the
	 * response data, we'll keep the main functioanlity here. This **should not** be
	 * called directly, but through a wrapper in the LoyaltyProvider, which will ensure
	 * that both the cart and loyalty state stay in sync
	 */
	const redeemReward = useCallback(
		async ({
			id,
			flowType,
			productId,
		}: {
			id: number
			flowType: LoyaltyRewardFlowType
			productId?: string
		}): Promise<CartProviderResult> => {
			const resp = await mutationAsFetchErrorResult(() =>
				redeemRewardMutation({
					variables: {
						LoyaltyClaimRewardInput: {
							rewardFlowType: flowType,
							rewardId: id,
							productItem: productId ? { productId, quantity: 1 } : null,
						},
						first: 50,
					},
				}),
			)

			if (resp.data) {
				return wrapGraphSuccess(undefined, mapAndSetCart(resp.data.claimLoyaltyReward.basket))
			}

			return wrapGraphError(resp, CartProviderErrorCode.UNHANDLED_ERROR, '')
		},
		[mapAndSetCart, redeemRewardMutation],
	)

	const removeCoupon = useCallback(
		async (id: string): Promise<CartProviderResult> => {
			const resp = await mutationAsFetchErrorResult(() =>
				removeCouponItemFromBasketMutation({
					variables: {
						input: {
							couponItem: {
								id,
							},
						},
						first: 50,
					},
				}),
			)

			if (resp.data?.removeCouponItemFromBasket) {
				const { basket } = resp.data.removeCouponItemFromBasket
				const cart = mapAndSetCart(basket)
				return wrapGraphSuccess(undefined, cart)
			}

			return wrapGraphError(resp, CartProviderErrorCode.UNHANDLED_ERROR, '')
		},
		[mapAndSetCart, removeCouponItemFromBasketMutation],
	)

	const removeIdMeDiscount = useCallback(async (): Promise<CartProviderResult> => {
		const resp = await mutationAsFetchErrorResult(() =>
			removeIdMeDiscountFromBasketMutation({
				variables: {
					first: 50,
					input: {},
				},
			}),
		)

		if (resp.data) {
			return wrapGraphSuccess(undefined, mapAndSetCart(resp.data.removeIdMeDiscountFromBasket.basket))
		}

		return wrapGraphError(resp, CartProviderErrorCode.UNHANDLED_ERROR, '')
	}, [mapAndSetCart, removeIdMeDiscountFromBasketMutation])

	const removeItems = useCallback(
		async (
			items: CartProduct[],
			isMiniCart = false,
		): Promise<CartProviderResult<undefined, CartProviderProductErrorCode>> => {
			const resp = await mutationAsFetchErrorResult(() =>
				removeItemsMutation({
					variables: {
						input: {
							productItems: items.map(({ id }) => ({ id })),
						},
						first: 50,
					},
				}),
			)

			if (resp.data) {
				items
					.map(analyticsManager.getCartObjectFromCartProduct)
					.forEach((obj) => analyticsManager.fireCartRemove(obj, isMiniCart))

				return wrapGraphSuccess(undefined, mapAndSetCart(resp.data.removeProductItemsFromBasket.basket))
			}

			return handleProductError(resp)
		},
		[analyticsManager, handleProductError, mapAndSetCart, removeItemsMutation],
	)

	const removePaymentInstruments = useCallback(
		async (
			filter?: (pi: BasketPartsFragment['paymentInstruments'][number]) => boolean,
		): Promise<CartProviderResult> => {
			const ids = (cart?.paymentInstruments ?? []).filter(filter ?? Boolean).map((pi) => pi.id)
			// Non need to call out to remove a PaymentInstrument when there are none on the cart
			if (!ids.length) {
				const updatedCart: Cart = cart ?? (await refreshCart()) ?? nullCart
				return { success: true, cart: updatedCart, data: undefined }
			}
			const resps = await Promise.all(
				ids.map((id) =>
					mutationAsFetchErrorResult(() =>
						removePaymentInstrumentMutation({
							variables: {
								input: {
									paymentInstrumentId: id,
								},
								first: 50,
							},
						}),
					),
				),
			)

			let updatedCart: Cart | undefined

			if (ids.length > 1) {
				updatedCart = await refreshCart()
			}

			const errors = resps
				.filter((r): r is { errors: ApolloError } => !!r.errors)
				.map((r) => wrapGraphError(r, CartProviderErrorCode.UNHANDLED_ERROR, ''))
				.flatMap((e) => e.errors)

			const successes = resps.filter((r): r is { data: RemovePaymentInstrumentInBasketMutation } => !!r.data)
			const success = successes.at(-1)

			if (errors.length || !success) {
				return { success: false, errors }
			}

			return {
				success: true,
				cart: updatedCart ?? mapAndSetCart(success.data.removePaymentInstrumentInBasket.basket),
				data: undefined,
			}
		},
		[cart, mapAndSetCart, refreshCart, removePaymentInstrumentMutation],
	)

	const removePaymentInstrument = useCallback(
		(id: string) => removePaymentInstruments((pi) => pi.id === id),
		[removePaymentInstruments],
	)

	const setSelectedStore = useCallback(
		async ({ storeId, cartItems }: { storeId: string; cartItems: CartProductItem[]; first: number }) => {
			const resp = await mutationAsFetchErrorResult(() =>
				setSelectedStoreMutation({
					variables: { storeId, cartItems: cartItems.map((i) => ({ ...i, cFromStoreId: storeId })), first: 50 },
				}),
			)

			return resp.data
				? wrapGraphSuccess(undefined, mapAndSetCart(resp.data.updateProductItemsInBasket.basket))
				: wrapGraphError(resp, CartProviderErrorCode.UNHANDLED_ERROR, '')
		},
		[mapAndSetCart, setSelectedStoreMutation],
	)

	const updateBillingAddress = useCallback(
		async ({ billingAddress, useAsShipping }: { billingAddress: AddressInput; useAsShipping?: boolean }) => {
			const resp = await mutationAsFetchErrorResult(() =>
				updateBillingAddressMutation({
					variables: {
						input: {
							billingAddress,
							useAsShipping,
						},
						first: 50,
					},
				}),
			)

			return resp.data
				? wrapGraphSuccess(undefined, mapAndSetCart(resp.data.updateBillingAddressInBasket.basket))
				: wrapGraphError(resp, CartProviderErrorCode.UNHANDLED_ERROR, '')
		},
		[mapAndSetCart, updateBillingAddressMutation],
	)

	const updateEGift = useCallback(
		async (item: EGiftCardDetailsInput): Promise<CartProviderResult<void, CartProviderProductErrorCode>> => {
			const resp = await mutationAsFetchErrorResult(() =>
				updateEGiftCardMutation({
					variables: {
						first: 50,
						input: {
							eGiftCardDetails: item,
						},
					},
				}),
			)

			if (resp.data) {
				return wrapGraphSuccess(undefined, mapAndSetCart(resp.data.updateEGiftCardInBasket.basket))
			}

			return {
				errors: getHandledGraphErrors(resp.errors),
				success: false,
			}
		},
		[mapAndSetCart, updateEGiftCardMutation],
	)

	const updateItems = useCallback(
		async (
			updates: {
				item: CartProduct
				quantity?: number
				store?: string
				variant?: string
				primaryBopisContact?: string
			}[],
		): Promise<CartProviderResult<CartProviderSplitCartData[], CartProviderProductErrorCode>> => {
			const bopisContact = updates.find((update) => !!update.primaryBopisContact)?.primaryBopisContact ?? undefined

			if (bopisContact) {
				window.localStorage.setItem(CUSTOMER_BOPIS_CONTACT_STORAGE_KEY, bopisContact)
			}

			const resp = await mutationAsFetchErrorResult(() =>
				updateBasketItemMutation({
					variables: {
						input: {
							productItems: updates.map((u) => ({
								id: u.item.id,
								cFromStoreId: u.store ?? u.item.cFromStoreId,
								productId: u.variant ?? u.item.productId,
								quantity: u.quantity ?? u.item.quantity,
								cPrimaryContactBOPIS: u.primaryBopisContact ?? undefined,
							})),
						},
						first: 50,
					},
				}),
			)

			if (resp.data) {
				const data = mapAndSetCart(resp.data.updateProductItemsInBasket.basket)

				if (data.products.length !== 0) {
					const [pickupItems, shippingItems] = partition(data.products, (product) => !!product.cFromStoreId)
					const splitResults: CartProviderSplitCartData[] = []

					// when a user has X items set for shipping and moves to pickup
					// if not all are available for pickup the cart product is split
					// return number of items split into shipping
					shippingItems.forEach((shippingItem) => {
						const totalQuantitySplit = pickupItems.find((pickupItem) => pickupItem.sku === shippingItem.sku)?.quantity

						if (totalQuantitySplit) {
							splitResults.push({
								sku: shippingItem.sku,
								totalQuantitySplit,
							})
						}
					})

					return wrapGraphSuccess(splitResults, data)
				}
			}

			return handleProductError(resp)
		},
		[handleProductError, mapAndSetCart, updateBasketItemMutation],
	)

	const updatePaymentInstrumentApplePay = useCallback(
		async ({
			id,
			data,
		}: {
			id?: string
			data: string
		}): Promise<CartProviderResult<undefined, ShippingAddressErrorCode>> => {
			const resp = await mutationAsFetchErrorResult(() =>
				updateApplePayMutation({
					variables: {
						input: {
							paymentInstrumentId: id,
							paymentData: data,
						},
						first: 50,
					},
				}),
			)

			if (resp.data) {
				return wrapGraphSuccess(undefined, mapAndSetCart(resp.data.updateApplePayPaymentInstrumentInBasket.basket))
			}

			const parseApolloErrorForShippingMessage = (
				apolloError: ApolloError,
			):
				| {
						code: ShippingAddressErrorCode
						error: Error
						message: string
				  }[]
				| undefined => {
				let shippingAddressErrors: unknown

				// attempting to parse the error for more user helpful messaging
				apolloError.graphQLErrors.forEach((graphError) => {
					try {
						if (graphError.extensions.arguments?.statusMessage) {
							const statusMessage: [{ [key: string]: unknown }] = JSON.parse(
								(shippingAddressErrors = graphError.extensions.arguments.statusMessage),
							)

							// parsing statusMessage for shipping errors
							shippingAddressErrors = statusMessage.find((obj) => !!Object.keys(obj).includes('shipping'))?.shipping
						}
					} catch (err) {
						logger.warn(`Error Parsing shipping address errors ${err}`)
					}
				})

				if (!shippingAddressErrors) {
					return undefined
				}

				const addressErrorFields = Object.keys(shippingAddressErrors)
				const errors = addressErrorFields.map((field) => {
					const code: ShippingAddressErrorCode = ((): ShippingAddressErrorCode => {
						switch (field) {
							case 'firstName':
								return ShippingAddressErrorCode.FIRSTNAME
							case 'lastName':
								return ShippingAddressErrorCode.LASTNAME
							case 'address1':
								return ShippingAddressErrorCode.ADDRESS1
							case 'city':
								return ShippingAddressErrorCode.CITY
							case 'stateCode':
								return ShippingAddressErrorCode.STATECODE
							case 'countryCode':
								return ShippingAddressErrorCode.COUNTRYCODE
							default:
								return ShippingAddressErrorCode.UNKNOWN_ERROR
						}
					})()

					return {
						code,
						error: { cause: `Invalid field ${field} in shipping address` } as Error,
						message: `Invalid field ${field} in shipping address`,
					}
				})
				return errors
			}

			// during ApplePay Instrument update if the Shipping Address is invalid
			// there will be error data will specify which fields were bad
			const shippingAddressErrors = parseApolloErrorForShippingMessage(resp.errors as ApolloError)
			if (shippingAddressErrors) {
				return {
					errors: shippingAddressErrors,
					success: false,
				}
			}

			setPaymentInstrumentApplePayError(resp.errors)

			return wrapGraphError(resp, ShippingAddressErrorCode.UNKNOWN_ERROR, '')
		},
		[mapAndSetCart, updateApplePayMutation],
	)

	const updatePaymentInstrument = useCallback(
		async ({
			id,
			cardToken,
			expiry,
			holder,
			lastFour,
			oneTimeToken,
			type,
		}: {
			id?: string
			cardToken?: string
			expiry?: string
			holder?: string
			lastFour?: string
			oneTimeToken?: string
			type?: string
		}) => {
			const resp = await mutationAsFetchErrorResult(() =>
				updateCreditCardMutation({
					variables: {
						input: {
							paymentInstrumentId: id,
							cardExpiryDate: expiry,
							cardType: type,
							creditCardLastFour: lastFour,
							creditCardToken: cardToken,
							holder,
							oneTimeToken,
						},
						first: 50,
					},
				}),
			)

			if (resp.data) {
				return wrapGraphSuccess(undefined, mapAndSetCart(resp.data.updateCreditCardPaymentInstrumentInBasket.basket))
			}

			return wrapGraphError(resp, CartProviderErrorCode.UNHANDLED_ERROR, '')
		},
		[mapAndSetCart, updateCreditCardMutation],
	)

	const updateSavedCardOnBasket = useCallback(
		(card: CartProviderCard) => {
			let expiry = `${card.expirationMonth}${card.expirationYear.toString(10).slice(-2)}`
			// Add the leading 0 if month is single digit since card.expirationMonth returns a single digit
			expiry = expiry.length === 3 ? `0${expiry}` : expiry
			return updatePaymentInstrument({
				cardToken: card.creditCardToken,
				expiry,
				holder: card.holder,
				lastFour: card.numberLastDigits,
				type: card.cardType,
			})
		},
		[updatePaymentInstrument],
	)

	const updatePaymentInstrumentKlarna = useCallback(
		async ({ authToken }: { authToken: string }): Promise<CartProviderResult> => {
			const resp = await mutationAsFetchErrorResult(() =>
				updateKlarnaMutation({
					variables: {
						input: {
							clientMutationId: cart?.basketId,
							paymentData: authToken,
						},
						first: 50,
					},
				}),
			)

			if (resp.data) {
				return wrapGraphSuccess(undefined, mapAndSetCart(resp.data.updateKlarnaPaymentInstrumentInBasket.basket))
			}

			return wrapGraphError(resp, CartProviderErrorCode.UNHANDLED_ERROR, '')
		},
		[cart?.basketId, mapAndSetCart, updateKlarnaMutation],
	)

	const updatePaymentInstrumentPaymetric = useCallback(
		async ({ auth, expiry, lastFour, type }: { auth: string; expiry?: string; lastFour?: string; type?: string }) => {
			const resp = await mutationAsFetchErrorResult(() =>
				updatePaymetricMutation({
					variables: {
						input: {
							authorizationPayload: auth,
							cardExpiryDate: expiry,
							cardType: type,
							creditCardLastFour: lastFour,
						},
						first: 50,
					},
				}),
			)

			if (resp.data) {
				return wrapGraphSuccess(undefined, mapAndSetCart(resp.data.updatePaymetricPaymentInstrumentInBasket.basket))
			}

			return wrapGraphError(resp, CartProviderErrorCode.UNHANDLED_ERROR, '')
		},
		[mapAndSetCart, updatePaymetricMutation],
	)

	const updatePaymentInstrumentPaymetricIframe = useCallback(
		async ({ accessToken }: { accessToken: string }) => {
			const resp = await mutationAsFetchErrorResult(() =>
				updatePaymetricIframeMutation({
					variables: {
						input: {
							accessToken,
						},
						first: 50,
					},
				}),
			)

			if (resp.data) {
				return wrapGraphSuccess(
					undefined,
					mapAndSetCart(resp.data.updatePaymetricXiInterceptPaymentInstrumentInBasket.basket),
				)
			}

			return wrapGraphError(resp, CartProviderErrorCode.UNHANDLED_ERROR, '')
		},
		[mapAndSetCart, updatePaymetricIframeMutation],
	)

	const updatePaymentInstrumentPayPal = useCallback(
		async ({
			email,
			payerId,
			token,
		}: {
			email: string
			payerId: string
			token: string
		}): Promise<CartProviderResult<void, CartProviderPayPalErrorCode>> => {
			const resp = await mutationAsFetchErrorResult(() =>
				updatePayPalMutation({
					variables: {
						input: {
							payPalEmail: email,
							payPalPayerId: payerId,
							payPalToken: token,
						},
						first: 50,
					},
				}),
			)

			if (resp.data) {
				return wrapGraphSuccess(undefined, mapAndSetCart(resp.data.updatePayPalPaymentInstrumentInBasket.basket))
			}

			if (resp.errors?.graphQLErrors.some((error) => error.extensions?.arguments?.statusCode === 'INVALID-ADDRESS')) {
				return wrapGraphError(resp, CartProviderPayPalErrorCode.INVALID_ADDRESS, '')
			}

			return wrapGraphError(resp, CartProviderPayPalErrorCode.UNHANDLED_ERROR, '')
		},
		[mapAndSetCart, updatePayPalMutation],
	)

	const updateShipmentGiftDetails = useCallback(
		async (input: UpdateShipmentByIdInBasketInput): Promise<CartProviderResult> => {
			const resp = await mutationAsFetchErrorResult(() =>
				updateGiftCheckboxMutation({
					variables: {
						input,
						first: 50,
					},
				}),
			)

			if (resp.data) {
				return wrapGraphSuccess(undefined, mapAndSetCart(resp.data.updateShipmentByIdInBasket.basket))
			}

			return wrapGraphError(resp, CartProviderErrorCode.UNHANDLED_ERROR, '')
		},
		[mapAndSetCart, updateGiftCheckboxMutation],
	)

	// TODO: is this still applicable?
	// Maybe remove after gutting cart reactiveVar
	const ensureShipmentId = useCallback(
		async (shipmentId: Maybe<string>): Promise<string> => {
			if (shipmentId) {
				return shipmentId
			}
			const cart = await refreshCart()
			const cartData = cart ?? nullCart
			return getShipmentId(cartData?.shipments)
		},
		[refreshCart],
	)

	const updateShippingMethodById = useCallback(
		async ({ shipmentId, shippingMethodId }: { shipmentId: string; shippingMethodId: string }) => {
			const resolvedShipmentId = await ensureShipmentId(shipmentId)
			const resp = await mutationAsFetchErrorResult(() =>
				updateShippingMethodByIdMutation({
					variables: {
						input: {
							shipmentId: resolvedShipmentId,
							shippingMethodId,
						},
						first: 50,
					},
				}),
			)

			if (resp.data) {
				const cart = mapAndSetCart(resp.data.updateShippingMethodByIdInBasketShipment.basket)
				return wrapGraphSuccess(undefined, cart)
			}

			return wrapGraphError(resp, CartProviderErrorCode.UNHANDLED_ERROR, '')
		},
		[ensureShipmentId, mapAndSetCart, updateShippingMethodByIdMutation],
	)

	const refreshShippingMethod = useCallback(
		async (basket: Omit<BasketPartsFragment, '__typename'>): Promise<CartProviderResult> => {
			const shipment = getShipment(basket.shipments)
			const availableShippingMethods = getAvailableShippingMethods(basket)
			if (shipment && !availableShippingMethods.some((sm) => sm.id === shipment?.shippingMethod?.id)) {
				const defaultShippingMethod =
					availableShippingMethods.find((m) => m.methodType === ShippingMethodType.STANDARD) ??
					availableShippingMethods[0]

				if (defaultShippingMethod) {
					return updateShippingMethodById({ shipmentId: shipment.id, shippingMethodId: defaultShippingMethod.id })
				}
				// TODO: should we return an error?
			}

			return { success: true, cart: mapAndSetCart(basket), data: undefined }
		},
		[mapAndSetCart, updateShippingMethodById],
	)

	const updateShipping = useCallback(
		async ({
			email,
			shipmentId,
			shippingAddress,
			useAsBilling,
		}: {
			email?: string
			shipmentId: string
			shippingAddress: AddressInput
			useAsBilling?: boolean
		}) => {
			let basket: BasketPartsFragment | undefined
			let error: ApolloError | undefined

			const resolvedShipmentId = await ensureShipmentId(shipmentId)

			// if email is provided, use the transactional document
			if (email) {
				const { data, errors } = await mutationAsFetchErrorResult(() =>
					updateShippingAddressWithEmailMutation({
						variables: {
							customerAddress: shippingAddress,
							email,
							shipmentId: resolvedShipmentId,
							useAsBilling: useAsBilling ?? false,
							first: 50,
						},
					}),
				)
				if (data) {
					basket = data.updateCustomerInfoInBasket.basket
				} else {
					error = errors
				}
			} else {
				const { data, errors } = await mutationAsFetchErrorResult(() =>
					updateShippingAddressMutation({
						variables: {
							input: { shipmentId: resolvedShipmentId, shippingAddress, useAsBilling: useAsBilling ?? false },
							first: 50,
						},
					}),
				)
				if (data) {
					basket = data.updateShippingAddressInBasket.basket
				} else {
					error = errors
				}
			}

			if (basket) {
				return refreshShippingMethod(basket)
			}

			return wrapGraphError({ errors: error }, CartProviderErrorCode.UNHANDLED_ERROR, '')
		},
		[ensureShipmentId, refreshShippingMethod, updateShippingAddressMutation, updateShippingAddressWithEmailMutation],
	)

	const updateCustomerEmail = useCallback(
		async ({ email }: { email: string }) => {
			// This is a pre-cursor to firing a placeOrder, but needs to propagate the placeOrder initiated state.
			setPlaceOrderSubmitted(true)
			const resp = await mutationAsFetchErrorResult(() =>
				updateCustomerInfoInBasketMutation({
					variables: {
						input: { customerInfo: { email } },
						first: 50,
					},
				}),
			)
			// removing the state in the event that this contact update is done de-coupled from PlaceOrder
			setPlaceOrderSubmitted(false)
			if (resp?.data?.updateCustomerInfoInBasket?.basket) {
				return wrapGraphSuccess(undefined, mapAndSetCart(resp.data.updateCustomerInfoInBasket.basket))
			}
			return wrapGraphError(resp, CartProviderErrorCode.UNHANDLED_ERROR, '????')
		},
		[mapAndSetCart, updateCustomerInfoInBasketMutation],
	)

	const updateShippingById = useCallback(
		async ({ addressId, shipmentId }: { addressId: string; shipmentId: string }) => {
			const resolvedShipmentId = await ensureShipmentId(shipmentId)
			const resp = await mutationAsFetchErrorResult(() =>
				updateShippingAddressByIdMutation({
					variables: { input: { customerAddressId: addressId, shipmentId: resolvedShipmentId }, first: 50 },
				}),
			)

			if (resp.data) {
				return refreshShippingMethod(resp.data.updateShippingAddressByIdInBasket.basket)
			}

			return wrapGraphError(resp, CartProviderErrorCode.UNHANDLED_ERROR, '')
		},
		[ensureShipmentId, refreshShippingMethod, updateShippingAddressByIdMutation],
	)

	const updateShoprunnerToken = useCallback(
		async (token: string): Promise<CartProviderResult> => {
			if (!cart?.basketId) {
				throw new Error('Missing basket id')
			}

			const resp = await mutationAsFetchErrorResult(() =>
				updateBasketMutation({
					variables: {
						input: {
							id: cart.basketId,
							basket: { cSRToken: token },
						},
						first: 50,
					},
				}),
			)

			if (resp.data) {
				return wrapGraphSuccess(undefined, mapAndSetCart(resp.data.updateBasket.basket))
			}

			return wrapGraphError(resp, CartProviderErrorCode.UNHANDLED_ERROR, '')
		},
		[cart?.basketId, mapAndSetCart, updateBasketMutation],
	)

	const verifyShippingAddress = useCallback(async (): Promise<CartProviderResult<Address>> => {
		const resp = await mutationAsFetchErrorResult(() => verifyShippingAddressQuery())
		// Only consider first?
		let address: Address | undefined = resp.data?.viewer?.basket?.shipments?.[0]?.shippingAddress || undefined

		if (cart && !!address) {
			if (address.validation?.alternatives?.length) {
				address = {
					...address,
					validation: {
						...address.validation,
						alternatives: sanitizeSuggestions(address.validation.alternatives),
					},
				}
			}

			return wrapGraphSuccess(address, cart)
		}

		return wrapGraphError(resp, CartProviderErrorCode.UNHANDLED_ERROR, '')
	}, [cart, verifyShippingAddressQuery])

	const uacapiAccessToken = useRef<string | undefined>()

	useEffect(() => {
		if (uacapiAccessToken.current !== user?.uacapiAccessToken) {
			uacapiAccessToken.current = user?.uacapiAccessToken
			refreshCart().then((cart) => {
				if (!cart) {
					setCart(newCart)
				}
			})
		}
	}, [user?.uacapiAccessToken, refreshCart, setCart, newCart])

	useEffect(() => {
		setCartOperationError(
			addCouponItemToBasketError ||
				addEGiftCartToBasketError ||
				addProductItemsToBasketError ||
				applyGiftCartError ||
				applyIdMeDiscountToBasketError ||
				beginPayPalCheckoutError ||
				getCartError ||
				moveBasketProductItemToWishListError ||
				moveWishListProductItemToBasketError ||
				placeOrderError ||
				redeemRewardError ||
				removeCouponItemFromBasketError ||
				removeIdMeDiscountFromBasketError ||
				removeItemsError ||
				removePaymentInstrumentError ||
				setSelectedStoreError ||
				paymentInstrumentApplePayError ||
				updateBasketError ||
				updateBasketItemError ||
				updateBillingAddressError ||
				updateCreditCardError ||
				updateEGiftCardError ||
				updateKlarnaError ||
				updateGiftCheckboxError ||
				updatePaymetricError ||
				updatePaymetricIframeError ||
				updatePayPalError ||
				updateShippingAddressError ||
				updateShippingAddressByIdError ||
				updateShippingAddressWithEmailError ||
				updateCustomerInfoInBasketError ||
				updateShippingMethodByIdError ||
				verifyShippingAddressError,
		)
	}, [
		addCouponItemToBasketError,
		addEGiftCartToBasketError,
		addProductItemsToBasketError,
		applyGiftCartError,
		applyIdMeDiscountToBasketError,
		beginPayPalCheckoutError,
		getCartError,
		moveBasketProductItemToWishListError,
		moveWishListProductItemToBasketError,
		placeOrderError,
		redeemRewardError,
		removeCouponItemFromBasketError,
		removeIdMeDiscountFromBasketError,
		removeItemsError,
		removePaymentInstrumentError,
		setSelectedStoreError,
		paymentInstrumentApplePayError,
		updateBasketError,
		updateBasketItemError,
		updateBillingAddressError,
		updateCreditCardError,
		updateEGiftCardError,
		updateKlarnaError,
		updateGiftCheckboxError,
		updatePaymetricError,
		updatePaymetricIframeError,
		updatePayPalError,
		updateShippingAddressError,
		updateShippingAddressByIdError,
		updateShippingAddressWithEmailError,
		updateCustomerInfoInBasketError,
		updateShippingMethodByIdError,
		verifyShippingAddressError,
	])

	useEffect(() => {
		setCartOperationLoading(
			addCouponItemToBasketLoading ||
				addEGiftCardToBasketLoading ||
				addProductItemsToBasketLoading ||
				applyIdMeDiscountToBasketLoading ||
				beginPayPalCheckoutLoading ||
				getCartLoading ||
				moveBasketProductItemToWishListLoading ||
				moveWishListProductItemToBasketLoading ||
				placeOrderLoading ||
				redeemRewardLoading ||
				removeCouponItemFromBasketLoading ||
				removeIdMeDiscountFromBasketLoading ||
				removeItemsLoading ||
				removePaymentInstrumentLoading ||
				setSelectedStoreLoading ||
				updateApplePayLoading ||
				updateBasketLoading ||
				updateBasketItemLoading ||
				updateCreditCardLoading ||
				updateEGiftCartLoading ||
				updateKlarnaLoading ||
				updateGiftCardPaymentLoading ||
				updateGiftCheckboxLoading ||
				updatePaymetricLoading ||
				updatePaymetricIframeLoading ||
				updatePayPalLoading ||
				updateShippingAddressLoading ||
				updateShippingAddressByIdLoading ||
				updateShippingAddressWithEmailLoading ||
				updateCustomerInfoInBasketLoading ||
				updateShippingMethodByIdLoading ||
				verifyShippingAddressLoading,
		)
	}, [
		addCouponItemToBasketLoading,
		addEGiftCardToBasketLoading,
		addProductItemsToBasketLoading,
		applyIdMeDiscountToBasketLoading,
		beginPayPalCheckoutLoading,
		getCartLoading,
		moveBasketProductItemToWishListLoading,
		moveWishListProductItemToBasketLoading,
		placeOrderLoading,
		redeemRewardLoading,
		removeCouponItemFromBasketLoading,
		removeIdMeDiscountFromBasketLoading,
		removeItemsLoading,
		removePaymentInstrumentLoading,
		setSelectedStoreLoading,
		updateApplePayLoading,
		updateBasketLoading,
		updateBasketItemLoading,
		updateBillingAddressLoading,
		updateCreditCardLoading,
		updateEGiftCartLoading,
		updateKlarnaLoading,
		updateGiftCardPaymentLoading,
		updateGiftCheckboxLoading,
		updatePaymetricLoading,
		updatePaymetricIframeLoading,
		updatePayPalLoading,
		updateShippingAddressLoading,
		updateShippingAddressByIdLoading,
		updateShippingAddressWithEmailLoading,
		updateCustomerInfoInBasketLoading,
		updateShippingMethodByIdLoading,
		verifyShippingAddressLoading,
	])

	const contextValue = useMemo(() => {
		return {
			addCouponLoading: addCouponItemToBasketLoading,
			addEGiftError: addEGiftCartToBasketError,
			addEGiftLoading: addEGiftCardToBasketLoading,
			addItemsError: addProductItemsToBasketError,
			addItemsLoading: addProductItemsToBasketLoading,
			cart: cart ?? undefined,
			cartOperationError,
			cartOperationLoading,
			updateItemsError: updateBasketItemError,
			updateItemsLoading: updateBasketItemLoading,
			updateKlarnaError,
			addCoupon,
			addEGift,
			addItems,
			applyGiftCard,
			applyIdMeDiscount,
			ensureShipmentId,
			getPaymentInstrumentKlarnaClientToken,
			getPaymentInstrumentPayPalToken,
			moveItemToWishlist,
			moveWishlistItemToCart,
			optIn,
			placeOrder,
			placeOrderSubmitted,
			redeemReward,
			refreshCart,
			removeCoupon,
			removeIdMeDiscount,
			removeItems,
			removePaymentInstrument,
			removePaymentInstruments,
			setPlaceOrderSubmitted,
			setSelectedStore,
			updateBillingAddress,
			updateEGift,
			updateItems,
			updatePaymentInstrument,
			updatePaymentInstrumentApplePay,
			updatePaymentInstrumentKlarna,
			updatePaymentInstrumentPaymetric,
			updatePaymentInstrumentPaymetricIframe,
			updatePaymentInstrumentPayPal,
			updateShipmentGiftDetails,
			updateShipping,
			updateCustomerEmail,
			updateShippingById,
			updateShippingMethodById,
			updateShoprunnerToken,
			verifyShippingAddress,
			updateSavedCardOnBasket,
		}
	}, [
		addCouponItemToBasketLoading,
		addEGiftCartToBasketError,
		addEGiftCardToBasketLoading,
		addProductItemsToBasketError,
		addProductItemsToBasketLoading,
		cart,
		cartOperationError,
		cartOperationLoading,
		updateBasketItemError,
		updateBasketItemLoading,
		updateKlarnaError,
		addCoupon,
		addEGift,
		addItems,
		applyGiftCard,
		applyIdMeDiscount,
		getPaymentInstrumentKlarnaClientToken,
		getPaymentInstrumentPayPalToken,
		moveItemToWishlist,
		moveWishlistItemToCart,
		optIn,
		placeOrder,
		placeOrderSubmitted,
		redeemReward,
		refreshCart,
		removeCoupon,
		removeIdMeDiscount,
		removeItems,
		removePaymentInstrument,
		removePaymentInstruments,
		setPlaceOrderSubmitted,
		setSelectedStore,
		updateBillingAddress,
		updateEGift,
		updateItems,
		updatePaymentInstrument,
		updatePaymentInstrumentApplePay,
		updatePaymentInstrumentKlarna,
		updatePaymentInstrumentPaymetric,
		updatePaymentInstrumentPaymetricIframe,
		updatePaymentInstrumentPayPal,
		updateShipmentGiftDetails,
		updateShipping,
		updateCustomerEmail,
		updateShippingById,
		updateShippingMethodById,
		updateShoprunnerToken,
		verifyShippingAddress,
		ensureShipmentId,
		updateSavedCardOnBasket,
	])

	return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
}

export default CartProvider
