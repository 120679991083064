import z from 'zod'
import { decodeUacapiToken } from '../api/uacapi'

export const UacapiTokenResponseSchema = z
	.object({
		accessToken: z.string(),
		// TODO: coerce to date
		expiresAt: z.string(),
	})
	.transform(({ accessToken, ...rest }) => ({
		accessToken,
		decodedToken: decodeUacapiToken(accessToken),
		...rest,
	}))
