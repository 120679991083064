/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */

import type { ApolloError } from '@apollo/client'
import type { Optional } from 'types/strict-null-helpers'
import {
	ensureArray,
	ensureNonNullishArray,
	ensureNumber,
	ensureString,
	forceNumber,
	isArrayWithItems,
} from 'types/strict-null-helpers'
import type { UserWithSession } from '~/lib/client-only/auth/types'
import type { BreadcrumbTrail } from '~/components/shared/Breadcrumbs'
import {
	type Basket,
	type ColorVariation,
	LoyaltyEventType,
	type OrderReceipt,
	type ProductItemEdge,
	type SizeVariation,
	ProductExperienceType,
} from '~/graphql/generated/uacapi/type-document-node'

import {
	buildProductPath,
	buildVariantLookupFromInitialLookupVariantsOrProduct,
	getBopisMessage,
	getExtendedSizes,
	getProductLineItemRevenue,
	getVariant,
	isOnSale,
} from '~/lib/products'
import type {
	ActionQueueItem,
	CartProductData,
	CheckoutPaypalData,
	DataLayerInterface,
	EmailChangedActionData,
	EmailSubscribedActionData,
	ErrorMessageShownActionData,
	FavoritesAddActionData,
	FavoritesRemoveActionData,
	FiredActionData,
	GlobalCampaignData,
	GlobalCheckoutPageData,
	GlobalConfirmationPageData,
	GlobalProductDetailPageData,
	GlobalSiteData,
	GlobalUserData,
	GridRefinementAttribute,
	LastTouchedCheckoutFieldActionData,
	LoginAttemptActionData,
	LoginSuccessActionData,
	LoyaltyActionData,
	ModalActionData,
	ProductDetailProduct,
	ProductGridItem,
	ProductSellingToolActionData,
	PromoCodeAttemptData,
	QuickAddToCartData,
	RegisterSuccessActionData,
	ThirdPartyPayStartData,
	VisitorType,
	ContentCarouselClickTarget,
} from '~/lib/types/analytics.interface'
import { AnalyticsAction, CheckoutStep } from '~/lib/types/analytics.interface'
import type { Cart, CartProduct } from '~/lib/types/cart.interface'
import type {
	ClientInitialLookupVariants,
	ClientProductData,
	ClientProductDetail,
	ClientProductVariantDetail,
	ClientReviewData,
} from '~/lib/types/product.interface'
import { decodeUacapiToken } from '~/lib/api/uacapi'
import { LoyaltyCouponsPrefixes } from '~/lib/enums/loyalty.enum'
import { getCurrencyByLocale } from '~/lib/i18n/currency'
import { getCountryCodeByLocale, getDefaultLocale, getLanguageByLocale, isValidLocale } from '~/lib/i18n/locale'
import { createClientLogger } from '~/lib/logger'
import type { NotifyMeFormAnalyticData } from '~/lib/types/notifyMe.interface'
import { NotifyMeButtonStates, NotifyMeEvents } from '~/lib/types/notifyMe.interface'
import { datadogRum } from '@datadog/browser-rum'
import type { LoyaltyEmailSubscriberSources } from '~/lib/types/loyalty.interface'
import { mapEnrollmentSource } from '~/lib/client-server/enrollment-helpers'
import type { SearchResponse } from '@constructor-io/constructorio-client-javascript/lib/types'
import { fireEvent, updateData, updateNextData } from './beacon-bridge'
import { getPublicConfig } from './client-server/config'
import type { NavigationTree } from './navigation'
import type { AnalyticsTracking } from '~/lib/client-server/cms/modules'

const logger = createClientLogger('analytics')
interface ProductDetailPageSourceData {
	locale: string
	product: ClientProductDetail
	selectedColor?: ColorVariation
	selectedVariant?: ClientProductVariantDetail | SizeVariation
}

interface DetailedProductSourceData extends ProductDetailPageSourceData {
	reviewData: ClientReviewData
	isRecommendedLook: boolean
	lookupVariants?: ClientInitialLookupVariants
}

export const emptyDataLayer: DataLayerInterface = {
	site_country_code: '',
	site_currency: '',
	site_language: '',
	site_shipto_country: '',
	site_type: 'sfra-headless',
	customer_id: '',
	logged_in_status: 'Guest',
	session_id: '',
	visitor_type: 'guest',
	site_section: '',
	products: [],
	full_url: '',
	navigation_structure: '',
	page_category: 'order-receipt',
	page_category_id: '',
	page_subcategory1: '',
	page_subcategory2: '',
	page_subcategory3: '',
	page_subcategory4: '',
	page_type: '',
	page_name: '',
	page_finding_method: '',
	cart_subtotal: 0,
	cart_shipping: '',
	cart_discount: 0,
	cart_total: 0,
	cart_tax: 0,
	cart_item_count: 0,
	search_term: '',
	search_type: '',
	search_method: '',
	search_location: '',
	search_results_count: 0,
	pdp_type: 'regular',
	pdp_360_video: false,
	pdp_merch_product_stack: '',
	pdp_price_type: 'full',
	pdp_combined_style: '',
	pdp_extended_sizing: false,
	pdp_outofstock: false,
	pdp_discount_exclusions: false,
	pdp_experience_type: '',
	pdp_gender: '',
	pdp_primary_category_id: '',
	checkout_step: '',
	gift_box_checked: false,
	customer_type: 'New',
	order_id: '',
	order_checkout_optin: 'no',
	order_discount: '0.00',
	order_flags: [],
	order_merchandize_tax: '0.00',
	order_payment_method: '',
	order_shipping_method: '',
	order_shipping_revenue: '0.00',
	order_shipping_subtotal: '0.00',
	order_shipping_tax: '0.00',
	order_subtotal: '0.00',
	order_tax: '0.00',
	order_total: '0.00',
	order_type: 'regular',
	promo_code: '',
	grid_refinement_attributes: [],
	grid_has_guidedselling: false,
	grid_has_loadmore: false,
	grid_paging_offset: 0,
	grid_single_ingrid: 0,
	grid_double_ingrid: 0,
	grid_sort_order: '',
	grid_stack_count: 0,
	grid_top_content: '',
	grid_total_count: 0,
	grid_video_count: 0,
	grid_visible_count: 0,
	plain_text_email: '',
	type: '',
	opt_in: false,
	internal_campaign_asset_name: '',
	internal_campaign_module: '',
	internal_campaign_link: '',
	internal_campaign_cta_text: '',
	internal_campaign_placement: '',
	internal_campaign_snipe: '',
	internal_campaign_headline: '',
	features: '',
	content_modules: [],
	content_asset_name: [],
}

export interface RouteHistory {
	nextJsPathName: string
	urlPathName: string
	firedPageView: boolean
}

export interface ContentData {
	type: string
	names: string[]
	moduleId: string
}
/**
 * The analytics object is a singleton that lives throughout the life of a page load.  It is
 * used to collect, parse and dispatch events related to analytics data.  With it, we can
 * initialize the data layer, fire events related to actions on the page and mutate the data
 * layer as the page changes.
 */
export class Analytics {
	// The current state of the global analytics data (data layer)
	'currentState': DataLayerInterface

	// The data that is stored in the local session for use in subsequent visits.
	nextPageData: Optional<Partial<DataLayerInterface>>

	// Keeps track of recently fired actions for debugging purposes.
	actionQueue: ActionQueueItem[]

	// Keeps track of content modules on any given page. We use a Map where the keys are the module's unique Id. We do this as a last line of defense to ensure that what we are sending to analytics does not include duplicates that were sent because of various state management/rerender situations.
	private trackableContent: Map<string, ContentData> = new Map()

	// Keep track of where the initiating event (e.g. pageView has occurred)
	private initiatingEventFired: boolean

	constructor() {
		this.currentState = emptyDataLayer
		this.nextPageData = null
		this.actionQueue = []
		this.initiatingEventFired = false
		this.trackableContent = new Map<string, ContentData>()
	}

	reset() {
		this.currentState = emptyDataLayer
		this.nextPageData = null
		this.actionQueue = []
		this.initiatingEventFired = false
	}

	exitPage(): void {
		this.initiatingEventFired = false
		this.drainActionQueue()
	}

	/// ///
	// Fireable Actions
	/// ///
	public fireCartQtyChange(data: CartProductData, qtyFrom: number) {
		this.fireAction(AnalyticsAction.cartQtyChange, { products: [data], qty_from: qtyFrom })
	}

	public fireCartAdd(data: CartProductData) {
		this.fireAction(AnalyticsAction.cartAdd, { products: [data] })
	}

	public fireCartRemove(data: CartProductData, isMiniCart = false) {
		this.fireAction(AnalyticsAction.cartRemove, { products: [data], cart_is_mini_cart: isMiniCart })
	}

	public fireVariantOrColorSelectionChanged(data: ProductDetailProduct) {
		this.fireAction(AnalyticsAction.genericLink, { products: [data] })
	}

	public fireToggleSmsOrderTracking(data: boolean) {
		this.fireAction(AnalyticsAction.genericLink, { opted_in_SMS: data })
	}

	// Sets the initial content data to be set with the pageView event
	public registerTrackableContent(data: ContentData) {
		// Check if an entry with the same moduleId already exists and return if so, because we do not want duplicates.
		if (this.trackableContent.has(data.moduleId)) {
			return
		}
		this.trackableContent.set(data.moduleId, data)
	}

	updateTrackableContentData() {
		const contentModules: string[] = []
		const contentAssetNames: string[] = []

		// Iterate over the Map and extract data
		this.trackableContent.forEach((c) => {
			contentModules.push(c.type)
			contentAssetNames.push(c.names.join(','))
		})

		this.updatePageData({
			content_modules: contentModules,
			content_asset_name: contentAssetNames,
		})
	}

	clearTrackableContent() {
		this.trackableContent.clear()
	}

	public firePageView() {
		this.updateTrackableContentData()
		// NOTE: It is important that we pass an empty
		//	object for this event because the data layer
		//	implementation in ua-tealium does the work of
		//	populating based on what's currently stored in
		//	both main data object and nextPageData (if anything)
		this._fireActionNow(AnalyticsAction.pageView, {})

		// NOTE: This is necessary because of a issue with tealium
		//	or the way we've implemented it where events that fire
		//	in quick succession do not necessarily get processed in
		//	the order in which they fired.  That is, the order is
		//	not guaranteed.  For events other than pageView, this
		//	is not a problem because we are not relying on the data
		//	being available immediately.  However, for pageView, we
		//	need to ensure that downstream third parties process that
		//	event first. So, we set a timeout to ensure that the
		//	pageView event is processed before any other events.  This
		//	is not a good solution but it is the best we can do for now.
		//	We are going to remove this once we have implemented Beacon
		//	Bridge and it can do the work of guarantee the order of events.
		setTimeout(() => {
			// This indicates to the manager that the page view has been fired
			//	and any other events can be fired.
			this.initiatingEventFired = true

			// Then we fire any events that were queued up before the page view
			//	was fired.
			this.drainActionQueue()

			this.clearTrackableContent()
		}, 2000)
	}

	private drainActionQueue() {
		let a = this.actionQueue.shift()
		while (a) {
			this._fireActionNow(a.action, a.data)
			a = this.actionQueue.shift()
		}
	}

	public fireNotifyMeEvent({
		product,
		formData = null,
		state = NotifyMeButtonStates.ENABLED,
		event = NotifyMeEvents.NULL,
	}: {
		product: ProductDetailProduct
		formData?: NotifyMeFormAnalyticData | null
		state?: NotifyMeButtonStates
		event?: NotifyMeEvents
	}) {
		this.fireAction(AnalyticsAction.genericLink, {
			products: [product],
			notifyMe: {
				data: formData,
				event,
				button_state: state,
			},
		})
	}

	public trackFeaturedProductButtonClick() {
		this.fireAction(AnalyticsAction.genericLink, {
			featured_products: {
				state: 'clicked',
			},
		})
	}

	public trackFeaturedProductsQATBClick() {
		this.fireAction(AnalyticsAction.genericLink, {
			featured_products: {
				state: 'qatb_clicked',
			},
		})
	}

	public trackFeaturedProductsPDPClick() {
		this.fireAction(AnalyticsAction.genericLink, {
			featured_products: {
				state: 'pdpRedirect',
			},
		})
	}

	public trackHotspotClick(assetName: string) {
		this.fireAction(AnalyticsAction.genericLink, {
			hotspots: {
				state: 'clicked',
				asset: assetName,
			},
		})
	}

	public trackContentCarouselNavEvent({
		module,
		direction,
		moduleName,
		moduleId,
	}: {
		module: string
		direction: 'next' | 'previous'
		moduleName?: string
		moduleId?: string
	}) {
		this.fireAction(AnalyticsAction.genericLink, {
			content_carousel: {
				content_modules_navigation: [direction],
				content_modules: [`${moduleName}|${moduleId}`],
				content_module_type: module,
			},
		})
	}

	// Handles Click on the Content Carousel Component itself. Helps to define if the image, asset, or icon was clicked
	public trackContentCarouselClick({
		module,
		clickTarget,
		imageSrc,
		moduleName,
		moduleId,
	}: {
		module: 'Content Carousel'
		clickTarget: ContentCarouselClickTarget
		imageSrc: string | undefined
		moduleName?: string
		moduleId?: string
	}) {
		this.fireAction(AnalyticsAction.genericLink, {
			content_carousel: {
				state: 'clicked',
				content_modules: [`${moduleName}|${moduleId}`],
				content_modules_clicked: [clickTarget],
				content_module_asset: imageSrc,
				content_module_type: module,
			},
		})
	}

	public fireCarouselScrollInView(data) {
		this.fireAction(AnalyticsAction.genericLink, {
			content_carousel: {
				state: 'viewed',
				content_modules: [`${data.moduleName}|${data.moduleId}`],
				content_module_type: data.type,
			},
		})
	}

	public fireModalOpened(data: ModalActionData) {
		this.fireAction(AnalyticsAction.modalOpened, data)
	}

	public fireEmailSubscribed(data: EmailSubscribedActionData) {
		this.fireAction(AnalyticsAction.emailSubscribed, data)
	}

	public fireClickedAddToCartFromSavedItems() {
		this.fireAction(AnalyticsAction.genericLink, {
			navigation_structure: 'SavedCart|add_to_cart',
		})
	}

	public fireChangedEmail(data: EmailChangedActionData) {
		this.fireAction(AnalyticsAction.genericLink, data)
	}

	public fireClickedHamburgerMenu() {
		this.fireAction(AnalyticsAction.genericLink, {
			navigation_structure: 'TopNav|mobile|hamburger|n/a',
		})
	}

	public fireClickedHelpCenterLink(source: string) {
		this.fireAction(AnalyticsAction.genericLink, {
			products: [
				{
					product_name: '',
					product_id: '',
					product_style: '',
					product_sku: '',
				},
			],
			navigation_structure: `HelpCenterLink|${source}`,
		})
	}

	public fireLoginAttempt(data: LoginAttemptActionData) {
		this.fireAction(AnalyticsAction.loginAttempt, data)
	}

	public fireErrorMessageShown(data: ErrorMessageShownActionData) {
		this.fireAction(AnalyticsAction.errorMessageShown, data)
	}

	public fireApolloErrorShown(error: ApolloError) {
		this.fireAction(AnalyticsAction.errorMessageShown, {
			error_name: error.name,
			error_message: error.message,
		})
	}

	public fireLoginSuccess(data: LoginSuccessActionData) {
		Analytics.storeInSession({ registered_on: null })
		this.fireAction(AnalyticsAction.loginSuccess, data)
	}

	public fireRegisterSuccess(data: RegisterSuccessActionData) {
		// Set the registration date to the current time so that we can
		//	more easily determine if the user is a new user or not (note, this
		//	should be coming from the server but we don't have that yet)
		Analytics.storeInSession({ registered_on: new Date().toISOString() })
		this.fireAction(AnalyticsAction.registerSuccess, data)
	}

	public fireUGCScrollInView() {
		this.fireAction(AnalyticsAction.genericLink, {
			ugc: {
				state: 'viewed',
			},
		})
	}

	public fireUGCInteraction() {
		this.fireAction(AnalyticsAction.genericLink, {
			ugc: {
				state: 'clicked',
			},
		})
	}

	public fireFavoritesAdd(data: FavoritesAddActionData) {
		this.fireAction(AnalyticsAction.favoritesAdd, data)
	}

	public fireFavoritesRemove(data: FavoritesRemoveActionData) {
		this.fireAction(AnalyticsAction.favoritesRemove, data)
	}

	public fireLoyaltyAction(data: LoyaltyActionData) {
		const newSource = mapEnrollmentSource(data.loyalty.source)
		if (!newSource && data.loyalty.source) {
			// How to delete source from `data` and still make eslint happy (don't change function inputs)
			// While also making the lovely engineers on the team are "happy"
			// The result of PR reviews and a long drawn-out Slack discussion, enjoy!
			const {
				loyalty: { source: _, ...loyalty },
			} = data
			this.fireAction(AnalyticsAction.loyaltyAction, {
				...data,
				loyalty,
			})
		} else if (newSource) {
			// Change the data for source from the enum numerical value
			// to the constant string value mapped from mapEnrollmentSource
			const newData: Omit<LoyaltyActionData, 'loyalty'> & {
				loyalty: Omit<LoyaltyActionData['loyalty'], 'source'> & { source: LoyaltyEmailSubscriberSources }
			} = {
				...data,
				loyalty: {
					...data.loyalty,
					source: newSource,
				},
			}
			this.fireAction(AnalyticsAction.loyaltyAction, newData)
		} else {
			// By default just fire the event with the data as is
			this.fireAction(AnalyticsAction.loyaltyAction, data)
		}
	}

	public fireProductSellingTool(data: ProductSellingToolActionData) {
		this.fireAction(AnalyticsAction.productSellingTool, data)
	}

	public firePromoCodeAttempt(data: PromoCodeAttemptData) {
		this.fireAction(AnalyticsAction.promoCodeAttempt, data)
	}

	public fireCheckoutStepChange(data: GlobalCheckoutPageData) {
		this.fireAction(AnalyticsAction.checkoutStepChange, data)
	}

	public fireLastTouchedCheckoutField(data: LastTouchedCheckoutFieldActionData) {
		this.fireAction(AnalyticsAction.lastTouchedCheckoutField, data)
	}

	public fireThirdPartyPayStart(data: ThirdPartyPayStartData) {
		this.fireAction(AnalyticsAction.thirdPartyPayStart, data)
	}

	public fireCheckoutPaypal(data: CheckoutPaypalData) {
		this.fireAction(AnalyticsAction.checkoutPaypal, data)
	}

	public trackQATBButtonClick(data: ProductDetailProduct, source?: QuickAddToCartData['quick_atb']['source']) {
		this.fireAction(AnalyticsAction.genericLink, {
			products: [data],
			quick_atb: {
				state: 'clicked',
				...(source ? { source } : {}),
			},
		})
	}

	public fireAbTestEvent(name: string, payload?: object) {
		this.fireAction(AnalyticsAction.abTestEvent, { abTestEvent: { name, payload } })
	}

	public fireCioSearchEvent(response: SearchResponse) {
		// features & feature_variants are properties of the results object we get back from Constructor.io
		// that tell us which behaviors were enabled for the current request.
		this.fireAbTestEvent('cio-search-results', {
			aiAttributesEnabled: response?.request?.features?.use_enriched_attributes_as_fuzzy_searchable,
		})
	}

	getDecodedProductId = (productId) => {
		const decodedString = typeof productId === 'string' ? Buffer.from(productId, 'base64').toString().split(':') : ''
		return decodedString.length > 1 ? decodedString[1] : decodedString[0]
	}

	public trackQATBView({
		product,
		color,
		size,
		selectedVariant,
	}: {
		product: ClientProductDetail
		color: string
		size: string
		selectedVariant?: ClientProductVariantDetail
	}) {
		this.fireAction(AnalyticsAction.genericLink, {
			products: [
				{
					product_color: color,
					product_gender: product.gender,
					product_id: this.getDecodedProductId(selectedVariant?.id || product.id),
					product_msrp: forceNumber(product?.price?.list?.max).toFixed(2),
					product_price: forceNumber(product?.price?.sale?.max).toFixed(2),
					product_name: product.name || '',
					product_style: product.style || '',
					product_sku: product.sku || `${product.style}-${color}-${size}`,
				},
			],
			quick_atb: {
				state: 'viewed',
			},
		})
	}

	public trackQATBClose({
		product,
		selectedVariant,
	}: {
		product: ClientProductDetail
		selectedVariant?: ClientProductVariantDetail
	}) {
		this.fireAction(AnalyticsAction.genericLink, {
			products: [
				{
					product_name: product.name || '',
					product_id: this.getDecodedProductId(selectedVariant?.id || product.id),
					product_style: product.style || '',
					product_sku: product.sku || '',
				},
			],
			quick_atb: {
				state: 'closed',
			},
		})
	}

	public trackQATBRedirect({
		product,
		selectedVariant,
	}: {
		product: ClientProductDetail
		selectedVariant?: ClientProductVariantDetail
	}) {
		this.fireAction(AnalyticsAction.genericLink, {
			products: [
				{
					product_name: product.name || '',
					product_id: this.getDecodedProductId(selectedVariant?.id || product.id),
					product_style: product.style || '',
					product_sku: product.sku || '',
				},
			],
			quick_atb: {
				state: 'pdpRedirect',
			},
		})
	}

	public trackNavigation(navCategory: NavigationTree, locale: string, data?: Partial<DataLayerInterface>) {
		this.applyNextPageData({
			...data,
			navigation_structure: `${navCategory.path?.join('|')}|/${locale}${navCategory.url}`,
			page_finding_method: 'Navigation',
		})
	}

	public trackSearchRecommendation({ productName }) {
		this.applyNextPageData({
			page_finding_method: 'Search',
			search_location: 'header',
			search_method: 'product',
			search_results_count: 1,
			search_term: productName, // the alt property is set to product.name in ProductTile
			search_type: 'Redirect',
		})
	}

	/**
	 * Use this as a way to set the page data directly in cases where no transformation
	 * is needed.  Avoid using this for things like products, cart, etc as there are special
	 * transformations that need to be done for analytics.
	 * @param data Any combination of page data
	 */
	setPageData(data: Partial<DataLayerInterface>) {
		this.updatePageData(data)
	}

	getRefinementsData(breadcrumbs: BreadcrumbTrail): GridRefinementAttribute[] {
		return breadcrumbs
			.filter((b) => !b.hideFromBreadCrumbs)
			.map((b) => ({
				grid_refinement_attributes: ensureString(b.name),
			}))
	}

	getUserData(user: UserWithSession, newRegistration: boolean): GlobalUserData {
		// TODO: This is a temporary fix for the fact that we don't have a way to determine if a user is a new user or not.
		//	We should instead be pulling this from the user session data but that information is not currently available through
		//	UACAPI.  We should update this once we have a way to determine this.
		const registrationDate = Analytics.getFromSession()?.registered_on
		const recentRegistration =
			typeof newRegistration === 'undefined'
				? registrationDate && new Date().getTime() - new Date(registrationDate).getTime() < 1000 * 60 * 60 * 24
				: newRegistration

		let visitorType: VisitorType = 'guest'
		if (user.isEmployee) {
			visitorType = 'employee'
		} else if (user.isVip) {
			visitorType = 'vip'
		} else if (user.isRegistered) {
			visitorType = recentRegistration ? 'logged in new' : 'logged in returning'
		}

		const sessionId =
			decodeUacapiToken(user.session.uacapi.accessToken)?.payload?.jti ?? user.session.uacapi.accessToken

		return {
			session_id: sessionId,
			customer_id: user.customerNumber,
			logged_in_status: user.isRegistered ? 'Logged In' : 'Guest',
			visitor_type: visitorType,
			plain_text_email: user.email,
			type: 'ua',
		}
	}

	getSiteData(locale: string): GlobalSiteData {
		const validLocale = isValidLocale(locale) ? locale : getDefaultLocale()

		return {
			site_country_code: getCountryCodeByLocale(validLocale),
			site_currency: getCurrencyByLocale(validLocale),
			site_language: getLanguageByLocale(validLocale),
			site_type: 'sfra-headless',
		}
	}

	getExternalCampaignData(): Pick<GlobalCampaignData, 'external_campaign_id'> {
		// cp.utag_main_externalCampaignID is not available on initial impact.
		// Therefore, we prefer url param, and fallback to utag.data for subsequent pages
		const urlParams = new URLSearchParams(window.location.search)
		const cid = urlParams.get('cid') || window.utag?.data['cp.utag_main_externalCampaignID']
		return {
			external_campaign_id: cid || '',
		}
	}

	getCartData(cart: Optional<Cart>) {
		return {
			cart_subtotal: cart?.subTotal || 0,
			cart_shipping: '',
			cart_discount: 0,
			cart_total: cart?.totals || 0,
			cart_tax: 0,
			cart_item_count: cart?.products?.length || 0,
		}
	}

	getCartProductDataFromCart(cart: Cart): CartProductData[] {
		return cart.products.map((p) => ({
			product_id: p.id,
			product_name: p.name,
			product_style: p.style,
			product_color: `${p.style}-${p.colorCode}`,
			product_sku: `${p.style}-${p.colorCode}-${p.size}`,
			product_quantity: p.quantity,
			product_price: p.totalPrice.totalAfterItemDiscount,
			product_msrp: p.prices?.list?.max || 0,
			product_onsale: isOnSale(p.prices),
			product_line_item_revenue: getProductLineItemRevenue(p),
			product_line_item_coupon_discount: '0.0',
			product_line_item_customergroup_discount: '0.0',
			product_line_item_sourcecode_discount: '0.0',
			product_bopis: p.availableForInStorePickup,
			product_bopis_available: p.availableForInStorePickup,
			product_bopis_message: getBopisMessage(p),
			product_bopis_selected: !!p.cFromStoreId,
			product_bopis_stock: p.availableForInStorePickup,
		}))
	}

	getCartObjectFromCartProduct(product: CartProduct): CartProductData {
		return {
			product_id: product.id,
			product_name: product.name,
			product_style: product.style,
			product_color: `${product.style}-${product.colorCode}`,
			product_sku: product.sku,
			product_quantity: product.quantity,
			product_price: product?.prices?.sale?.max || product?.prices?.list?.max || 0,
			product_onsale: isOnSale(product.prices) ? 'yes' : 'no',
			product_line_item_revenue: getProductLineItemRevenue(product),
			product_msrp: product?.prices?.list?.max || 0,
			product_bopis: product.availableForInStorePickup,
			product_bopis_available: product.availableForInStorePickup,
			product_bopis_message: getBopisMessage(product),
			product_bopis_selected: !!product.cFromStoreId,
			product_bopis_stock: product.availableForInStorePickup,
			product_uuid: product.id,
			product_silhouette: product.silhouette,
			product_gender: product.productGender,
			product_preorder: product.preorderable,
			product_outlet: product.outlet,
			product_oos: product.oos,
			complete_look_recommended: product.shopTheLook,
		}
	}

	getCartProductDataFromProductDetail(
		product: ClientProductDetail | ClientProductData | CartProduct,
		color: ColorVariation,
		variant: ClientProductVariantDetail,
		quantity: number,
	): CartProductData {
		const salePrice = variant.prices?.sale || variant.prices?.list || 0
		const msrpPrice = variant.prices?.list || 0
		const availableForInStorePickup =
			'availableForInStorePickup' in product ? !!product.availableForInStorePickup : false

		return {
			product_id: product.id,
			product_name: product.name || '',
			product_style: product.style || '',
			product_color: color.color,
			product_sku: `${product.style}-${color.color}-${variant.size}`,
			product_quantity: quantity,
			product_price: variant.prices?.sale || variant.prices?.list || 0.0,
			product_msrp: variant.prices?.list || 0,
			product_onsale: msrpPrice !== salePrice,
			product_bopis: availableForInStorePickup,
			product_bopis_available: availableForInStorePickup,
			product_bopis_message: getBopisMessage(product as CartProduct),
			product_bopis_selected: 'cFromStoreId' in product ? !!product.cFromStoreId : false,
			product_bopis_stock: availableForInStorePickup,
		}
	}

	getOrderPromoCodes(order: OrderReceipt): GlobalConfirmationPageData['order_promo_codes'] {
		const { couponItems, productItems, shippingItems, orderPriceAdjustments } = order
		const mapPromotions = (
			code = '',
			triggerId = '',
			promoTriggerType = 'coupon',
			discount = 0,
			promoName = '',
			promoSegment = '',
			promoClass = '',
			shippingDiscount = 0,
		) => ({
			order_promo_codes: code,
			order_promo_trigger_ids: triggerId,
			order_promo_trigger_types: promoTriggerType,
			order_promo_discount: Math.abs(discount).toFixed(2),
			order_promo_names: promoName,
			order_promo_segments: promoSegment,
			order_promo_classes: promoClass,
			order_shipping_discount: Math.abs(shippingDiscount).toFixed(2),
		})
		// Order Promotions
		const orderPromotions: GlobalConfirmationPageData['order_promo_codes'] = orderPriceAdjustments
			// Exclude any objects in the array where the 'couponCode' property does not exists.
			.filter((adjustment) => !!adjustment.couponCode)
			// Map the remaining objects. Create new objects for each one and include any matching coupons.
			.map((adjustment) => ({
				adjustment,
				coupon: couponItems.find((c) => c.code === adjustment.couponCode),
			}))
			.map(({ adjustment, coupon }) => ({
				// Build the promotions data object
				...mapPromotions(
					coupon?.code ?? 'no couponLineItem', // order_promo_codes
					coupon?.promoName ?? adjustment.itemText, // order_promo_trigger_ids
					// eslint-disable-next-line camelcase
					'coupon', // order_promo_trigger_types
					adjustment.price, // order_promo_discount
					adjustment.itemText, // order_promo_names
					adjustment.itemText, // order_promo_segments
					'order', // order_promo_classes
					0, // order_shipping_discount
				),
			}))
		// Product Promotions
		const productPromotions: GlobalConfirmationPageData['order_promo_codes'] = productItems.edges
			// Map over ordered products and get the array of priceAdjustments from each. This indicates a product level promo.
			.map((product) =>
				product.node.priceAdjustments
					// Exclude any objects in the array where the 'couponCode' property does not exists.
					.filter((priceAdjustment) => !!priceAdjustment.couponCode)
					.map((priceAdjustment) => ({
						// Build the promotions data object
						...mapPromotions(
							priceAdjustment.couponCode ?? 'no couponLineItem', // order_promo_codes
							priceAdjustment.itemText, // order_promo_trigger_ids
							'coupon', // order_promo_trigger_types
							priceAdjustment.price, // order_promo_discount
							priceAdjustment.itemText, // order_promo_names
							priceAdjustment.itemText, // order_promo_segments
							'product', // order_promo_classes
							0, // order_shipping_discount
						),
					})),
			)
			.flat()
		// Shipping Promotions
		const shippingPromotions: GlobalConfirmationPageData['order_promo_codes'] = shippingItems
			// Map over the orders shippingItems and get the array of priceAdjustments from each. This indicates a shipping level promo.
			.map((shippingItem) =>
				shippingItem.priceAdjustments
					// Exclude any objects in the array where the 'couponCode' property does not exists.
					.filter((priceAdjustment) => !!priceAdjustment.couponCode)
					.map((priceAdjustment) => ({
						// Build the promotions data object
						...mapPromotions(
							priceAdjustment.couponCode ?? 'no couponLineItem', // order_promo_codes
							priceAdjustment.itemText, // order_promo_trigger_ids
							'coupon', // order_promo_trigger_types
							0, // order_promo_discount
							priceAdjustment.itemText, // order_promo_names
							priceAdjustment.itemText, // order_promo_segments
							'shipping', // order_promo_classes
							priceAdjustment.price, // order_shipping_discount
						),
					})),
			)
			.flat()
		// Customer Group Promotions
		const customerGroupPromos: GlobalConfirmationPageData['order_promo_codes'] = orderPriceAdjustments
			// Exclude any objects in the array where the 'couponCode' property exists and is truthy. This is how we determine customer-group promos.
			.filter((adjustment) => !adjustment.couponCode)
			// Map the remaining objects. Create new objects for each one and include any matching coupons.
			.map((adjustment) => ({
				// Build the promotions data object
				...mapPromotions(
					adjustment.itemText, // order_promo_codes
					adjustment.itemText, // order_promo_trigger_ids
					'customer-group', // order_promo_trigger_types
					adjustment.price, // order_promo_discount
					adjustment.itemText, // order_promo_names
					adjustment.itemText, // order_promo_segments
					'order', // order_promo_classes
					0, // order_shipping_discount
				),
			}))
		// Create a new array containing all elements from each of the arrays above.
		return [...orderPromotions, ...productPromotions, ...shippingPromotions, ...customerGroupPromos]
	}

	getLoyaltyPromo(order: OrderReceipt) {
		if (!order.totals?.estimatedLoyaltyPoints) return null

		const { orderPriceAdjustments, productItems } = order

		const couponTypePromotions = orderPriceAdjustments
			.filter(({ couponCode }) => couponCode?.includes(LoyaltyCouponsPrefixes.Lyld))
			.map(() => 'dollar certificate online')

		const productTypePromotions = productItems.edges
			.filter((productItemEdge: ProductItemEdge) => {
				const productNode = productItemEdge.node
				const loyaltyCoupon = productNode.priceAdjustments.find((coupon) =>
					coupon.couponCode?.includes(LoyaltyCouponsPrefixes.Lyld),
				)

				return !!loyaltyCoupon && productNode.prices.totalBeforeAdjustments === Math.abs(loyaltyCoupon.price)
			})
			.map(() => 'apparel')

		return {
			loyalty: {
				loyalty: true,
				action: LoyaltyEventType.PURCHASE,
				loyalty_points_earned: order.totals.estimatedLoyaltyPoints,
				loyalty_type_of_reward_action: [...couponTypePromotions, ...productTypePromotions] || [],
			},
		}
	}

	getCartPromoCodes(basket: Basket, couponItem: string) {
		// Get coupons applied to the cart
		const { couponItems, productItems, shippingItems, orderPriceAdjustments } = basket
		const triggerId = couponItems.find((c) => c.code === couponItem) || undefined

		// Order Promotions
		const orderPromotion = orderPriceAdjustments.find((adjustment) => adjustment.couponCode === couponItem)
		if (orderPromotion) {
			return {
				promoName: triggerId?.promoName || orderPromotion.itemText,
				promoSegment: orderPromotion.promotionId,
				promoClass: 'order',
				triggerId: triggerId?.promoName || orderPromotion.itemText,
			}
		}
		// Product Promotions
		const productPromotions = productItems.edges.flatMap((product) =>
			product.node.priceAdjustments.filter((priceAdjustment) => priceAdjustment.couponCode === couponItem),
		)
		if (productPromotions.length > 0) {
			return {
				promoName: triggerId?.promoName || productPromotions[0].itemText,
				promoSegment: productPromotions[0].promotionId,
				promoClass: 'product',
				triggerId: triggerId?.promoName || productPromotions[0].itemText,
			}
		}
		// Shipping Promotions
		const shippingPromotions = shippingItems.flatMap((shippingItem) =>
			shippingItem.priceAdjustments.filter((priceAdjustment) => priceAdjustment.couponCode === couponItem),
		)
		if (shippingPromotions.length > 0) {
			return {
				promoName: triggerId?.promoName || shippingPromotions[0].itemText,
				promoSegment: shippingPromotions[0].promotionId,
				promoClass: 'shipping',
				triggerId: triggerId?.promoName || shippingPromotions[0].itemText,
			}
		}
		return {
			promoName: triggerId?.promoName || 'customer-group',
			promoSegment: '',
			promoClass: 'order',
			triggerId: triggerId?.promoName || 'customer-group',
		}
	}

	getProductGridData(products: ClientProductData[]): ProductGridItem[] {
		return products.map((p, idx) => ({
			product_id: p.id,
			product_style: p.style,
			product_grid_position: `0:${idx}`,
		}))
	}

	getProductLineItemRevenueList(productItems: ProductItemEdge[]): string[] {
		return productItems.flatMap(
			(p) =>
				// This calculates individual product revenue given quantity, but had to be backed out due to AA issue.
				// Array(p.node.quantity).fill(
				// 	(ensureNumber(p.node.prices.totalAfterOrderDiscount) / p.node.quantity).toFixed(2) ?? '0.00',
				// )

				ensureNumber(p.node.prices.totalAfterOrderDiscount).toFixed(2) ?? '0.00',
		)
	}

	getProductLineItemPriceList(productItems: ProductItemEdge[]): string[] {
		return productItems.flatMap(
			(p) =>
				// This calculates individual product prices given quantity, but had to be backed out due to AA issue.
				// Array(p.node.quantity).fill(
				// 	(ensureNumber(p.node.prices?.totalBeforeAdjustments) / p.node.quantity).toFixed(2) ?? '0.00',
				// )

				ensureNumber(p.node.prices?.totalBeforeAdjustments).toFixed(2) ?? '0.00',
		)
	}

	getProductLineItemExchangeRate(productItems: ProductItemEdge[], exchangeRate: number): string[] {
		return productItems.flatMap(({ node: { prices } }) => {
			const price = ensureNumber(prices.totalAfterOrderDiscount)
			const total = price * exchangeRate
			// This calculates individual product total given quantity, but had to be backed out due to AA issue.
			// return Array(quantity).fill((total / quantity).toFixed(2))

			return total.toFixed(2)
		})
	}

	static getPdpExperienceType(experienceType: Optional<ProductExperienceType>) {
		switch (experienceType) {
			case ProductExperienceType.OUTLET:
			case ProductExperienceType.OUTLET_MERCH_OVERRIDE:
				return 'outlet'
			case ProductExperienceType.PREMIUM:
			case ProductExperienceType.PREMIUM_MERCH_OVERRIDE:
				return 'premium'
			default:
				return 'mixed'
		}
	}

	getPdpData(data: ProductDetailPageSourceData): GlobalProductDetailPageData {
		const { product, selectedVariant, selectedColor } = data

		// We consider the extended sizes to be any sizes that include variants other than 'R' (regular)
		const extendedSizes = product.variants ? getExtendedSizes(product.variants).filter((s) => s !== 'R') : []

		return {
			pdp_360_video: !!(selectedColor?.video360 || null),
			pdp_type: 'regular', // NOTE: I don't know how to determine which type this is
			pdp_merch_product_stack: '', // NOTE: This is doing something with next page data that I don't understand yet.
			pdp_price_type: isOnSale(product.price) ? 'on-sale' : 'full',
			pdp_combined_style: product?.combinedStyle || '',
			pdp_extended_sizing: extendedSizes.length > 0,
			pdp_outofstock: !selectedVariant?.orderable,
			pdp_discount_exclusions:
				product?.productPromotions?.some((p) => forceNumber(p.calloutMsg?.indexOf('exclude'), -1) > -1) || false,
			pdp_experience_type: Analytics.getPdpExperienceType(product.experienceType),
			pdp_gender: product?.gender || '',
			pdp_primary_category_id: product?.primaryCategory?.id || '',
		}
	}

	getDetailedProductData(detailedProductData: DetailedProductSourceData): ProductDetailProduct {
		const { locale, product, selectedColor, selectedVariant, isRecommendedLook, reviewData, lookupVariants } =
			detailedProductData

		let selectedVariantPrice: number | undefined
		if (detailedProductData.selectedVariant && 'prices' in detailedProductData.selectedVariant) {
			selectedVariantPrice = detailedProductData?.selectedVariant?.prices?.sale
		}

		const productData: ProductDetailProduct = {
			product_id: product.id,
			product_name: product?.name ?? '',
			product_style: product?.style ?? '',
			product_color: selectedColor ? `${product.style}-${selectedColor?.color}` : '',
			product_sku: selectedVariant ? `${product.style}-${selectedColor?.color}-${selectedVariant.size}` : '',

			product_silhouette: product?.silhouette ?? '',
			product_bopis_stock: !!product.availableForInStorePickup,
			product_alert_text: ensureNonNullishArray(
				ensureNonNullishArray(product.productPromotions).map((p) => p?.calloutMsg?.replace(/<[^>]+>/g, '')),
			),
			complete_look_recommended: isRecommendedLook,
			product_options_color_full: forceNumber(product?.colors?.length),
			product_options_size_full: forceNumber(product?.sizes?.length),
			product_rating: reviewData.rating,
			product_review_count: reviewData.reviewCount,
			product_bopis_selected: false,
			product_size_prepopulated: false,

			// TODO: Retrieved but needs to be checked.
			product_gender: product?.gender || '',
			product_preorder: !!product?.preorderable,
			product_price: forceNumber(selectedVariantPrice || product?.price?.sale?.max),
			product_msrp: forceNumber(product?.price?.list?.max),
			product_onsale: isArrayWithItems(product.productPromotions),
			product_bopis: product?.availableForInStorePickup || false,
			product_bopis_available: product?.availableForInStorePickup || false,
			product_bopis_message: product?.availableForInStorePickup ? 'available for pickup' : 'unavailable for pickup',
			product_image_count: forceNumber(product?.colors?.reduce((acc, c) => acc + (c.assets?.images?.length || 0), 0)),
			product_badge_text: product?.badges?.upperLeft || '',
			product_tech_icon: product?.icons?.length ? 'yes' : 'no',
			product_options_color_total: ensureArray(product.colors).length,
			product_options_size_total: ensureArray(product.variants).length,
			complete_look: forceNumber(product?.shopTheLookColors?.length) > 0,
			product_image_url: product.colors?.find((c) => c.color === selectedColor?.color)?.assets?.images?.[0]?.url || '',
			product_url: buildProductPath(locale, product, selectedColor, selectedVariant, true),
		}

		const variant = getVariant(
			buildVariantLookupFromInitialLookupVariantsOrProduct({ lookupVariants, product }),
			selectedColor?.color,
			selectedVariant?.size,
		)

		if (typeof variant?.inventory?.stockLevel === 'number') {
			productData.product_inventory_stock_level = variant.inventory.stockLevel
		}

		return productData
	}

	/**
	 * This will update the current state with just the site data.  It will generate a new
	 * object that includes whatever is in the current state and override with the given data.
	 * @param siteData The site-specific data
	 */
	updatePageData(pageData: Partial<DataLayerInterface>) {
		this.currentState = { ...this.currentState, ...pageData }
		if (this.currentState) {
			Analytics.updateDataLayer(this.currentState)
		}
	}

	applyNextPageData(nextPageData: Partial<DataLayerInterface>) {
		this.nextPageData = { ...this.nextPageData, ...nextPageData }
		updateNextData(nextPageData)
		window.uaDatalayer?.applyNextPageData(nextPageData)
	}

	/**
	 * This updates the data layer with the information given.  Note this updates
	 * only the window.uaDatalayer and window.uaReportingData
	 * @param data The data to update the data layer with
	 */
	private static updateDataLayer(data: DataLayerInterface) {
		updateData(data)
		window.uaDatalayer?.applyPageData(data)
		window.uaReportingData = data
	}

	/**
	 * Store datalayer data in session local storage for cases where we have to
	 * refer to data throughout the session and not just the active page.  The next
	 * page data that is part of dltg only maintains data for the next page not the
	 * session.
	 * @param data
	 */
	private static storeInSession(data: Partial<DataLayerInterface>) {
		const storedData = window.sessionStorage.getItem('uaDatalayer')
		if (storedData) {
			const parsedData = JSON.parse(storedData)
			window.sessionStorage.setItem('uaDatalayer', JSON.stringify({ ...parsedData, ...data }))
		} else {
			window.sessionStorage.setItem('uaDatalayer', JSON.stringify(data))
		}
	}

	/**
	 * Retrieves the data layer data that is stored in session storage.  This is used
	 * to determine if a user is a new user or not across multiple page loads.  But it
	 * can also be used for an part of the data layer that needs to be maintained across
	 * multiple page loads.
	 * @returns The data layer data that is stored in session storage
	 */
	private static getFromSession() {
		const storedData = window.sessionStorage.getItem('uaDatalayer')
		if (storedData) {
			return JSON.parse(storedData) as DataLayerInterface
		}
		return null
	}

	/**
	 * Fires an event that is dispatched using the uaDataLayer `action` method as well
	 * as a custom event on the DOM using the window as the source.
	 * @param action The action to fire
	 * @param data The data associated with the action
	 */
	protected fireAction(action: AnalyticsAction, data: FiredActionData) {
		if (!getPublicConfig().metrics?.queuing_enabled || this.initiatingEventFired) {
			this._fireActionNow(action, data)
		} else {
			logger.info(`Queueing action ${action}`, data)
			this.actionQueue.push({ action, data })
		}
	}

	private _fireActionNow(action: AnalyticsAction, data: FiredActionData) {
		logger.info(data, `Firing action ${action}`)
		fireEvent(action, data)
		window.uaDatalayer?.action(action, data)
		if (action === AnalyticsAction.pageView) {
			// SB page transitions don't reinitialize, nor clear the previous uaDatalayer
			// state. To handle the odd cases in which this affects the underlying Datalayer
			// code, we call the following method the purge the triggered and not stale data.
			this._clearStalePageData()
		}
		this.fireDataDogAction(action, data)
		window.dispatchEvent(new CustomEvent<FiredActionData>(`ua-${action}`, { detail: data }))
	}

	private fireDataDogAction(action: AnalyticsAction, data: FiredActionData) {
		let ddAction = action as string
		let ddData = data
		if (action === AnalyticsAction.pageView) {
			// Datadog requires that the name of the event contain information about the
			//	page type so that we can setup the funnel properly.  It appears that we
			//	can't use data in the payload to configure the funnel.  The way our events
			//	work, though, there is only a pageView type with data describing the
			//	page.  So, instead, we create a new event for each page type and use that
			//	to send to datadog.
			switch (this.currentState.page_type) {
				case 'product-listing':
					ddAction = 'pageViewPlp'
					break
				case 'product-detail':
					ddAction = 'pageViewPdp'
					break
				case 'empty-cart':
				case 'cart':
					ddAction = 'pageViewCart'
					break
				case 'checkout':
					ddAction = 'pageViewCheckout'
					break
				case 'order-receipt':
					ddAction = 'pageViewCheckoutConfirmation'
					break
				case 'home':
					ddAction = 'pageViewHome'
					break
				case 'content':
					ddAction = 'pageViewContent'
					break
				default:
					ddAction = 'pageViewOther'
					break
			}

			ddData = this.currentState
		} else if (action === AnalyticsAction.checkoutStepChange) {
			switch (this.currentState.checkout_step) {
				case CheckoutStep.SHIPPING:
					ddAction = 'checkoutStepShipping'
					break
				case CheckoutStep.PAYMENT:
					ddAction = 'checkoutStepPayment'
					break
				case CheckoutStep.CONTACT:
					ddAction = 'checkoutStepContact'
					break
				case CheckoutStep.RECEIPT:
					ddAction = 'checkoutStepConfirmation'
					break
			}

			ddData = this.currentState
		}

		datadogRum.addAction(ddAction, ddData)
	}

	/**
	 * Helper to restore the uaDatalayer pageData state back to
	 * a "fresh" state. There are specific properties which
	 * affect the Datalayer's Action handling logic.
	 */
	private _clearStalePageData() {
		this.updatePageData({
			// If the `refinement_type` property exists, the pageView's
			// tealiumEvent always defaults to `listing_sort_refine`
			// re: https://github.com/ua-digital-commerce/ua-tealium/blob/master/src_uaDatalayer/lib/Actions.js#L129-L132
			refinement_type: undefined,
		})
	}

	public trackContentModule(ctaUrl: string, ctaText: string, tracking?: AnalyticsTracking) {
		const linkData = {
			internal_campaign_asset_name: tracking?.asset_name?.id || '',
			internal_campaign_module: tracking?.module || '',
			internal_campaign_link: ctaUrl,
			internal_campaign_cta_text: ctaText || '',
			internal_campaign_placement: tracking?.placement || '',
			internal_campaign_snipe: tracking?.snipe || '',
			internal_campaign_headline: tracking?.header || '',
		}
		this.applyNextPageData(linkData)
	}
}
