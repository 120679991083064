import type {
	Address,
	AddressInput,
	AddressSuggestion,
	CustomerAddress,
	Maybe,
} from '~/graphql/generated/uacapi/type-document-node'
import { formatAddressDetails } from '~/lib/i18n/address'
import type { ExtendedCustomerAddress } from '~/lib/types/addresses.interface'

type AddressCompare = Pick<
	Address | CustomerAddress,
	'address1' | 'address2' | 'city' | 'firstName' | 'lastName' | 'postalCode' | 'stateCode'
>

export function compareAddresses(address1?: Maybe<AddressCompare>, address2?: Maybe<AddressCompare>): boolean {
	if (!address1 || !address2) return false

	const trimLower = (input: Maybe<string> | undefined): string | undefined => (input || undefined)?.trim().toLowerCase()
	const compareField = (left: Maybe<string> | undefined, right: Maybe<string> | undefined): boolean =>
		trimLower(left) === trimLower(right)

	return (
		compareField(address1.firstName, address2.firstName) &&
		compareField(address1.lastName, address2.lastName) &&
		compareField(address1.address1, address2.address1) &&
		compareField(address1.address2, address2.address2) &&
		compareField(address1.city, address2.city) &&
		compareField(address1.stateCode, address2.stateCode) &&
		compareField(address1.postalCode, address2.postalCode)
	)
}

export function extendShippingAddressWithSavedAddress(
	address1?: CustomerAddress,
	customerAddresses?: CustomerAddress[],
): AddressInput {
	const savedAddress = customerAddresses?.find((savedAdd) => compareAddresses(address1, savedAdd))
	return {
		...(address1 as AddressInput),
		...(savedAddress as AddressInput),
	}
}

// TODO: remove when handled via `GetCartShippingAddressForVerification`
const problematicSuggestion = (suggestion: AddressSuggestion): boolean =>
	suggestion.address1.length > 35 || (suggestion.address2?.length ?? 0) > 35

export const sanitizeSuggestions = (suggestions: AddressSuggestion[]) =>
	suggestions.filter((suggestion) => !problematicSuggestion(suggestion))

export const trimAddress = ({
	address1,
	address2,
	city,
	countryCode,
	firstName,
	lastName,
	phone,
	postBox,
	postalCode,
	salutation,
	secondName,
	stateCode,
	suffix,
	suite,
	title,
}: Partial<Omit<CustomerAddress, '__typename' | 'addressId' | 'id'>>): Address => ({
	address1: address1 ?? '',
	address2: address2 ?? '',
	city: city ?? '',
	countryCode: countryCode ? countryCode.toUpperCase() : 'US',
	firstName: firstName ?? '',
	lastName: lastName ?? '',
	phone,
	postBox,
	postalCode: postalCode ?? '',
	salutation,
	secondName,
	stateCode,
	suffix,
	suite,
	title,
})

export function getDateValue(date?: Maybe<Date>) {
	return Date.parse(date?.toString() ?? '') || 0
}

export function mapAddresses(addresses: CustomerAddress[], locale: string): ExtendedCustomerAddress[] {
	return (
		addresses
			.map((node) => ({
				fullName: `${node.firstName} ${node.lastName}`,
				selected: node.isDefaultBilling || node.preferred || false,
				address3: formatAddressDetails(
					{
						city: node.city ?? '',
						stateCode: node.stateCode,
						postalCode: node.postalCode ?? '',
					},
					locale,
				),
				...node,
			}))

			// Sort the addresses with preferred and default billing addresses appearing first
			.sort((a, b) => {
				if (a.preferred && !b.preferred) {
					return -1
				}
				if (!a.preferred && b.preferred) {
					return 1
				}
				if (a.isDefaultBilling && !b.isDefaultBilling) {
					return -1
				}
				if (!a.isDefaultBilling && b.isDefaultBilling) {
					return 1
				}
				return 0
			})
	)
}
