import logger from '~/lib/logger'

export type Optional<T> = T | undefined | null
export type NonEmptyArray<T> = [T, ...T[]]
export type Modify<T, R> = Omit<T, keyof R> & R

// Prevent re-renders by using a single, immutable array for missing arrays
const immutableDefaultArray = Object.freeze([])
// Given `undefined`, return an empty array of type T; otherwise return Array<T>.
export function ensureArray<T>(maybeArray: T | Array<T> | undefined | null): Array<T> {
	if (maybeArray === undefined || maybeArray === null) {
		return []
	}
	return Array.isArray(maybeArray) ? maybeArray : [maybeArray]
}

/**
 * Checks to see that the given item is an array like object AND that it has at least one item in it.
 * @param array An array like object or null or undefined
 * @returns
 */
export function isArrayWithItems<T>(array: Optional<Array<T>>): boolean {
	if (Array.isArray(array)) {
		return array.length > 0
	}
	return false
}

// Given `undefined`, return an empty array of type T
// Otherwise, given an Array<T | null | undefined>, filter out the nullish elements, and return an Array<T>
// Otherwise, return the Array<T> input
export function ensureNonNullishArray<T>(array: undefined | null | Array<T | null | undefined>): Array<T> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return array ? array.filter(isNonNullish) : (immutableDefaultArray as any)
}

export function ensureNonNull<T>(val: T | null | undefined): T | undefined {
	return val === null ? undefined : val
}

/**
 * If you need a string, and it's okay to default it to `''`.
 * Given `undefined` or `null`, return the `''` (the empty string)
 * Given a `string`, return it
 */
export function ensureString(str: string | undefined | null): string {
	return str || ''
}

// If you need a number, and it's okay to default it to `0`
// Given `undefined` or `null`, return `0`
// Given a `number`, return it
export function ensureNumber(number: number | undefined | null): number {
	return number || 0
}

// If you need a boolean, and it's okay to default it to `0`
// Given `undefined` or `null`, return `false`
// Given a `boolean`, return it
export function ensureBoolean(boolean: boolean | undefined | null): boolean {
	return !!boolean
}

// If you need an ECMAScript epoch, and it's okay to default it to `0`
// Given `undefined`, `null` or `NaN` during time parsing, return `0`
// Given a `number` or valid parsed time, return it
export function ensureEpochTime(date: Date | undefined | null | string | number, defaultValue = 0) {
	const time = new Date(date as string).getTime()
	return Number.isNaN(time) ? defaultValue : time
}

// If you have an arbitrary input, and you need to force it to be a number
// Given a number, return the input
// If the input can be coerced to a number via `parseInt`, return that
// Return `def` otherwise
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function forceNumber(o: unknown, def = 0): number {
	if (typeof o === 'number') return o
	const parsedInt = parseFloat(String(o))
	return parsedInt || def
}

// This is a hack.
// There might be a better way to have the typechecker work with sass modules;
// until then, this at least documents where things aren't necessarily typesafe
export function ensureComputedCSSProperty(computedProperty: string | undefined) {
	return typeof computedProperty === 'string' ? computedProperty : ''
}

// If the code should _not_ function if a nullish value is being used, use this method.
// It logs a 'strictNullChecks: unexpected nullish' error and then throws
export function validateNonNullish<T>(o: T | undefined | null): o is T {
	if (o === undefined || o === null) {
		const message = 'strictNullChecks: unexpected nullish'
		logger.error(message)
		throw new Error(message)
	}
	return true
}

/* -------------------- Type Guards -------------------- */

// These type guards can be used either in a branch, or as the predicate for Array functions (like `every`)

// Checks to make sure the value isn't `null` or `undefined`
export function isNonNullish<T>(o: T | null | undefined): o is T {
	return o !== null && o !== undefined
}

// Checks to make sure the value isn't `null` or `undefined`; if it _is_
// an Array, checks to make sure all its elements are of type T (not `null` or `undefined`)
export function isNonNullishArray<T>(
	array: undefined | null | Array<T | null | undefined> | undefined,
): array is Array<T> {
	return !!(array && array.every(isNonNullish))
}

/**
 * Accepts a list of components and returns the first truthy element. If no truthy element
 * is found, then it returns a component returning null.
 * @param args
 * @returns
 */
export function ensureComponent<T extends () => React.ReactNode>(...args: (T | null | undefined | boolean)[]): React.FC
export function ensureComponent<T extends (props?: any) => React.ReactNode>( // eslint-disable-line @typescript-eslint/no-explicit-any -- Typescript correctly infers the parameter type so `any` is OK here
	...args: (T | null | undefined | boolean)[]
): React.FC<Parameters<T>[0]>
export function ensureComponent(...args: any[]) {
	return args.find((x) => !!x) ?? (() => null)
}
