import pino, { type Level, type LogEvent, type LoggerOptions } from 'pino'
import { getPublicConfig } from '~/lib/client-server/config'
import { getContext } from '~/lib/get-context'

function cleanStack(stack: string) {
	return stack.split('\n').slice(3).join('\n')
}

interface GetTagParameters {
	area: string
	quantumMetricSessionId?: string
	quantumMetricUserId?: string
}

/**
 * Returns a list of tags that can be used to identify the source of the log in DataDog.
 * @param area
 * @returns
 */
function getTags(input: GetTagParameters): Record<string, string> {
	return {
		env: process.env.CONFIG_ENVIRONMENT as string,
		version: getPublicConfig().app.version,
		'sb.area': input.area,
		'sb.context': getContext(),
		'sb.quantumSessionId': input.quantumMetricSessionId || '<unknown>',
		'sb.quantumUserId': input.quantumMetricUserId || '<unknown>',
	}
}

/**
 * Takes a logging area and generates a comman separated list of tags that can be assigned to
 * the ddtags property.
 * @param area
 */
export function getTagsAsString(input: GetTagParameters): string {
	return Object.entries(getTags(input))
		.map(([key, value]) => `${key}:${value}`)
		.join(',')
}

export function buildError(logEvent: LogEvent): {
	errorObj: unknown
	error: Error
} {
	let errorObj: undefined | unknown
	// the first pino param is an object
	// but most cases it's omitted and the first is just a string message
	if (typeof logEvent.messages[0] !== 'string' && !(logEvent.messages[0] instanceof Error)) {
		;[errorObj] = logEvent.messages
		logEvent.messages.shift()
	}

	const messageStrings = logEvent.messages.map((msg) => {
		if (msg instanceof Error) {
			return msg.message
		}

		if (msg.msg) {
			return msg.msg
		}

		if (msg.message) {
			return msg.message
		}

		if (typeof msg === 'string') {
			return msg
		}

		// If we get here then we don't know exactly what type the message is
		//	we we will just stringify it.
		return JSON.stringify(msg)
	})

	// NOTE: Datadog will only give us a stack trace if there is an error object
	//	so we establish a new error regardless of what was originally sent.  Note
	//	that the behavior here will vary depending on the source of the log.  For
	//	example, if the log is coming from the client, then the stack trace will
	//	be the client stack trace.  If the log is coming from the server, then the
	//	stack trace will be the server stack trace.
	const error = new Error(messageStrings.join(','))

	if (error.stack) {
		try {
			// Non-standard browsers may not allow the stack trace to be modified
			error.stack = cleanStack(error.stack)
		} catch (e) {
			// ignore
		}
	}
	return { errorObj, error }
}

/**
 * Used for all code that can be rendered on the client OR the server.  Unlike the server side logger which is
 * configured to send logs to DataDog, this logger is configured to send logs to the browser console only.
 * The RUM agent will pick up these logs and send them to DataDog automatically.
 *
 * @param area The area of the application that this logger is for.  This will be used to identify
 * 		   the source of the log in DataDog.
 * @returns
 */
export function createClientLogger(area: string) {
	const options = {
		name: area,
		level: getPublicConfig()?.metrics?.debug ? 'debug' : 'info',
		browser: {
			serialize: true,
			write: {
				error: (o) => {
					if (process.env.CONFIG_ENVIRONMENT !== 'production') {
						console.error(o)
					}
				},
			},
			transmit: {
				// We only send errors to DataDog
				level: 'warn',
				send: (_level: Level, logEvent: LogEvent) => {
					const { error, errorObj } = buildError(logEvent)
					if (getContext() === 'client') {
						let quantumMetric:
							| {
									quantumMetricSessionId: string | undefined
									quantumMetricUserId: string | undefined
							  }
							| undefined

						if (typeof window !== 'undefined' && globalThis?.QuantumMetricAPI) {
							quantumMetric = {
								quantumMetricSessionId: globalThis?.QuantumMetricAPI?.getSessionID(),
								quantumMetricUserId: globalThis?.QuantumMetricAPI?.getUserID(),
							}
						}

						// In the case of a client error, we will use the global DD_RUM object to send the error
						//	using the RUM analytics agent
						globalThis.DD_RUM?.addError(error, {
							...getTags({
								area,
								...quantumMetric,
							}),
							...(!!errorObj && { 'sb.errorObj': errorObj }),
						})
					} else {
						// We would only get here if we are building client output on
						//	server (like in the case of a revalidation request).  In this case
						//	we don't have any user on the other side meaning we don't have a lot
						//	of the context that comes with a request that was triggered by a user
						//	action (like coming to the page).
						console.error(error)
					}
				},
			},
		},
	} satisfies LoggerOptions

	return pino(options)
}

export default createClientLogger('general')
