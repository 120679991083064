import type { useFormatMessage } from '~/components/hooks/useFormatMessage'
import type {
	ApplePayPaymentInstrument,
	AurusCreditCardPaymentInstrument,
	BasketPartsFragment,
	GiftCardPaymentInstrument,
	KlarnaPaymentInstrument,
	OrderReceipt,
	PaymetricPaymentInstrument,
	PayPalPaymentInstrument,
	Shipment,
	VipPaymentInstrument,
} from '~/graphql/generated/uacapi/type-document-node'
import { GiftCardType, ShippingMethodType } from '~/graphql/generated/uacapi/type-document-node'
import { sum } from '~/lib/arrays'
import type { Cart, CartProduct, CartShippingMethods, CartTotals } from '~/lib/types/cart.interface'
import { ensureNonNullishArray, ensureNumber, ensureString } from '~/types/strict-null-helpers'
import { getCurrencyByLocale } from './i18n/currency'
import { getCountryCodeByLocale } from './i18n/locale'
import { CADFreeShippingRequiredAmount, USFreeShippingRequiredAmount } from './preferences/cart'
import { isEGiftCard } from './products'

// TODO: switch mocked numbers for correct value
// once we have where to pull this data from
export const getRequiredAmountForFreeShipping = (locale: string) =>
	locale === 'en-us' ? USFreeShippingRequiredAmount : CADFreeShippingRequiredAmount

// Get the "shipment" that will actually be shipped to customer address.
// This assumes that there can't be both a SR and a Standard shipment
// in the same Basket.  We believe that assumption holds because the checkout UI
// will only show ShopRunner as an option if all ProductItems are available for ShopRunner.
export const getShipment = (shipments?: Shipment[]) => {
	const allowedMethodTypes = [ShippingMethodType.STANDARD, ShippingMethodType.SHOPRUNNER]
	return (shipments || []).find((s) => allowedMethodTypes.includes(s.shippingMethod?.methodType as ShippingMethodType))
}

/**
 * There are instances where the returned shipment from getShipment might not return anything, when
 * the methodType does not match the valid list, or when the method is null. This will ensure we have
 * a shipmentId regardless of methodType
 */
export const getShipmentId = (shipments?: Shipment[]): string => {
	const shipment = getShipment(shipments)
	if (shipment) return shipment.id
	const fallbackShipment = shipments && shipments.length ? shipments[0].id : ''
	return fallbackShipment
}

export const getShippingAddress = (basket?: Cart | OrderReceipt) =>
	getShipment(basket?.shipments)?.shippingAddress || undefined

export const getAvailableShippingMethods = (cart?: Pick<Cart, 'shipments'> | null) => {
	const shipment = getShipment(cart?.shipments)
	const availableShippingMethods = (shipment ?? cart?.shipments[0])?.availableShippingMethods || []
	return availableShippingMethods.filter((method) => method?.methodType !== ShippingMethodType.STOREPICKUP)
}

export const generateApplePayRequest = ({
	locale,
	cart,
	formatMessage,
}: {
	locale: string
	cart: Cart
	formatMessage: ReturnType<typeof useFormatMessage>
}): ApplePayJS.ApplePayPaymentRequest => {
	const availableShippingMethods = getAvailableShippingMethods(cart).filter(
		({ methodType }) => methodType !== ShippingMethodType.SHOPRUNNER,
	)
	const shipment = getShipment(cart.shipments)
	const shippingAddress = shipment?.shippingAddress
	const selectedShippingMethod = shipment?.shippingMethod
	const country = getCountryCodeByLocale(locale)
	const countryCode = country.toUpperCase()

	return {
		countryCode,
		currencyCode: getCurrencyByLocale(locale),
		supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
		merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit'],
		total: {
			label: formatMessage('under-armour'),
			type: 'final',
			amount: ensureNumber(cart.grandTotal).toString(),
		},
		lineItems: [
			{
				label: formatMessage('subtotal'),
				amount: ensureNumber(cart.subTotal).toString(),
			},
			{
				label: formatMessage('discount'),
				amount: ensureNumber(-1 * cart.productDiscountAmount).toString(),
			},
			{
				label: formatMessage('employee-discount'),
				amount: ensureNumber(-1 * cart.employeeDiscountAmount).toString(),
			},
			{
				label: formatMessage('promo-code'),
				amount: ensureNumber(-1 * (cart.orderDiscountAmount + cart.promoAmount)).toString(),
			},
			{
				label: formatMessage('tax'),
				amount: ensureNumber(cart.tax).toString(),
			},
			{
				label: ensureString(shipment?.shippingMethod?.name),
				amount: ensureNumber(cart.shipping).toString(),
			},
		].filter((x) => parseFloat(x.amount) > 0),
		shippingMethods: availableShippingMethods.map((method) => ({
			label: ensureString(method.name),
			detail: '',
			amount: ensureNumber(method.price).toString(),
			identifier: method.id,
			shippingType: 'shipping', // TODO: change to 'storePickup' for BOPIS
			selected: method.id === selectedShippingMethod?.id,
		})),
		shippingContact: {
			givenName: ensureString(shippingAddress?.firstName),
			familyName: ensureString(shippingAddress?.lastName),
			addressLines: ensureNonNullishArray([shippingAddress?.address1, shippingAddress?.address2]),
			locality: ensureString(shippingAddress?.city),
			administrativeArea: ensureString(shippingAddress?.stateCode),
			postalCode: ensureString(shippingAddress?.postalCode),
			country: ensureString(shippingAddress?.countryCode).toLocaleLowerCase(),
			countryCode: ensureString(shippingAddress?.countryCode).toUpperCase(),
		},
		requiredBillingContactFields: ['postalAddress', 'name'],
		requiredShippingContactFields: ['postalAddress', 'name', 'phone', 'email'],
	}
}

// Find calloutMsg for each priceAdjustments in product
export const getCalloutMsgByCode = (code: string, cartData?: Cart): string | undefined =>
	cartData?.couponItems?.find((item) => item.code === code)?.calloutMsg ?? undefined

export const getPromotions = (product: CartProduct, cartData?: Cart) =>
	product.priceAdjustments.map((priceAdj) => ({
		calloutMsg: getCalloutMsgByCode(priceAdj.couponCode, cartData) || product.productPromotions[0]?.calloutMsg,
	}))

export const isShoprunnerSelected = (cartData?: Cart) => {
	const shipment = getShipment(cartData?.shipments)
	return shipment?.shippingMethod?.name === 'ShopRunner'
}

export const amountOwedAfterGiftCards = (
	basket?: Partial<Pick<BasketPartsFragment, 'paymentInstruments'>> & CartTotals,
): number => {
	const giftCards = getGiftCardPaymentInstruments(basket?.paymentInstruments)
	const giftCardBalance = sum(giftCards.map((gc) => gc.availableBalance || 0))
	const totalOwed = basket?.grandTotal || 0
	return Math.max(0, totalOwed - giftCardBalance)
}

export const orderPaidInFullByGiftCard = (
	basket?: Partial<Pick<BasketPartsFragment, 'paymentInstruments'>> & CartTotals,
): boolean => {
	if (!basket || !getGiftCardPaymentInstruments(basket?.paymentInstruments).length) return false
	return amountOwedAfterGiftCards(basket) === 0
}

export const hasGiftCardInCart = (cartData?: Cart) =>
	cartData?.products?.some((product) => product.giftCardType !== GiftCardType.NONE)

export const hasEGiftCardInCart = (cartData?: Cart) =>
	cartData?.products?.some((product) => product.giftCardType === GiftCardType.EGIFTCARD)

export const nullCart = {
	basketId: '',
	billingAddress: null,
	checkoutStep: 'shipping',
	employeeDiscountAmount: 0,
	estimatedLoyaltyPoints: 0,
	giftCardTotal: 0,
	grandTotal: 0,
	itemCount: 0,
	orderDiscountAmount: 0,
	paymentInstruments: [],
	productDiscountAmount: 0,
	products: [],
	limitExceededItems: [],
	productsMeta: {
		includesBopis: false,
		includesEgift: false,
		includesShippable: false,
		onlyBopis: false,
		onlyEgift: false,
		onlyShippable: false,
	},
	promoAmount: 0,
	shippingMethods: {} as unknown as CartShippingMethods,
	subTotal: 0,
	subTotalAfterDiscounts: 0,
	subTotalBeforeAdjustments: 0,
	tax: 0,
	totals: 0,
	shipments: [],
	shipping: 0,
	uiHints: null,
	contactEmail: undefined,
	customerNo: undefined,
} as Cart

export function getKlarnaPaymentInstrument(paymentInstruments: Cart['paymentInstruments']) {
	return paymentInstruments.find((pi) => pi.__typename === 'KlarnaPaymentInstrument') as
		| KlarnaPaymentInstrument
		| undefined
}

// This accounts for current (Aurus) and legacy (Paymetric) CC processor instruments
export function getCreditCardPaymentInstrument(paymentInstruments: Cart['paymentInstruments']) {
	return paymentInstruments.find(
		(pi) => pi.__typename === 'AurusCreditCardPaymentInstrument' || pi.__typename === 'PaymetricPaymentInstrument',
	) as AurusCreditCardPaymentInstrument | PaymetricPaymentInstrument | undefined
}

export function getGiftCardPaymentInstruments(paymentInstruments?: Cart['paymentInstruments']) {
	return (paymentInstruments ?? []).filter(
		(pi) => pi.__typename === 'GiftCardPaymentInstrument',
	) as GiftCardPaymentInstrument[]
}

export function getPayPalPaymentInstrument(paymentInstruments: Cart['paymentInstruments']) {
	return paymentInstruments.find((pi) => pi.__typename === 'PayPalPaymentInstrument') as
		| PayPalPaymentInstrument
		| undefined
}

export function getApplePayPaymentInstrument(paymentInstruments: Cart['paymentInstruments']) {
	return paymentInstruments.find((pi) => pi.__typename === 'ApplePayPaymentInstrument') as
		| ApplePayPaymentInstrument
		| undefined
}

export function getVipPaymentInstrument(paymentInstruments: Cart['paymentInstruments']) {
	return paymentInstruments.find((pi) => pi.__typename === 'VipPaymentInstrument') as VipPaymentInstrument | undefined
}
// Get the sum of products excluding eGC's
// and subtract any promo or discount from total
export const getProductsSubtotalForFreeShipping = (totals: CartTotals) =>
	totals.subTotalBeforeAdjustments -
	(totals.products?.reduce(
		(total, current) => (isEGiftCard(current.giftCardType) ? total + current.totalPrice.base : total),
		0,
	) ?? 0) -
	totals.promoAmount -
	totals.productDiscountAmount -
	totals.orderDiscountAmount -
	totals.employeeDiscountAmount
