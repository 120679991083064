import type {
	ColorVariation,
	ColorVariationAssetDetail,
	ShopTheLookImage,
} from '~/graphql/generated/uacapi/type-document-node'
import { ensureNonNullishArray, forceNumber } from '~/types/strict-null-helpers'
import { updateUrlParams } from './utils'
import type { ClientProductDetail, ClientProductVariantDetail } from './types/product.interface'
import type { useFormatMessage } from '~/components/hooks/useFormatMessage'

export const SIZE_MAP: Record<string, string[]> = {
	XS: ['XS', 'TP', '30A', '30B', '32AA', '32A', '32B'],
	SM: ['SM', 'S', 'P', 'LG, MD, SM', '32C', '32D', '34AA', '34A', '34B'],
	MD: ['MD', 'M', '32DD', '34C', '34D', '36A', '36B'],
	LG: ['LG', 'L', 'G', '34DD', '34DDD', '36C', '36D', '38B', '38C'],
	XL: ['XL', 'TG', '36DD', '36DDD', '38D', '40C'],
	XXL: ['XXL', 'TTG', '38DD', '38DDD', '40D'],
}

export type SizeMapTypes = keyof typeof SIZE_MAP
export type SizeMapOptionsType = {
	XS: string
	SM: string
	MD: string
	LG: string
	XL: string
	XXL: string
}

const IMAGE_ORDER = [
	'FC_XS',
	'BC_XS',
	'FC_30A',
	'BC_30A',
	'FC_30B',
	'BC_30B',
	'FC_32AA',
	'BC_32AA',
	'FC_32A',
	'BC_32A',
	'FC_32B',
	'BC_32B',

	'FC',
	'FC_Main',
	'FCADD_MAIN',
	'BC',
	'FC_32C',
	'BC_32C',
	'FC_32D',
	'BC_32D',
	'FC_34AA',
	'BC_34AA',
	'FC_34A',
	'BC_34A',
	'FC_34B',
	'BC_34B',

	'FC_MD',
	'BC_MD',
	'FC_32DD',
	'BC_32DD',
	'FC_34C',
	'BC_34C',
	'FC_34D',
	'BC_34D',
	'FC_36A',
	'BC_36A',
	'FC_36B',
	'BC_36B',

	'FC_LG',
	'BC_LG',
	'FC_34DD',
	'BC_34DD',
	'FC_34DDD',
	'BC_34DDD',
	'FC_36C',
	'BC_36C',
	'FC_36D',
	'BC_36D',
	'FC_38B',
	'BC_38B',
	'FC_38C',
	'BC_38C',

	'FC_XL',
	'BC_XL',
	'FC_36DD',
	'BC_36DD',
	'FC_36DDD',
	'BC_36DDD',
	'FC_38D',
	'BC_38D',
	'FC_40C',
	'BC_40C',

	'FC_XXL',
	'BC_XXL',
	'FC_38DD',
	'BC_38DD',
	'FC_38DDD',
	'BC_38DDD',
	'FC_40D',
	'BC_40D',

	// Placing these here so they will be at the bottom of the sort
	'HF',
	'BF',
	'FCADD',
	'FSFADD',
	'FABR_SL',
	'BCKDET',
	'BCKDET_SL',
	'LDB_SL',
	'LDF_SL',
	'SIDEDET',
]

export const sortImages = (images: ShopTheLookImage[]) => {
	if (!images) {
		return []
	}

	// Matches two patterns in one string
	// 1: V5-(7 digits)-(3 digits)_
	// 2: (.*) matches everything after the first pattern
	// Other images do not always follow this same pattern

	// Ideally image order would be sorted in the api because this is
	// a brittle approach and all images do not follow this pattern.
	const regex = /^V5-\d{7}-\d{3}_(.*)$/i

	// There are some images that don't follow the regex pattern.
	// All of the images I've encountered that don't follow the pattern also don't
	// have a model, so we filter them out here.
	const imagesWithModel = images.filter((img) => img.modelHeightCm !== null)

	return imagesWithModel
		.map((img) => ({ ...img, matchResult: img.image.match(regex) }))
		.sort((a, b) =>
			a.matchResult?.[1] && b.matchResult?.[1]
				? IMAGE_ORDER.indexOf(a.matchResult?.[1]) - IMAGE_ORDER.indexOf(b.matchResult?.[1])
				: 0,
		)
		.map((img) => {
			const { matchResult, ...image } = img
			return image
		})
}

/**
 * Get the shop the look images by the color variation.
 * @param product
 * @param displayedColor
 * @returns
 */
export const getShopTheLookImages = (
	product: ClientProductDetail | null,
	displayedColor: ColorVariation | undefined,
) => {
	const shopTheLookByColor = product?.shopTheLookColors?.find((item) => item.color === displayedColor?.color)
	return ensureNonNullishArray(shopTheLookByColor?.images?.map((img) => img))
}

/**
 * Find the `ShopTheLookImage` based on the size provided.
 * @param size
 * @returns
 */
const getShopTheLookImageBySize = (size: string, shopTheLookImages: ShopTheLookImage[]): ShopTheLookImage | undefined =>
	shopTheLookImages?.find((item) => item?.modelSize === size)

/**
 * Get information about the athlete from the image based on the size provided.
 * @param size
 * @returns
 */
export const getAthleteInfoDetailed = (
	size: string,
	shopTheLookImages: ShopTheLookImage[],
	formatMessage: ReturnType<typeof useFormatMessage>,
): string => {
	const shopImage = getShopTheLookImageBySize(size, shopTheLookImages)
	if (!shopImage || shopImage?.modelSize === 'OSFA' || !shopImage?.modelHeightFtIn) {
		return ''
	}
	const heightInches = forceNumber(shopImage.modelHeightFtIn)
	const feet = Math.floor(heightInches / 12)
	const heightFtIn = `${feet}'${heightInches - feet * 12}"`

	return formatMessage('model-specific-copy', {
		heightCm: shopImage.modelHeightCm,
		heightFtIn,
		size: shopImage.modelSize,
	}).toString()
}

/**
 * Get information about the athlete from the image based on the size provided.
 * @param size
 * @returns
 */
export const getAthleteInfo = (
	size: string,
	shopTheLookImages: ShopTheLookImage[],
	formatMessage: ReturnType<typeof useFormatMessage>,
): string => {
	const shopImage = getShopTheLookImageBySize(size, shopTheLookImages)
	if (!shopImage || shopImage?.modelSize === 'OSFA' || !shopImage?.modelHeightFtIn) {
		return ''
	}
	const heightInches = forceNumber(shopImage.modelHeightFtIn)
	const feet = Math.floor(heightInches / 12)
	const heightFtIn = `${feet}'${heightInches - feet * 12}"`

	return formatMessage('athlete-height-simple', {
		heightCm: shopImage.modelHeightCm,
		heightFtIn,
	}).toString()
}

/**
 * Get the displayed image src based on the size provided.
 * @param size
 * @returns
 */
const getDisplayedImageSrc = (
	size: string,
	displayedImages: ColorVariationAssetDetail[],
	shopTheLookImages: ShopTheLookImage[],
) => {
	const shopImage = getShopTheLookImageBySize(size, shopTheLookImages)
	const displayImage = displayedImages?.find((img) => img?.assetName === shopImage?.image)
	if (!displayImage?.url) {
		return undefined
	}
	return displayImage.url
}

/**
 * Check if the variant provided is the current variant.
 * Note: If there is a current `viewPreference`, that will take priority over the `selectedVariant`
 * @param size
 * @returns
 */
const getCurrentVariantSize = (
	size: string,
	viewPreference: string | undefined,
	selectedVariant?: ClientProductVariantDetail,
) => (viewPreference ? viewPreference === size : selectedVariant?.size === size)

export function getFilteredSizes(
	allSizes: string[],
	availableSizes: string[],
	routerPath: string,
	viewPreference: string | undefined,
	displayedImages: ColorVariationAssetDetail[],
	selectedVariant: ClientProductVariantDetail | undefined,
	formatMessage,
	shopTheLookImages: ShopTheLookImage[],
) {
	return allSizes
		.filter((size) => availableSizes.includes(size))
		.map((size) => ({
			size,
			isCurrentVariant: getCurrentVariantSize(size, viewPreference, selectedVariant),
			displayImageSrc: getDisplayedImageSrc(size, displayedImages, shopTheLookImages),
			athleteInfoCopy: getAthleteInfo(size, shopTheLookImages, formatMessage),
			filterUrl: updateUrlParams(routerPath, { viewPreference: size, start: null }),
		}))
}
