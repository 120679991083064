'use client'

import { useFrontendConfiguration } from '../providers/CommerceConfigurationProvider/CommerceConfigurationProvider'

/**
 * Uses the router to get the locale
 * @returns Returns the locale found or the default locale if none was found.
 */
export function useLocale(): string {
	const { locale } = useFrontendConfiguration()
	return locale
}
