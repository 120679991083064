import { createClientLogger } from '~/lib/logger'
import { getErrorMessage } from '~/lib/utils'
import {
	ZodProfileSchemaV1,
	ZodSessionSchemaV2,
	ZodUserPreferenceSchemaV2,
	type UserProfileInfo,
	type UserSessionInfo,
	type UserPreferenceInfo,
} from './types'

const logger = createClientLogger('auth.storage')

// As we add new versions of the session, we can add new keys here.
//  It's important to version these so that we can seemlessly migrate
//  users from one version to the next.
const SESSION_STORAGE_KEY_V2 = 'ua-session-v2'
const SESSION_STORAGE_KEY = SESSION_STORAGE_KEY_V2

const PROFILE_STORAGE_KEY_V1 = 'ua-profile-v1'
const PROFILE_STORAGE_KEY = PROFILE_STORAGE_KEY_V1

const USER_PREFERENCE_STORAGE_KEY_V2 = 'user-preferences-v2'
const USER_PREFERENCE_STORAGE_KEY = USER_PREFERENCE_STORAGE_KEY_V2

export type SerializerFunction<T> = (value: T) => string | undefined
export type UnSerializerFunction<T> = (value?: string | null) => T | undefined

// TODO: should we add a ttl?
function store<T>(
	key: string,
	value: T | undefined,
	serializer: SerializerFunction<T>,
	storage: Storage = window.localStorage,
) {
	if (value === undefined) {
		storage.removeItem(key)
		return
	}

	const serializedObject = serializer(value)
	if (serializedObject) {
		storage.setItem(key, serializedObject)
		return
	}

	logger.error(`Unable to store ${key} because it failed to match the requested schema`)
}

function load<T>(
	key: string,
	unserializer: UnSerializerFunction<T>,
	storage: Storage = window.localStorage,
): T | undefined {
	try {
		const val = storage.getItem(key)
		return unserializer(val)
	} catch (e) {
		const msg = getErrorMessage(e)
		logger.error(`Unable to read ${key} from store because: ${msg}`)
	}

	return undefined
}

const serializeV1Profile: SerializerFunction<UserProfileInfo> = (profileInfo): string | undefined => {
	try {
		return JSON.stringify({
			...profileInfo,

			// Convert the loyalty status date to an ISO string
			...(profileInfo?.loyalty?.statusDate
				? {
						loyalty: {
							...profileInfo.loyalty,
							statusDate: profileInfo.loyalty.statusDate.toISOString(),
						},
				  }
				: {}),
		})
	} catch (e) {
		logger.error(`Unable to serialize profile data: ${getErrorMessage(e)}`)
		return undefined
	}
}

const unSerializeV1Profile: UnSerializerFunction<UserProfileInfo> = (value): UserProfileInfo | undefined => {
	try {
		if (!value) {
			return undefined
		}

		const ob = JSON.parse(value)
		if (ob.loyalty?.statusDate) {
			ob.loyalty.statusDate = new Date(ob.loyalty.statusDate)
		}

		return ZodProfileSchemaV1.parse(ob)
	} catch (e) {
		logger.error(`Unable to serialize profile data: ${getErrorMessage(e)}`)
		return undefined
	}
}

/**
 * Serialize the session information to a string.  Note that this will convert date objects to ISO strings.
 * @param sessionInfo
 * @returns
 */
const serializeV1Session: SerializerFunction<UserSessionInfo> = (sessionInfo): string | undefined => {
	try {
		return JSON.stringify({
			accountType: sessionInfo.accountType,
			customerNo: sessionInfo.customerNo,
			uacapi: {
				accessToken: sessionInfo.uacapi.accessToken,
				expiresAt: sessionInfo.uacapi.expiresAt.toISOString(),
				iat: sessionInfo.uacapi.iat,
			},
			idm: sessionInfo.idm
				? {
						accessToken: sessionInfo.idm.accessToken,
						refreshToken: sessionInfo.idm.refreshToken,
						expiresAt: sessionInfo.idm.expiresAt.toISOString(),
				  }
				: undefined,
		})
	} catch (e) {
		logger.error('Unable to serialize session data to local storage')
		return undefined
	}
}

export const serializeV1UserPreference: SerializerFunction<UserPreferenceInfo> = (
	userPreferenceInfo,
): string | undefined => {
	try {
		return JSON.stringify({
			auth: {
				rememberMe: userPreferenceInfo?.auth?.rememberMe,
				lastEmailUsed: userPreferenceInfo?.auth?.lastEmailUsed,
			},
			welcomeMat: {
				regionChecked: userPreferenceInfo?.welcomeMat?.regionChecked,
			},
		})
	} catch (err) {
		logger.error('Unable to serialize user preference data to local storage')
		return undefined
	}
}

export const unSerializeV1UserPreference: UnSerializerFunction<UserPreferenceInfo> = (
	userPreferenceInfoString,
): UserPreferenceInfo | undefined => {
	try {
		if (!userPreferenceInfoString) {
			return undefined
		}
		const userPreferenceInfo = JSON.parse(userPreferenceInfoString)
		return ZodUserPreferenceSchemaV2.parse({
			auth: {
				rememberMe: userPreferenceInfo?.auth?.rememberMe,
				lastEmailUsed: userPreferenceInfo?.auth?.lastEmailUsed,
			},
			welcomeMat: {
				regionChecked: userPreferenceInfo?.welcomeMat?.regionChecked,
			},
		})
	} catch (err) {
		logger.error('Unable to parse user preference data from local storage')
		return undefined
	}
}

export function storeUserPreferenceData(userPreferenceInfo: UserPreferenceInfo) {
	const existingUserPreference = load<UserPreferenceInfo>(USER_PREFERENCE_STORAGE_KEY, unSerializeV1UserPreference)

	const defaultAuth = { rememberMe: false, lastEmailUsed: '' }
	const defaultWelcomeMat = { regionChecked: '' }

	const newUserPreference = {
		auth: {
			...defaultAuth,
			...(existingUserPreference?.auth ?? {}),
			...userPreferenceInfo?.auth,
		},
		welcomeMat: {
			...defaultWelcomeMat,
			...(existingUserPreference?.welcomeMat ?? {}),
			...userPreferenceInfo?.welcomeMat,
		},
	}

	store<UserPreferenceInfo>(USER_PREFERENCE_STORAGE_KEY, newUserPreference, serializeV1UserPreference)
}

export function getStoredUserPreferenceData(): UserPreferenceInfo | undefined {
	return load<UserPreferenceInfo>(USER_PREFERENCE_STORAGE_KEY, unSerializeV1UserPreference)
}

export function getStoredProfile(): UserProfileInfo | undefined {
	return load<UserProfileInfo>(PROFILE_STORAGE_KEY, unSerializeV1Profile, window.sessionStorage)
}

/**
 * This code sets the stored profile to the customerNo of the session if a profile exists. It returns the stored profile data.
 * If the profile is undefined, it removes the profile from local storage and returns undefined.
 * @returns
 */
export async function setStoredProfile(profile?: UserProfileInfo) {
	return store(PROFILE_STORAGE_KEY, profile, serializeV1Profile, window.sessionStorage)
}

/**
 * Unserialize the session information from local storage.  This first converts the string
 * to an object and then it does a validation against the V1 schema.
 * @param sessionString
 * @returns
 */
const unserializeV1Session: UnSerializerFunction<UserSessionInfo> = (sessionString): UserSessionInfo | undefined => {
	try {
		if (!sessionString) {
			return undefined
		}

		const sessionInfo = JSON.parse(sessionString)
		return ZodSessionSchemaV2.parse({
			accountType: sessionInfo.accountType,
			customerNo: sessionInfo.customerNo,
			uacapi: {
				accessToken: sessionInfo.uacapi.accessToken,
				expiresAt: new Date(sessionInfo.uacapi.expiresAt),
				iat: sessionInfo.uacapi.iat,
			},
			idm: sessionInfo.idm
				? {
						accessToken: sessionInfo.idm.accessToken,
						refreshToken: sessionInfo.idm.refreshToken,
						expiresAt: new Date(sessionInfo.idm.expiresAt),
				  }
				: undefined,
		})
	} catch (e) {
		logger.error('Unable to parse session data from local storage')
		return undefined
	}
}

/**
 * Store session information in local storage using the current version of the object.
 * @param sessionInfo
 */
export function storeSessionData(sessionInfo?: UserSessionInfo) {
	store<UserSessionInfo>(SESSION_STORAGE_KEY, sessionInfo, serializeV1Session)
}

/**
 * Get the session information from local storage.  If the session information
 * is not valid, then we'll return undefined.  This will attempt to convert older
 * versions of the session data to the new format if necessary.
 *
 * @returns
 */
export function getStoredSession(): UserSessionInfo | undefined {
	return load<UserSessionInfo>(SESSION_STORAGE_KEY, unserializeV1Session)
}
