import { ensureString } from 'types/strict-null-helpers'
import type { ColorVariation } from '~/graphql/generated/uacapi/type-document-node'
import { getProductTileImage } from '~/lib/products'
import type { MappedWishListProduct, WishList } from '~/lib/types/wishlist.interface'

export const mapWishListProducts = (edges: WishList['productListItems']['edges']): MappedWishListProduct[] => {
	const products = edges
		.filter(
			(edge) =>
				!!edge.node.product.copy?.name &&
				(edge.node.product.__typename === 'VariantProduct' ||
					(edge.node.product.colors && edge.node.product.colors.length > 0 && edge.node.product.prices)),
		)
		.map((edge) => {
			const { node } = edge
			const { product } = node

			let mappedProduct: MappedWishListProduct = {
				id: product.id,
				nodeId: node.id,
				currency: product.currency,
				style: product.style,
				length: product.lengths?.length,
				name: ensureString(product.copy.name),
				price: product.prices,
				colors: product.colors?.filter((color: ColorVariation) => color.orderable),
				colorCode: product.color?.code ?? '',
				url: product.url,
				badges: product.badges,
				orderable: product.inventory.orderable || false,
				preorderable: product.inventory.preorderable || false,
				productPromotions: product.productPromotions,
				image: getProductTileImage(product.colors, ensureString(product.copy.name)),
				exclusiveType: product.inventory.exclusiveType,
				cFromStoreId: product.cFromStoreId,
				cStoreInventory: product.cStoreInventory,
				quantity: node.quantity,
				__typename: product.__typename,
				team: node.product.team,
			}

			if (product.__typename === 'MasterProduct') {
				mappedProduct.sizes = product.sizes
			}

			if (product.__typename === 'VariantProduct') {
				const { list, sale } = product.price
				mappedProduct = {
					...mappedProduct,
					...{
						price: {
							list: { min: list, max: list },
							sale: { min: sale, max: sale },
						},
						image: getProductTileImage([{ assets: product.assets } as ColorVariation], ensureString(product.copy.name)),
						size: product.size.size,
						color: `${product.color.colorway} - ${product.color.code}`,
						sku: product.sku,
						length: product.sizePreferenceOption?.extendedSize || '',
					},
				}
			}

			return mappedProduct
		})
	return products.reverse()
}

export const sortWishlistProducts = (products: MappedWishListProduct[]): MappedWishListProduct[] => [
	...products.filter((product) => product.orderable),
	...products.filter((product) => !product.orderable),
]
