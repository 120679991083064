/* eslint-disable prefer-destructuring */
import type { NormalizedCacheObject } from '@apollo/client'
import { ApolloClient, InMemoryCache, createHttpLink, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import fetch from 'cross-fetch'

import introspection from '~/graphql/generated/uacapi/possible-types'
import { createClientLogger } from '~/lib/logger'
import { asBool } from '~/lib/types'
import { getStoredSession } from '~/lib/client-only/auth/storage'
import { UaConfigPublicImpl } from '~/lib/client-only/ua-config'

import { createErrorLink } from '../link/error-link'
import { createRecorderApolloLink } from '../link/recorder-link'
import { RetryWithTokenLink } from '../link/retry-link-with-token'
import { validateLocale } from '~/lib/i18n/locale'

const logger = createClientLogger('uacapi-client')

// Versioning
const uaClientType = `${UaConfigPublicImpl.appName}/${UaConfigPublicImpl.appVersion}`
const uacapiProxy = `${UaConfigPublicImpl.uacapiUrl}/graphql/`
const defaultHost = UaConfigPublicImpl.defaultDomain

const localeGlobalApolloClient: Record<string, ApolloClient<NormalizedCacheObject>> = {}

const getCustomFetch = () => (input: RequestInfo | URL, init?: RequestInit) => {
	const { operationName, query } = JSON.parse(init?.body?.toString() ?? '{}')
	const [optype] = `${query}`.split(' ')
	return fetch(`${input}?opname=${operationName}&optype=${optype}`, init)
}

function createUacapiClient(
	locale: string,
	handleUnauthorizedResponse?: () => void,
): ApolloClient<NormalizedCacheObject> {
	const [lang, site] = locale.split('-')
	const commonHeadersLink = setContext(async (_, { headers }) => ({
		headers: {
			Accept: 'application/json, text/plain, */*',
			'Accept-Language': locale,
			uaClientType,
			'ua-language-code': lang.toLocaleLowerCase(),
			'ua-site-code': site.toUpperCase(),
			origin: defaultHost,
			...(UaConfigPublicImpl.ocapiUrl ? { 'ua-ocapi-url': UaConfigPublicImpl.ocapiUrl } : {}),
			...headers,
		},
	}))

	/**
	 * The recorder link is a way to record requests and responses for use in generating
	 * mocks and general debugging.  It stores data on disk (by default /_recordings).
	 */
	const recorderLink = asBool(process.env.NEXT_PUBLIC_ENABLE_GLOBAL_RECORDER) ? createRecorderApolloLink() : undefined
	const errorLink = createErrorLink({ logger, service: 'UACAPI' })

	const httpLink = createHttpLink({
		uri: uacapiProxy,
		fetch: UaConfigPublicImpl.uacapiDebug ? getCustomFetch() : fetch,
		credentials: 'same-origin',
	})

	const retryLink = new RetryWithTokenLink({
		ignoredOperations: ['GetUserProfile'],
		isServer: typeof window === 'undefined',
		maxAttempts: UaConfigPublicImpl.uacapiMaxRetryCount,
		getUserSession: getStoredSession,
		handleUnauthorizedResponse,
	})

	const linkChain = [errorLink, commonHeadersLink, retryLink]
	if (recorderLink) {
		linkChain.push(recorderLink)
	}
	linkChain.push(httpLink)

	return new ApolloClient({
		link: from(linkChain),
		cache: new InMemoryCache({
			possibleTypes: introspection.possibleTypes,
			typePolicies: {
				MasterProductSearchHit: {
					keyFields: ['product', ['style']],
					merge: false,
				},
				SlicingGroupSearchHit: {
					keyFields: ['slicedProductId'],
					merge: false,
				},
				ColorVariation: {
					keyFields: false,
					merge: false,
				},
				Category: {
					keyFields: (category) => {
						const fields = ['id']

						if (category.parent) {
							fields.push('parent')
						}
						if (category.url) {
							fields.push('url')
						}
						return fields
					},
				},
				Query: {
					fields: {
						categories: {
							keyArgs: ['id', 'url'],
						},
					},
				},
			},
		}),
		defaultOptions: {
			query: { errorPolicy: 'all' },
			mutate: { errorPolicy: 'all' },
		},
		ssrMode: typeof window === 'undefined', // Disables forceFetch on the server (so queries are only run once)
	})
}

export function initApolloClient(
	locale?: string,
	initialState?: NormalizedCacheObject,
	handleUnauthorizedResponse?: () => void,
) {
	const saneLocale = validateLocale(locale)

	if (!(saneLocale in localeGlobalApolloClient)) {
		localeGlobalApolloClient[saneLocale] = createUacapiClient(saneLocale, handleUnauthorizedResponse)
	} else if (initialState) {
		localeGlobalApolloClient[saneLocale].cache.restore({
			...localeGlobalApolloClient[saneLocale].cache.extract(),
			...initialState,
		})
	}

	return localeGlobalApolloClient[saneLocale]
}
