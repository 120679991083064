'use client'

import { createContext, useContext, useEffect, useMemo, useState, type PropsWithChildren } from 'react'
import Cookies from 'universal-cookie'
import { useFeatureFlags } from '~/components/providers/CommerceConfigurationProvider/CommerceConfigurationProvider'
import { constructAbTestList, type AbTestList, type AbTestValue } from '~/lib/client-server/abtest'

export interface AbTestProviderProps {
	staticSearchParams?: string
}

export const AbTestContext = createContext<AbTestList>({})

/**
 * Hook for retrieving a specific test from the active tests list.
 * @param test
 * @returns
 */
export function useAbTest(test: string): AbTestValue {
	const activeTests = useContext(AbTestContext)
	if (test in activeTests) {
		return activeTests[test]
	}
	return false
}

/**
 * Hook for retrieving all registered tests
 * @param test
 * @returns
 */
export function useAbTests(): AbTestList {
	return useContext(AbTestContext)
}

/**
 * The AbTestProvider is a provider that that maintains a list of active tests by looking at the following
 * sources in order of precedence:
 * 	1. Tests passed in as a prop
 * 	2. Tests passed in as a query parameter
 * 	3. Tests passed in as a cookie
 *
 * The provider will also check the feature flags to determine whether or not a test should be active.
 *
 * Remember that the AbTestProvider is rendered on both the server and the client.  This means that the
 * active tests could be different in each context.  For example, when running on the server is static rendering
 * mode (ISR), cookies and query parameters are not available.  In these contexts, the tests passed in as a prop
 * will be the only active tests.  It is the responsibility of the caller to ensure that the tests passed in as a prop
 * are the same as the tests that would be passed in as a cookie or query parameter.
 *
 * @param param0
 * @returns
 */
export default function AbTestProvider({
	staticSearchParams,
	children,
}: PropsWithChildren<AbTestProviderProps>): React.ReactElement {
	const overrideTests = useMemo(() => {
		return new URLSearchParams(decodeURIComponent(staticSearchParams || '')).get('ab')?.split(',') || []
	}, [staticSearchParams])

	// // We only will use feature flags to determine whether or not a test with the same name
	// //	should be active.  Here are the rules:
	// //	- If a test is marked as active but there is a feature flag with the same name and it's false, remove it
	// //	- If a test is marked as active but there is a feature flag with the same name and it's true, keep it
	// //	- If a test is marked as active but there is no feature flag with the same name, keep it
	const features = useFeatureFlags()

	// // This is not typical. We allow stacking of the AbTestProvider in order to allow for a situation that requires
	// //	overriding the active tests.  This is useful for testing purposes but should not be used in production.
	const currentValue = useContext(AbTestContext)

	// Although not recommended, it is possible to active tests by passing in a value to the provider.
	//	This is useful for testing purposes, but also as a way of ensuring that a test is active when server
	//	rendering a page and the feature flag is not available through cookies or query params.
	const [activeTests, setActiveTests] = useState<AbTestList>(constructAbTestList(features, currentValue, overrideTests))

	useEffect(() => {
		const fromCookies = new Cookies(document.cookie).get('ab')?.split(',') || []
		const fromQuery = new URLSearchParams(window.location.search).get('ab')?.split(',') || []
		setActiveTests(constructAbTestList(features, currentValue, fromCookies, fromQuery, overrideTests))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [features, overrideTests])

	// const activeTests = []
	return <AbTestContext.Provider value={activeTests}>{children}</AbTestContext.Provider>
}
