import type { MessageFormatElement } from 'react-intl'
import northAmericaTranslations from './translations/north-america'
import emeaTranslations from './translations/emea'

export function getTranslationsByLocale(
	locale: string,
): Record<string, string> | Record<string, MessageFormatElement[]> {
	// NOTE: This is purposely not using the `import` statement to avoid webpack from bundling all the translations
	//  Also important is that we are using explicit `if` statements to avoid webpack from bundling all the translations
	// DO NOT USE `return northAmericaTranslations[locale]` as it will cause webpack to bundle multi-regional translations

	if (locale === 'en-us') {
		return northAmericaTranslations['en-us']
	}

	if (locale === 'en-ca') {
		return northAmericaTranslations['en-ca']
	}

	if (locale === 'fr-ca') {
		return northAmericaTranslations['fr-ca']
	}

	if (locale === 'en-nl') {
		return emeaTranslations['en-nl']
	}

	return northAmericaTranslations['en-us']
}
