import type { ZodTypeAny, ZodTypeDef } from 'zod'
import { ZodArray, ZodError, ZodNullable, ZodObject, ZodOptional, ZodType } from 'zod'

/**
 * Checks if a given value is an instance of ZodError.
 * @param e - The value to check.
 * @returns True if the value is a ZodError, false otherwise.
 */
export const isZodError = (e: unknown): e is ZodError => e instanceof ZodError

/**
 * Retrieves the keys of a Zod schema.
 * @param schema - The Zod schema.
 * @returns An array of keys.
 * https://github.com/colinhacks/zod/discussions/2134
 */
const zodKeys = <T extends ZodTypeAny>(schema: T): string[] => {
	// make sure schema is not null or undefined
	if (schema === null || schema === undefined) return []
	// check if schema is nullable or optional
	if (schema instanceof ZodNullable || schema instanceof ZodOptional) return zodKeys(schema.unwrap())
	// check if schema is an array
	if (schema instanceof ZodArray) return zodKeys(schema.element)
	// check if schema is an object
	if (schema instanceof ZodObject) {
		// get key/value pairs from schema
		const entries = Object.entries(schema.shape)
		// loop through key/value pairs
		return entries.flatMap(([key, value]) => {
			// get nested keys
			const nested = value instanceof ZodType ? zodKeys(value).map((subKey) => `${key}.${subKey}`) : []
			// return nested keys
			return nested.length ? nested : key
		})
	}
	// return empty array
	return []
}

/**
 * Parses a value using a Zod model and throws an error if the parsing fails.
 * @param model - The Zod model.
 * @param body - The value to parse.
 * @param error - An optional error to throw if the parsing fails.
 * @returns The parsed value.
 */
export function zodParseHandler<T extends ZodTypeDef, V, I = T>(
	model: ZodType<V, T, I>,
	body: unknown,
	error?: { message?: string },
): V {
	try {
		const data = model.parse(body)
		return data
	} catch (parseError) {
		throw error?.message ? new Error(error.message) : parseError
	}
}

/**
 * Parses a body as JSON using a Zod model and throws an error if the parsing fails.
 * @param model - The Zod model.
 * @param body - The body to parse.
 * @param error - An optional error to throw if the parsing fails.
 * @returns A promise that resolves to the parsed value.
 */
export async function zodParseBodyJson<T extends ZodTypeDef, V, I = T>(
	model: ZodType<V, T, I>,
	body: Body,
	error?: { message?: string },
): Promise<V> {
	const json = await body.json()
	return zodParseHandler(model, json, error)
}

/**
 * Parses form elements using a Zod model and throws an error if the parsing fails.
 * @param model - The Zod model.
 * @param elements - The form elements to parse.
 * @returns The parsed value.
 */
export function zodParseFormElements<T extends ZodTypeDef, V>(
	model: ZodType<V, T>,
	elements: HTMLFormControlsCollection,
): V {
	const keys = zodKeys(model)
	const formObj = Object.entries(elements)
		.filter(([key]) => keys.includes(key))
		.reduce((prev, [key, val]) => ({ ...prev, [key]: val instanceof HTMLInputElement ? val.value : undefined }), {})
	return zodParseHandler(model, formObj)
}

/**
 * Parses URL search params using a Zod model and throws an error if the parsing fails.
 * @param model - The Zod model.
 * @param urlSearchParams - The URL search params to parse.
 * @returns The parsed value.
 */
export function zodParseURLSearchParams<T extends ZodTypeDef, V>(
	model: ZodType<V, T>,
	urlSearchParams: URLSearchParams,
): V {
	const keys = zodKeys(model)
	// TODO: consider non ZodObject types
	const urlParamObj = keys.reduce((prev, cur) => ({ ...prev, [cur]: urlSearchParams.get(cur) ?? undefined }), {})
	return zodParseHandler(model, urlParamObj)
}
