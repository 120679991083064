import type { ScriptProps } from 'next/script'
import Script from 'next/script'

type GoogleAnalyticsProps = {
	gaMeasurementId?: string
	strategy?: ScriptProps['strategy']
}

export function GoogleAnalytics({
	gaMeasurementId,
	strategy = 'afterInteractive',
}: GoogleAnalyticsProps): JSX.Element | null {
	if (!gaMeasurementId) {
		return null
	}
	return (
		<>
			<Script src={`https://www.googletagmanager.com/gtag/js?id=${gaMeasurementId}`} strategy={strategy} />
			<Script id="sb-google-analytics">
				{/* eslint-disable-next-line local-rules/disallow-untranslated-literals-in-jsx */}
				{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${gaMeasurementId}', {
            'web_platform': 'seabiscuit'
          });
        `}
			</Script>
		</>
	)
}

export function event(action: string, eventOptions: object): void {
	if (window.gtag) {
		window.gtag('event', action, eventOptions)
	}
}
