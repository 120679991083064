'use client'

import type { ReadonlyURLSearchParams } from 'next/navigation'
import { useParams, usePathname, useRouter } from 'next/navigation'
import querystring, { type ParsedUrlQuery } from 'querystring'
import { useEffect, useMemo, useRef } from 'react'

import { type NavigateOptions } from 'next/dist/shared/lib/app-router-context.shared-runtime'
import { joinPathAndSearchParams, recordSetToUrlSearchParams, removeTrailingSlash } from '~/lib/utils'
import { canonicalizeUrl } from '~/lib/client-only/canonicals'

interface TransitionOptions {
	shallow?: boolean
	locale?: string | false
	scroll?: boolean
	unstable_skipClientCache?: boolean
}

export interface NavigationObject {
	/// NEXTJS ROUTER PROPERTIES

	/**
	 * This will include the path and the query string (if it exists).  Note that this will match
	 * the URL as it is being processed internally by NextJS.  So, for rewritten URLs this should be
	 * the rewritten URL.  For example, if the URL bar path is /en-us/p/456.html?foo=bar and the rewritten
	 * URL is /en-us/product/foo=bar/style/456 then this will be /en-us/product/foo=bar/style/456.  For
	 * the rewritten version, use canonicalPath.
	 *
	 * Backward compatibility with asPath as described here https://nextjs.org/docs/pages/api-reference/functions/use-router#router-object
	 *
	 * **This will only contain search params (`?foo=bar`) if the `readonlySearchParams` property is provided.**
	 */
	asPath: string

	// NEW ROUTER PROPERTIES

	/**
	 * This is the path that can be used safely in the canonical meta parameter. It is an indication
	 * of the URL that we would want search engines to use.
	 *
	 * **This will only contain search params (`?foo=bar`) if the `readonlySearchParams` property is provided.**
	 */
	canonicalPath: string

	/** This is true if the URL bar is showing a different URL than the URL being processed */
	isRewrittenUrl: boolean

	/** This contains an object with a key for each dynamic segment variable.
	 *  For example, if the route is /route/[id]/[name], this will be { id: '123', name: 'foo' }
	 * 	If the route is /route/[id]/[name]?id=456, this will be { id: '456', name: 'foo' }
	 *  If the route is not dynamic then this will be an empty object
	 */
	dynamicSegments: URLSearchParams

	/**
	 * **You must provide the `readonlySearchParams` property to `useNavigation()` retrieved via the
	 * `useSearchParams()` navigation hook.**
	 *
	 * This is the query string as a ParsedUrlQuery which is a PJO.
	 */
	queryAsParsedUrlQuery: ParsedUrlQuery

	/**
	 * **You must provide the `readonlySearchParams` property to `useNavigation()` retrieved via the
	 * `useSearchParams()` navigation hook.**
	 *
	 * This is the query string as a URLSearchParams object.
	 */
	queryAsUrlSearchParams: URLSearchParams

	/** ROUTER METHODS (exactly the same as useRouter.[push/replace/refresh]) */

	/**
	 * This will push a new URL onto the history stack.  It handles switching between page and app router.
	 */
	push: (url: string, options?: NavigateOptions) => void

	/**
	 * This will replace the URL on the history stack.  It handles switching between page and app router.
	 */
	replace: (url: string, options?: TransitionOptions) => void

	/**
	 * This will reload the current router
	 */
	refresh: () => void
}

export interface NavigationHookOptions {
	readonlySearchParams?: ReadonlyURLSearchParams
	/**
	 * This is a callback that will be called when the route changes.
	 */
	onRouteChange?: (asPath: string, previousPath: string) => void
}

/**
 * This hook is meant to act as a drop-in replacement for useRouter on both app and pages router.
 * Unfortunately, they act pretty differently.  Here are some of the main differences:
 * - This hook unifies the behavior between the app router and pages router in Next.js.
 * - Provides additional properties and methods to handle dynamic segments and query parameters.
 *
 * @returns {NavigationObject} The navigation object with various properties and methods for routing.
 *
 * @example
 * // Using the useNavigation hook in a component
 * import { useSearchParams } from 'next/navigation';
 * import { useCallback } from 'react';
 * import useNavigation from '~/hooks/useNavigation';
 *
 * const MyComponent = () => {
 *   const readonlySearchParams = useSearchParams();
 *
 *   const handleRouteChange = useCallback((newPath, oldPath) => {
 *     console.log(`Route changed from ${oldPath} to ${newPath}`);
 *   }, []);
 *
 *   const {
 *     asPath,
 *     canonicalPath,
 *     isRewrittenUrl,
 *     dynamicSegments,
 *     queryAsParsedUrlQuery,
 *     queryAsUrlSearchParams,
 *     push,
 *     replace,
 *     refresh
 *   } = useNavigation({
 *     readonlySearchParams,
 *     onRouteChange: handleRouteChange
 *   });
 *
 *   return (
 *     <div>
 *       <p>Current Path: {asPath}</p>
 *       <p>Canonical Path: {canonicalPath}</p>
 *       <p>Is Rewritten: {isRewrittenUrl ? 'Yes' : 'No'}</p>
 *       <button onClick={() => push('/new-path')}>Go to New Path</button>
 *       <button onClick={() => replace('/replace-path')}>Replace with New Path</button>
 *       <button onClick={() => refresh()}>Refresh</button>
 *     </div>
 *   );
 * };
 *
 * @example
 * // Handling dynamic segments and query parameters
 * import useNavigation from '~/hooks/useNavigation';
 *
 * const DynamicRouteComponent = () => {
 *   const { dynamicSegments, queryAsParsedUrlQuery } = useNavigation();
 *
 *   // Assuming the route is /product/[id]?filter=latest
 *   console.log(dynamicSegments.get('id')); // Outputs the dynamic segment value, e.g., '123'
 *   console.log(queryAsParsedUrlQuery.filter); // Outputs 'latest'
 *
 *   return (
 *     <div>
 *       <p>Product ID: {dynamicSegments.get('id')}</p>
 *       <p>Filter: {queryAsParsedUrlQuery.filter}</p>
 *     </div>
 *   );
 * };
 */
export default function useNavigation(props?: NavigationHookOptions): NavigationObject {
	const pathname = usePathname()
	const appRouter = useRouter()
	const appRouterDynamicSegments = useParams()
	const readonlySearchParams = useMemo(
		() => props?.readonlySearchParams ?? new URLSearchParams(),
		[props?.readonlySearchParams],
	)
	const controlledPath = useRef<string | null>(null)

	// This block will prepare the query and dynamic segment parameters.  Note that this will handle differentiate
	//	the dynamic segments from query parameters.  It will also identify the searchParams segment and merge it
	//	with the query parameters (UA-specific)
	const [queryAsUrlSearchParams, queryAsParsedUrlQuery, dynamicSegments] = useMemo(() => {
		let parsedQuery: ParsedUrlQuery

		// The query property is meant to be a backward compatibility shim for the queryAsParsedUrlQuery property.  Note
		//	that it can never be undefined or null so we need to default it to an empty object.  There are a couple of differences
		//	as well.  We will remove the dynamic segments from the query object and put them in the dynamicSegments object.
		//	Also, we will add the searchParams to the query object.  This last is a special UA-only feature that allows us to
		//	embed query parameters in the path.  This is used for product list and product detail pages so that we can cache
		//	them in the CDN.
		if (readonlySearchParams) {
			parsedQuery = querystring.parse(readonlySearchParams.toString())
		} else {
			parsedQuery = {}
		}

		// Now convert to a URLSearchParams object for convenience
		const searchParamsOb = parsedQuery ? new URLSearchParams(querystring.stringify(parsedQuery)) : new URLSearchParams()
		const segments = appRouterDynamicSegments
			? recordSetToUrlSearchParams(appRouterDynamicSegments)
			: new URLSearchParams()
		return [searchParamsOb, { ...parsedQuery }, segments]

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [readonlySearchParams, appRouterDynamicSegments])

	// For asPath, we want to use the pages router if available.  Otherwise, we want to use the app router.  For the
	//  app router, we need to ensure that we are referring to the equivalent which, I believe, is coming from the
	//  usePathname() hook.
	const asPath = useMemo(
		() => joinPathAndSearchParams(removeTrailingSlash(pathname ?? '', true), readonlySearchParams || undefined),
		[pathname, readonlySearchParams],
	)

	// If there is no previous path, then just use the current path.  This will happen on the first render.
	//  After that we will only update the controlled path if the asPath changes and only after we've notified
	//  any handlers.
	if (!controlledPath.current) {
		controlledPath.current = asPath
	}

	// We are introducing a new property for canonical URLs.  This is meant to help us with SEO.  The idea is that
	//  we will be able to rewrite URLs to make them more SEO friendly.  For example, we have cases where we rewrite
	//  /en-us/p/456.html?foo=bar to /en-us/product/foo=bar/style/456.  But for the purposes of rendering we want to ensure
	//  that we are referring to the originating URL.  This is possible when rendering this hook on the client as we can use
	//  the browser bar's URL.  But on the server, there's no way to know what the originating URL is.  For that reason,
	//  we use the canonicalizeUrl function to handle this per page type.
	const canonicalPath = useMemo(
		() => canonicalizeUrl(asPath, dynamicSegments, readonlySearchParams ?? new URLSearchParams()),
		[asPath, dynamicSegments, readonlySearchParams],
	)

	/**
	 * Try to determine if the URL was rewritten by comparing the URL bar's URL to the URL that we are processing.
	 *  This is only possible on the client.  If we are on the server, we will assume that the URL was rewritten
	 *  if the asPath and the canonicalPath are different.
	 */
	const isRewrittenUrl = useMemo(() => {
		const clientUrl =
			typeof window !== 'undefined' ? new URL(window.location.toString()) : new URL(canonicalPath, 'https://n')
		return clientUrl?.pathname !== asPath || clientUrl?.search.substring(1) !== queryAsUrlSearchParams?.toString()
	}, [asPath, canonicalPath, queryAsUrlSearchParams])

	/**
	 * This is used to call the given routeChangeStart event when the
	 * route changes.  This is done by comparing the current path to
	 * previous path.  Note this will not be called initially when the
	 * currentPath is not set.  It will only be called on subsequent
	 * path changes.
	 */
	useEffect(() => {
		if (controlledPath.current && controlledPath.current !== asPath) {
			// If there is a handler given, then call it with the new asPath
			props?.onRouteChange?.(asPath, controlledPath.current)
			controlledPath.current = asPath
		}
	}, [asPath, props])

	const { push, replace, refresh } = appRouter

	return {
		asPath,
		dynamicSegments,
		queryAsParsedUrlQuery,
		queryAsUrlSearchParams,
		canonicalPath,
		isRewrittenUrl,
		push,
		replace,
		refresh,
	}
}
