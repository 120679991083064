/* eslint-disable global-require */
/* eslint-disable no-console */
import { ApolloLink } from '@apollo/client'
import { print } from 'graphql/language/printer'
import logger from '~/lib/logger'

export interface QueryRecorderParams {
	query: string
	input: string
	operationName: string
	result: string
}

interface RequestRecord {
	operation: string
	type: string
	input?: Record<string, unknown>
	query?: string
	data: Record<string, unknown> | string
}

/**
 * These parameters indicate how to store the data on the drive and how to
 * make server side requests to record data (the endpoint to use)
 */
const recordingParams = {
	path: process.env.NEXT_PUBLIC_RECORDER_PATH || './_recordings',
	api: process.env.NEXT_PUBLIC_RECORDER_ENDPOINT || '/api/meta/recorder/',
}

let fs
let path
/**
 * This will take information about a query and write it to the appropriate location
 * on disk based on information like operation name and the global value for the path.
 *
 * Note that each time this is called, it will read whatever data is already present, parse
 * it into a JSON object, append the new data and then write it back.  This can be slow and
 * is not intended to be used on an ongoing basis.  Rather, you can turn on recording by setting
 * the appropriate environment variable, navigating around the site to collect the data then
 * turn it off.
 * @param record The information about the query
 */
export const writeGraphQlQuery = (record: QueryRecorderParams) => {
	if (typeof window === 'undefined') {
		fs = fs || require('fs')
		path = path || require('path')
		if (fs) {
			if (!fs.existsSync(recordingParams.path)) {
				fs.mkdirSync(recordingParams.path, { recursive: true })
			}

			const rec = {
				type: 'graphql',
				operation: record.operationName,
				input: JSON.parse(record.input),
				query: record.query,
				data: JSON.parse(record.result),
			} satisfies RequestRecord

			const pathToFile = path.join(recordingParams.path, `${record.operationName}.json`)
			let existingRecords: RequestRecord[] = []
			if (fs.existsSync(pathToFile)) {
				const raw = fs.readFileSync(pathToFile)
				try {
					existingRecords = JSON.parse(raw)
				} catch (err) {
					logger.warn(`Unable to parse recorded file ${pathToFile}`)
				}
			}

			// Keep the number of records to a reasonable number to prevent runaway file sizes.
			existingRecords = [...existingRecords.slice(0, 4), rec]

			fs.writeFileSync(pathToFile, `\n${JSON.stringify(existingRecords, undefined, 2)}`)
		}
	} else {
		logger.warn('You cannot save a query from the brower')
	}
}

/**
 * Apollo allows you to inject transformers and handlers into the request pipeline
 * through links.  Links can be passthroughs or terminators.  This is a passthrough
 * which does the work of recording the information whether or not the request is
 * being made on the server or client.  For the client, it makes an API call to the
 * server which then writes the information to a file.  On the server, it writes it
 * directly to the file system.
 * @returns
 */
export const createRecorderApolloLink = () =>
	new ApolloLink((operation, forward) =>
		forward(operation).map((data) => {
			// Called after server responds
			const body = JSON.stringify(data.data, undefined, 2)
			const query = print(operation.query)
			const input = JSON.stringify(operation.variables, undefined, 2)
			if (typeof window !== 'undefined') {
				// Log to console
				console.groupCollapsed('GraphQL')
				console.log(operation.operationName)
				console.log(body)
				console.groupEnd()

				// Log to endpoint (if one is given)
				if (recordingParams.api) {
					fetch(recordingParams.api, {
						method: 'POST',
						body: JSON.stringify({
							operationName: operation.operationName,
							input,
							query,
							result: body,
						}),
					}).catch((err) => {
						logger.warn(`GraphQL query could not be sent to recording endpoint: ${(err as Error).message}`)
					})
				}
			} else {
				writeGraphQlQuery({
					operationName: operation.operationName,
					query,
					input,
					result: body,
				})
			}

			return data
		}),
	)
