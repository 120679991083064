'use client'

import {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
	type Dispatch,
	type PropsWithChildren,
	type SetStateAction,
} from 'react'
import type { CommerceConfiguration, FeatureFlags } from '~/graphql/generated/uacapi/type-document-node'
import type { FlagsmithFeatureFlags } from '~/lib/types/flagsmith-features.interface'
import { getFeatureFlagOverrideList, getFrontendConfigurationWithOverrides } from '~/lib/get-feature-flags'
import type { ConfigFeatureFlags } from '~/lib/types/config-features.interface'

export type FeatureFlagsConfiguration = FeatureFlags & Partial<FlagsmithFeatureFlags> & Partial<ConfigFeatureFlags>

export type CommerceConfigurationExtendedFlagsType = Omit<CommerceConfiguration, 'featureFlags'> & {
	featureFlags?: FeatureFlagsConfiguration
}

export interface FrontendConfiguration {
	locale: string
	commerceConfig?: CommerceConfigurationExtendedFlagsType
	featureFlagOverrides: Record<string, boolean | undefined>
}

export const FrontendConfigurationContext = createContext<FrontendConfiguration | undefined>(undefined)

export type FrontendConfigurationProviderProps = PropsWithChildren<{
	config: FrontendConfiguration
}>

export function useFrontendConfiguration(): FrontendConfiguration {
	const context = useContext(FrontendConfigurationContext)

	if (!context) {
		throw new Error('useFrontendConfiguration must be used within a CommerceConfigurationProvider')
	}

	return context
}

export function useFeatureFlags(): Partial<FeatureFlagsConfiguration> {
	const config = useFrontendConfiguration()
	return config.commerceConfig?.featureFlags || {}
}

interface FrontendConfigurationDispatch {
	setFeatureFlagOverrides: Dispatch<SetStateAction<FrontendConfiguration['featureFlagOverrides']>>
}

/**
 * This is a separate context for "updating the state" defined inside FrontendConfigurationContext
 * The purpose for the separation is twofold:
 * 1. Prevention of unnecessary re-renders for components that only care about the "update logic" while others only care about the "state"
 * 2. Separation of concerns by separating the "state" from the "state update" logic
 *
 * Recommendations on this approach can be found here:
 * 1. https://react.dev/learn/scaling-up-with-reducer-and-context
 * 2. https://kentcdodds.com/blog/how-to-optimize-your-context-value
 */
const FrontendConfigurationDispatchContext = createContext<FrontendConfigurationDispatch | undefined>(undefined)

export function useFrontendConfigurationDispatch(): FrontendConfigurationDispatch {
	const context = useContext(FrontendConfigurationDispatchContext)

	if (!context) {
		throw new Error('useFrontendConfigurationDispatch must be used within a CommerceConfigurationProvider')
	}

	return context
}

export const FrontendConfigurationProvider: React.FC<FrontendConfigurationProviderProps> = ({ children, config }) => {
	const [featureFlagOverrides, setFeatureFlagOverrides] = useState<FrontendConfiguration['featureFlagOverrides']>({})

	useEffect(() => {
		setFeatureFlagOverrides(getFeatureFlagOverrideList())
	}, [])

	const configWithOverrides = useMemo(() => {
		return getFrontendConfigurationWithOverrides(config, featureFlagOverrides)
	}, [config, featureFlagOverrides])

	const dispatch = useMemo(
		() => ({
			setFeatureFlagOverrides,
		}),
		[],
	)

	return (
		<FrontendConfigurationContext.Provider value={configWithOverrides}>
			<FrontendConfigurationDispatchContext.Provider value={dispatch}>
				{children}
			</FrontendConfigurationDispatchContext.Provider>
		</FrontendConfigurationContext.Provider>
	)
}
