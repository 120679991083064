import type { Maybe } from '~/graphql/generated/uacapi/type-document-node'

export const groupBy = <T, K extends string | number | symbol>(
	array: T[],
	key: (i: T) => K,
	initialValue: Record<K, T[]> = {} as Record<K, T[]>,
) =>
	array
		.filter((item) => !!key(item))
		.reduce((prev, cur) => ({ ...prev, [key(cur)]: [...(prev[key(cur)] ?? []), cur] }), initialValue)

export const nonNullMaybe = <T>(array: (Maybe<T> | undefined)[] | undefined): T[] =>
	(array ?? []).filter((item): item is T => !!item)

export const range = (length: number): number[] => Array.from({ length }, (_, i) => i)

export const uniqBy = <T, K extends string | number | symbol>(array: T[], key: (i: T) => K) =>
	array.reduce((prev, cur) => (prev.some((i) => key(i) === key(cur)) ? prev : [...prev, cur]), [] as T[])

export const unique = <T>(array: T[]): T[] => array.filter((value, index, values) => values.indexOf(value) === index)

export const sum = (array: number[]): number => array.reduce((partialSum, a) => partialSum + a, 0)

export const mergeMaybe = <T, K extends string | number | symbol>(
	key: (i: T) => K,
	left?: Maybe<T[]>,
	right?: Maybe<T[]>,
): T[] => uniqBy([...nonNullMaybe(left ?? []), ...nonNullMaybe(right ?? [])], key)

export const partition = <T>(array: T[], criteria: (x: T) => boolean) => [
	array.filter((item) => criteria(item)),
	array.filter((item) => !criteria(item)),
]
