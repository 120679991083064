/**
 * These are the Scene7 Recipes extracted from CoreMedia.
 * These are the settings used to grab an image from Scene7 and compiled in the MediaContent.tsx file.
 * The recipeConfiguration of 'recipe-banner-5050' is coming in either via the module-settings-map.ts OR
 * through the mobile media query from CoreMedia.
 *
 * Currently - you can amend these resolutions and it will change what size image is called from Scene7.
 * This file was a direct copy from the CoreMedia repo json file and formated as an object.
 */

/**
 *
 * @param image - S7 Asset ID: `"SS21_GLF_Masters_Site_7_5"`
 * @param width - width: number
 * @param heght - height: number
 * @param qlt - quality: default `85`
 * @returns
 */
export const scene7RecipeBuilder = (image, width: number, height: number, qlt = 85) => {
	const url = 'https://underarmour.scene7.com/is/image/Underarmour'
	return `${url}/${image}?qlt=${qlt}&wid=${width}&hei=${height}`
}

export const productTileImgBuilder = (image: string) =>
	`https://underarmour.scene7.com/is/image/Underarmour/${image}?rp=standard-0pad%7CpdpMainDesktop&scl=1&fmt=jpg&qlt=85&resMode=sharp2&cache=on%2Con&bgc=F0F0F0&wid=566&hei=708&size=566%2C708`

export interface RecipeParams {
	wid: number
	hei?: number
	fmt?: string
	qlt?: number
	op_sharpen?: number
}

export interface ImageRecipes {
	default: RecipeParams
	'recipe-banner-5050': RecipeParams
	'recipe-banner-single-text': RecipeParams
	'recipe-bucket-product': RecipeParams
	'recipe-bucket-router': RecipeParams
	'recipe-bucket-square': RecipeParams
	'recipe-bucket-scattered-primary': RecipeParams
	'recipe-bucket-scattered-secondary': RecipeParams
	'recipe-bucket-3up': RecipeParams
	'recipe-jigsaw-primary': RecipeParams
	'recipe-jigsaw-secondary': RecipeParams
	'recipe-storytelling-3up': RecipeParams
	'recipe-storytelling-image': RecipeParams
	'recipe-hero-3section-primary': RecipeParams
	'recipe-hero-3section-secondary': RecipeParams
	'recipe-hero-singleimage': RecipeParams
	'recipe-hero-mb-wide': RecipeParams
	'recipe-hero-split-primary': RecipeParams
	'recipe-hero-split-secondary': RecipeParams
	'recipe-hero-split-extended-primary': RecipeParams
	'recipe-hero-split-extended-secondary': RecipeParams
	'recipe-hero-split-mb': RecipeParams
	'recipe-plp-hero': RecipeParams
	'recipe-single-in-grid': RecipeParams
	'recipe-double-in-grid': RecipeParams
	'recipe-double-in-grid-seo': RecipeParams
	'recipe-triple-in-grid': RecipeParams
	'recipe-triple-in-grid-tall': RecipeParams
	'recipe-sig': RecipeParams
	'recipe-dig': RecipeParams
	'recipe-tig': RecipeParams
	'recipe-storytelling-full': RecipeParams
	'recipe-storytelling-split': RecipeParams
	'recipe-storytelling-organic-primary': RecipeParams
	'recipe-storytelling-organic-secondary': RecipeParams
	'recipe-email-capture': RecipeParams
	'recipe-collection-logo': RecipeParams
	'recipe-collection-logo-sm': RecipeParams
	recipe_quiz_option: RecipeParams
}

export const scene7Recipes: ImageRecipes = {
	default: {
		wid: 1440,
		qlt: 85,
		fmt: 'jpg',
	},
	'recipe-banner-5050': {
		hei: 720,
		wid: 720,
		fmt: 'jpg',
	},
	'recipe-banner-single-text': {
		hei: 720,
		wid: 1440,
		op_sharpen: 1,
		fmt: 'jpg',
	},
	'recipe-bucket-product': {
		hei: 470,
		wid: 276,
		fmt: 'jpg',
	},
	'recipe-bucket-router': {
		hei: 428,
		wid: 684,
		fmt: 'jpg',
	},
	'recipe-bucket-square': {
		hei: 374,
		wid: 374,
		fmt: 'jpg',
	},
	'recipe-bucket-scattered-primary': {
		wid: 448,
		fmt: 'jpg',
	},
	'recipe-bucket-scattered-secondary': {
		wid: 330,
		fmt: 'jpg',
	},
	'recipe-bucket-3up': {
		wid: 320,
		fmt: 'jpg',
	},
	'recipe-jigsaw-primary': {
		hei: 720,
		wid: 944,
		fmt: 'jpg',
	},
	'recipe-jigsaw-secondary': {
		hei: 354,
		wid: 496,
		fmt: 'jpg',
	},
	'recipe-storytelling-3up': {
		hei: 288,
		wid: 288,
		fmt: 'jpg',
	},
	'recipe-storytelling-image': {
		hei: 480,
		wid: 1440,
		op_sharpen: 1,
		fmt: 'jpg',
	},
	'recipe-hero-3section-primary': {
		hei: 336,
		wid: 472,
		fmt: 'jpg',
	},
	'recipe-hero-3section-secondary': {
		wid: 944,
		fmt: 'jpg',
	},
	'recipe-hero-singleimage': {
		wid: 1440,
		op_sharpen: 1,
		fmt: 'jpg',
	},
	'recipe-hero-mb-wide': {
		wid: 768,
		op_sharpen: 1,
	},
	'recipe-hero-split-primary': {
		hei: 672,
		wid: 944,
		fmt: 'jpg',
	},
	'recipe-hero-split-secondary': {
		hei: 620,
		wid: 496,
		fmt: 'jpg',
	},
	'recipe-hero-split-extended-primary': {
		hei: 720,
		wid: 1080,
		op_sharpen: 1,
		fmt: 'jpg',
	},
	'recipe-hero-split-extended-secondary': {
		hei: 720,
		wid: 360,
		fmt: 'jpg',
	},
	'recipe-hero-split-mb': {
		hei: 615,
		wid: 384,
		op_sharpen: 1,
		fmt: 'jpg',
	},
	'recipe-plp-hero': {
		hei: 480,
		wid: 1440,
		op_sharpen: 1,
		fmt: 'jpg',
	},
	'recipe-single-in-grid': {
		hei: 370,
		wid: 296,
		op_sharpen: 1,
		fmt: 'jpg',
	},
	'recipe-double-in-grid': {
		wid: 608,
		op_sharpen: 1,
		fmt: 'jpg',
	},
	'recipe-double-in-grid-seo': {
		wid: 608,
		op_sharpen: 1,
		fmt: 'jpg',
	},
	'recipe-triple-in-grid': {
		hei: 307,
		wid: 920,
		op_sharpen: 1,
		fmt: 'jpg',
	},
	'recipe-triple-in-grid-tall': {
		hei: 572,
		wid: 920,
		op_sharpen: 1,
		fmt: 'jpg',
	},
	'recipe-sig': {
		wid: 296,
		hei: 370,
		op_sharpen: 1,
		fmt: 'jpg',
	},
	'recipe-dig': {
		wid: 920,
		op_sharpen: 1,
		fmt: 'jpg',
	},
	'recipe-tig': {
		wid: 920,
		op_sharpen: 1,
		fmt: 'jpg',
	},
	'recipe-storytelling-full': {
		hei: 720,
		wid: 1440,
		op_sharpen: 1,
		fmt: 'jpg',
	},
	'recipe-storytelling-split': {
		hei: 452,
		wid: 566,
		fmt: 'jpg',
	},
	'recipe-storytelling-organic-primary': {
		hei: 272,
		wid: 272,
		fmt: 'jpg',
	},
	'recipe-storytelling-organic-secondary': {
		hei: 560,
		wid: 448,
		fmt: 'jpg',
	},
	'recipe-email-capture': {
		wid: 708,
		fmt: 'jpg',
	},
	'recipe-collection-logo': {
		wid: 288,
		fmt: 'png-alpha',
	},
	'recipe-collection-logo-sm': {
		wid: 64,
		hei: 64,
		fmt: 'png-alpha',
	},
	recipe_quiz_option: {
		wid: 328,
		hei: 192,
	},
}

export const getScene7Recipe = (recipeKey: string): RecipeParams | null => scene7Recipes[recipeKey]
// export const getScene7Recipe = (recipeKey: keyof ImageRecipes): RecipeParams | undefined => scene7Recipes[recipeKey]
