import type { ApolloClient, ApolloQueryResult } from '@apollo/client'
import { CUSTOMER_BOPIS_CONTACT_STORAGE_KEY, DEFAULT_LINE_ITEM_QUANTITY_LIMIT } from 'lib/constants'
import type {
	ClientInitialLookupVariants,
	ClientProductData,
	ClientProductDetail,
	ClientProductDetailVariants,
	ClientProductList,
	ClientProductTile,
	ClientProductTileImage,
	ClientProductVariantDetail,
	Gender,
	MergedVariantProduct,
	ExtendedSizeVariation,
	ServerProductsList,
	SplitSizeType,
	VariantLookup,
	ExtendedSizeOption,
} from 'lib/types/product.interface'
import type { ParsedUrlQuery } from 'querystring'
import type { Optional } from 'types/strict-null-helpers'
import { ensureArray, ensureNonNullishArray, ensureNumber, ensureString } from 'types/strict-null-helpers'
import type {
	AlternativeProductIdInput,
	ColorVariation,
	ColorVariationAssetDetail,
	GetProductTileByStyleIdQuery,
	GetProductDetailsByStyleIdQuery,
	GetProductMetadataByStyleIdQuery,
	GetVariantAvailabilityByUpcQuery,
	GetVariantsByStyleIdQuery,
	MasterProduct,
	MasterProductSearchHit,
	Maybe,
	OisProductItem,
	PricesRollup,
	ProductGridSearchFastQuery,
	ProductGridSearchFastQueryVariables,
	SearchSortingOption,
	ShopTheLookColor,
	ShopTheLookImage,
	ShopTheLookOutfit,
	SizeOption,
	SizePreferenceOption,
	SizeVariation,
	SlicingGroupSearchHit,
	Variant,
	VariantProduct,
	VariationSize,
	GetBriefProductDataByAnyIdQuery,
	SearchRefinementAttributeValue,
	SearchRefinementAttribute,
} from '~/graphql/generated/uacapi/type-document-node'
import {
	ExclusiveType,
	GetProductByStyleIdDocument,
	GetProductDetailsByStyleIdDocument,
	GetProductMetadataByStyleIdDocument,
	GetProductUrlByStyleIdDocument,
	GetProductUrlByUpcDocument,
	GetProductTileByStyleIdDocument,
	GetProductsUrlByStyleIdDocument,
	GetSortingOptionsDocument,
	GetVariantAvailabilityByUpcDocument,
	GetVariantsByStyleIdDocument,
	GiftCardType,
	HitType,
	LimitQuantity,
	ProductExperienceType,
	ProductGridSearchFastDocument,
	GetProductSuggestionsDocument,
	RecommendationInputIdType,
	GetBriefProductDataByAnyIdDocument,
	GetProductTilesByProductIDsDocument,
} from '~/graphql/generated/uacapi/type-document-node'
import { range } from '~/lib/arrays'
import { getPublicConfig } from '~/lib/client-server/config'
import { pick } from '~/lib/objects'
import { productTileImgBuilder } from '~/lib/scene7-recipes'
import type { Cart, CartProduct } from '~/lib/types/cart.interface'
import { isVariantProduct, isVariantSize } from '~/types/type-guards'
import { getDomainFromLocale } from './i18n/locale'
import { getLocaleUrl } from './i18n/urls'
import type { SizeMapTypes } from './size-like-mine'
import { sortImages } from './size-like-mine'
import {
	combineParamsAndMergeArrays,
	extractSearchParamsFromDynamicParameters,
	firstStringValue,
	isParsedUrlQuery,
	maybeObject,
	parsedUrlQueryToUrlSearchParams,
	updateUrlParams,
} from './utils'
import type { useFormatMessage } from '~/components/hooks/useFormatMessage'

import { createClientLogger } from '~/lib/logger'
import type { AggregateRatings, Review } from '~/lib/types/ratings.interface'
import type { UserProfileInfo } from '~/lib/client-only/auth/types'

const logger = createClientLogger('products')

export const PRODUCTS_PER_PAGE = LimitQuantity.TWENTY_FOUR
export const NUMBER_PRODUCTS_PER_PAGE = 24

export const PRODUCT_FALLBACK_IMAGE = '/images/product/product-image-unavailable.jpeg'
export const PRODUCT_TILE_LOADING_IMAGE = '/images/product/product-image-loading.svg'

export const BLUR_DATA_URL =
	'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgAAAABACAQAAADGtgc0AAAApUlEQVR42u3UQQEAAAQEMNe/rAaU8LOFWHoKeCoCAAEAAgAEAAgAEAAgAEAAgAAAAQACAAQACAAQACAAQACAAAABAAIABAAIABAAIABAAIAAAAEAAgAEAAgAEAAgAEAAgAAAAQACAAQACAAEIAAQACAAQACAAAABAAIABAAIABAAIABAAIAAAAEAAgAEAAgAEAAgAEAAgAAAAQACAAQACAAQAHBnAbRlfAGw96g0AAAAAElFTkSuQmCC'

export const EMPTY_PRICE_ROLLUP: PricesRollup = {
	list: { min: 0, max: 0 },
	sale: { min: 0, max: 0 },
}

/** Contains the query parameters needed to produce high resolution images for the PDP */
export const highResolutionParameters = {
	Accessories: { scl: '0.85', size: '1500,1500' },
	Apparel: { scl: '0.72', size: '1500,1500' },
	Footwear: { scl: '0.5', size: '850,850' },
} as const

type SearchHit = MasterProductSearchHit | SlicingGroupSearchHit

export const colorWithImage = (colors: Optional<ColorVariation[]>) => colors?.find((c) => !!c?.assets?.images?.[0]?.url)

export const getProductTileImage = (
	colors: Optional<ColorVariation[]>,
	fallbackAlt: string,
): ClientProductTileImage => {
	const color = colorWithImage(colors)
	return color?.assets?.images?.[0]?.url
		? {
				assetName: color?.assets?.images?.[0]?.assetName,
				url: color?.assets?.images?.[0]?.url,
				alt: color?.assets?.alt || '',
				urlHov: color?.assets?.images?.[1]?.url || color?.assets?.images?.[0].url,
		  }
		: { url: PRODUCT_FALLBACK_IMAGE, alt: fallbackAlt, urlHov: PRODUCT_FALLBACK_IMAGE }
}

export interface SearchProductsQuery extends ParsedUrlQuery {
	locale?: string
	cid?: string[]
	srule?: string
	q?: string
	routeParams?: string[]
	pmax?: string
	pmin?: string
	start?: string
	prefn1?: string
	prefv1?: string
	prefn2?: string
	prefv2?: string
	viewPreference?: string
}

/**
 * Converts a URLSearchParams object to a SearchProductsQuery object and uses reasonable defaults
 * @param query
 * @returns
 */
export function urlSearchParamsToSearchProductsQuery(query: URLSearchParams): SearchProductsQuery {
	return {
		...maybeObject('cid', query.has('cid') ? query.getAll('cid') : undefined),
		...maybeObject('routeParams', query.has('routeParams') ? query.getAll('routeParams') : undefined),
		...maybeObject('locale', query.get('locale')),
		...maybeObject('srule', query.get('srule')),
		...maybeObject('q', query.get('q')),
		...maybeObject('pmax', query.get('pmax')),
		...maybeObject('pmin', query.get('pmin')),
		...maybeObject('start', query.get('start')),
		...maybeObject('prefn1', query.get('prefn1')),
		...maybeObject('prefv1', query.get('prefv1')),
		...maybeObject('prefn2', query.get('prefn2')),
		...maybeObject('prefv2', query.get('prefv2')),
		...maybeObject('prefn3', query.get('prefn3')),
		...maybeObject('prefv3', query.get('prefv3')),
		...maybeObject('viewPreference', query.get('viewPreference')),
	}
}

/**
 * This will do the work of putting together a full PLP/Search Query Params object based on a variety of
 * inputs.  It will first extract the search params from the dynamic parameters if they are present in the
 * first parameter.  Then
 * it will combine the params with the overrides.  If there are no overrides, then it will just use the
 * params.  Finally, it will convert the combined params into a SearchProductsQuery object.  Note that it
 * will actually expect that the "dynamic params" are actually embedded inside of the params object under the
 * key "searchParams".  If they are not, then it will assume that dynamic params are not present and will
 * just use the params object as is.  This is true for both the params and the overrides.
 * @param params
 * @param overrides
 * @returns
 */
export function combineSegmentParametersWithQueryParameters(
	params: Record<string, string | string[] | undefined>,
	overrides?: URLSearchParams,
): SearchProductsQuery {
	// First check to see if the params given are actually embedded inside of dynamic params. If they are, then
	//  we need to extract them out and use them as the base params.
	const embeddedDynamicParams = extractSearchParamsFromDynamicParameters(params)

	// Now combine the params and the overrides
	const combinedParams = overrides
		? combineParamsAndMergeArrays(embeddedDynamicParams, overrides)
		: embeddedDynamicParams

	// And convert them to a SearchProductsQuery object
	return urlSearchParamsToSearchProductsQuery(combinedParams)
}

export function excludeSegmentParametersFromQueryParameters(
	params: Record<string, string | string[] | undefined>,
): SearchProductsQuery {
	const originalQueryParams = parsedUrlQueryToUrlSearchParams(params)
	;['cid', 'locale'].forEach((exclusion) => originalQueryParams.delete(exclusion))
	return urlSearchParamsToSearchProductsQuery(originalQueryParams)
}

export interface StaticPropsCategoryListParams extends ParsedUrlQuery {
	searchParams?: string
	cid?: string[]
}

export interface ParsedSku {
	styleCode: string
	materialCode?: string
	variantCode?: string
}

export const EXTENDED_SIZES = {
	S: 'short',
	R: 'regular',
	T: 'tall',
} as const

export type ExtendedSizes = keyof typeof EXTENDED_SIZES

export const SIZE_ID_MAP = {
	Petite: 'S',
	Tall: 'T',
	Regular: 'R',
} as const

/**
 * Extracts the size prefernece options from a ProductVariant list.
 * @param variants The product variants to pull the size options from
 * @returns
 */
export const getSizePreferenceOptions = (variants: ClientProductVariantDetail[]): SizePreferenceOption[] =>
	ensureNonNullishArray(variants.map((v) => v.sizePreferenceOption))

/**
 * Extracts the extended sizes from the list of product variants given.  If no extended
 * sizes were found, then this will return 'R'
 * @param variants
 * @returns
 */
export const getExtendedSizes = (variants: ClientProductVariantDetail[]): string[] => {
	const sizePreferenceOptions = getSizePreferenceOptions(variants)
	const extendedSizes = Object.keys(EXTENDED_SIZES).filter(
		(e) => !!sizePreferenceOptions.find((s) => s.extendedSize === e),
	)
	return extendedSizes.length ? extendedSizes : ['R']
}

/**
 * Gets the variants associated with the extended sizes provided.  Given a
 * set of variants and sizes and an extended size, this will filter the list
 * of sizes to have only those sizes that match the given extended size.  If there
 * are no variants given, the entire size array will be returned.  If the
 * there are no extended sizes in the variant list, then all sizes will be returned
 *
 * @returns
 */
export const getSizesForExtendedSize = ({
	variants,
	sizes,
	extendedSize,
}: {
	variants: Optional<ClientProductVariantDetail[]>
	sizes: SizeVariation[]
	extendedSize: string
}): SizeVariation[] => {
	if (!variants) return sizes

	const hasExtendedSizes = getSizePreferenceOptions(variants).some((p) => Boolean(p.extendedSize))

	if (!hasExtendedSizes) return sizes

	return sizes.filter(({ size }) => {
		const extraSmallPattern = /^([x]+[s])$/i
		const regularSizeGroup = !extraSmallPattern.test(size) ? !size.endsWith('S') && !size.endsWith('T') : true
		const shortSizeGroup = !extraSmallPattern.test(size) ? size.endsWith('S') : false
		const tallSizeGroup = size.endsWith('T')

		switch (extendedSize) {
			case 'S':
				return shortSizeGroup
			case 'T':
				return tallSizeGroup
			default:
				return regularSizeGroup
		}
	})
}

/**
 * Pulls the extended size from the query.  If multiple joined sizes are s
 * specified in the query then null is returned.
 * @param query The query values.
 * @returns
 */
export function getExtendedSizeFromQuery(query?: URLSearchParams | null): string | undefined {
	if (!query) return undefined

	const sizeParam = query.get('extendedSize')
	// sizeParam will have a | if there are two params joined together
	// If this is the case, we don't want to persist either one
	return sizeParam && !sizeParam.includes('|') ? SIZE_ID_MAP[sizeParam] : null
}

/**
 * Returns the default color from the given query object.
 * @param query - The parsed URL query object.
 * @returns The default color code or undefined if not found.
 */
export function getDefaultColorFromQuery(query?: URLSearchParams | null): string | undefined {
	if (!query) return undefined

	// NOTE: This will find any param that has this format `dwvar_[number]_color` and return
	//	that key. This was done because we don't want to limit color selection to specifically this
	//	style.  Instead, we can assume that the color value requested is on this page.
	const colorCodeParamKey = Array.from(query.keys()).find((k) => /dwvar_\d*_color/.test(k))
	if (!colorCodeParamKey) return undefined

	return query.get(colorCodeParamKey) ?? undefined
}

/**
 * Returns the default size from the given query object.
 * @param query
 * @returns
 */
export function getDefaultSizeFromQuery(query: URLSearchParams) {
	// NOTE: This will find any param that has this format `dwvar_[number]_color` and return
	//	that key. This was done because we don't want to limit color selection to specifically this
	//	style.  Instead, we can assume that the color value requested is on this page.
	const paramKey = Array.from(query.keys()).find((k) => /dwvar_\d*_size/.test(k))
	return {
		size: paramKey ? query.get(paramKey) : undefined,
		extendedSize: getExtendedSizeFromQuery(query),
	}
}

/**
 * Determines which color will be shown initially based on the given product information
 * and URL query.
 * @param product
 * @param query
 * @returns
 */
export const getInitialColor = (product: ClientProductDetail, preferredColor?: string): ColorVariation | undefined => {
	// This will return the first color for which the product image matches the color image.
	const defaultColor = product?.colors?.find(
		(c) => getProductTileImage([c], ensureString(product?.name)).url === product?.image?.url,
	)

	// if a preferred color is given then choose that, otherwise choose the default color
	if (preferredColor) {
		return product?.colors?.find((c) => c.color === preferredColor) ?? defaultColor
	}

	// Return the default color if it is orderable. If not, try to find another orderable color.
	if (defaultColor?.orderable) {
		return defaultColor
	}

	// Find the first orderable color if we can't find any other color
	return product?.colors?.find((c) => c.orderable) ?? defaultColor
}

/**
 * Returns the size that will be shown, e.g. on PDP, given a predetermined color.
 */
export const getInitialSize = (
	product: ClientProductDetail | ClientProductData,
	color: string | undefined | null,
	preferredSize: string | undefined | null,
	preferredExtendedSize: string | undefined | null,
): SizeVariation | undefined => {
	const { variants, sizes } = product
	const validSizes = ensureArray<SizeVariation>(sizes)
	const applicableSizes = getSizesForExtendedSize({
		variants,
		sizes: validSizes,
		extendedSize: preferredExtendedSize || 'R',
	})
	const applicableSizeStrings = applicableSizes.map((item) => item.size)

	// Filter to only the variants that match color and are in applicableSizes
	const validVariants = variants?.filter((v) => applicableSizeStrings.includes(v.size) && v.color === color)

	const appropriateVariant = preferredSize
		? // Find the first variant that matches the preferredSize.
		  validVariants?.find((v) => v.size === preferredSize)
		: // Find the first variant that is orderable
		  validVariants?.find((v) => v?.orderable)

	if (appropriateVariant) {
		return applicableSizes.find((s) => s.size === appropriateVariant.size)
	}
	return applicableSizes?.[0]
}

/**
 * Wrapper around a `variantLookup` dictionary that _safely_ retrieves and types a variant value
 */
export function getVariant(
	variantsLookup: VariantLookup,
	color: string | undefined,
	size: string | undefined,
): ClientProductVariantDetail | undefined {
	if (!color || !size) return undefined
	let variant = color in variantsLookup ? variantsLookup?.[color]?.[size] : undefined
	// Fill in data for missing sizes in this color
	if (!variant && color in variantsLookup) {
		const { lineItemQuantityLimit, prices, style, productPromotions } = Object.values(variantsLookup[color])[0]
		variant = {
			id: '',
			color,
			size,
			orderable: false,
			lineItemQuantityLimit,
			prices,
			style,
			productPromotions,
		}
	}
	return variant
}

/**
 * Returns the product's images that should be displayed for a specific size and color.
 */
export const getSpecificImages = ({
	product,
	size = '',
	color,
	fallbackUrl,
	viewType,
}: {
	product: ClientProductDetail
	size?: Optional<string>
	color: Optional<ColorVariation>
	fallbackUrl?: string
	viewType?: string
}): ColorVariationAssetDetail[] => {
	const fallbackViewType = 'image'
	const filterByViewType = (images, viewType) => {
		// to restrict return value not to be an empty array
		if (!viewType && images?.length) {
			return images
		}
		const filteredImageByViewType = images?.filter((image) => [fallbackViewType, viewType].indexOf(image.viewType) > -1)

		if (filteredImageByViewType && filteredImageByViewType.length) {
			return filteredImageByViewType
		}

		// returns a falsy value when filteredImageByViewType is an empty array, so that a fallback asset is returned
		return null
	}

	const fallback: ColorVariationAssetDetail[] = [
		{ viewType: fallbackViewType, url: fallbackUrl ?? PRODUCT_FALLBACK_IMAGE },
	]

	if (!size) return filterByViewType(color?.assets?.images, viewType) || fallback

	const shopTheLookImageNames = product.shopTheLookColors
		?.find((item) => item.color === color?.color)
		?.images?.filter((img) => size === img?.modelSize)
		.map((img) => img?.image)

	const orderedDisplayImages = color?.assets?.images?.slice().sort((imageA, imageB) => {
		if (
			shopTheLookImageNames?.includes(imageA?.assetName as string) &&
			shopTheLookImageNames?.includes(imageB.assetName as string)
		)
			return 0
		if (shopTheLookImageNames?.includes(imageA?.assetName as string)) return -1
		return 1
	})

	return filterByViewType(orderedDisplayImages, viewType) || fallback
}

/**
 * Generates a color query parameter for a product page.
 * @param style The style to generate the param for
 * @returns
 */
export function getColorQueryParam(style: string) {
	return `dwvar_${style}_color`
}

/**
 * Generates a size query parameter for a product page.
 * @param style The style to generate the param for
 * @returns
 */
export function getSizeQueryParam(style: Optional<string>) {
	return `dwvar_${style}_size`
}

/**
 * This will generate a relative url from the given base that includes the correct
 * query parameters to link to a specific color on the product page.  It will include
 * the locale given
 * @param locale The locale to use
 * @param basePath The pase path (e.g. /p/123456.html?myparam=1)
 * @param style The relevant style
 * @param colorCode The color code to use
 * @returns
 */
export function getProductPath(locale: string, basePath: string, style: string, colorCode: string) {
	// NOTE: This will only add the given parameter if it has a different key
	//	than one that is already there.
	return getLocaleUrl(getProductPathWithoutLocale(basePath, style, colorCode), locale)
}

/**
 * This will generate a relative url from the given base that includes the correct
 * query parameters to link to a specific color on the product page but will not prefix
 * with a locale
 * @param basePath The pase path (e.g. /p/123456.html?myparam=1)
 * @param style The relevant style
 * @param colorCode The color code to use
 * @returns
 */
export function getProductPathWithoutLocale(basePath: string, style: string, colorCode: string) {
	// NOTE: This will only add the given parameter if it has a different key
	//	than one that is already there.
	return updateUrlParams(basePath, {
		[getColorQueryParam(style)]: colorCode,
	})
}

/**
 * Get the offset from the query parameters as a number
 * @param query
 * @returns
 */
export function getOffsetFromQuery(query: ParsedUrlQuery | URLSearchParams): number {
	if (isParsedUrlQuery(query)) {
		return query.start ? parseInt(firstStringValue(query.start), 10) : 0
	}
	return query.has('start') ? parseInt(query.get('start') as string, 10) : 0
}

export function getSortRuleFromQuery(query: ParsedUrlQuery | URLSearchParams): string {
	if (isParsedUrlQuery(query)) {
		return firstStringValue(query.start)
	}

	return query.get('srule') || ''
}

/**
 * Gets the query params from the given query object that are relevant to the
 * product grid.
 * @param query
 * @returns
 */
export function getProductGridParamsFromQuery(query: ParsedUrlQuery): SearchProductsQuery {
	return pick<SearchProductsQuery, string>(query, ['q', 'max', 'min', 'start', 'prefn1', 'prefv1', 'prefn2', 'prefv2'])
}

/**
 * Function that will remove supplied product query params passed through the url and return
 * a new url.
 * @param url
 * @param queryDeletions
 */
export function removeSearchParamsFromURL(url: URL, queryDeletions: string[] = []) {
	queryDeletions.forEach((queryParam) => {
		url.searchParams.delete(queryParam)
	})
	return url.href
}

/**
 * Given a color string, checks to see if a given product is the same color.
 * UACAPI and CIO color variants vary slightly, so we must check both the
 * "color" and "colorway" properties on the product variant.
 */
export function findColorMatch(color: string | undefined, product?: Pick<ColorVariation, 'color' | 'colorway'> | null) {
	if (!color || !product) return false
	return color === product?.color || color === product?.colorway
}

const getColorByPreferredSize = (
	colors: ColorVariation[],
	product: ClientProductTile | ClientProductData,
	preferredSize: SizeMapTypes,
	faceOutColors: string[],
): ColorVariation | null =>
	colors.find((color) => {
		const colorWithPreferredSizeAvailable = product.shopTheLookColors?.find((shopTheLookColor) =>
			shopTheLookColor.images?.some(
				(image) =>
					image?.modelSize && image.modelSize === preferredSize && findColorMatch(shopTheLookColor?.color, color),
			),
		)

		return (
			!!color.name &&
			(faceOutColors.length > 0 ? faceOutColors.includes(color.name) : true) &&
			findColorMatch(colorWithPreferredSizeAvailable?.color, color)
		)
	}) ?? null

/**
 * Filter the given colors by team filters
 * @param colors
 * @param teamFilters
 * @returns
 */
export const getColorByTeamFilters = (colors: ColorVariation[], teamFilters: string[]): ColorVariation | null =>
	colors.find((c) => teamFilters.some((tf) => c.team?.includes(tf))) || null

/**
 * This will choose a single color variation based on criteria like sort order, face out colors, etc.
 * @param colors The color variation to choose from
 * @param faceOutColors The names or value of the colors that are meant to be shown based on a refinement.
 * @returns
 */
export function getDefaultColor(
	colors: ColorVariation[],
	faceOutColors: string[],
	product?: ClientProductTile | ClientProductData,
	preferredSize?: SizeMapTypes | null,
	teamFilters?: string[] | null,
): ColorVariation | null {
	if (colors.length === 0) {
		return null
	}

	if (!!teamFilters && ensureArray(teamFilters).length > 0) {
		return getColorByTeamFilters(colors, teamFilters)
	}

	// if there is a view preference
	// try to find a color with preferred size available
	if (product?.shopTheLookColors && preferredSize) {
		return getColorByPreferredSize(colors, product, preferredSize, faceOutColors)
	}

	// There are instances where the image assets for a particular index can be null. This just locates the first instance where there are image assets to display
	const firstViableColorVariation =
		colors.find((color) => color?.assets?.images && color?.assets?.images.length > 0) || colors[0]

	// if there's no view preference
	// or no color with the preferred size available
	// just return the results using original logic

	return faceOutColors.length
		? colors
				.filter(
					(color) =>
						(!!color.name && faceOutColors.includes(color.name)) ||
						(!!color.color && faceOutColors.includes(color.color)),
				)
				.sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0))[0] ?? firstViableColorVariation
		: firstViableColorVariation
}

export function isGiftCard(card: Optional<GiftCardType>): card is GiftCardType.EGIFTCARD | GiftCardType.GIFTCARD {
	return [GiftCardType.GIFTCARD, GiftCardType.EGIFTCARD].includes(card as GiftCardType)
}
export const isEGiftCard = (card: Optional<GiftCardType>) => card === GiftCardType.EGIFTCARD
export const isPhysicalGiftCard = (card: Optional<GiftCardType>) => card === GiftCardType.GIFTCARD

/** Returns the structure for the "Product" element for rich content.
		This is what lets detailed results show up in e.g. Google Shopping Search. */
export function getProductJsonLd({
	aggregateRatings,
	color,
	locale,
	product,
	reviews,
	size,
}: {
	aggregateRatings?: AggregateRatings
	color?: ColorVariation
	locale: string
	product: ClientProductDetail
	reviews?: Review[]
	size?: string
}) {
	const images = getSpecificImages({ product, size, color })
	const price = product.price?.sale?.min || product.price?.list?.min
	const host = getDomainFromLocale(locale)
	const canonicalUrl = `https://${host}/${locale}${product.url}`

	const numberToFixedNumber = (input: number, fixed = 0): number => Number(input.toFixed(fixed))
	const aggregateRating = aggregateRatings
		? {
				aggregateRating: {
					// https://schema.org/AggregateRating
					'@type': 'AggregateRating',
					// Integer - The highest value allowed in this rating system. If bestRating is omitted, 5 is assumed.
					bestRating: numberToFixedNumber(aggregateRatings.best),
					// Number or Text - The rating for the content.
					ratingValue: numberToFixedNumber(aggregateRatings.value, 1),
					// Integer - The count of total number of ratings.
					reviewCount: numberToFixedNumber(aggregateRatings.count),
					// Integer - The lowest value allowed in this rating system. If worstRating is omitted, 1 is assumed.
					worstRating: numberToFixedNumber(aggregateRatings.worst),
				},
		  }
		: {}

	const review = reviews
		// TODO: does this filter matter?
		?.filter((r) => !!r.text)
		.map(({ date, published, range, rating, text, title, user: { name } }) => ({
			// https://schema.org/Review
			'@type': 'Review',
			author: {
				type: 'Person',
				name,
			},
			comment: [],
			dateCreated: date,
			datePublished: published,
			headline: title,
			image: [],
			reviewBody: text,
			reviewRating: {
				'@type': 'Rating',
				bestRating: range,
				ratingValue: rating,
			},
			video: [],
		}))

	return {
		'@context': 'https://schema.org',
		'@type': 'Product',
		'@id': canonicalUrl,
		name: product.name,
		image: images.map((i) => i.url),
		description: product.whatsItDo,
		mpn: product.style,
		sku: `${product.style}-${color?.color}-${size}`,
		brand: { '@type': 'Brand', name: 'UnderArmour' },
		...aggregateRating,
		review,
		offers: {
			url: canonicalUrl,
			'@type': 'Offer',
			priceCurrency: product.currency,
			price,
			availability: 'http://schema.org/InStock',
		},
	}
}

export function isOnSale(displayedPrices: Optional<PricesRollup>): boolean {
	return (
		displayedPrices?.list?.min !== displayedPrices?.sale?.min ||
		displayedPrices?.list?.max !== displayedPrices?.sale?.max
	)
}

export function getBopisMessage(product: { availableForInStorePickup?: boolean | null } | null) {
	return product?.availableForInStorePickup ? 'available for pickup' : 'unavailable for pickup'
}

/**
 * Builds a product URL with parameters given appropriate data.
 * @param locale The locale to which this product link should point
 * @param product The product object
 * @param color The specific color to point to
 * @param variant The specific size to point to
 * @param absolute True if it should return an absolute URL (only works when run on client)
 * @returns
 */
export function buildProductPath(
	locale: string,
	product: ClientProductDetail,
	color?: ColorVariation,
	variant?: VariantProduct | SizeVariation | VariationSize,
	absolute = false,
) {
	if (!product.style) {
		return undefined
	}

	let origin = ''
	if (window && absolute) {
		const url = new URL(window.location.href)
		origin = url.origin
	}

	const base = product.url

	const params = new URLSearchParams()

	let colorParamValue: Optional<string> = null
	if (color?.color || product.colors) {
		if (color?.color) {
			colorParamValue = color.color
		} else if (product.colors) {
			colorParamValue = getDefaultColor(product.colors, [])?.color
		}

		if (colorParamValue) {
			params.append(getColorQueryParam(product.style), colorParamValue)
		}
	}

	let variantParamValue: Optional<string>
	if (variant?.size) {
		if (isVariantProduct(variant.size)) {
			if (isVariantSize(variant.size.size)) {
				variantParamValue = variant.size.size.size
			} else {
				variantParamValue = variant.size.size
			}
		} else if (isVariantSize(variant.size)) {
			variantParamValue = variant.size.size
		} else {
			variantParamValue = variant.size
		}

		if (variantParamValue) {
			params.append(getSizeQueryParam(product.style), variantParamValue)
		}
	}

	return `${origin}/${locale}${base}?${params.toString()}`
}

/**
 * Returns the Products which have atleast one color with image.
 * @param products The products to filter
 * */
export const selectProductsWithImages = (products: ClientProductData[]) =>
	products.filter((product) => product?.colors?.[0]?.assets?.images?.[0])

/**
 * Filter the outfit materials code in order to display related product tiles
 * @param shopTheLookColors The loaded shopTheLook data for specific product
 * @param style The product style value
 * @param displayedColorValue The displayed(selected) color of the product
 * @param displayedColorImageAssetName The displayed(selected) color first image asset name
 * @param selectedSize The selected variation size
 * @returns filtered ShopTheLookMaterialCode[]
 */
export function getProductOutfitItems(
	shopTheLookColors: Optional<ShopTheLookColor[]>,
	style: Optional<string>,
	displayedColorValue: string,
	displayedColorImageAssetName: Optional<string>,
	selectedSize: Optional<string>,
) {
	const selectedSizeLowerCase = selectedSize?.toLowerCase()
	const filteredOutfitsByColorAndImage = ensureNonNullishArray(
		shopTheLookColors?.find(
			(item) =>
				item.color === displayedColorValue &&
				ensureNonNullishArray(item.images).some(({ image }) => image === displayedColorImageAssetName),
		)?.outfit || ([] as ShopTheLookOutfit[]),
	)

	const filteredOutfitBySize =
		filteredOutfitsByColorAndImage?.find(({ size }) => size === selectedSizeLowerCase) ||
		filteredOutfitsByColorAndImage?.[0] ||
		({} as ShopTheLookOutfit)
	return ensureNonNullishArray(
		filteredOutfitBySize?.materialCodes?.filter((materialCode) => materialCode?.style !== style) || [],
	)
}

/**
 * Converts an array of variants into a map that's grouped by color and variants
 * @param variants
 * @returns
 */
export function buildVariantLookup(variants: ClientProductVariantDetail[]): VariantLookup {
	return variants.reduce((acc, variant) => {
		if (!variant.color || !variant.size) {
			return acc
		}
		if (acc[variant.color]) {
			acc[variant.color][variant.size] = variant
		} else {
			acc[variant.color] = { [variant.size]: variant }
		}

		return acc
	}, {})
}

export function buildVariantLookupFromInitialLookupVariantsOrProduct({
	lookupVariants,
	product,
}: {
	lookupVariants: ClientInitialLookupVariants | undefined
	product: ClientProductDetail
}) {
	return lookupVariants?.variants?.length
		? buildVariantLookup(lookupVariants.variants)
		: buildVariantLookup(ensureNonNullishArray(product.variants))
}

/**
 * Returns an array of numbers with a minimum and maximum (e.g. [0,1,2,3,4,5,6,7,8,9]). It's based on the
 *  product's store inventory, the product's line item quantity if it has one, or the default quantity limit.
 * @param product
 * @returns
 */
export function getProductQuantityRange(product: CartProduct) {
	let max
	if (product.cFromStoreId !== undefined && product.cStoreInventory > 0) {
		max = product.cStoreInventory
	} else if (ensureNumber(product?.quantity) > DEFAULT_LINE_ITEM_QUANTITY_LIMIT) {
		max = product?.quantity
	} else {
		max = Math.min(DEFAULT_LINE_ITEM_QUANTITY_LIMIT, product?.lineItemQuantityLimit || Infinity)
	}
	return range(max)
}

/**
 * Returns false if not all of the products are available from an inventory perspective.
 * @param cartData
 * @returns
 */
export function getAreAllProductsAvailable(cartData: Cart) {
	return cartData ? !cartData.limitExceededItems?.length : false
}

/**
 *
 * @param cartData
 * @returns
 */
export function cartPromoCodesAreEligible(cartData: Cart) {
	return !!cartData?.couponItems?.every((coupon) => coupon.valid)
}

/**
 * Determines if the given variant should be considered to be at low inventory.
 * It uses the given quantity level as the threshold.
 * @param variant
 * @returns
 */
export function isVariantWithLowInventory(variant: ClientProductVariantDetail, qtyLimitLevel = 10) {
	const stockLevel = variant.inventory?.stockLevel || 0
	return (
		!variant.customerLineItemQtyLimit &&
		!variant.employeeLineItemQtyLimit &&
		(!variant.inventory || variant.inventory.exclusiveType === ExclusiveType.NONE) &&
		variant?.orderable &&
		stockLevel > 0 &&
		stockLevel < qtyLimitLevel
	)
}

function isMasterHit(p: SearchHit): p is MasterProductSearchHit {
	return p.__typename === 'MasterProductSearchHit'
}

function isSlicingGroupSearchHit(p: SearchHit): p is SlicingGroupSearchHit {
	return p.__typename === 'SlicingGroupSearchHit'
}

/**
 * For MasterProductSearchHits, the tile will normally show all of the Product's colors.
 * For SlicingGroupSearchHits, there should only be a single color on the tile,
 * corresponding to the slice of the product that the tile represents.
 */
export function colorsForTile(node: SearchHit): ColorVariation[] {
	const colors = node.colors || node.product?.colors || []
	return ensureArray(colors)
}

/**
 * Gets the style from the search trying the more detailed data first then looking
 * in other places, ultimately returning an empty string if nothing is found.
 * @param node
 * @returns
 */
function getStyle(node: SearchHit) {
	if (node.product?.style) {
		return node.product.style
	}
	return isMasterHit(node) ? ensureString(node.style) : getProductIdFromUrl(ensureString(node.url))
}

/**
 * Takes a single search hit and returns an object that can be used within our product
 * grid UI.
 * @param node
 * @returns
 */
export const buildProductDataFromSearchHit = (node: SearchHit): ClientProductData => {
	const colors = colorsForTile(node).filter((color) => color.orderable && !!color.assets?.images?.length)
	const image = getProductTileImage(colors, ensureString(node.productName))
	const style = getStyle(node)
	const shopTheLookColors =
		(!isSlicingGroupSearchHit(node) &&
			node?.shopTheLookInfo?.colors?.map((color) => {
				const shopTheLookImages = ensureArray(color?.images)
				const sortedImages = sortImages(ensureNonNullishArray(shopTheLookImages))
				return { color: ensureString(color?.color), outfit: ensureArray(color?.outfit), images: sortedImages }
			})) ||
		[]

	const url = node.isOutletCategory
		? getProductPathWithoutLocale(node.url || '', style || '', colors?.[0]?.color)
		: ensureString(node.url)

	if (style === '') {
		logger.error('Received a result for which a style could not be retrieved')
	}

	return {
		id: isSlicingGroupSearchHit(node) ? ensureString(node.slicedProductId ?? url) : ensureString(url),

		currency: ensureString(node.currency),

		// TODO: The style is not available on a sliced search result but is available on a master product hi
		style,

		isSliced: !isMasterHit(node),
		name: node.productName,
		price: node.prices,
		colors,
		url,
		badges: node.badges,
		orderable: node.orderable || false,
		// NOTE: This is no longer available in the slimmed down query
		preorderable: node.product?.inventory?.preorderable || false,

		productPromotions: ensureArray(node.productPromotions),
		image,

		// NOTE: This is no longer available in the slimmed down query
		tilePreorderMessage: ensureString(node.product?.copy?.preorderCopy?.tileMessage),

		// NOTE: This is no longer available in the slimmed down query
		exclusiveType: node.product?.inventory?.exclusiveType || ExclusiveType.NONE,

		// NOTE: This is no longer available in the slimmed down query
		experienceType: node.product?.experienceType || ProductExperienceType.BOTH,

		// NOTE: This is no longer available in the slimmed down query
		comingSoonMessage: ensureString(node.product?.comingSoonMessage),

		// NOTE: This is no longer available in the slimmed down query
		shopTheLookColors,

		// NOTE: Only needed to disable quick add to bag for egiftcards
		giftCardType: node.giftCardType || null,

		sizes: ensureArray(node.sizes),
	}
}

/**
 * Gets the pagination data for a product grid.
 * @param offset The offset from the start of the list
 * @param totalCount The total number of items in the full list
 * @param urlPathWithParams The relative URL with params - to be used to generate the page URLs
 * @param pageSize The number of items per page
 * @returns
 */
export function getPaginationData(
	offset: number,
	totalCount: number,
	urlPathWithParams: string,
	pageSize: number,
): {
	currentPage: number
	totalPages: number
	pageMap: { pageNumber: number; url: string }[]
	nextUrl: string | undefined
	previousUrl: string | undefined
} {
	// The next button should be shown if the current offset (e.g. 24 + total number of products per
	//	page is less than the total count of products)
	const hasMoreProducts = offset + pageSize < totalCount

	const currentPage = Math.ceil(offset / pageSize) + 1
	const totalPages = Math.ceil(totalCount / pageSize)
	const pageMap = Array(totalPages)
		.fill({})
		.map((_, pageIndex) => ({
			pageNumber: pageIndex + 1,
			url: updateUrlParams(urlPathWithParams, {
				start: pageIndex === 0 ? null : (pageIndex * pageSize).toString(),
			}),
		}))

	// The next URL start location is calculated by taking the current offset and adding the page count.
	const nextStart = offset + pageSize
	const nextUrl = hasMoreProducts ? updateUrlParams(urlPathWithParams, { start: nextStart.toString() }) : undefined

	// The previous button should be shown if the current offset is greater than zero.
	//	The previous URL start location is calculated by taking the current offset and subtracting the page count
	//	- if the value is less than 0 then the value is zero.
	const previousStart = Math.max(0, offset - pageSize)
	const previousUrl =
		offset > 0
			? updateUrlParams(urlPathWithParams, { start: previousStart === 0 ? null : previousStart.toString() })
			: undefined

	return {
		currentPage,
		totalPages,
		pageMap,
		nextUrl,
		previousUrl,
	}
}

/**
 * The URL of the product detail page for a given product.
 * @param url The URL containing a standard product url
 * @returns
 */
export function getProductIdFromUrl(url: string): string | undefined {
	const groups = Array.from(url.matchAll(/.*\/([\d]+)\.html/g))
	return groups?.[0]?.[1]
}

const DEFAULT_REFINEMENT = { hitType: [HitType.MASTER, HitType.SLICING] }

/**
 * This pulls more detailed data about a grid of products.  This is meant to
 * be used behind the scenes as it could be VERY slow.
 * @param client
 * @param variables
 * @param userToken
 * @returns
 */
export const getGridProductsDetails = async (
	client: ApolloClient<object>,
	variables: ProductGridSearchFastQueryVariables,
	userToken: string | null = null,
): Promise<ApolloQueryResult<ProductGridSearchFastQuery>['data']> => {
	const refinement = { ...DEFAULT_REFINEMENT, ...variables.refinement }
	const defaultRefinements = [{ attributeId: 'orderable_only', values: ['true'] }]
	const currentRefinements = variables.refinement?.customRefinements ?? []
	const results = await client.query({
		query: ProductGridSearchFastDocument,
		...(userToken
			? {
					context: {
						headers: {
							authorization: `Bearer ${userToken}`,
						},
					},
			  }
			: {}),
		variables: {
			...variables,
			refinement: {
				...refinement,
				customRefinements: [...defaultRefinements, ...currentRefinements],
			},
		},
		fetchPolicy: 'no-cache',
	})
	return results.data
}

/**
 * This pulls a list of products in a way that is ideal for performance and for
 * an initial request for product data.  It might be necessary to pull additional
 * information on the client.
 * @param client
 * @param variables
 * @param userToken
 * @returns
 */
export async function getGridProducts(
	client: ApolloClient<object>,
	variables: ProductGridSearchFastQueryVariables,
	userToken: string | null = null,
) {
	const refinement = { ...DEFAULT_REFINEMENT, ...variables.refinement }
	const defaultRefinements = [{ attributeId: 'orderable_only', values: ['true'] }]
	const currentRefinements = variables.refinement?.customRefinements ?? []
	const results = await client.query({
		query: ProductGridSearchFastDocument,
		...(userToken
			? {
					context: {
						headers: {
							authorization: `Bearer ${userToken}`,
						},
					},
			  }
			: {}),
		variables: {
			...variables,
			refinement: {
				...refinement,
				customRefinements: [...defaultRefinements, ...currentRefinements],
			},
		},
		fetchPolicy: 'no-cache',
	})
	return results.data
}

/**
 * Gets the data necessary to naively populate a product list.  This impelementation
 * uses a query that is designed to be faster to help ensure that the user sees
 * products as soon as possible.  The intention is that the client will hydrate
 * the UI with additional details that may be needed for a better experience.
 *
 * IMPORTANT: This returns the data in a form that is API-agnostic and used by the
 * UI.  Any changes to the output here will impact the ProductGrid.
 * @param client
 * @param parameters
 * @param userToken
 * @returns
 */
export const getProductListByCategory = async (
	client: ApolloClient<object>,
	parameters: ProductGridSearchFastQueryVariables,
	userToken: string | null = null,
): Promise<ClientProductList> => {
	// Makes the call to UACAPI to pull the products that match the given constraints.
	const productQuery = await getGridProducts(client, parameters, userToken)

	// Extract the nodes from the edges in the product results
	const productResult = productQuery.searchProductsPaginatedOffset.edges.map((item) => item.node)

	// Convert graphql search results to well known, Seabiscuit-specific structure
	const products = productResult.map((node) => buildProductDataFromSearchHit(node)).filter((product) => product.colors)

	return {
		paginationInfo: productQuery.searchProductsPaginatedOffset.pageInfo,
		totalCount: productQuery.searchProductsPaginatedOffset.totalCount,
		sortingOptions: productQuery.searchProductsPaginatedOffset.sortingOptions,
		products,
		refinementAttributes: productQuery.searchProductsPaginatedOffset.refinementAttributes,
	}
}

/**
 * Gets all of the sorting options available to users on PLPs
 *
 * @param client
 * @returns All sortingOptions available for category pages
 */
export const getProductSortingOptions = async (client: ApolloClient<object>): Promise<SearchSortingOption[]> => {
	const results = await client.query({
		query: GetSortingOptionsDocument,
		variables: {
			first: 0,
		},
	})

	return results.data?.products.sortingOptions || []
}

/**
 * Gets products suggestions for a given product style from UACAPI.
 *
 * @param client - The Apollo client instance to use for the query.
 * @param parameters - An object containing query parameters.
 * @param parameters.productStyle - The style of the product to get suggestions for.
 *
 * @returns A promise that resolves to the server's response containing the product suggestions.
 */
export const getProductSuggestions = async <T extends object = object>(
	client: ApolloClient<T>,
	parameters: {
		productStyle: string
	},
) => {
	// NOTE! if we don't have a productStyle, we don't want to make the call.
	if (!parameters?.productStyle)
		return {
			data: {
				productRecommendations: [],
			},
			loading: false,
		}

	const productsSuggestions = await client.query({
		query: GetProductSuggestionsDocument,
		variables: {
			productIds: [parameters.productStyle],
			recommenderName: 'pdp-ymal',
			recommendationTypeId: RecommendationInputIdType.STYLE,
		},
	})

	return productsSuggestions
}

/**
 * Fetches product tiles based on product IDs.
 *
 * @param client - The Apollo client instance to use for the query.
 * @param parameters - An object containing the product IDs to fetch.
 * @param parameters.productIds - The product IDs to fetch.
 *
 * @returns A promise that resolves to the server's response containing fetched product tiles.
 */
export const getProductTilesByProductIds = async <T extends object = object>(
	client: ApolloClient<T>,
	parameters: {
		productIds: AlternativeProductIdInput | AlternativeProductIdInput[]
	},
) => {
	const products = await client.query({
		query: GetProductTilesByProductIDsDocument,
		variables: {
			productIds: parameters.productIds,
		},
	})

	return products
}

/**
 * Gets the data necessary to populate a product list with detailed information.
 *  This impelementation uses a query that is slow and should only be used asynchronously
 *
 * IMPORTANT: This returns the data in a form that is API-agnostic and used by the
 * UI.  Any changes to the output here will impact the ProductGrid.
 *
 * @param client
 * @param parameters
 * @param userToken
 * @returns
 */
export const getProductListDetailsByCategory = async (
	client: ApolloClient<object>,
	variables: ProductGridSearchFastQueryVariables,
	userToken: string | null = null,
): Promise<ClientProductList> => {
	// Makes the call to UACAPI to pull the products that match the given constraints.
	const productQuery = await getGridProductsDetails(client, variables, userToken)

	// Extract the nodes from the edges in the product results
	const productResult = productQuery.searchProductsPaginatedOffset.edges.map((item) => item.node)

	// Convert graphql search results to well known, Seabiscuit-specific structure
	const products = productResult
		.map((node) => buildProductDataFromSearchHit(node))
		.filter((product) => product.colors && product.colors?.filter((color) => color?.orderable).length > 0)

	return {
		paginationInfo: productQuery.searchProductsPaginatedOffset.pageInfo,
		totalCount: productQuery.searchProductsPaginatedOffset.totalCount,
		sortingOptions: productQuery.searchProductsPaginatedOffset.sortingOptions,
		products,
		refinementAttributes: productQuery.searchProductsPaginatedOffset.refinementAttributes,
	}
}

/**
 * Will construct the productId from a supplied style string
 * @param style
 */
export function getMasterProductId(style?: Maybe<string>): string | null {
	if (!style) return null
	return btoa(`MasterProduct:${style}`)
}

/**
 * Improves the resolution of product images url
 * @param url Product image url
 * @param productDivision Product division Ex. 'Footwear'
 * @param width Product image width Ex. '500'
 * @param height Product image height Ex. '500'
 * @param imgSize Product image size Ex. '250,250'
 * @param bgc Product image background color Ex. 'f0f0f0'
 * @returns
 */
export const getHighResolutionUrl = (
	url: string,
	productDivision: string,
	width?: string,
	height?: string,
	imgSize?: string,
	bgc = 'f0f0f0',
): string => {
	const newUrl = new URL(url)
	const { scl, size } = highResolutionParameters[productDivision as keyof typeof highResolutionParameters] || {}
	if (scl) {
		newUrl.searchParams.set('scl', scl)
	}

	if (width) {
		newUrl.searchParams.set('wid', width)
	} else newUrl.searchParams.set('wid', '1836')
	if (height) {
		newUrl.searchParams.set('hei', height)
	} else newUrl.searchParams.set('hei', '1950')
	if (imgSize) {
		newUrl.searchParams.set('size', imgSize)
	} else if (size) {
		newUrl.searchParams.set('size', size)
	}
	if (bgc) {
		newUrl.searchParams.set('bgc', bgc)
	}
	return newUrl.toString()
}

export const productTile = async (
	client: ApolloClient<object>,
	id: string,
	// eslint-disable-next-line consistent-return
): Promise<ClientProductTile | undefined> => {
	const { data } = await client.query<GetProductTileByStyleIdQuery>({
		query: GetProductTileByStyleIdDocument,
		variables: {
			id,
		},
	})

	const product = data.product as MasterProduct

	const mappedProduct = mapMasterToTile(product)

	return mappedProduct
}

export type NonNullableVariantProductWithRequiredFields = Omit<Variant, 'product' | 'variationValues'> & {
	product: Omit<NonNullable<Variant['product']>, 'lineItemQuantityLimit'> & {
		lineItemQuantityLimit: NonNullable<NonNullable<Variant['product']>['lineItemQuantityLimit']>
	}
	variationValues: Omit<NonNullable<Variant['variationValues']>, 'color' | 'size'> & {
		color: NonNullable<NonNullable<Variant['variationValues']>['color']>
		size: NonNullable<NonNullable<Variant['variationValues']>['size']>
	}
	orderable: NonNullable<Variant['orderable']>
}

export const flattenVariant = (v: NonNullableVariantProductWithRequiredFields): ClientProductVariantDetail => {
	const flattenedVariant: ClientProductVariantDetail = {
		id: v.product.id,
		lineItemQuantityLimit: v.product.lineItemQuantityLimit,
		orderable: v.orderable,
		color: v.variationValues.color,
		size: v.variationValues.size,
		productPromotions: v.product.productPromotions,
	}

	if (v.product.customerLineItemQtyLimit) {
		flattenedVariant.customerLineItemQtyLimit = v.product.customerLineItemQtyLimit
	}

	if (v.product.employeeLineItemQtyLimit) {
		flattenedVariant.employeeLineItemQtyLimit = v.product.employeeLineItemQtyLimit
	}

	if (v.product.sku) {
		flattenedVariant.sku = v.product.sku
	}

	if (v.product.upc) {
		flattenedVariant.upc = v.product.upc
	}

	if (v.product.style) {
		flattenedVariant.style = v.product.style
	}

	if (v.variationValues.length) {
		flattenedVariant.length = v.variationValues.length
	}

	if (
		v.product.prices &&
		v.product.prices.list !== undefined &&
		v.product.prices.list !== null &&
		v.product.prices.sale !== undefined &&
		v.product.prices.sale !== null
	) {
		flattenedVariant.prices = v.product.prices as ClientProductVariantDetail['prices']
	}

	if (v.product.inventory) {
		flattenedVariant.inventory = v.product.inventory
	}

	if (v.product.sizePreferenceOption) {
		flattenedVariant.sizePreferenceOption = v.product.sizePreferenceOption
	}

	return flattenedVariant
}

export const mapAndFlattenVariants = (product?: MasterProduct): ClientProductVariantDetail[] => {
	if (!product?.variants) {
		return []
	}
	const { variants } = product
	const filteredVariants = variants.filter((v) => {
		// Required fields
		if (
			!v.product ||
			v.product.lineItemQuantityLimit === undefined ||
			v.product.lineItemQuantityLimit === null ||
			!v.variationValues ||
			!v.variationValues.color ||
			!v.variationValues.size ||
			v.orderable === undefined ||
			v.orderable === null
		) {
			return false
		}

		return true
	}) as NonNullableVariantProductWithRequiredFields[]
	return filteredVariants.map((v) => flattenVariant(v))
}

export function mapMasterToTile(product: MasterProduct): ClientProductTile | undefined {
	if (product?.prices && product?.copy?.name && product?.colors) {
		return {
			id: product.id,
			colors: product?.colors,
			currency: product?.currency || undefined,
			style: product?.style || undefined,
			url: product.url || undefined,
			badges: product.badges || undefined,
			productPromotions: product.productPromotions || undefined,
			exclusiveType: product?.inventory?.exclusiveType || undefined,
			name: product?.copy?.name,
			price: product?.prices,
			orderable: product?.inventory?.orderable || false,
			preorderable: product?.inventory?.preorderable || false,
			image: getProductTileImage(product.colors, product?.copy?.name),
			isSliced: false,
			sizes: product?.sizes,
		}
	}

	return undefined
}

export function mapVariantToTile(product: MergedVariantProduct | VariantProduct): ClientProductTile | undefined {
	const mergedProduct = product as MergedVariantProduct
	if (mergedProduct.prices && mergedProduct?.copy?.variantName && mergedProduct.id) {
		const colors = mergedProduct.color
			? ([
					{
						...mergedProduct.color,
						color: mergedProduct.color?.code,
						assets: mergedProduct?.assets,
					},
			  ] as ColorVariation[])
			: []

		return {
			id: mergedProduct.id,
			currency: mergedProduct.currency || undefined,
			style: mergedProduct.style || undefined,
			url: mergedProduct.url || undefined,
			name: mergedProduct?.copy?.variantName,
			price: {
				sale: {
					min: mergedProduct.prices.variantSale,
					max: mergedProduct.prices.variantSale,
				},
				list: {
					min: mergedProduct.prices.variantList,
					max: mergedProduct.prices.variantList,
				},
			},
			colors,
			badges: mergedProduct.badges || undefined,
			productPromotions: mergedProduct.productPromotions || undefined,
			orderable: true,
			image: {
				alt: mergedProduct?.assets?.images?.[0].assetName || '',
				url: mergedProduct?.assets?.images?.[0].url ?? PRODUCT_FALLBACK_IMAGE,
				urlHov: mergedProduct?.assets?.images?.[1]
					? mergedProduct?.assets?.images?.[1]?.url ?? PRODUCT_FALLBACK_IMAGE
					: mergedProduct?.assets?.images?.[0]?.url ?? PRODUCT_FALLBACK_IMAGE,
			},
			preorderable: mergedProduct.inventory?.preorderable || false,
			exclusiveType: mergedProduct.inventory?.exclusiveType,
			sku: mergedProduct.sku,
		}
	}

	return undefined
}

export const productUrlByStyle = async (client: ApolloClient<object>, id: string) => {
	const results = await client.query({
		query: GetProductUrlByStyleIdDocument,
		variables: {
			id,
		},
	})
	return results.data?.product?.url
}
// Return multiple product urls by passing an array of style ids. This is used for CoreMedia Content CTAs.
export const productsUrlByStyleArray = async (
	client: ApolloClient<object>,
	styleIds: { style?: string }[] | { upc?: string }[],
) => {
	const results = await client.query({
		query: GetProductsUrlByStyleIdDocument,
		variables: {
			styleIds,
		},
	})
	return results.data.productsById
}

export type BriefProductData = GetBriefProductDataByAnyIdQuery['productsById'][number]

/**
 * Return product information needed to render hotspots for content hotspot modules.
 * @param client
 * @param idPairs
 * @returns
 */
export const getBriefProductDataByAnyId = async (
	client: ApolloClient<object>,
	idPairs: AlternativeProductIdInput | AlternativeProductIdInput[],
): Promise<BriefProductData[]> => {
	const results = await client.query({
		query: GetBriefProductDataByAnyIdDocument,
		variables: {
			idPairs,
		},
	})
	return results.data.productsById
}

export const productUrlByUpc = async (client: ApolloClient<object>, upc: string) => {
	const results = await client.query({
		query: GetProductUrlByUpcDocument,
		variables: {
			upc,
		},
	})
	const product = results.data.product as VariantProduct
	if (!product) {
		return undefined
	}
	return {
		masterUrl: product.master?.product?.url || '',
		style: product.style || '',
		color: product.color?.code || '',
		size: product.size?.size || '',
	}
}

export async function product(client: ApolloClient<object>, id: string): Promise<ClientProductData> {
	const { data } = await client.query<GetVariantsByStyleIdQuery>({
		query: GetProductByStyleIdDocument,
		variables: {
			id,
		},
	})

	const product = data.product as MasterProduct
	return {
		id: product.id,
		name: ensureString(product?.copy?.name),
		colors: product.colors,
		currency: product.currency || '',
		style: product.style || '',
		url: product.url || '',
		badges: product.badges,
		productPromotions: product.productPromotions,
		exclusiveType: product?.inventory?.exclusiveType || ExclusiveType.NONE,
		dna: product.copy?.dna,
		whatsItDo: product.copy?.whatsItDo,
		price: product.prices,
		orderable: !!product?.inventory?.orderable,
		preorderable: !!product?.inventory?.preorderable,
		image: getProductTileImage(product?.colors, ensureString(product?.copy?.name)),
		isSliced: false,
		isLoyaltyExclusive: product?.isLoyaltyExclusive,
	}
}

export function isMasterProduct(p: GetProductDetailsByStyleIdQuery['product']): p is MasterProduct {
	return !!(p && p.__typename === 'MasterProduct')
}

export function isVariantProductByTypeName(p: GetProductDetailsByStyleIdQuery['product']): p is VariantProduct {
	return !!(p && p.__typename === 'VariantProduct')
}

export async function getProductDetail(
	client: ApolloClient<object>,
	id: string,
): Promise<ClientProductDetail | undefined> {
	const { data } = await client.query<GetProductDetailsByStyleIdQuery>({
		query: GetProductDetailsByStyleIdDocument,
		variables: {
			id,
		},
	})

	let product = {} as MasterProduct
	if (isMasterProduct(data.product)) {
		product = data.product
	}
	if (isVariantProductByTypeName(data.product) && data.product.master?.product) {
		product = data.product.master.product
	}

	if (!product.style) {
		return undefined
	}

	return {
		sizes: product?.sizes,
		colors: product?.colors,
		availableForInStorePickup: product?.availableForInStorePickup,
		unstableSizeChart: product?.unstableSizeChart,
		id: product.id,
		style: product.style,
		url: product.url,
		currency: product.currency,
		badges: product.badges,
		name: product?.copy?.name,
		isLoyaltyExcluded: product.isLoyaltyExcluded,
		isPdpReturnMsgExcluded: product.isPdpReturnMsgExcluded,
		productPromotions: product.productPromotions,
		division: product.division,
		fitType: product.fitType,
		fitCare: product?.copy?.fitCare,
		specs: product?.copy?.specs,
		dna: product?.copy?.dna,
		icons: product.icons,
		gender: product.gender,
		whatsItDo: product?.copy?.whatsItDo,
		giftCardType: product?.giftCardType,
		price: product?.prices,
		primaryCategory: product?.primaryCategory,
		categoryTree: product.primaryCategory?.parentCategoryTree ?? [],
		image: getProductTileImage(product.colors, ensureString(product?.copy?.name)),
		variants: mapAndFlattenVariants(product),
		preorderable: product?.inventory?.preorderable,
		pdpPreorderMessage: product.copy?.preorderCopy?.pdpMessage,
		promoCalloutAssetID: product?.promoCalloutAssetID || null,
		shopTheLookColors:
			ensureNonNullishArray(product?.shopTheLookInfo?.colors).map((color) => {
				const images = ensureNonNullishArray(color?.images)
				const sortedImages = images ? sortImages(images) : undefined
				return { ...color, images: sortedImages }
			}) || null,
		exclusiveType: product?.inventory?.exclusiveType,
		comingSoonMessage: product.comingSoonMessage,
		combinedStyle: product.combinedStyle || null,
		isLoyaltyExclusive: product?.isLoyaltyExclusive,
		silhouette: product?.silhouette,
		sizePreferenceType: product?.sizePreferenceType,
		experienceType: product?.experienceType,
		copy: product?.copy,
	}
}

export async function getProductMetadata(
	client: ApolloClient<object>,
	id: string,
): Promise<Pick<ClientProductDetail, 'name' | 'image'> | undefined> {
	const { data } = await client.query<GetProductMetadataByStyleIdQuery>({
		query: GetProductMetadataByStyleIdDocument,
		variables: {
			id,
		},
	})

	const name = data.product?.copy?.name
	let image: ClientProductTileImage | undefined

	if (data?.product?.__typename === 'MasterProduct') {
		image = getProductTileImage(data.product?.colors, ensureString(name))
	}

	return {
		name,
		image,
	}
}

export async function productVariants(
	client: ApolloClient<object>,
	styleId: string,
	userToken: string | null = null,
): Promise<ClientProductDetailVariants> {
	const { data } = await client.query<GetVariantsByStyleIdQuery>({
		query: GetVariantsByStyleIdDocument,
		...(userToken
			? {
					context: {
						headers: {
							authorization: `Bearer ${userToken}`,
						},
					},
			  }
			: {}),
		variables: {
			styleId,
		},
		fetchPolicy: 'no-cache',
	})

	const product = data.product as MasterProduct

	return {
		masterProductId: product.id,
		variants: mapAndFlattenVariants(product),
	}
}

export async function productVariantAvailability(
	client: ApolloClient<object>,
	upc: string,
): Promise<Pick<ClientProductVariantDetail, 'id' | 'inventory' | 'orderable' | 'prices'>> {
	const { data } = await client.query<GetVariantAvailabilityByUpcQuery>({
		query: GetVariantAvailabilityByUpcDocument,
		variables: {
			id: {
				upc,
			},
		},
		fetchPolicy: 'no-cache',
	})

	const product = data.product as VariantProduct
	return {
		id: product.id,
		orderable: product?.inventory?.orderable || false,
		inventory: product?.inventory || undefined,
		prices: (product?.prices as { list: number; sale: number }) || undefined,
	}
}

/**
 * Converts the provided `value` to a valid color hex string if possible, otherwise returns an empty string.
 * Primarily intended for use with `product colors.hex`, which can unpredictably be a number _or_ a string.
 * @param value unknown object checks for string | number
 * @returns string as hex value
 */
export function convertToHexString(value: unknown): string {
	if (typeof value !== 'number' && typeof value !== 'string') return ''
	if (typeof value === 'number') return `#${value}`

	return value.startsWith('#') ? value : `#${value}`
}

/**
 * Converts a complex SKU into an easily-referenceable ParsedSku including styleCode, materialCode, and variantCode
 * @param sku value to parse as string following format StyleCode-materialCode-variantCode
 * @returns ParsedSku object or null if it didn't parse as expected
 */
export function skuParser(sku: string | undefined): ParsedSku | null {
	const skuParts = sku?.split('-')
	return skuParts && skuParts.length === 3
		? {
				styleCode: skuParts[0],
				materialCode: skuParts[1],
				variantCode: skuParts.slice(2).join('-') || undefined,
		  }
		: null
}

/**
 * Checks it variation is not orderable or it configured as OOS
 * @param variant product variant
 * @returns boolean flag is variant is OOS
 */
export function isVariantOutOfStock(variant: ClientProductVariantDetail): boolean {
	const isOrderable = variant.inventory?.orderable ?? variant.orderable
	const isFlaggedAsOOS = variant.inventory?.exclusiveType === ExclusiveType.OUT_OF_STOCK
	const isComingSoon = variant.inventory?.exclusiveType === ExclusiveType.COMING_SOON
	return (!isOrderable && !isComingSoon) || (isOrderable && isFlaggedAsOOS)
}

/**
 * Returns Oos products from cart
 * @param cartData
 * @returns CartProduct[] a list of products or empty array
 */
export function getOosItems(cartData: Cart): CartProduct[] {
	return cartData.limitExceededItems
		.map((item) => cartData.products.filter((product) => item.itemId === product.id))
		.flat()
}

/**
 * Checks if product is out of stock
 * @param product product tile
 * @returns boolean flag is product is out of stock
 */
export function isProductOutOfStock(product: ClientProductTile): boolean {
	const comingSoon = product.exclusiveType === ExclusiveType.COMING_SOON
	const soldOut = product.exclusiveType === ExclusiveType.OUT_OF_STOCK
	return !product.orderable && !(comingSoon || soldOut)
}

/**
 * Checks if the products query is out of range.
 * @param productList - The list of products.
 * @returns A boolean indicating whether the products query is out of range.
 */
export const productsQueryOutOfRange = (productList?: ServerProductsList | null) => {
	const { totalCount, products } = productList || {}
	return !!(totalCount && !products?.length)
}

/**
 * Returns a product's total count of colors with images
 * @param product
 * @returns total count of colors with images
 */
export const getProductColorCount = (product: ClientProductTile): number =>
	product.colors?.filter((color) => color.assets?.images?.[0])?.length || 0

/**
 * Return color count a product tile based on non-mobile view and limit size
 * @param totalColorCount product color count
 * @param limit max number of colors returned
 * @param isDesktop if is desktop view
 * @returns color count for the product tile
 */
export const getColorDisplayCount = ({
	totalColorCount,
	limit,
	isDesktop,
}: {
	totalColorCount: number
	limit?: Maybe<number>
	isDesktop: boolean
}): number | null => {
	if (limit && !isDesktop) {
		if (totalColorCount > limit) {
			return totalColorCount - limit
		}
		return null
	}
	return totalColorCount
}

const isColorVariationAssetDetail = (ob: ColorVariationAssetDetail | unknown): ob is ColorVariationAssetDetail =>
	(ob as ColorVariationAssetDetail)?.assetName !== undefined ||
	(ob as ColorVariationAssetDetail)?.viewType !== undefined

const isShopTheLookImage = (ob: ShopTheLookImage | unknown): ob is ShopTheLookImage =>
	(ob as ShopTheLookImage)?.materialCodes !== undefined && (ob as ShopTheLookImage)?.image !== undefined

const isProductTileImage = (ob: ProductTileImage | unknown): ob is ProductTileImage =>
	(ob as ProductTileImage)?.urlHov !== undefined &&
	(ob as ProductTileImage)?.url !== undefined &&
	(ob as ProductTileImage)?.alt !== undefined

const isClientProductTileImage = (ob: ClientProductTileImage | unknown): ob is ClientProductTileImage =>
	(ob as ClientProductTileImage)?.urlHov !== undefined &&
	(ob as ClientProductTileImage)?.url !== undefined &&
	(ob as ClientProductTileImage)?.alt !== undefined

const mapImageType = (
	image: ColorVariationAssetDetail | ShopTheLookImage | Partial<ProductTileImage> | ClientProductTileImage | undefined,
	hovImage?: ColorVariationAssetDetail | ShopTheLookImage | Partial<ProductTileImage> | ClientProductTileImage,
) => {
	if (isClientProductTileImage(image)) {
		return {
			alt: image.alt || '',
			url: image.url || '',
			urlHov: image.urlHov || image.url || '',
		}
	}

	if (isColorVariationAssetDetail(image)) {
		return {
			alt: image.assetName || '',
			url: image.url || '',
			urlHov: image.url || '',
		}
	}

	if (isShopTheLookImage(image)) {
		return {
			alt: image.image || '',
			url: productTileImgBuilder(image.image),
			urlHov: productTileImgBuilder(hovImage && 'image' in hovImage ? hovImage.image : image.image),
		}
	}

	if (isProductTileImage(image)) {
		return {
			alt: image.alt || '',
			url: image.url || '',
			urlHov: image.urlHov || image.url || '',
		}
	}

	return {
		url: PRODUCT_FALLBACK_IMAGE,
		urlHov: PRODUCT_FALLBACK_IMAGE,
		alt: 'Fallback Image',
	}
}

interface ProductTileImage {
	alt: string
	url: string
	urlHov: string
}

/**
 * This returns the tile image to use for a given color.  It follows these rules:
 *
 *    1. Are there shop the look colors and is a preferred size specified?
 *      - If there is a color that has the preferred size imagery, use that.
 *      - If there is no color with the preferred size imagery, use the given color
 *      - If no given color then use the product image
 *      - If no product image then use fallback image
 *    2. Was a color given
 *      - Does the color have images? Use that image
 *      - Does the product have images? Use that image.
 *      - Otherwise, use the fallback image
 *    3. No color given
 *      - Does the product have an image? Use that image.
 *      - Otherwise use fallback image.
 *
 *
 * @param color The color to use assuming there isn't a preferred size image available
 * @param product The top level product (used to see if it has shopTheLookColors and to get it's top level image if needed)
 * @param preferredSize The preferred size image to show (for example XL)
 * @param isHollowImage
 * @returns A product tile image that has a url, a hover url and alt text.
 */
export const getTileImage = (
	color: ColorVariation | null,
	product: ClientProductTile | ClientProductData,
	preferredSize: SizeMapTypes | null,
	isHollowImage: boolean,
): ProductTileImage => {
	const { shopTheLookColors } = product

	// If there is a size preference and there are shop the look colors available then
	//	try to find a match between a shop the look size and view preference size.
	if (shopTheLookColors && preferredSize) {
		// Check if the default color matches any of the shop the look colors
		const matchingShopTheLookColor = shopTheLookColors.find((c) => findColorMatch(c?.color, color))
		// If we have a match on color then let's check the size next.
		if (matchingShopTheLookColor && matchingShopTheLookColor.images) {
			// If there are any models that have the same size as the given size then
			//	use that color image, otherwise skip it.
			const shopTheLookImages = ensureNonNullishArray(
				matchingShopTheLookColor.images.filter((img) => img && img.modelSize && img.modelSize === preferredSize),
			)

			// If there are any images that match the size and color of the shop the look preference,
			//	then use that, otherwise skip.
			if (shopTheLookImages?.length > 0 && shopTheLookColors?.length > 0) {
				const frontImage = shopTheLookImages[0]
				const backImage = shopTheLookImages[1] ?? undefined
				return mapImageType(frontImage, backImage)
			}
		}
	}

	if (color) {
		// If we have a default color, then just use the image associated with that color
		return getProductTileImage([color], color.assets?.alt || '')
	}
	if (isHollowImage && product.image) {
		const { assetName, alt, url } = product.image
		if (assetName && assetName.includes('-') && assetName.includes('_')) {
			const colorId = assetName?.split('-')?.pop()?.split('_')[0]
			const imageHost = getPublicConfig().cms.scene7_base_url
			const imagePrefix = '/PS'
			const imageName = `${imageHost}${imagePrefix}${product.style}-${colorId}`
			const imageUrl = new URL(url)
			imageUrl.searchParams.set('bgc', 'FFFFFF')
			const urlPrepend = imageUrl.toString().split(assetName)[1]
			const imageObject = {
				alt,
				url: `${imageName}_HF${urlPrepend}`,
				urlHov: `${imageName}_HB${urlPrepend}`,
			}
			return mapImageType(imageObject)
		}
	}
	// If all else fails then just use whatever image is associated with the product.
	return mapImageType(product.image)
}

/**
 * Returns a Boolean value indicating that the selectedVariant is available for exchange or no.
 *
 * @param variant The product variant we calculate for
 * @param validSkus Array with valid string skus
 */
export const isVariantAllowedToExchange = (
	variant: ClientProductVariantDetail | undefined,
	validSkus: string[] | null | undefined,
): boolean => {
	if (!variant?.sku || !validSkus) return false
	return validSkus.includes(variant.sku)
}

/**
 * Returns the quantity available for exchange/return for given OisProductItem.
 *
 *   We exclude quantityReturned, quantityExchanged and quantityCancelled(if exist)
 *
 * @param product The OisProductItem for which we calculate the quantity available for exchange/return
 */
export const getProductAllowedForReturnQty = (product: OisProductItem): number =>
	product.quantity - product.quantityReturned - product.quantityExchanged - (product?.quantityCancelled || 0)
interface InseamDataArgs {
	productSpecs: Optional<Maybe<string[]>> | undefined
	searchKeyword: string
}

/**
 * @param productSpecs array of strings from product.specs
 * @param searchKeyword translated word "inseam" that is what we are looking for to be present in the productSpecs array
 * @returns either the string that contains the searchKeyword, or undefined if it is not found in the array
 */
export function productHasInseamInfo({ productSpecs = [], searchKeyword }: InseamDataArgs): string | undefined {
	let isFound: string | undefined

	if (productSpecs) {
		productSpecs.forEach((spec) => {
			// to be safe, just toLowerCase everything because we are doing string matching
			const currentSpec = spec.toLowerCase()
			const inseamData = currentSpec.includes(searchKeyword)

			if (inseamData) {
				isFound = currentSpec
			}
		})
	}
	return isFound
}

/**
 * @param inputString string from product.specs array contains the word "inseam" (translated for locale)
 * @returns the inseam length as a number
 */
export function extractNumberFromString(inputString: string): number | undefined {
	// Use a regular expression to search for a number in the string
	const match = inputString.match(/\d+(\.\d+)?/)
	let inseamMeasurement: number | undefined

	if (match) {
		inseamMeasurement = Number(match[0]) // convert to a number as is with no rounding or manipulation.
	}
	return inseamMeasurement
}

export interface SizeLengthInfo {
	S: number | string
	R: number | string
	T: number | string
}

/**
 * This returns an object containing formatted strings to be displayed on the UI under the extended sizes tabs. This is the last line in a chain of helper functions that identifies if there's a string containing the word "inseam" (translated to the respective locale) and if so, find the number in the string, and construct new strings to be displayed that are mapped to the size chips. This function indescriminantly will return the same data shape where "R" "S" & "T" are the data keys to allow for the greatest flexibility. The absence of a needed key/value will break where it's being used, whereas the presense of an uneeded key/value will not.
 *
 * @param formatMessage method in order to translate a string based on passing a mapped key
 * @param inseamInfo string extracted from product specs with inseam data. If not present, a default message will be displayed
 * @param locale the browsers set language and country.
 * @returns An object where keys are the extended size and values are their respective strings to display for inseam data
 */
export function formatExtendedSizeStrings({ formatMessage, inseamInfo, locale }) {
	// 1. Set up the values and variables needed
	// - extendedSizeDifferentialsByLocale contains the +/- number that will be applied to the inseam length.
	// - 2 is inches while 5 is cm
	// - In the future only en-us should need to be 2, while the rest can default to 5, but right now en-ca returns inches  for the inseam length
	const extendedSizeDifferentialsByLocale = { 'en-us': 2, 'en-ca': 2, 'fr-ca': 5 }
	const differential = extendedSizeDifferentialsByLocale[locale]

	// 2. Create the object that we will manipulate and return at the end.
	// - We will add the inseam length to all values if there is inseamInfo, so the starting value is in accordance
	// - S: starts at diffential * -1, because when we add inseamInfo, it is equivalent to (inseamInfo - differential)
	// - R: starts at 0, because when we add inseamInfo, it will equal the original starting number
	// - L starts at differential, because when we add inseam info, it is equivalent to (inseamInfo + differential)
	const sizeLengthInfo = { S: differential * -1, R: 0, T: differential } as SizeLengthInfo
	const sizeKeys = Object.keys(sizeLengthInfo)

	// 3. If there is a string containining inseam information we will do the following
	if (inseamInfo) {
		const inseamLength = extractNumberFromString(inseamInfo)
		sizeKeys.forEach((sizeKey: string) => {
			const length = sizeLengthInfo[sizeKey] + inseamLength
			const extendedSize = formatMessage(`extended-sizes-${sizeKey.toLowerCase()}`).toLowerCase()
			sizeLengthInfo[sizeKey] = formatMessage('extended-sizes-inseam', { extendedSize, length })
		})
	} else {
		// 4. If there is no inseam data (like for shirts that have a tall option), we will not do any manipulation, and all entries will be the same static string. For the sake continuity, we just populate each key/value with the same value
		sizeKeys.forEach((sizeKey: string) => {
			sizeLengthInfo[sizeKey] = formatMessage('extended-size-length-no-inseam')
		})
	}
	return sizeLengthInfo
}

/**
 * calculates if low inventory message should be shown based on product inventory data of selected variation and other product parameters
 *
 * @param arguments which contain properties `showUnavailableMessage`, `variantOutOfStock`, `forceOutOfStockError` flags and `selectedVariant` object
 * @returns boolean
 */
export const shouldLowInventoryMessageBeShown = ({
	selectedVariant,
	showUnavailableMessage,
	variantOutOfStock,
	forceOutOfStockError,
}) =>
	!!(
		selectedVariant &&
		isVariantWithLowInventory(selectedVariant) &&
		!(showUnavailableMessage || variantOutOfStock || forceOutOfStockError)
	)

export function productsInBothShippingAndPickup(pickupItems: CartProduct[], shippingItems: CartProduct[]) {
	const result = new Set<string>()
	shippingItems.forEach((s) => {
		if (pickupItems.some((p) => p.sku === s.sku)) {
			result.add(s.sku)
		}
	})

	return result
}

export const sizePatterns = {
	unisex: /^(\d{1,2}(?:\.5)?)\/(\d{1,2}(?:\.5)?)$/,
	pants: /^([0-9]{2})\/([0-9]{2})$/,
	bras: /^([0-9]{2})([a-zA-Z]{1,3})$/,
	infinityBras: /^([a-zA-Z]{1,3})\s(A-C|D-DD)$/,
	golfGloves: /^([LR]Y?)((XXS|XS|SM|MD|LG|XL|XXL|2XL|3XL|4XL|5XL|ML)C?)$/,
	inclusiveBras: /^([0-9]{1}X)\s(A-C|D-DD|DDD)$/,
}

export const genders: Gender[] = ['men', 'women']
export const unisexGenders = ['Unisex', 'Adult Unisex', 'Youth Unisex']

/**
 * Determines the size type based on which we split product sizes into multiline radio groups.
 *
 * @param {ClientProductDetail} product - The product details.
 * @returns {SplitSizeType | undefined} The split size type, or undefined if no match is found.
 */
export function getSplitSizeType(
	product: Pick<ClientProductDetail, 'gender' | 'silhouette' | 'sizes'>,
): SplitSizeType | undefined {
	if (
		unisexGenders.includes(product.gender || '') &&
		product?.sizes?.every(({ size }) => sizePatterns.unisex.test(size))
	) {
		return 'unisex'
	}
	if (
		product.gender === 'Mens' &&
		product.silhouette === 'Bottoms' &&
		product?.sizes?.every(({ size }) => sizePatterns.pants.test(size))
	) {
		return 'pants'
	}
	if (product.gender === 'Womens' && product.silhouette === 'Bras') {
		if (product?.sizes?.every(({ size }) => sizePatterns.bras.test(size))) {
			return 'bras'
		}
		if (product?.sizes?.every(({ size }) => sizePatterns.infinityBras.test(size))) {
			return 'infinityBras'
		}
		if (product?.sizes?.every(({ size }) => sizePatterns.inclusiveBras.test(size))) {
			return 'inclusiveBras'
		}
	}
	if (product.silhouette === 'Gloves' && product?.sizes?.every(({ size }) => sizePatterns.golfGloves.test(size))) {
		return 'golfGloves'
	}
	return undefined
}

/**
 * Formats the size name based on the split size type and value.
 *
 * @param {Object} params - The parameters.
 * @param {Function} params.formatMessage - The function for formatting messages based on current locale.
 * @param {string} params.value - The size value(key).
 * @param {SplitSizeType} params.splitSizeType - The split size type. (optional)
 * @returns {string} The formatted size name.
 */
export function getFormattedSizeName({
	formatMessage,
	value,
	splitSizeType,
}: {
	formatMessage: ReturnType<typeof useFormatMessage>
	value: string
	splitSizeType?: SplitSizeType
}) {
	return (splitSizeType === 'golfGloves' && ['R', 'L', 'RY', 'LY'].includes(value)) ||
		(splitSizeType === 'unisex' && ['men', 'women'].includes(value))
		? formatMessage(`size-name-${splitSizeType}-${value}`)
		: value
}

export type SplitSizesLookup = {
	first: {
		[key: string]: { [key: string]: ExtendedSizeVariation | ExtendedSizeOption }
	}
	second: {
		[key: string]: { [key: string]: ExtendedSizeVariation | ExtendedSizeOption }
	}
}

function isSizeOption(x: unknown): x is SizeOption {
	return (x as SizeOption)?.__typename === 'SizeOption'
}

/**
 * Create a lookup object to use with split sizing
 *
 * @param {Object} params - The parameters.
 * @param {ExtendedSizeVariation[] | SizeOption[]} params.sizes - An array of sizes to proccess.
 * @param {Function} params.formatMessage - The function for formatting messages based on current locale.
 * @param {SplitSizeType} params.splitSizeType - The split size type.
 * @returns {SplitSizesLookup} The lookup object.
 */
export function getSplitSizeLookup({
	sizes,
	splitSizeType,
	formatMessage,
}: {
	sizes: (ExtendedSizeVariation | SizeOption)[]
	splitSizeType: SplitSizeType
	formatMessage: ReturnType<typeof useFormatMessage>
}): SplitSizesLookup {
	const nameKey = isSizeOption(sizes[0]) ? 'displayName' : 'name'
	return sizes.reduce(
		(acc, sizeEntry: ExtendedSizeVariation | SizeOption, index) => {
			const sizeParts = sizeEntry.size.match(sizePatterns[splitSizeType])
			if (sizeParts?.[1] && sizeParts?.[2]) {
				if (splitSizeType === 'unisex') {
					genders.forEach((genderId, i) => {
						const genderSize = sizeParts[i + 1]
						const splitSizeVariation = {
							...sizeEntry,
							gender: genderId,
							index,
							name: getFormattedSizeName({ value: genderSize, splitSizeType, formatMessage }),
						}

						if (acc.first[genderId]) {
							acc.first[genderId][genderSize] = splitSizeVariation
						} else {
							acc.first[genderId] = { [genderSize]: splitSizeVariation }
						}

						const splitSizeVariation2 = {
							...sizeEntry,
							gender: genderId,
							index,
							name: getFormattedSizeName({ value: genderId, splitSizeType, formatMessage }),
						}
						if (acc.second[genderSize]) {
							acc.second[genderSize][genderId] = splitSizeVariation2
						} else {
							acc.second[genderSize] = { [genderId]: splitSizeVariation2 }
						}
					})
					return acc
				}
				const splitSizeEntry = {
					...sizeEntry,
					splitValue: sizeParts,
					[nameKey]: getFormattedSizeName({ value: sizeParts[2], splitSizeType, formatMessage }),
				} as typeof sizeEntry
				if (acc.first[sizeParts[1]]) {
					acc.first[sizeParts[1]][sizeParts[2]] = splitSizeEntry
				} else {
					acc.first[sizeParts[1]] = { [sizeParts[2]]: splitSizeEntry }
				}

				const splitSizeEntry2 = {
					...sizeEntry,
					splitValue: sizeParts,
					[nameKey]: getFormattedSizeName({ value: sizeParts[1], splitSizeType, formatMessage }),
				} as typeof sizeEntry
				if (acc.second[sizeParts[2]]) {
					acc.second[sizeParts[2]][sizeParts[1]] = splitSizeEntry2
				} else {
					acc.second[sizeParts[2]] = { [sizeParts[1]]: splitSizeEntry2 }
				}
			}
			return acc
		},
		{ first: {}, second: {} } as SplitSizesLookup,
	)
}

/**
 * A list of all first split sizes, with either the matching second split size variant/size data
 * attached or the next available variant/size data if that combination does not exist.
 *
 * @param {Object} params - The parameters.
 * @param {SplitSizesLookup} params.splitSizesLookup - A lookup object created with getSplitSizeLookup().
 * @param {string} params.selectedSize - The currently selected size (optional).
 * @param {SplitSizeType} params.splitSizeType - The split size type.
 * @returns {(ExtendedSizeVariation | ExtendedSizeOption)[]} An array of sizes for the first split group.
 */
export function getSplitSizesFirstSplit({
	splitSizesLookup,
	selectedSize,
	splitSizeType,
	selectedGender,
	formatMessage,
}: {
	splitSizesLookup: SplitSizesLookup
	selectedSize?: string | undefined
	splitSizeType: SplitSizeType
	selectedGender?: Gender
	formatMessage: ReturnType<typeof useFormatMessage>
}): (ExtendedSizeVariation | ExtendedSizeOption)[] {
	const isUnisexWithSelectedGender = splitSizeType === 'unisex' && selectedGender
	const selectedSizeParts = selectedSize
		? selectedSize.match(sizePatterns[splitSizeType])
		: Object.values(Object.values(splitSizesLookup.first)[0])[0].size.match(sizePatterns[splitSizeType])
	if ((selectedSizeParts?.[1] && selectedSizeParts?.[2]) || isUnisexWithSelectedGender) {
		let sizePart = selectedSizeParts?.[2]
		const result = Object.keys(splitSizesLookup.first).reduce((acc, firstPart) => {
			if (isUnisexWithSelectedGender && selectedSizeParts) {
				sizePart = selectedSizeParts[genders.indexOf(firstPart as Gender) + 1]
			}
			let sizeVariation = sizePart ? splitSizesLookup.second[sizePart][firstPart] : undefined
			if (!sizeVariation) {
				const backupSize = Object.values(splitSizesLookup.first[firstPart])[0]
				if (backupSize) {
					sizeVariation = isSizeOption(backupSize)
						? ({
								...backupSize,
								displayName: getFormattedSizeName({ value: firstPart, splitSizeType, formatMessage }),
						  } as ExtendedSizeOption)
						: ({
								...backupSize,
								name: getFormattedSizeName({ value: firstPart, splitSizeType, formatMessage }),
								orderable: !!selectedGender,
								variant: {
									...backupSize.variant,
									orderable: !!selectedGender,
								},
						  } as ExtendedSizeVariation)
				}
			}
			if (sizeVariation) acc.push(sizeVariation)
			return acc
		}, [] as (ExtendedSizeVariation | ExtendedSizeOption)[])
		return isUnisexWithSelectedGender ? result : result.sort((a, b) => (a.index || 0) - (b.index || 0))
	}
	return [] as (ExtendedSizeVariation | ExtendedSizeOption)[]
}

/**
 * A list of second split sizes for the matching first split size
 *
 * @param {Object} params - The parameters.
 * @param {SplitSizesLookup} params.splitSizesLookup - A lookup object created with getSplitSizeLookup().
 * @param {string} params.selectedSize - The currently selected size.
 * @param {SplitSizeType} params.splitSizeType - The split size type.
 * @returns {(ExtendedSizeVariation | ExtendedSizeOption)[]} An array of sizes for the second split group.
 */
export function getSplitSizesSecondSplit({
	splitSizesLookup,
	selectedSize,
	splitSizeType,
	selectedGender,
}: {
	splitSizesLookup: SplitSizesLookup
	selectedSize?: string | undefined
	splitSizeType: SplitSizeType
	selectedGender?: Gender
}): (ExtendedSizeVariation | ExtendedSizeOption)[] {
	if (splitSizeType === 'unisex' && selectedGender) {
		return Object.values(splitSizesLookup.first[selectedGender]).sort((a, b) => (a.index || 0) - (b.index || 0))
	}
	const selectedSizeParts = selectedSize
		? selectedSize.match(sizePatterns[splitSizeType])
		: Object.values(Object.values(splitSizesLookup.first)[0])[0].size.match(sizePatterns[splitSizeType])
	if (selectedSizeParts?.[1] && selectedSizeParts?.[2]) {
		return Object.values(splitSizesLookup.first[selectedSizeParts[1]]).sort((a, b) => (a.index || 0) - (b.index || 0))
	}
	return [] as (ExtendedSizeVariation | ExtendedSizeOption)[]
}

export function isProductUpc(val: string) {
	return val.length === 12 && !Number.isNaN(Number(val))
}

export function isProductStyle(val: string) {
	return val.length < 12 && !Number.isNaN(Number(val))
}

export function isProductSku(val: string) {
	const sku = skuParser(val)
	return sku !== null && sku.styleCode !== undefined
}

export function getAllowedQuantity(selectedQuantity: number, inventoryStockLevel: number) {
	const stockLevel = Math.max(1, inventoryStockLevel)
	return stockLevel > selectedQuantity ? selectedQuantity : stockLevel
}

export function productOosAfterStoreChange(pickupItems: CartProduct[], products: CartProduct[]): string[] | undefined {
	const productsOos: string[] = []

	pickupItems.forEach((item) => {
		const product = products?.find((product) => item.sku === product.sku)
		if (product && !product.cFromStoreId) {
			productsOos.push(product?.sku)
		}
	})

	return productsOos.length > 0 ? productsOos : undefined
}

export type BopisContactInfo = {
	firstName: string
	lastName: string
}

export function getBopisContactInfo(profileContact?: UserProfileInfo): BopisContactInfo | undefined {
	const bopisContactStorage = window.localStorage.getItem(CUSTOMER_BOPIS_CONTACT_STORAGE_KEY)
	const bopisContact = bopisContactStorage || undefined

	if (bopisContact) {
		return {
			firstName: bopisContact.split(' ')?.[0],
			lastName: bopisContact.split(' ')?.[1],
		}
	}
	if (profileContact?.firstName && profileContact?.lastName) {
		return {
			firstName: profileContact?.firstName,
			lastName: profileContact?.lastName,
		}
	}
	return undefined
}

export type ProductIdentifier =
	| {
			style: string
	  }
	| {
			sku: string
	  }
	| {
			upc: string
	  }
	| {
			unknown: string
	  }

export function getProductIdentifier(product: ClientProductData): ProductIdentifier {
	let id: ProductIdentifier

	if (product.style && isProductStyle(product.style)) {
		// STYLE IS ACTUAL STYLE
		id = {
			style: product.style,
		}
	} else if (product.style && isProductSku(product.style)) {
		// STYLE IS SKU
		id = {
			sku: product.style,
		}
	} else if (isProductStyle(product.id)) {
		// ID IS STYLE
		id = {
			style: product.id,
		}
	} else if (isProductUpc(product.id)) {
		// ID IS UPC
		id = {
			upc: product.id,
		}
	} else {
		// WE DON'T KNOW WHAT IT IS
		id = {
			unknown: product.id,
		}
	}
	return id
}

export function getProductLineItemRevenue(product: CartProduct) {
	return String(
		product.totalPrice?.totalAfterOrderDiscount
			? Math.min(product.totalPrice.totalAfterItemDiscount, product.totalPrice.totalAfterOrderDiscount)
			: product.totalPrice?.totalAfterItemDiscount || product.totalPrice?.base,
	)
}

export function getSelectedVariantPrice(selectedVariant: ClientProductVariantDetail | undefined): number {
	const list = selectedVariant?.prices?.list || 0
	const sale = selectedVariant?.prices?.sale || 0

	return Math.min(list, sale)
}

/**
 * Given a list of all refinements, this function returns a list of all active/selected refinements
 */
export function getSelectedRefinementValues(
	populatedRefinements: SearchRefinementAttribute[],
): SearchRefinementAttributeValue[] {
	return populatedRefinements.reduce<SearchRefinementAttributeValue[]>((acc, refinement) => {
		const values = refinement.values.filter((value) => value.selected)
		return acc.concat(values)
	}, [])
}

/**
 * Given a list of all refinements and a label, this function returns a refinement category ID by value label
 */
export function getRefinementCategoryIdByLabel(populatedRefinements: SearchRefinementAttribute[], label: string) {
	return populatedRefinements.find((refinement) => refinement.values.some((r) => r.label === label))?.attributeId
}
