/* eslint-disable @typescript-eslint/naming-convention */
/*
 * https://github.com/ua-digital-commerce/ua-tealium/wiki
 * */

import type { Modify, Optional } from 'types/strict-null-helpers'
import type { UserWithSession } from '~/lib/client-only/auth/types'
import type { Cart } from '~/lib/types/cart.interface'
import type { NotifyMeActionData } from '~/lib/types/notifyMe.interface'
import type { BasePageProps } from '~/lib/types/pageprops.interface'
import type { LoyaltyEventType } from '~/graphql/generated/uacapi/type-document-node'
import type { EnrollmentSource } from '~/lib/types/loyalty.interface'
import type { CartQtyChangeEventData } from '@ua-digital-commerce/beacon-bridge/dist/core/src/event'

export type GlobalAnalyticsData = GlobalUserData & GlobalSiteData & GlobalCartData
export type DataLayerInterface = GlobalAnalyticsData &
	GlobalSearchResultsPageData &
	GlobalProductDetailPageData &
	GlobalProductListData &
	GlobalCartPageData &
	GlobalCheckoutPageData &
	GlobalConfirmationPageData &
	GlobalFeaturesPageData &
	GlobalCampaignData

export type AnalyticBoolean = 'yes' | 'no'
export type CustomerType = 'Existing' | 'New'
export type SiteType = 'sfra-headless' | 'sfra-responsive'
export type VisitorType = 'guest' | 'employee' | 'vip' | 'logged in returning' | 'logged in new'
export type ThirdPartyPaymentType = 'klarna' | 'applePay'

export interface GlobalSiteData {
	site_country_code: string
	site_currency: string
	site_language: string
	site_type: SiteType
}

interface GlobalFeaturesPageData {
	features: string
}

export interface GlobalUserData {
	customer_id: string
	logged_in_status: 'Logged In' | 'Guest'
	session_id: string
	visitor_type: VisitorType
	plain_text_email: string
	type: '' | 'ua'
	opt_in?: boolean
	opted_in_SMS?: boolean
	registered_on?: string | null
}

///
///  PAGE DATA
///
export interface GlobalPageData {
	content_asset_name?: string[]
	content_modules: string[]
	site_section: string
	products: CartProductData[] | ProductDetailProduct[] | ProductGridItem[]
	full_url: string
	page_meta_path: string
	navigation_structure: string
	app_version: string
	page_category: string
	page_category_id: string
	page_subcategory1: string
	page_subcategory2: string
	page_subcategory3: string
	page_subcategory4: string
	page_type: string
	page_name: string
	page_finding_method: string
	page_finding_method_style: string
	featured_products?: {
		state: 'viewed' | null
	}
	ugc?: {
		state: 'loaded' | null
	}
	hotspots?: {
		state: 'viewed' | null
	}
	content_carousel?: {
		state: 'loaded' | 'viewed' | null
	}
	refinement_type?: string
	refinement_attribute?: string
}

export interface GlobalProductListData extends Partial<GlobalPageData> {
	grid_refinement_attributes: GridRefinementAttribute[]
	grid_has_guidedselling: boolean
	grid_has_loadmore: boolean
	grid_paging_offset: number
	grid_single_ingrid: number
	grid_double_ingrid: number
	grid_sort_order: string
	grid_stack_count: number
	grid_top_content: string
	grid_total_count: number
	grid_video_count: number
	grid_visible_count: number
}

export interface GlobalSearchResultsPageData {
	search_term: string
	search_type: '' | 'Null' | 'Redirect' | 'Regular'
	search_method: string
	search_location: string
	search_results_count: number
}

export interface GlobalProductDetailPageData {
	pdp_type: 'regular' | 'regular|inclusive'
	pdp_360_video: boolean
	pdp_merch_product_stack: string
	pdp_price_type: 'on-sale' | 'full'
	pdp_combined_style: string
	pdp_extended_sizing: boolean
	pdp_outofstock: boolean
	pdp_discount_exclusions: boolean
	pdp_experience_type: string
	pdp_gender: string
	pdp_primary_category_id: string
}

export interface GlobalCampaignData {
	internal_campaign_asset_name: string
	internal_campaign_module: string
	internal_campaign_link: string
	internal_campaign_cta_text: string
	internal_campaign_placement: string
	internal_campaign_snipe: string
	internal_campaign_headline: string
	external_campaign_id?: string
}

export interface GlobalCartPageData {
	noop?: number // TODO: As of now, there are no cart page data items that differ from global page data
}

export enum CheckoutStep {
	SHIPPING = 'shipping',
	PAYMENT = 'payment',
	CONTACT = 'contact',
	RECEIPT = 'order-receipt',
}

export interface GlobalCheckoutPageData {
	checkout_step: CheckoutStep | ''
	gift_box_checked: boolean
	site_shipto_country: string
}

export interface GlobalConfirmationPageData {
	customer_type: CustomerType
	order_id: string
	order_checkout_optin: AnalyticBoolean
	order_discount: string
	order_flags: [] | ['hold_at_location']
	order_merchandize_tax: string
	order_payment_method: string
	order_promo_codes?: {
		order_promo_classes?: string
		order_promo_codes?: string
		order_promo_discount?: string
		order_promo_names?: string
		order_promo_segments?: string
		order_promo_trigger_ids?: string
		order_promo_trigger_types?: string
	}[]
	order_type: string
	order_shipping_method: string
	order_shipping_revenue: string
	order_shipping_subtotal: string
	order_shipping_tax: string
	order_subtotal: string
	order_tax: string
	order_total: string
	promo_code: string
	site_currency: string
	loyalty?: {
		loyalty: boolean
		action: LoyaltyEventType
		loyalty_points_earned: number
		loyalty_type_of_reward_action: string[]
	}
}

///
/// SUBTYPES
///
export interface GlobalCartData {
	cart_subtotal: number
	cart_shipping: string
	cart_discount: number
	cart_total: number
	cart_tax: number
	cart_item_count: number
}

export interface GridRefinementAttribute {
	grid_refinement_attributes: string
}

export interface ProductDetailProduct {
	product_id: string
	product_name?: string
	product_rating?: number
	product_review_count?: number
	product_gender?: Optional<string>
	product_preorder?: Optional<boolean>
	product_style?: string
	product_color?: string
	product_sku?: string
	product_price?: number
	product_msrp?: number
	product_onsale?: boolean
	product_bopis?: boolean
	product_bopis_available?: boolean
	product_bopis_stock?: boolean
	product_bopis_selected?: boolean
	product_bopis_message?: string
	product_inventory_stock_level?: number
	product_image_count?: number
	product_alert_text?: string[]
	product_badge_text?: string
	product_tech_icon?: string
	product_options_color_total?: number
	product_options_color_full?: number
	product_options_size_total?: number
	product_options_size_full?: number
	product_silhouette?: string
	product_size_prepopulated?: boolean
	complete_look?: boolean
	complete_look_recommended?: boolean
	product_image_url?: string
	product_url?: string
}

export type ProductGridItem = Pick<ProductDetailProduct, 'product_id' | 'product_style'> & {
	product_grid_position?: string
}

export interface CartProductData {
	product_id: string
	qty_from?: number
	complete_look_recommended?: boolean
	product_bopis: boolean
	product_bopis_available: boolean
	product_bopis_message: string
	product_bopis_selected: boolean
	product_bopis_stock: boolean
	product_color: string
	product_exchange_rate?: string
	product_exchange_rate_usd?: string
	product_gender?: string
	product_line_item_price?: string
	product_line_item_revenue?: string
	product_line_item_tax?: string
	product_line_item_coupon_discount?: string
	product_line_item_customergroup_discount?: string
	product_line_item_sourcecode_discount?: string
	product_msrp: number
	product_name: string
	product_onsale?: boolean | string
	product_outlet?: boolean
	product_oos?: string
	product_preorder?: boolean | undefined | null
	product_price: number
	product_quantity: number
	product_silhouette?: string
	product_sku: string
	product_style: string
	product_uuid?: string
}

export interface PageStateData {
	pageProps: BasePageProps
	locale: string
	userSession: UserWithSession
	cartData: Cart | null
}

///
/// FIRED ACTIONS TYPES
///
export enum AnalyticsAction {
	modalOpened = 'modalOpened',
	genericLink = 'genericLink',
	favoritesAdd = 'favoritesAdd',
	favoritesRemove = 'favoritesRemove',
	pageView = 'pageView',
	loyaltyAction = 'genericLink',
	pageStateChanged = 'pageStateChanged',
	loginAttempt = 'loginAttempt',
	loginSuccess = 'loginSuccess',
	errorMessageShown = 'errorMessageShown',
	emailSubscribed = 'emailSubscribed',
	cartAdd = 'cartAdd',
	cartRemove = 'cartRemove',
	cartQtyChange = 'cartQtyChange',
	checkoutStepChange = 'checkoutStepChange',
	lastTouchedCheckoutField = 'lastTouchedCheckoutField',
	registerSuccess = 'registerSuccess',
	productSellingTool = 'productSellingTool',
	promoCodeAttempt = 'promoCodeAttempt',
	thirdPartyPayStart = 'thirdPartyPayStart',
	checkoutPaypal = 'checkoutPaypal',
	abTestEvent = 'genericLink',
	SFISEvents = 'genericLink',
}

export interface AnalyticsActionDataBase {
	type?: string
}

export interface EmailSubscribedActionData extends AnalyticsActionDataBase {
	plain_text_email: string
	email_hashed: string
	opt_in_source: string
	opt_in_location: string
}

export interface EmailChangedActionData extends AnalyticsActionDataBase {
	plain_text_email: string
	previous_email: string
}

export interface ErrorMessageShownActionData extends AnalyticsActionDataBase {
	error_name: string
	error_message: string
}

export interface LastTouchedCheckoutFieldActionData extends AnalyticsActionDataBase {
	abandon_checkout_field: string
}

export interface QuickAddToCartData extends AnalyticsActionDataBase {
	products: Modify<
		ProductDetailProduct,
		{
			product_msrp?: string
			product_price?: string
		}
	>[]
	quick_atb: {
		state: 'clicked' | 'viewed' | 'closed' | 'pdpRedirect'
		source?: 'savedItemList' | 'hotspots'
	}
}

export type LoginAttemptActionData = ErrorMessageShownActionData

export interface ModalActionData extends AnalyticsActionDataBase {
	site_modal:
		| 'bopis:select a store'
		| 'idmeLogin'
		| 'login'
		| 'new-visitor-modal'
		| 'password-forgot-form'
		| 'privacy info banner'
		| 'register'
		| 'sitewide: promo banner'
}

export interface ProductSellingToolActionData extends AnalyticsActionDataBase {
	pdp_selling_tool:
		| 'order history: submit review'
		| 'pdp: start question'
		| 'pdp: submit question'
		| 'pdp: submit review'
		| 'pdp: view reviews'
	products: {
		product_style: string
		product_rating: number
		product_review_count: number
	}[]
}

export interface RewardTrackingData extends AnalyticsActionDataBase {
	loyalty_video_name?: string
	card_title?: string
	rewards_locker_page?: string
	expert_guidance_video_title?: string
}

export interface LoyaltyActionData extends AnalyticsActionDataBase {
	loyalty: {
		loyalty: boolean
		action: 'subscribe' | 'unsubscribe' | 'claimed_locker' | 'reward_card_open' | 'reward_card_closed_no_interaction'
		type_of_reward?: string
		source?: EnrollmentSource
	} & Partial<RewardTrackingData>
}

export interface FavoritesAddActionData extends AnalyticsActionDataBase {
	products: {
		product_style: ProductDetailProduct['product_style']
		product_color: ProductDetailProduct['product_color']
		product_sku?: ProductDetailProduct['product_sku']
	}[]
}

export interface PromoCodeAttemptData extends AnalyticsActionDataBase {
	state: string
	promo_code: string
	abandon_checkout_field: string
	tealium_event: string
	promo_name?: string
	promo_segment?: string
	error_message?: string
	promo_class?: string
	promo_trigger_id?: string
}

export interface FavoritesRemoveActionData extends AnalyticsActionDataBase {
	products: {
		product_style: ProductDetailProduct['product_style']
		product_color: ProductDetailProduct['product_color']
		product_sku?: ProductDetailProduct['product_sku']
	}[]
}

export interface CartAddProductData extends AnalyticsActionDataBase {
	products: Partial<CartProductData>[]
}

export interface CartRemoveProductData extends AnalyticsActionDataBase {
	products: Partial<CartProductData>[]
	cart_is_mini_cart?: boolean
}

export interface GenericLinkActionData extends AnalyticsActionDataBase {
	product_sku?: ProductDetailProduct['product_sku']
	opted_in_SMS?: boolean
}

export interface NavigationLinkActionData extends AnalyticsActionDataBase {
	navigation_structure: GlobalPageData['navigation_structure']
}

export interface LoginSuccessActionData extends AnalyticsActionDataBase {
	logged_in_status?: GlobalUserData['logged_in_status']
	customer_id?: GlobalUserData['customer_id']
	plain_text_email?: GlobalUserData['plain_text_email']
	idme_group?: string
}

export interface RegisterSuccessActionData extends AnalyticsActionDataBase, GlobalUserData {
	logged_in_status: GlobalUserData['logged_in_status']
	customer_id: GlobalUserData['customer_id']
	plain_text_email: GlobalUserData['plain_text_email']
	type: GlobalUserData['type']
	opt_in: GlobalUserData['opt_in']
	opted_in_SMS?: GlobalUserData['opted_in_SMS']
}

export interface ThirdPartyPayStartData extends AnalyticsActionDataBase {
	tealium_event: string
	type: ThirdPartyPaymentType
}

export interface CheckoutPaypalData extends AnalyticsActionDataBase {
	payPal: boolean
}

export interface FeaturedProductsData extends AnalyticsActionDataBase {
	products?: ProductDetailProduct[]
	featured_products: { state: 'clicked' | 'qatb_clicked' | 'pdpRedirect' }
}

export interface UGCInteractionData extends AnalyticsActionDataBase {
	ugc: { state: 'viewed' | 'clicked' }
}

export interface HotspotInteractionData extends AnalyticsActionDataBase {
	hotspots: {
		state: 'clicked'
		asset?: string
	}
}

export type ContentCarouselClickTarget = 'icon' | 'image' | 'text'

export interface ContentCarouselInteractionData extends AnalyticsActionDataBase {
	content_carousel: {
		state?: 'viewed' | 'clicked'
		content_modules?: string[]
		content_asset_name?: string[]
		content_module_type?: string
		content_modules_navigation?: ('next' | 'previous')[]
		content_modules_clicked?: ContentCarouselClickTarget[]
		content_module_asset?: string
	}
}

interface AbTestEvent {
	abTestEvent: {
		name: string
		payload?: object
	}
}

export type PageViewActionData = Partial<GlobalPageData>

export type FiredActionData =
	| GlobalAnalyticsData
	| ModalActionData
	| PageViewActionData
	| LoyaltyActionData
	| NotifyMeActionData
	| FavoritesAddActionData
	| FavoritesRemoveActionData
	| Partial<GenericLinkActionData>
	| LoginAttemptActionData
	| ErrorMessageShownActionData
	| EmailSubscribedActionData
	| CartAddProductData
	| CartRemoveProductData
	| CartQtyChangeEventData
	| LoginSuccessActionData
	| PromoCodeAttemptData
	| LastTouchedCheckoutFieldActionData
	| QuickAddToCartData
	| FeaturedProductsData
	| UGCInteractionData
	| HotspotInteractionData
	| GlobalCheckoutPageData
	| AbTestEvent
	| ContentCarouselInteractionData

export interface ActionQueueItem {
	action: AnalyticsAction
	data: FiredActionData
}
