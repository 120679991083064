import { getDefaultLocale } from './locale'
import { getPublicConfig } from '~/lib/client-server/config'

const acceptedCurrency = getPublicConfig().accepted_currency

/**
 * Determines which currency type to use based on the locale given.
 * @param locale The locale to find the currency for.
 * @returns
 */
export const getCurrencyByLocale = (locale: string): string => {
	const currencyInfo = locale in acceptedCurrency ? acceptedCurrency[locale] : acceptedCurrency[getDefaultLocale()]
	return currencyInfo.toString()
}

/**
 * This will automatically determine the proper locale to use to format and then do the formatting.
 * For example, if you pass in (32.50 and 'en-us' it will return $32.50 and if you pass in 'fr-ca'
 * it will return $32.50 CAD)
 * @param money The amount to format
 * @param locale The locale to convert the currency to
 * @param options
 * @param truncate Whether to truncate price fraction digits
 * @returns
 */
export function formatMoney(
	money: number,
	locale: string,
	options: Intl.NumberFormatOptions = {},
	truncate = false,
): string {
	const shouldTruncate = options.maximumFractionDigits === 0 || (truncate && !money.toString().includes('.'))

	return new Intl.NumberFormat(locale, {
		style: 'currency',
		currency: getCurrencyByLocale(locale),
		...{ options, maximumFractionDigits: shouldTruncate ? 0 : 2 },
	}).format(money)
}

/**
 * This will take a number and return that number formatted to match the locale
 * currency - for example, if you pass in 54.39 and a locale of fr-fr and a currency
 * of EUR, it will return 54,39.  If you pass in 123456.50 and en-us and a currency
 * of USD, it will return 123,456.50
 * @param money The money to format
 * @param locale The locale to format to
 * @param options The options for the conversion
 * @returns
 */
export function getFormattedMoney(money: number, locale: string, options: Intl.NumberFormatOptions = {}): string {
	const currency = getCurrencyByLocale(locale)
	const allowedMoneyParts: Intl.NumberFormatPartTypes[] = ['integer', 'group', 'decimal', 'fraction']

	const moneyParts = new Intl.NumberFormat(locale, {
		style: 'currency',
		currency,
		...options,
	}).formatToParts(money)
	return moneyParts
		.filter((part) => allowedMoneyParts.includes(part.type))
		.map((part) => part.value)
		.join('')
}
