import type { Optional } from 'types/strict-null-helpers'
import type {
	BasketPartsFragment,
	CategoryExperienceType,
	CouponItem,
	Discount,
	LimitExceededItem,
	IdMeScope,
	PricesRollup,
	ProductExperienceType,
	ProductItem,
	ProductPromotion as Promotions,
	Shipment,
	ShippingMethod,
	ShippingMethodType,
	VariantProduct,
} from '~/graphql/generated/uacapi/type-document-node'
import type { CheckoutStep } from './analytics.interface'

export type CartShippingMethods = Record<ShippingMethodType, ShippingMethod[]>

export interface UserData {
	email: string
	firstName: string
	lastName: string
	isEmployee: boolean
}

export interface TotalPrice {
	base: number
	totalBeforeAdjustments: number
	totalAfterItemDiscount: number
	totalAfterOrderDiscount?: number
}

export interface CartProduct {
	availableForInStorePickup: boolean
	qtyFrom?: number
	masterProductId?: string
	productId?: string
	id: string
	cFromStoreId?: string
	cStoreInventory: number
	sku: string
	currency: string
	quantity: number
	style: string
	url: string
	name: string
	colorCode: string
	color: string
	team?: string
	size: string
	length: string
	prices: PricesRollup
	image: string
	lineItemQuantityLimit?: number
	orderable: boolean
	productPromotions: Promotions[]
	totalPrice: TotalPrice
	preorderMessage: Optional<string>
	promoCalloutAssetID: Optional<string>
	preorderable: Optional<boolean>
	ats: number
	giftCardType?: VariantProduct['giftCardType']
	priceAdjustments: CustomPriceAdjustment[] | []
	eGiftCardDetails?: ProductItem['eGiftCardDetails']
	productExperienceType: ProductExperienceType
	categoryExperienceType: CategoryExperienceType
	outlet: boolean
	oos: string
	productGender: string
	silhouette: string
	shopTheLook: boolean
	upc: string
	cPrimaryContactBOPIS?: string
}

export interface CustomPriceAdjustment {
	__typename?: 'PriceAdjustment'
	appliedDiscount?: Discount | null
	couponCode?: string | null
	id: string
	itemText: string
	price: number
}

export interface CartTotals {
	employeeDiscountAmount: number
	estimatedLoyaltyPoints: number
	giftCardTotal: number
	grandTotal?: number
	orderPriceAdjustments?: CustomPriceAdjustment[]
	orderDiscountAmount: number
	productDiscountAmount: number
	promoAmount: number
	shipping: number
	subTotal: number
	subTotalAfterDiscounts: number
	subTotalBeforeAdjustments: number
	tax?: number
	totals: number
	products?: CartProduct[]
}

export interface Cart extends CartTotals {
	basketId: string
	billingAddress: BasketPartsFragment['billingAddress']
	checkoutStep: CheckoutStep
	contactEmail: string | undefined
	customerNo: Optional<string>
	couponItems?: CouponItem[]
	paymentInstruments: BasketPartsFragment['paymentInstruments']
	products: CartProduct[]
	limitExceededItems: LimitExceededItem[]
	productsMeta: {
		includesBopis: boolean
		includesEgift: boolean
		includesShippable: boolean
		onlyBopis: boolean
		onlyEgift: boolean
		onlyShippable: boolean
	}
	shipments: Shipment[]
	shippingMethods: CartShippingMethods
	uiHints?: BasketPartsFragment['uiHints']
	currency?: BasketPartsFragment['currency']
	idMeScope?: Optional<IdMeScope>
	applicablePaymentMethods?: BasketPartsFragment['applicablePaymentMethods']
}

export type CartProviderError<T = CartProviderErrorCode> = {
	code: T
	error?: Error
	message: string
}

export enum CartProviderErrorCode {
	UNHANDLED_ERROR = 'unknown_error',
	MISSING_BASKET_ID = 'missing_basket_id',
	SHIPPING_ADDRESS_ERROR = 'shipping_address_error',
}

export enum ShippingAddressErrorCode {
	FIRSTNAME = 'first_name',
	LASTNAME = 'last_name',
	ADDRESS1 = 'address1',
	CITY = 'city',
	STATECODE = 'state_code',
	COUNTRYCODE = 'country_code',
	UNKNOWN_ERROR = 'unknown_error',
}

export enum CartProviderApplyGiftErrorCode {
	INVALID_NUMBER = 'invalid_number',
	UNHANDLED_ERROR = 'unknown_error',
}

export enum CartProviderPayPalErrorCode {
	INVALID_ADDRESS = 'invalid_address',
	UNHANDLED_ERROR = 'unknown_error',
}

export enum CartProviderPlaceOrderErrorCode {
	MISSING_BASKET_ID = 'missing_basket_id',
	INVALID_EMAIL = 'invalid_email',
	ITEM_OUT_OF_STOCK = 'item_oos',
	UNHANDLED_ERROR = 'unknown_error',
	VALIDATION_ERROR = 'validation_error',
}

export enum CartProviderProductErrorCode {
	ITEM_UNAVAILABLE = 'item_unavailable',
	MISSING_PRODUCT = 'missing_product',
	UNHANDLED_ERROR = 'unknown_error',
}
