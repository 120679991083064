'use client'

import type { DependencyList } from 'react'
import { useCallback, useEffect, useRef } from 'react'
import { useCart } from '~/components/providers/CartProvider/CartProvider'
import { useSession } from '~/components/providers/UaSessionProvider/UaSessionProvider'
import { Analytics, emptyDataLayer } from '~/lib/analytics'
import { getShippingAddress } from '~/lib/cart'
import { getCountryCodeByLocale } from '~/lib/i18n/locale'
import logger from '~/lib/logger'
import type { GlobalPageData } from '~/lib/types/analytics.interface'
import { useFormatMessage } from './useFormatMessage'
import { useLocale } from './useLocale'
import type { ReadonlyURLSearchParams } from 'next/navigation'
import { usePathname } from 'next/navigation'
import useNavigation from './useNavigation'
import { UaConfigPublicImpl } from '~/lib/client-only/ua-config'
import { getNonLocaleURLSegments } from '~/lib/i18n/urls'

/**
 * HOW TO USE THIS:
 *
 * Call the useAnalyticsSetup() hook in your page component (once) and pass in the following information:
 * 	- allowPageView: true/false - Set to false if you want to prevent the pageview from firing until this is set to true
 *  - pageCategory: This is a convenient way to set the category and subcategory fields of the data layer
 *  - page_name: This will be applied to the datalayer's page_name property
 *  - site_section: This will be applied to the datalayer's site_section property
 *  - page_type: This will be applied to the datalayer's site_section property
 *  - beforePageView: This will be called prior to firing the page view event in the data layer.  You should use this
 * 		to set additional information that might not have been ready when the hook was initialized.
 *
 * The allowPageView is meant to be set based on what is currently available in the page.  It was used for the cart
 * because the cart page does not load data from the server until after the page has loaded and the page view event
 * relies on data in the cart to populate the datalayer.  So we set allowPageView based on the existing of the cart object.
 *
 * HOW DOES THIS WORK:
 *
 * These hooks are used to interact with our Analtics data layer in a way that is
 * consistent across pages no matter how they are loaded.  To understand why this complicated
 * you must first recognize that there are actually a few different loading strategies.
 *
 * 1. Loading a page with no dependencies without navigating from another page on the site
 * 2. Loading a page when navigating from another nextjs page (no full reload)
 * 3. Loading a page when the page needs to gather more information on the client before firing all the necessary data in the page view
 * 4. Loading a page that is the same page type as the previous page but the dependencies have changed\
 *
 * Each of these strategies has to be handled using nuanced approaches.  These are represented in the codebase
 * through different page view firing "sources".  The sources can be one of the following:
 *
 * 1. routechange: This is triggered by a change in the router object's "asPath" which is a representation of the
 * 		NextJS page that generated the page.  For example, /c/mens/socks/
 * 2. dependencieschanged: This is triggered when the hook has been setup with dependencies and those dependencies
 * 		have changed.  If dependencies are given then the routechange strategy won't be used.
 * 3. allowpageview: This is triggered when the hook was setup with allowPageView set to false and then subsequently
 * 		changed to true
 *
 * The implementation relies heavily on the behavior on useEffect.  We use this method for checking for changes
 * in router.asPath, any given dependencies, and the allowPageView  property.  Because useEffect behavior is different
 * when running locally vs. in prod, we hedge our bets by preventing a page view event from firing if the route
 * has not changed since the last time it was fired.
 *
 */

export type PageViewCallbackFunc = (analytics: Analytics, source: string) => void

type AnalyticsSharedData = Pick<GlobalPageData, 'page_name' | 'page_type' | 'site_section'>
type AnalyticsHookProperties = AnalyticsSharedData & {
	pageCategory: string[]
	allowPageView: boolean
	beforePageView?: PageViewCallbackFunc
}

interface AnalyticsHookReturnValue {
	analyticsManager: Analytics
	waitingForAllowPageView: boolean
}

// NOTE: This is private to the hook to prevent others from using
//  a shared instance of this object. This helps us abstract away
//  the implementation details of the hook and gives us some options
//  in the future for changing the way we manage analytics in the future.
const analytics = new Analytics()

/**
 * Use this when you want to get the shared analytics manager instance
 * @returns Returns the single instance of the analytics manager
 */
export function useAnalytics() {
	return {
		analyticsManager: analytics,
	}
}

/**
 * If a dependency list is ignored it means that the dependencies given will not attempt to trigger a new page view.
 * @param d The dependency list to check
 * @returns true if the dependencies should be ignored entirely.
 */
function ignoreDependencies(d: DependencyList | null | undefined) {
	if (typeof d === 'undefined' || d === null || d.length === 0) {
		return true
	}
	return false
}

/**
 * This hook will setup analytics such that the data that is common across all pages will be updated in the
 * data layer then, a callback (given) will be called just before the pageView event is fired.  The user should
 * use this callback to set additional page-specific data in the data layer (using the given Analytics object).
 *
 * IMPORTANT: This should only be used once per page.
 *
 * @param properties A series of properties that allows the page to tweak the way analytics is used
 * @param dependencies If given, this will be used to trigger a new page view event when the dependencies change
 * @param readonlySearchParams Must be provided if search params are expected (via `useSearchParams()`)
 * @returns
 */
export function useAnalyticsSetup(
	properties: AnalyticsHookProperties,
	dependencies?: DependencyList,
	readonlySearchParams?: ReadonlyURLSearchParams,
): AnalyticsHookReturnValue {
	const { user: userSession } = useSession()
	const { cart: cartObject } = useCart()
	const interpolatedPathName = usePathname()
	// canonicalPath will not include search params unless `readonlySearchParams` has been provided
	const { canonicalPath } = useNavigation({ readonlySearchParams })
	const locale = useLocale()

	const formatMessage = useFormatMessage()

	// TODO: Can we safely remove this? This requires useNavigation/useSearchParams
	// Resulting in: "Entire page deopted into client-side rendering"
	// Whenever useAnalytics is used. ie. layout providers
	// const { welcomeMatData } = useWelcomeMat()

	const shipToCountry = formatMessage(
		`country-${
			getShippingAddress(cartObject)?.countryCode.toUpperCase() ??
			// welcomeMatData?.shipToCountry.toUpperCase() ??
			getCountryCodeByLocale(locale).toUpperCase()
		}`,
	).toString()

	// We use this in the case where a page has paused page view event firing
	//	but an event *should* have been fired.  If something changes, then we
	//	use this to determine that we should be firing the event as soon as
	//	allowed.
	const waitingForAllowPageView = useRef(false)

	// We use this as a way to prevent double firing of pageView events.  It shouldn't
	//	be necessary but there are differences in dev vs prod builds in terms of how
	//	useEffect callbacks are called (they are called twice even when given an empty
	//	dependency list).
	const activePath = useRef<string | null>('')

	// Indicates that a different URL is being invoked (this could mean that it's the same page component - use isChangingPage to determine if the page is changing)
	// canonicalPath replaces legacy router.asPath as it includes query params to signal different URL
	const isChangingRoute = () => canonicalPath !== activePath.current

	/**
	 * This section is used to update the page details - it is sensitive to the
	 * path name.  When the router object changes, we get the path from the router
	 * object and split it into categories based on the path segments.
	 */
	const setupDataLayer = useCallback(() => {
		const siteData = analytics.getSiteData(locale)
		const cartData = analytics.getCartData(cartObject)
		const userData = userSession ? analytics.getUserData(userSession, false) : null

		analytics.setPageData({
			...emptyDataLayer,
			app_version: UaConfigPublicImpl.appVersion,
			full_url: window.location.href,
			page_meta_path: getNonLocaleURLSegments(interpolatedPathName).join('/'),
			page_name: properties.page_name,
			page_type: properties.page_type,
			site_section: properties.site_section,
			page_category: properties.pageCategory[0] || undefined,
			page_subcategory1: properties.pageCategory[1] || undefined,
			page_subcategory2: properties.pageCategory[2] || undefined,
			page_subcategory3: properties.pageCategory[3] || undefined,
			page_subcategory4: properties.pageCategory[4] || undefined,
			site_shipto_country: shipToCountry,
			...siteData,
			...cartData,
			...userData,
		})
	}, [properties, cartObject, locale, userSession, shipToCountry, interpolatedPathName])

	useEffect(() => {
		if (!isChangingRoute()) {
			// This updates global data that can change during the life of the page
			//  But only update it after the initial page view has been fired.  The
			//  initial values should be set when the hook is first called.
			const siteData = analytics.getSiteData(locale)
			const cartData = analytics.getCartData(cartObject)
			const userData = userSession ? analytics.getUserData(userSession, false) : null

			analytics.setPageData({
				site_shipto_country: shipToCountry,
				...siteData,
				...cartData,
				...userData,
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userSession, cartObject, locale, shipToCountry])

	const beginFiringPageView = (source: string) => {
		// eslint-disable-next-line testing-library/no-debugging-utils
		logger.info(`DL: Firing page view from source ${source}`)

		activePath.current = canonicalPath

		// If we got here then we are no longer waiting for the page
		//	to set the allowPageView prop to be set.
		waitingForAllowPageView.current = false

		// Initialize the data layer with the properties that are common
		//	across all pages.
		setupDataLayer()

		if (properties.beforePageView) {
			// Note: This gives the page to setup page data layer propreties that are specific to that page
			//  prior to the page view being fired.  Note that we are passing the props in here and the
			//	implemented callback should only use these props to populate the datalayer.  This is because
			//	there's no guarantee that the context in which the callback was first defined has the most
			//	up to date page data.
			properties.beforePageView(analytics, source)
		}

		analytics.firePageView()
	}

	const firePageView = (source: string) => {
		if (isChangingRoute()) {
			if (properties.allowPageView) {
				if (userSession) {
					// NOTE: We are in a route change AND the page has allowed
					//	to go ahead and fire the page view event.
					beginFiringPageView(source)
				} else {
					// NOTE: We are in a route change and we are not waiting
					//	for data but the user session has not yet been established
					//	so we need to wait for that.
				}
			} else {
				// NOTE: A route change is in progress but the page
				//	has set the fire page view property to false.  When that
				//	changes, this function will be called again (see the useEffect below)
				// console.info(`~~ Page View Not Allowed ~ from ${_source}`)
				waitingForAllowPageView.current = true
			}
		} else {
			// NOTE: No route change so ignoring request
			// console.info(`~~ Not in Route Change ~ from ${_source}`)
		}
	}

	const firePageViewFromNextRouteChange = () => {
		if (ignoreDependencies(dependencies)) {
			firePageView('routechange')
		}
	}

	const firePageViewFromAllowedPageViewChange = () => {
		firePageView('pageviewallowed')
	}

	const firePageViewFromDendencyChange = () => {
		if (!ignoreDependencies(dependencies)) {
			firePageView('dependencieschanged')
		}
	}

	/**
	 * This useEffect is specifically for catching the case where the user
	 * session is established after the page has loaded.
	 */
	useEffect(() => {
		if (userSession) {
			firePageView('sessionestablished')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userSession])

	/**
	 * This useEffect is specifically for catching the case where the user
	 * set the allowPageView to false then set it to true.  This is useful
	 * in cases where the page has loaded but hasn't yet hydrated.  The
	 * cart page and rewards locker pages that hydrate on the client only.
	 */
	useEffect(() => {
		if (waitingForAllowPageView.current) {
			firePageViewFromAllowedPageViewChange()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [properties.allowPageView])

	/**
	 * This useEffect is specifically for catching the case where something
	 * triggered a route change but the dependencies have not yet changed.
	 * This can happen if the user goes from one page type to the same page
	 * type but with different properties.  Going from one category page to
	 * another is a common example of this.  The dependency change can be triggered
	 * even in cases where the page hasn't changed.  To guard against this we
	 * validate that the currently "active" path is not the same as the one
	 * that the router object returns.  If they are different then we have not
	 * yet fired the page view for this url.
	 */
	useEffect(() => {
		firePageViewFromDendencyChange()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, dependencies)

	/**
	 * This useEffect is specifically for catching the case where the route
	 * has changed.  Note that a page view won't be fired if dependencies
	 * were given, allowPageView is set to false or if the path has
	 * not changed since the last firing.
	 */
	useEffect(() => {
		// the page has changed
		firePageViewFromNextRouteChange()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [interpolatedPathName])

	/**
	 * This useEffect is specifically for catching the case where a
	 * new page has been pushed onto the route stack, or if the user is
	 * closing the tab or navigating to another site (onUnload). This is to
	 * handle to let the analytics manager know that the user is
	 * transitioning to a new page to make sure that no events are
	 * fired during the transition before the page view event is fired, or
	 * no queued events remain in the cache when the user exits the site.
	 *
	 * For example, in the case where the user arrives on a page, but
	 * we haven't yet established the session, the page view event won't
	 * fire until the session is established.  In the meantime, we might
	 * show a modal or something that happens automatically regardless of the session
	 * state. In this case, we want to make sure that the page view event is fired
	 * *before* the click event is fired or we will get some unexpected behavior
	 * in our analytics.
	 *
	 * NOTE: If you are wondering why we don't just use the router event handling
	 * in other parts of this hook, it's because the timing of the router event
	 * handlers is not super reliable.  In this case, though, we don't need to have it
	 * happen at a specific time, it just needs to happen.
	 */
	useEffect(() => {
		const handlePageChanging = () => analytics.exitPage()

		window?.addEventListener('popstate', handlePageChanging)
		window?.addEventListener('beforeunload', handlePageChanging)
		return () => {
			window?.removeEventListener('popstate', handlePageChanging)
			window?.removeEventListener('beforeunload', handlePageChanging)
		}
	})
	return {
		analyticsManager: analytics,
		waitingForAllowPageView: waitingForAllowPageView.current,
	}
}
