import * as jose from 'jose'
import { getPublicConfig } from '~/lib/client-server/config'
import logger from '~/lib/logger'
import { formatUrl } from '../../utils'
import type { UacapiTokenResponse } from '~/lib/types/uacapi.interface'
import { zodParseBodyJson } from '~/lib/schemas/utils'
import { UacapiTokenResponseSchema } from '~/lib/schemas/uacapi'
import { getSiteCodeByLocale } from '~/lib/i18n/locale'

export interface UacapiRegisteredAuthOptions {
	accessToken: string
	refreshToken: string
	accessTokenExpiration: string
	locale: string
	previousToken?: string
}

// Versioning
const uaClientType = `${getPublicConfig().app.name}/${getPublicConfig().app.version}`

const uacapiHost = getPublicConfig().uacapi.url

interface DecodedUacapiToken {
	payload: jose.JWTPayload
	subjectDecoded: {
		site: string
		customerNo: string
		guest: boolean
	}
}
// Decodes the UACAPI access token to retrieve user info from subject
export function decodeUacapiToken(accessToken: string): DecodedUacapiToken | undefined {
	try {
		const decoded = jose.decodeJwt(accessToken)
		if (decoded?.sub) {
			return {
				payload: decoded,
				subjectDecoded: JSON.parse(decoded.sub),
			}
		}
	} catch (_) {
		// Ignore and default to undefined
	}

	return undefined
}

type AuthRequestHeaders = {
	'Content-Type': string
	uaClientType: string
	'ua-site-code': string
	'ua-language-code': string
	Authorization?: string
}

const mapAuthHeaders = async ({
	locale,
	previousToken,
}: {
	locale: string
	previousToken?: string
}): Promise<AuthRequestHeaders> => {
	const [lang, site] = locale.split('-')
	// common header properties shared across all auth requests
	let headers: AuthRequestHeaders = {
		'Content-Type': 'application/json',
		uaClientType,
		'ua-language-code': lang.toLocaleLowerCase(),
		'ua-site-code': site.toUpperCase(),
	}

	// prioritize previous ucapi tokens for Authorization
	if (previousToken) {
		headers = {
			...headers,
			Authorization: `Bearer ${previousToken}`,
		}
	}

	return headers
}

export async function uacapiAuthGuest({
	locale,
	previousToken,
}: {
	locale: string
	previousToken?: string
}): Promise<UacapiTokenResponse> {
	const headers = await mapAuthHeaders({ locale, previousToken })
	const res = await fetch(
		formatUrl({
			host: uacapiHost,
			pathname: '/auth',
		}),
		{
			method: 'POST',
			headers,
			body: JSON.stringify({
				type: 'guest',
				site: getSiteCodeByLocale(locale),
			}),
		},
	)
	if (res.status !== 200) {
		throw new Error('[Authentication] Guest session authorization error')
	}

	const respJson = await zodParseBodyJson(UacapiTokenResponseSchema, res)

	return {
		...respJson,
		cookies: res.headers.get('set-cookie') ?? undefined,
	}
}

export async function uacapiAuthRegistered({
	accessToken,
	refreshToken,
	accessTokenExpiration,
	previousToken,
	locale,
}: UacapiRegisteredAuthOptions): Promise<UacapiTokenResponse> {
	const headers = await mapAuthHeaders({ locale, previousToken })
	const res = await fetch(
		formatUrl({
			host: uacapiHost,
			pathname: '/auth',
		}),
		{
			method: 'POST',
			headers,
			body: JSON.stringify({
				type: 'UASSO',
				site: getSiteCodeByLocale(locale),
				accessToken,
				refreshToken,
				accessTokenExpiration,
			}),
		},
	)
	if (res.status !== 200) {
		logger.error(`Registered session authorization error ${res.statusText}`)
		throw new Error('Registered session authorization error')
	}

	const respJson = await zodParseBodyJson(UacapiTokenResponseSchema, res)

	return {
		...respJson,
		cookies: res.headers.get('set-cookie') ?? undefined,
	}
}

export async function uacapiLogout(accessToken: string): Promise<void> {
	const res = await fetch(
		formatUrl({
			host: uacapiHost,
			pathname: '/auth',
		}),
		{
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${accessToken}`,
				uaClientType,
			},
		},
	)
	if (res.status !== 204) {
		throw new Error('Error logging out')
	}
}
