

export default {
  "cms": {
    "coremedia_preview_graphql_url": "https://caas-preview.production.ua.coremedia.cloud/graphql",
    "coremedia_graphql_url": "https://caas.production.ua.coremedia.cloud/graphql",
    "scene7_base_url": "https://underarmour.scene7.com/is/image/Underarmour",
    "preview_access_token": "JzdWIiOiIxMjM0NTY3"
  },
  "returns": {
    "labels_environment": "production"
  },
  "metrics": {
    "dd_client_token": "pubf3bdad554f52f41cb13f4cfd287ed91e",
    "dd_aplication_id": "ab04713b-93da-44f0-87e2-61a420552b4e",
    "dd_rum_enabled": true,
    "dd_server_logging_enabled": true,
    "dd_replay_enabled_locales": [],
    "dd_session_replay_sample_rate": 0,
    "dd_session_sample_rate": 100,
    "dd_environment": "production",
    "debug": false,
    "ga_measurement_id": "G-3S96BP4YG0",
    "bb_adobe_channel_manager_enabled": false,
    "bb_adobe_report_suite_name": "",
    "bb_enabled": true,
    "bb_dual_tagging_enabled": true,
    "queuing_enabled": true
  },
  "app": {
    "name": "seabiscuit",
    "version": "2.29.0"
  },
  "uacapi": {
    "debug": false,
    "url": "https://api.shop.ua.com",
    "max_retry_count": 3,
    "unauth_handler_timeout": 10000
  },
  "idme": {
    "enabled": true,
    "client_id": "693380ec2b8507ae5815c5ad1d4c628f"
  },
  "paymetric": {
    "sandbox_url": "https://tokens.underarmour.com"
  },
  "paypal": {
    "client_id": "AQIc8P6C0TfHSbJKpepYYONyfdb6GkbfsaYlNzHigp-wy7Nq9aEOeoxw-mOMeBXabqIli8u7pwnK9hmv"
  },
  "curalate": {
    "site_name": "underarmour-mjqrqa",
    "url": "https://edge.curalate.com/v1/media/",
    "product_source_id": "WsiANDqaliuzmiDm",
    "category_source_id": "cDCTEvCdmpZdysVf"
  },
  "athletepicks": {
    "enabled": true
  },
  "preferredsizes": {
    "locales": [
      "en-us"
    ]
  },
  "loyaltynav": {
    "enabled": true
  },
  "target": {
    "enabled": true,
    "timeout_ms": 2000
  },
  "tealium": {
    "profile": "seabiscuit-na",
    "env": "prod"
  },
  "bazaarvoice": {
    "env": "production"
  },
  "uat": {
    "enabled": false,
    "form_url": "https://forms.office.com/Pages/ResponsePage.aspx?id=vM5VctCEwEeXQLABzqr35UaEdg3EHUFNtFqgq8F6MB5UMjlYSUY3VlFONVBQNEZVVVFLM1JDWjM1Qy4u&embed=true",
    "split_linking": [
      "account",
      "content"
    ]
  },
  "storybook": {
    "enabled": false
  },
  "shoprunner": {
    "enabled": true,
    "retailer_id": "UARM",
    "api_endpoint": "https://apiv2.shoprunner.com",
    "env": "production",
    "local_endpoint": ""
  },
  "toolkit": {
    "enabled": false,
    "salesforce_toolkit_url": ""
  },
  "borderfree": {
    "url": "https://bfx-objects.prd.borderfree.com/v1/dist/bfx.js",
    "key": "41a1f990-a119-11ea-9767-f9dfebd38fce",
    "env": "PROD",
    "mode": "default",
    "brand": "default",
    "log_level": "ERROR",
    "enabled": false
  },
  "experian": {
    "token": "be4a810a-1c26-4115-bae1-aa9263d7dd01"
  },
  "uaapps": {
    "shop": {
      "locales": [
        "en-us"
      ]
    },
    "mapmy": {
      "locales": [
        "en-us",
        "en-ca",
        "fr-ca"
      ]
    }
  },
  "i18n": {
    "default_locale": "en-us",
    "default_domain": "www.underarmour.com",
    "locales": [
      {
        "locale": "en-us",
        "build": {
          "pages": [
            "plp",
            "homepage",
            "search",
            "landing",
            "header",
            "error",
            "checkout",
            "cart",
            "account"
          ]
        }
      },
      {
        "locale": "en-ca",
        "build": {
          "pages": [
            "homepage",
            "search",
            "landing",
            "header",
            "error",
            "checkout",
            "cart",
            "account"
          ]
        }
      },
      {
        "locale": "fr-ca",
        "build": {
          "pages": [
            "homepage",
            "search",
            "landing",
            "header",
            "error",
            "checkout",
            "cart",
            "account"
          ]
        }
      },
      {
        "locale": "es-mx",
        "build": {
          "pages": []
        }
      }
    ],
    "domains": [
      {
        "domain": "www.underarmour.com.mx",
        "locales": [
          "es-mx"
        ],
        "default_locale": "es-mx",
        "http": false
      },
      {
        "domain": "www.underarmour.com",
        "locales": [
          "en-us"
        ],
        "default_locale": "en-us",
        "http": false
      },
      {
        "domain": "www.underarmour.ca",
        "locales": [
          "en-ca",
          "fr-ca"
        ],
        "default_locale": "en-ca",
        "http": false
      }
    ]
  },
  "accertify_url": "https://prod.accdab.net/cdn/cs/SmW2R1pQgEUeyYdOBrfAHyUeR04.js",
  "credit_cards_accepted": {
    "en-ca": [
      "amex",
      "visa",
      "mastercard"
    ],
    "fr-ca": [
      "amex",
      "visa",
      "mastercard"
    ],
    "en-us": [
      "amex",
      "visa",
      "mastercard",
      "discover"
    ]
  },
  "credit_card_processor": {
    "en-us": "aurus",
    "en-ca": "paymetric",
    "fr-ca": "paymetric"
  },
  "cio": {
    "service_url": "https://ezeubuswo-zone.cnstrc.com/",
    "network_timeout": 2000,
    "category_data_source": 1,
    "index_keys": {
      "en-ca": "key_MbFu7hkkn75UIUIA",
      "fr-ca": "key_sF7rHdZLhaeYJT0p",
      "en-us": "key_Gz4VzKsXbR7b7fSh"
    }
  },
  "billing_countries_accepted": {
    "en-us": [
      "US",
      "CA",
      "AT",
      "BE",
      "DK",
      "FR",
      "DE",
      "GB",
      "IE",
      "IT",
      "NL",
      "ES",
      "SE"
    ],
    "fr-ca": [
      "CA"
    ],
    "en-ca": [
      "CA"
    ]
  },
  "apple_pay": {
    "locales": [
      "en-us"
    ]
  },
  "allow_cancel_orders": {
    "locales": [
      "en-us"
    ]
  },
  "emails": {
    "vip": "UAVIP@underarmour.com"
  },
  "phones": {
    "customer_service": "1-888-727-6687"
  },
  "gift_cards": {
    "pdp_enabled_locales": [
      "en-us"
    ]
  },
  "search": {
    "default_suggestions": {
      "en-us": {
        "mens": "/c/mens",
        "womens": "/c/womens-all",
        "boys": "/c/boys",
        "girls": "/c/girls",
        "new-arrivals": "/c/new-arrivals",
        "best-sellers": "/c/best-sellers"
      },
      "fr-ca": {
        "mens": "/c/hommes",
        "womens": "/c/femmes",
        "boys": "/c/garcons",
        "girls": "/c/filles",
        "new-arrivals": "/c/nouveautes",
        "best-sellers": "/c/produits-favoris"
      }
    }
  },
  "chat": {
    "enabled_locales": [
      "en-us",
      "es-mx"
    ]
  },
  "evergage": {
    "data_set": "ua_prod_sku"
  },
  "okta": {
    "client_id": "3da6dbce-ce3f-4ce3-8b9c-e2424d64da49"
  },
  "qualtrics": {
    "post_purchase_project_id": "ZN_cvutHDJuneYjzUi"
  },
  "accepted_currency": {
    "en-us": "USD",
    "en-ca": "CAD",
    "fr-ca": "CAD",
    "en-nl": "EUR"
  },
  "pdp": {
    "has_enhanced_images": null,
    "has_enhanced_product_name": null,
    "has_enhanced_buy_panel": null
  },
  "flagsmith": {
    "revalidation_interval": 1200
  }
}
    