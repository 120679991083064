import ConstructorIOClient from '@constructor-io/constructorio-client-javascript'
import type { UserWithSession } from '~/lib/client-only/auth/types'
import { getPublicConfig } from '~/lib/client-server/config'
import { getDefaultLocale } from '~/lib/i18n/locale'

let cachedCioClient: ConstructorIOClient | null = null
let cachedEmail: string | null = null

/**
 * Builds a browser-side ConstructorIO Client.  Unlike the server client, the browser client
 * does not require a security token but it does include user information to get personalized data back.
 *
 * User data is not available on the server.
 * @returns
 */
export function getConstructorClientForBrowser({
	locale,
	user,
}: {
	locale: string
	user?: UserWithSession | null
}): ConstructorIOClient {
	const indexedLocale = getPublicConfig().cio.index_keys[locale] ? locale : getDefaultLocale()

	const userEmail = user?.email || null

	if (!cachedCioClient || cachedEmail !== userEmail) {
		const userId = user?.hashedEmail

		cachedCioClient = new ConstructorIOClient({
			apiKey: getPublicConfig().cio.index_keys[indexedLocale],
			serviceUrl: getPublicConfig().cio.service_url,
			...(userId && { userId }),
		})

		cachedEmail = userEmail
	}

	return cachedCioClient
}
