// Adapted from https://observablehq.com/@mbostock/localized-number-parsing
export function parseNumber(number: string, locale: string): number {
	const parts = new Intl.NumberFormat(locale).formatToParts(12345.6)
	const numerals = new Intl.NumberFormat(locale, { useGrouping: false }).format(9876543210).split('').reverse()
	const index = new Map(numerals.map((d, i) => [d, i]))
	const group = parts.find((d) => d.type === 'group')?.value
	// Some locales use spaces as thousands separators. We need to translate those spaces to regex
	const groupRegex = new RegExp(`[${group?.trim() === '' ? '\\s' : group}}]`, 'g')
	const decimalRegex = new RegExp(`[${parts.find((d) => d.type === 'decimal')?.value}]`)
	const numeralRegex = new RegExp(`[${numerals.join('')}]`, 'g')
	const parsedNumber = number
		.trim()
		.replace(groupRegex, '')
		.replace(decimalRegex, '.')
		.replace(numeralRegex, (d) => `${index.get(d)}`)

	return parsedNumber ? +parsedNumber : NaN
}
