import { getPublicConfig } from '../client-server/config'

export interface ConfigFeatureFlags {
	hasPdpEnhancedImages?: boolean
	hasPdpEnhancedProductName?: boolean
}

export function getConfigFeatureFlags() {
	const configFlags = {
		hasPdpEnhancedImages: getPublicConfig().pdp.has_enhanced_images ?? undefined,
		hasPdpEnhancedProductName: getPublicConfig().pdp.has_enhanced_product_name ?? undefined,
	}

	Object.keys(configFlags).forEach((key) => configFlags[key] === undefined && delete configFlags[key])
	return configFlags
}
