import type { GiftCardBalanceResponse } from '~/lib/types/api.interface'
import { GiftCardBalanceResponseSchema } from '~/lib/schemas/api'

export async function getGiftCardBalance({
	cardNumber,
	pin,
	locale,
	token,
}: {
	cardNumber: string
	pin: string
	locale: string
	token: string
}): Promise<GiftCardBalanceResponse> {
	const resp = await fetch('/api/gift-card-balance/', {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			cardNumber,
			pin,
			locale,
		}),
	}).then((response) => response.json())

	return GiftCardBalanceResponseSchema.parse(resp)
}

export function getGiftCardErrorMessage(code: unknown) {
	switch (code) {
		case 2:
		case 3:
		case 4:
		case 5:
		case 6:
		case 8:
		case 30:
		case 45:
		case 58:
		case 73:
			return 'gift-card-balance-error'
		case 1:
		case 10:
		case 11:
		case 12:
		case 17:
		case 18:
		case 31:
		case 37:
		case 38:
		case 42:
		case 50:
		case 55:
		case 56:
		case 70:
		case 71:
		case 72:
		case 74:
			return 'gift-card-balance-error-funds'
		case 9:
		case 27:
		case 28:
		case 29:
		case 32:
		case 34:
		case 35:
		case 36:
		case 41:
		case 44:
		case 59:
		case 67:
		case 68:
		case 69:
		case 75:
			return 'gift-card-balance-error-default'
		default:
			return 'gift-card-balance-error'
	}
}

export default { getGiftCardErrorMessage }
